import { useQuery, QueryHookOptions } from '@apollo/react-hooks';
import { OperationVariables } from 'apollo-client';
import { DocumentNode } from 'graphql';
import { navigate } from '@reach/router';
import ROUTES from '../routes';

const defaultOnError = () => {
  return navigate(ROUTES.ERROR);
};

export default function usePgeQuery<
  TData = any,
  TVariables = OperationVariables
>(query: DocumentNode, options: QueryHookOptions<TData, TVariables> = {}) {
  const { errorPolicy } = options;
  const ignoreErrors = errorPolicy === 'all' || errorPolicy === 'ignore';
  const onError =
    options.onError || (ignoreErrors ? undefined : defaultOnError);
  return useQuery<TData, TVariables>(query, {
    ...options,
    onError,
  });
}
