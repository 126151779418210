import TokenStorage from '../AuthenticationService/TokenStorage';
import { AuthState } from '../AuthenticationService/types';
import { AuthAdapterInstallers } from './types';

class AuthService {
  listeners: { (changes: any): any }[] = [];
  private refreshInterval: NodeJS.Timeout | undefined;
  private state: AuthState = {
    isAuthenticated: false,
    isLoading: true,
    error: undefined,
    tokens: undefined,
  };

  constructor(
    private authAdapter: AuthAdapterInstallers,
    public tokensStorage: TokenStorage,
  ) {
    try {
      if (!this.tokensStorage.hasTokens() || this.tokensStorage.isExpired()) {
        throw new Error('Sign In required');
      }
      this.setState({
        isLoading: false,
        isAuthenticated: true,
        tokens: this.tokensStorage.get(),
      });
    } catch (error) {
      this.setState({ isLoading: false, error: error as Error });
      this.signOut();
    }
  }

  getState(): AuthState {
    return this.state;
  }

  startTokenRefresher(): void {
    if (!this.refreshInterval) {
      this.refreshInterval = setInterval(async () => {
        try {
          if (this.tokensStorage.isExpiring()) {
            console.log('refreshing installer session');
            await this.refreshTokens();
          }
        } catch (_error) {
          this.signOut();
        }
      }, 10000);
    }
  }

  stopTokenRefresher(): void {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  }

  async signInWithPassword(email: string, password: string): Promise<void> {
    this.setState({ isLoading: true });
    const tokens = await this.authAdapter.signInWithPassword(email, password);
    this.tokensStorage.set(tokens);
    this.setState({
      tokens,
      isAuthenticated: true,
      isLoading: false,
      error: undefined,
    });
  }

  async signInWithEmailLink(email: string): Promise<void> {
    this.setState({ isLoading: true });
    const tokens = await this.authAdapter.signInWithEmailLink(email);
    this.tokensStorage.set(tokens);
    this.setState({
      tokens,
      isAuthenticated: true,
      isLoading: false,
      error: undefined,
    });
  }

  async refreshTokens() {
    const tokens = this.tokensStorage.get();
    const newTokens = await this.authAdapter.refreshTokens(
      tokens.refreshToken!,
    );
    this.tokensStorage.set(newTokens);
    this.setState({ tokens: newTokens, isAuthenticated: true });
  }

  signOut() {
    this.tokensStorage.clear();
    this.setState({ isAuthenticated: false, tokens: undefined });
  }

  private setState(changes: Partial<AuthState>): void {
    this.state = { ...this.state, ...changes };
    this.listeners.forEach(cb => cb(this.state));
  }

  subscribe(cb: (state: AuthState) => any): () => void {
    cb(this.state);
    this.listeners = [...this.listeners, cb];
    return () => {
      this.listeners = this.listeners.filter(listener => listener !== cb);
    };
  }
}
export default AuthService;
