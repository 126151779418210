import React, { useState, useEffect } from 'react';
import { Alert } from '@material-ui/lab';
import Info from '@material-ui/icons/Info';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../themes/main-colors';
import { Collapse, Typography } from '@material-ui/core';
import { useTranslation } from '../../hooks/useTranslation';
import { getFromStorage, removeFromStorage } from '../../util/storage-utils';
import {
  LANGUAGE_PREFERENCE_CHANGED,
  SupportedLanguages,
  getLanguage,
} from '../../providers/LanguageProvider';
import useAuth from '../../hooks/useAuth';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    languagePreferenceAlert: {
      fontWeight: 'bold',
      fontSize: '20px',
      margin: '5px',
      padding: '0px 10px',
      backgroundColor: colors.info,
      flexDirection: 'row',
      [theme.breakpoints.down('sm')]: {
        margin: '5px',
        borderRadius: 0,
      },
      '& .MuiAlert-action': {
        [theme.breakpoints.down('sm')]: {
          fontSize: '25px',
          display: 'unset',
        },
      },
    },
    languagePreferenceAlertInfoIcon: {
      fontSize: '30px',
      '& path': {
        transform: 'scale(1.25) translateX(-2.5px) translateY(-2.5px)',
      },
      fill: colors.darkBlue,
      background: colors.white,
      borderRadius: '50%',
    },
    languagePreferenceAlertLink: {
      color: colors.white,
    },
    languagePreferenceAlertMessage: {
      marginTop: 0,
      marginBottom: 0,
      fontWeight: 'bold',
    },
  }),
);

export const LanguagePreferenceAlert = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const { isAuthenticated } = useAuth();
  const preferenceChanged = getFromStorage(LANGUAGE_PREFERENCE_CHANGED);

  useEffect(() => {
    if (preferenceChanged && getLanguage() === SupportedLanguages.Spanish) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [preferenceChanged]);

  const handleAlertClose = () => {
    removeFromStorage(LANGUAGE_PREFERENCE_CHANGED);
    setOpen(false);
  };

  return !isAuthenticated ? (
    <Collapse in={open} data-testid="language-preference-alert">
      <Alert
        icon={<Info className={classes.languagePreferenceAlertInfoIcon} />}
        variant="filled"
        className={classes.languagePreferenceAlert}
        onClose={handleAlertClose}
      >
        <Typography
          variant={'body1'}
          className={classes.languagePreferenceAlertMessage}
          data-testid="language-preference-alert-desc"
        >
          {t('LANGUAGE_PREFERENCE_ALERT_MESSAGE')}
        </Typography>
      </Alert>
    </Collapse>
  ) : null;
};
