import { navigate } from '@reach/router';
import { ApolloClient } from 'apollo-client';
import { InMemoryCache, defaultDataIdFromObject } from 'apollo-cache-inmemory';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import fetch from 'isomorphic-fetch';
import ROUTES from '../routes';
import { getFromStorage } from '../util/storage-utils';
import authService from './authService';
import { local } from './storage';
import config from '../config';
import authServiceInstaller from './authServiceInstaller';
import { TokenStorage } from './AuthenticationService';

function resolveId(obj: any) {
  if (!obj) {
    return null;
  }
  switch (obj.__typename) {
    case 'AccountCustomer':
      return obj.uid;
    case 'AccountDetail':
      return obj.accountNumber;
    case 'Group':
      return obj.groupId;
    default:
      return null;
  }
}

export const cache = new InMemoryCache({
  dataIdFromObject: obj => {
    const id = resolveId(obj);
    if (id !== null) {
      return `${obj.__typename}:${id}`;
    }
    return defaultDataIdFromObject(obj);
  },
  cacheRedirects: {
    Query: {
      getAccountDetails: (_, args, { getCacheKey }) =>
        args?.params?.accountNumberList?.map((item: any) =>
          getCacheKey({
            __typename: 'AccountDetail',
            accountNumber: item?.accountNumber,
          }),
        ),
    },
  },
});

export const SELECTED_GROUP_ID = 'selectedGroupId';
export const SELECTED_ACCOUNT_PARAMS = 'selectedAccountParams-v2';

function getDefaultCacheData() {
  const selectedGroupId = getFromStorage(SELECTED_GROUP_ID);
  const stringifiedSelectedAccountParams = getFromStorage(
    SELECTED_ACCOUNT_PARAMS,
  );
  const selectedAccountParams = stringifiedSelectedAccountParams
    ? JSON.parse(stringifiedSelectedAccountParams)
    : null;

  return {
    selectedGroupId,
    selectedAccountParams,
  };
}

function writeDefaultCache() {
  cache.writeData({ data: getDefaultCacheData() });
}

const httpLinkPge = createHttpLink({
  uri: config.graphql.baseUrl,
  fetch: fetch,
});

const httpLinkPgePlus = createHttpLink({
  uri: config.graphql.baseUrlPgePlus,
  fetch,
  headers: {
    'x-api-version': 'pgeplus',
  },
});

const httpLink = ApolloLink.split(
  operation => !!operation.getContext().pgePlus,
  httpLinkPgePlus,
  httpLinkPge,
);

const getHeaderWithAccessToken = (headers: any, tokenStorage: TokenStorage) => {
  let token;
  try {
    token = tokenStorage.get()?.accessToken?.token;
  } catch (error) {
    console.warn(error);
  }

  //return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
};

const authLinkPge = setContext((_, { headers }) => {
  return getHeaderWithAccessToken(headers, authService.tokensStorage);
});

const authLinkInstaller = setContext((_, { headers }) => {
  return getHeaderWithAccessToken(headers, authServiceInstaller.tokensStorage);
});

const authLink = ApolloLink.split(
  operation => !!operation.getContext().installerPortal,
  authLinkInstaller,
  authLinkPge,
);

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  }
  const context = operation.getContext();
  const status = context?.response?.status;

  if (status === 403 && !context?.installerPortal) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      authService.signOut();
      local.clear();
      await navigate(ROUTES.SIGN_IN);
    })();
  }

  if (status === 403 && context?.installerPortal) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    (async () => {
      authServiceInstaller.signOut();
      await navigate(ROUTES.PGE_PLUS_INSTALLER_PORTAL_SIGN_IN);
    })();
  }
});

export const apolloClient = new ApolloClient({
  cache,
  resolvers: {},
  link: ApolloLink.from([errorLink, authLink, httpLink]),
});

apolloClient.onResetStore(async () => {
  writeDefaultCache();
});

writeDefaultCache();

export default apolloClient;
