import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import PgeButton from '../../pge-button/_PgeButton';
import RichText, { richTextStyles } from '../../rich-text';
import useAttrs from '../../../hooks/useAttrs';
import { HeaderVariant, Omits, ProgramCardProps as Props } from './types.d';
import useStyles from './ProgramCard.styles';
import colors from '../../../themes/main-colors';

const getBackgroundColor = (color: any | null): any | null => {
  let bgColor = colors.white;
  switch (color) {
    case 'Blue':
      bgColor = colors.riverBlue;
      break;
    case 'Grey':
      bgColor = colors.cloudGrey2;
      break;
    case 'Linen-White':
      bgColor = colors.linenWhite;
      break;
    case 'Yellow':
      bgColor = colors.lightYellow;
      break;
    default:
      bgColor = colors.white;
      break;
  }
  return bgColor;
};

function ProgramCard({
  heading,
  headingType,
  headingLink,
  leadStatement,
  shortDescription,
  image,
  ctaButton,
  ctaButtonLink,
  supportingCta,
  supportingCtaLink,
  programBadge,
  colorBackground,
  column,
  fullWidth,
  ...props
}: Props): ElementNode {
  // Default props
  const _badge = programBadge || 'None';
  const _color = colorBackground || 'None';
  const _column = column || '1';
  const _type = ctaButton?.buttonType || 'Primary';

  // Vars
  const hasBadge = _badge !== 'None';
  const badgeType = _badge.replace(/\s/g, '');
  const withContent = shortDescription ? 'with-Content' : '';
  const richText = richTextStyles();
  const { className = '', ...attrs } = useAttrs<Props>(props, Omits);

  const classes = useStyles({
    isSingleColumn: _column === '1' || fullWidth ? true : false,
    bgColor: getBackgroundColor(_color),
  });

  const getSubHeaderTag = (header: HeaderVariant): HeaderVariant => {
    const headerNumber = parseInt(header.replace('h', ''));

    return `h${headerNumber + 1}` as HeaderVariant;
  };

  const mainHeaderTag =
    !headingType || !/^h[1-4]$/.test(headingType)
      ? ('h2' as HeaderVariant)
      : (headingType as HeaderVariant);
  const subHeaderTag = getSubHeaderTag(mainHeaderTag);

  return (
    <article
      className={`ProgramCard col-${_column} ${classes.root} ${className}`}
      aria-label={heading || undefined}
      {...attrs}
    >
      {hasBadge && (
        <Box className={`${classes.badgeContainer} type-${badgeType}`}>
          <Typography
            className={`${classes.badge} type-${badgeType}`}
            component="p"
            variant="subtitle2"
          >
            <small>{_badge}</small>
          </Typography>
        </Box>
      )}

      <Grid container className={classes.cardContainer}>
        {image?.file?.url && (
          <picture className={classes.image}>
            <img src={image.file.url} alt="" width="100" height="100" />
          </picture>
        )}
        <div className={classes.content}>
          {heading && (
            <Typography variant={mainHeaderTag}>
              {headingLink ? (
                <PgeButton link={headingLink} display="Inline" theme="Text">
                  {heading}
                </PgeButton>
              ) : (
                heading
              )}
            </Typography>
          )}
          {leadStatement && (
            <Typography variant={subHeaderTag} className="sub-header">
              {leadStatement}
            </Typography>
          )}
          <RichText className={`RichText ${richText.styles}`}>
            {shortDescription}
          </RichText>
          {ctaButtonLink && (
            <PgeButton
              className={`${classes.cta} ${withContent}`}
              link={ctaButtonLink}
              theme={_type}
            >
              {ctaButton?.buttonText}
            </PgeButton>
          )}
          {supportingCtaLink && (
            <PgeButton
              className={classes.support}
              link={supportingCtaLink}
              theme="Text"
            >
              {supportingCta?.buttonText}
            </PgeButton>
          )}
        </div>
      </Grid>
    </article>
  );
}

export default ProgramCard;
