import Backdrop from '@material-ui/core/Backdrop';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { TransitionContext } from '../../providers/TransitionProvider';
import ColorBlendSpinner from '../spinner/ColorBlendSpinner';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 9999,
      color: '#fff',
      fontSize: '1.5em',
      fontWeight: 'bold',
      textAlign: 'center',
      padding: theme.spacing(2),
    },
  }),
);

export default (props: { forceOpen?: boolean; message?: string }) => {
  const classes = useStyles();

  return (
    <TransitionContext.Consumer>
      {(value: { state: { open: boolean; message: string } }) => {
        const { open, message } = value.state || {};
        return (
          <Backdrop
            className={classes.backdrop}
            open={!!props.forceOpen || !!open}
          >
            <ColorBlendSpinner>{props.message || message}</ColorBlendSpinner>
          </Backdrop>
        );
      }}
    </TransitionContext.Consumer>
  );
};
