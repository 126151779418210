import { DeepPartial } from '../util/types';
import z, { boolean as Boolean, object as Object, string as String } from 'zod';

// Hybrid is a combination of firebase and apigee
// use 'Firebase' for local api integration (does not use Apigee)
const AuthType = z.literal('Hybrid').or(z.literal('Firebase'));

const ConfigSchema = Object({
  env: String(),
  googleTagManager: Object({
    oPowerWidgetCode: String(),
  }),
  storageKey: String(),
  graphql: Object({
    baseUrl: String(),
    baseUrlPgePlus: String(),
  }),
  apigee: Object({
    baseUrl: String(),
    auth: Object({
      clientId: String(),
    }),
    widgetAuth: Object({
      clientId: String(),
    }),
  }),
  apm: Object({
    active: Boolean(),
    serverUrl: String(),
  }),
  auth: Object({
    type: AuthType,
  }),
  contentful: Object({
    host: String(),
    spaceId: String(),
    environmentName: String(),
    accessToken: String(),
  }),
  firebase: Object({
    key: String(),
    databaseURL: String(),
    reCaptchaSiteKey: String(),
    projectId: String(),
    appId: String(),
    installerTenentId: String(),
  }),
  legacySiteURL: String(),
  bizWebLegacyURL: String(),
  bizApiLegacyURL: String(),
  csApiLegacyURL: String(),
  salseforceEVFormURL: String(),
});

type Config = z.infer<typeof ConfigSchema>;

const rawConfig: DeepPartial<Config> = {
  env: process.env.GATSBY_ACTIVE_ENV,
  auth: {
    type:
      (process.env.GATSBY_AUTH_TYPE as z.infer<typeof AuthType>) || 'Hybrid',
  },
  googleTagManager: {
    oPowerWidgetCode: process.env.GATSBY_GTM_WORKSPACE_ID,
  },
  storageKey: 'p2s5u8x/A?D(G+KbPeShVmYq3t6w9y$B',
  graphql: {
    baseUrl: `${process.env.GATSBY_APIGEE_URL}/${process.env.GATSBY_APIGEE_PATH}`,
    baseUrlPgePlus: `${process.env.GATSBY_APIGEE_URL_PGEPLUS}/${process.env.GATSBY_APIGEE_PATH_PGEPLUS}`,
  },
  apigee: {
    baseUrl: process.env.GATSBY_APIGEE_URL,
    auth: {
      clientId: process.env.GATSBY_APIGEE_AUTH_CLIENT_ID,
    },
    widgetAuth: {
      clientId: process.env.GATSBY_APIGEE_WIDGETAUTH_CLIENT_ID,
    },
  },
  apm: {
    active: process.env.GATSBY_APM_ACTIVE === 'true',
    serverUrl: process.env.GATSBY_APM_URL,
  },
  contentful: {
    host: process.env.GATSBY_REALTIME_CONTENTFUL_HOST,
    spaceId: process.env.GATSBY_REALTIME_CONTENTFUL_SPACE_ID,
    environmentName: process.env.GATSBY_REALTIME_CONTENTFUL_ENVIRONMENT_NAME,
    accessToken: process.env.GATSBY_REALTIME_CONTENTFUL_ACCESS_TOKEN,
  },
  firebase: {
    key: process.env.GATSBY_FIREBASE_API_KEY,
    databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
    reCaptchaSiteKey: process.env.GATSBY_FIREBASE_RECAPTCHA_SITE_KEY,
    projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
    appId: process.env.GATSBY_FIREBASE_APP_ID,
    installerTenentId: process.env.GATSBY_FIREBASE_INSTALLER_TENENT_ID,
  },
  legacySiteURL: process.env.GATSBY_LEGACY_URL_SITE,
  bizWebLegacyURL: process.env.GATSBY_LEGACY_URL_BIZ_WEB,
  bizApiLegacyURL: process.env.GATSBY_LEGACY_URL_BIZ_API,
  csApiLegacyURL: process.env.GATSBY_LEGACY_URL_CS_API,
  salseforceEVFormURL: process.env.GATSBY_SALESFORCE_EV_FORM_URL,
};

const config = ConfigSchema.parse(rawConfig);

export default config;
