export const FileType = Object.freeze({
  SVG: /\.svg$/,
  JPG: /\.jpg$/,
  JPEG: /\.jpeg$/,
  PNG: /\.png$/,
  GIF: /\.gif$/,
  PDF: /.pdf$/,
  XLSX: /\.xlsx$/,
  DOCX: /\.docx$/,
  PPTX: /\.pptx$/,
  RTF: /\.rtf$/,
  MP4: /\.mp4$/,
  WMV: /\.wmv$/,
  MOV: /\.mov$/,
  IMG: /\.(svg|jpg|jpeg|png|gif|webp)$/,
  VID: /\.(mp4|wmv|mov|webm)$/,
  DOC: /\.(pdf|xlsx|docx|pptx|xlsm|xls)$/,
});

export const FileTypes = [
  '.svg',
  '.jpg',
  '.png',
  '.gif',
  '.pdf',
  '.xlsx',
  '.docx',
  '.pptx',
  '.rtf',
  '.mp4',
  '.wmv',
  '.mov',
  '.webp',
  '.webm',
] as const;

export const LinkType = Object.freeze({
  HTTP: /^http(?=s?:)/,
  INT: /^\/?.+/,
  EXT: /^http|mailto|tel|sms(?=s?:)/,
  TEL: /^tel:/,
  SMS: /^sms:/,
  MAIL: /^mailto:/,
});

export const MimeType = Object.freeze({
  APP: /^application\//,
  IMG: /^image\//,
  VID: /^video\//,
});

export const MimeTypes = ['application', 'image', 'video'] as const;

export type Asset = {
  File: typeof FileTypes[number];
  Mime: typeof MimeTypes[number];
};
