import { ComponentPropsWithoutRef } from 'react';
import { CardLayoutFragment } from '../../../__generated__/gatsby-types';
import { Field } from '../../../contentful.d';
import { ProgramCardProps } from '../../program-card';
import { Variant } from '@material-ui/core/styles/createTypography';

export const Omits = ['aria-label', 'style', 'colorBackground'] as const;

interface BaseProps
  extends CardLayoutFragment,
    ComponentPropsWithoutRef<'section'> {
  readonly columns?: Field['Column'];
  readonly addItems?: Array<Readonly<ProgramCardProps>>;
  readonly pageTemplate?: Field['Template'];
  readonly promoHeaderType?: string;
  readonly bgColor?: string;
}

type CardLayoutProps = Partial<Omit<BaseProps, typeof Omits[number]>>;

type HeaderVariant = Extract<Variant, 'h1' | 'h2' | 'h3' | 'h4'>;
