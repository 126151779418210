import { getFromStorage } from '../../../util/storage-utils';

enum AccountDashboardInterceptLocalStorageValues {
  REMIND_ME_LATER = 'remindMeLater',
  NEVER_SHOW_AGAIN = 'neverShowAgain',
}

const ACCOUNT_DASHBOARD_INTERCEPT_MODAL_STORAGE_KEY =
  'accountDashboardInterceptModalStorageKey';

const RESET_ACCOUNT_DASHBOARD_INTERCEPT_MODAL_STORAGE_KEY =
  'resetAccountDashboardInterceptModal';

const localStorageKeysExists = () =>
  getFromStorage(ACCOUNT_DASHBOARD_INTERCEPT_MODAL_STORAGE_KEY);

const resetDashboardInterceptStorageKeyExists = () =>
  getFromStorage(RESET_ACCOUNT_DASHBOARD_INTERCEPT_MODAL_STORAGE_KEY);

const neverShowAccountDashboardInterceptModalAgain = () =>
  getFromStorage(ACCOUNT_DASHBOARD_INTERCEPT_MODAL_STORAGE_KEY) ===
  AccountDashboardInterceptLocalStorageValues.NEVER_SHOW_AGAIN;

export default {
  ACCOUNT_DASHBOARD_INTERCEPT_MODAL_STORAGE_KEY,
  RESET_ACCOUNT_DASHBOARD_INTERCEPT_MODAL_STORAGE_KEY,
  AccountDashboardInterceptLocalStorageValues,
  localStorageKeysExists,
  resetDashboardInterceptStorageKeyExists,
  neverShowAccountDashboardInterceptModalAgain,
};
