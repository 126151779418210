import React, {
  FunctionComponent,
  useState,
  useEffect,
  useContext,
} from 'react';
import { createClient, Asset } from 'contentful';
import { Document } from '@contentful/rich-text-types';
import ReactMarkdown from 'react-markdown';

import config from '../config';
import { SupportedLanguages, getLanguage } from '../providers/LanguageProvider';
import { replaceVariables } from '../util/string-utils';
import { NotificationsContext } from '../providers/NotificationsProvider';
import { useTranslation } from './useTranslation';

// use direct import when Contentful version is upgraded
// import { AssetFields } from 'contentful';
type AssetFields = Asset['fields'];

// use direct import when react-markdown version is upgraded
// import { Options } from 'react-markdown';
type Options = {
  children?: React.ReactNode;
  disallowedElements: ['paragraph'];
  unwrapDisallowed: boolean;
};

const client = createClient({
  host: config.contentful.host,
  space: config.contentful.spaceId,
  environment: config.contentful.environmentName,
  accessToken: config.contentful.accessToken,
});

export interface PageTextContentMap {
  pageContentMap: PageTextContentType;
}

export type PageTextContentType = Map<string, string>;

type BaseType = {
  /** @description optional, if not provided, we'll use the language set in the app's local storage **/
  language?: SupportedLanguages;
};
type QueryByEntryIdList = BaseType & {
  /** @description this string list contains the contentful entry ids to be queried.
   * The list can have a max of 330 IDs based on URI request length max of 7600 */
  entryIdList: Array<string>;
};
type QueryByMicrocopyGroupEntryId = BaseType & {
  microcopyGroupEntryId: string;
};
type MicrocopyData = {
  slug: string;
  text: string;
};
type MicrocopyType = {
  contentTypeId: 'microcopy';
  fields: MicrocopyData;
  sys: {
    id: string;
  };
};
type MicrocopyGroupType = {
  contentTypeId: 'microcopyGroup';
  includes: MicrocopyType;
  fields: { entryName: string };
  sys: {
    id: string;
  };
};

type ProductImage = {
  fields: {
    file: {
      contentType: string;
      details: {
        image: {
          height: number;
          width: number;
        };
      };
      fileName: string;
      url: string;
    };
    title: string;
  };
};

export type ProductData = {
  id: string;
  entryName: string;
  brand?: string;
  modelNo?: string;
  type?: string;
  manufacturer?: string;
  price?: string;
  description?: Document;
  details?: Document;
  images?: ProductImage[];
  efficacy?: Document;
  efficiencyRating?: string;
  systemSize?: string;
  energyStarRated?: boolean;
  active: boolean;
  outOfStock: boolean;
};
type ProductType = {
  contentTypeId: 'product';
  fields: ProductData;
  sys: {
    id: string;
  };
};
type ProductListType = {
  contentTypeId: 'productList';
  includes: ProductType;
  fields: { entryName: string };
  sys: {
    id: string;
  };
  products: ProductType[];
};

export enum ProductStatus {
  Active = 'active',
  Inactive = 'inactive',
  All = 'all',
}

type QueryByProductListEntryId = BaseType & {
  productListEntryId: string;
  status?: ProductStatus;
};

export interface Variables {
  [key: string]: string | undefined;
}

export default function useContentMgmt(microcopyGroupEntryId = '') {
  const [content, setContent] = useState<PageTextContentType>(new Map());
  const [isContentLoading, setIsContentLoading] = useState<boolean>(false);

  const notificationContext = useContext(NotificationsContext);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchContentData = async () => {
      try {
        setIsContentLoading(true);
        // an idea is to fetch contentMaps for anticipated next pages rather
        // than the current page to eliminate perceived load time.
        const contentMap = await getMicrocopies({
          microcopyGroupEntryId,
        });

        setContent(contentMap);
      } catch (e) {
        //Handle error
        notificationContext.setState({
          isOpen: true,
          message: t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
          severity: 'error',
        });
      } finally {
        setIsContentLoading(false);
      }
    };

    if (microcopyGroupEntryId) {
      void fetchContentData();
    }
  }, [microcopyGroupEntryId]);

  /** @description get a map where the content slug is the key and the content text is the value.
       * @example const fetchContentData = async () => {
        const contentMap = await getMicrocopies({
          microcopyGroupEntryId,
        });
      */
  const getMicrocopies = async (
    query: QueryByMicrocopyGroupEntryId,
  ): Promise<Map<string, string>> => {
    const entryCollection = await client.getEntries<MicrocopyGroupType>({
      content_type: 'microcopyGroup',
      'sys.id': query.microcopyGroupEntryId,
      locale: query.language ? query.language : getLanguage(),
    });
    return new Map(
      entryCollection.includes?.Entry?.map((eachEntry: MicrocopyType) => {
        return [eachEntry.fields.slug, eachEntry.fields.text];
      }),
    );
  };

  /** @description get image assets via contentful entry id.
   * @example const imageDataList = await contentMgmt.getImageAssetsByEntryIds({
        locale: 'es',
        entryIds: ['5mkF54X3pX7jjuhU5dOf18'],
      });
  */
  const getImageAssetsByEntryIds = async (
    query: QueryByEntryIdList,
  ): Promise<Array<AssetFields>> => {
    const assetCollection = await client.getAssets({
      'sys.id[in]': query.entryIdList,
      locale: query.language,
      select: ['fields'],
    });
    return assetCollection?.items?.map(asset => {
      return asset?.fields;
    });
  };

  /** @description get a list of products for given product list entry id.
       * @example const fetchProductList = async () => {
        const productList = await getProductList({
          productListEntryId,
        });
      */
  const getProductList = async (
    query: QueryByProductListEntryId,
  ): Promise<Array<ProductData>> => {
    const entryCollection = await client.getEntries<ProductListType>({
      content_type: 'productList',
      'sys.id': query.productListEntryId,
      locale: query.language ? query.language : getLanguage(),
    });

    const productList = entryCollection.includes?.Entry
      ? entryCollection.includes.Entry.map((eachEntry: ProductType) => {
          const product = eachEntry.fields;

          return {
            id: eachEntry.sys.id,
            entryName: product.entryName,
            brand: product.brand,
            modelNo: product.modelNo,
            type: product.modelNo,
            manufacturer: product.manufacturer,
            price: product.price,
            description: product.description,
            details: product.details,
            images: product.images,
            efficacy: product.efficacy,
            efficiencyRating: product.efficiencyRating,
            systemSize: product.systemSize,
            energyStarRated: product.energyStarRated,
            active: product.active,
            outOfStock: product.outOfStock,
          };
        })
      : [];

    const sortedProductIds = entryCollection.items[0].fields.products.map(
      (product: ProductType) => {
        return product.sys.id;
      },
    );

    const sortedProductsMap: { [key: string]: any } = {};
    productList.forEach((obj: any) => {
      sortedProductsMap[obj.id] = obj;
    });

    const sortedProducts = sortedProductIds.map(id => sortedProductsMap[id]);

    if (!query.status || query.status === ProductStatus.All) {
      return sortedProducts;
    }

    return sortedProducts.filter(
      product =>
        (query.status === ProductStatus.Active && product.active) ||
        (query.status === ProductStatus.Inactive && !product.active),
    );
  };

  const richText = (
    text: string | undefined,
    variables?: Variables,
    linkTarget = '_blank', //Used to open the links in new tab. Pass "" to open link on the same tab
  ): React.ReactNode => {
    if (!text) {
      return '';
    }

    const replacedText = variables ? replaceVariables(text, variables) : text;
    return React.createElement(
      ReactMarkdown,
      { linkTarget, escapeHtml: false },
      replacedText,
    );
  };

  const inlineRichText = (
    text: string | undefined,
    variables?: Variables,
    linkTarget = '_blank', //Used to open the links in new tab. Pass "" to open link on the same tab
  ): React.ReactNode => {
    if (!text) {
      return '';
    }

    const replacedText = variables ? replaceVariables(text, variables) : text;

    return React.createElement(
      ReactMarkdown,
      {
        disallowedTypes: ['paragraph'],
        unwrapDisallowed: true,
        linkTarget,
        escapeHtml: false,
      },
      replacedText,
    );
  };

  /***
   Allows for exporting a shorter var name that has two advantages:
   1. Follows the site-wide codebase convention
   2. Allows for cleaner prop drilling when same reference is being passed
      to avoid a text injection flash on page load
  ***/
  const getText = (key: string) => content.get(key);

  return {
    content,
    t: getText,
    isContentLoading,
    getImageAssetsByEntryIds,
    getProductList,
    richText,
    inlineRichText,
    contentfulClient: client,
  };
}
