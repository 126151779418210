import { navigate } from '@reach/router';
import React, { FC, createContext, useContext, useState } from 'react';
import {
  contactInfoFormStateAdapter,
  selectChargerFormStateAdapter,
  installationFormStateAdapter,
  uploadPhotosFormStateAdapter,
  evRebatesFormStateAdapter,
} from '../components/pge-plus-common/utils/pgeplusFormstateAdapters';
import {
  ContactInfo,
  RebateEligibility,
  SelectCharger,
  Installation,
  UploadPhotos,
} from '../components/pge-plus-form/evcharger.types';
import routes from '../routes';
import { AccountDetail } from '../__generated__/pge-types';
import {
  ApplicationEv,
  ApplicationEvSectionType,
  ApplicationSection,
  ApplicationSectionStatus,
  SectionEvAccountInfo,
  SectionEvChargerSelection,
  SectionEvInstall,
  SectionEvPanelInfo,
  SectionEvRebates,
} from '../__generated__/pgeplus-types';

interface Props {
  children: React.ReactNode;
}

export type EVChargerFormState = {
  application: ApplicationEv | null;
  account: AccountDetail | null;
  contactInfo: ContactInfo;
  rebateEligibility: RebateEligibility;
  selectCharger: SelectCharger;
  installation: Installation;
  uploadPhotos: UploadPhotos;
};

export type EVChargerContextState = {
  loading: boolean;
  formState: EVChargerFormState;
  initFormStateAndNavigate: (application: ApplicationEv) => void;
  resetForm: () => void;
  updateFormState: <T extends EVChargerFormState, K extends keyof T>(
    key: K,
    data: T[K],
  ) => boolean;
  getSectionData: (section: ApplicationEvSectionType) => any;
};

const inititalValues: EVChargerContextState = {
  loading: false,
  formState: {
    application: null,
    account: null,
    contactInfo: {},
    rebateEligibility: {},
    selectCharger: {},
    installation: {
      dayOfWeek: [],
      urgency: '',
      timeFrame: [],
    },
    uploadPhotos: {},
  },
  initFormStateAndNavigate: () => false,
  updateFormState: () => false,
  resetForm: () => false,
  getSectionData: () => undefined,
};
const EVChargerFormStateContext = createContext<EVChargerContextState>(
  inititalValues,
);

const EVChargerFormStateProvider: FC<Props> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [formState, setFormState] = useState<EVChargerFormState>(
    inititalValues.formState,
  );

  const updateFormState = <T extends EVChargerFormState, K extends keyof T>(
    key: K,
    data: T[K],
  ) => {
    setFormState(e => ({ ...e, [key]: data }));
    return true;
  };

  //initialize the form state and navigate to where they left off (First 'pending' step)
  const initFormStateAndNavigate = (application: ApplicationEv) => {
    if (!application) {
      throw new Error('Application details not found');
    }

    //Saving the raw API data to fetch section ID's later
    updateFormState('application', application);

    application?.details.map(section => {
      //TODO: Pre-populate data for EV rebates

      if (section.sectionType === ApplicationEvSectionType.EvAccountInfo) {
        updateFormState(
          'contactInfo',
          contactInfoFormStateAdapter().toFormState(
            (section as SectionEvAccountInfo).data,
          ),
        );
      }

      if (section.sectionType === ApplicationEvSectionType.EvRebates) {
        updateFormState(
          'rebateEligibility',
          evRebatesFormStateAdapter().toFormState(
            (section as SectionEvRebates).data,
          ),
        );
      }

      if (section.sectionType === ApplicationEvSectionType.EvChargerSelection) {
        updateFormState(
          'selectCharger',
          selectChargerFormStateAdapter().toFormState(
            (section as SectionEvChargerSelection).data,
          ),
        );
      }

      if (section.sectionType === ApplicationEvSectionType.EvInstall) {
        updateFormState(
          'installation',
          installationFormStateAdapter().toFormState(
            (section as SectionEvInstall).data,
          ),
        );
      }

      if (section.sectionType === ApplicationEvSectionType.EvPanelInfo) {
        updateFormState(
          'uploadPhotos',
          uploadPhotosFormStateAdapter().toFormState(
            (section as SectionEvPanelInfo).data,
          ),
        );
      }
    });

    continueWhereLeftOff(application);
  };

  const continueWhereLeftOff = (application: ApplicationEv) => {
    //Navigate users to the step where he left
    if (!application) {
      throw new Error('Set the form state "application" before navigating');
    }

    const selectCharger = application?.details.filter(
      sec => sec.sectionType === ApplicationEvSectionType.EvChargerSelection,
    )[0] as SectionEvChargerSelection;

    //TODO: Revisit below logic for possible refactoring. Here is what is implemented.
    //1. Added charger selection on the right step of the flow
    //2. Sort the application sections based on the order from step 1
    //3. Filter the pending steps to find the incomplete step and navigate.

    const sortOrder = [
      //Select charger will be here for BYOC flow
      ApplicationEvSectionType.EvAccountInfo,
      ApplicationEvSectionType.EvRebates,
      //Select charger will be added here for buy flow
      ApplicationEvSectionType.EvInstall,
      ApplicationEvSectionType.EvPanelInfo,
      ApplicationEvSectionType.EvSummary,
      ApplicationEvSectionType.EvEstimate,
    ];

    //Push the select charger on the right place for Buy/BYOC flow
    sortOrder.splice(
      selectCharger?.data?.suppliedByCustomer ? 0 : 2,
      0,
      ApplicationEvSectionType.EvChargerSelection,
    );

    const evSectionTypeRouteMap = {
      [ApplicationEvSectionType.EvAccountInfo]:
        routes.PGE_PLUS_EV_CHARGERS_CONTACT_INFO,
      [ApplicationEvSectionType.EvRebates]:
        routes.PGE_PLUS_EV_CHARGERS_REBATE_ELIGIBILITY,
      [ApplicationEvSectionType.EvChargerSelection]: selectCharger?.data
        ?.suppliedByCustomer
        ? routes.PGE_PLUS_EV_CHARGERS_CONFIRM_YOUR_CHARGER
        : routes.PGE_PLUS_EV_CHARGERS_SELECT_CHARGER,
      [ApplicationEvSectionType.EvInstall]:
        routes.PGE_PLUS_EV_CHARGERS_INSTALLATION,
      [ApplicationEvSectionType.EvPanelInfo]:
        routes.PGE_PLUS_EV_CHARGERS_UPLOAD_PHOTOS,
      [ApplicationEvSectionType.EvSummary]: routes.PGE_PLUS_EV_CHARGERS_SUMMARY,
      [ApplicationEvSectionType.EvEstimate]:
        routes.PGE_PLUS_EV_CHARGERS_COST_ESTIMATION,
    };

    const pendingSteps = application.details
      .sort((a, b) => {
        return (
          sortOrder.indexOf(a.sectionType) - sortOrder.indexOf(b.sectionType)
        );
      })
      .filter(
        section =>
          ((section as unknown) as ApplicationSection).status ===
          ApplicationSectionStatus.Pending,
      );

    //Navigate to first pending step or
    //Navigate to the first step (Based on BYOC/Buy flow)
    const URLToNavigate =
      pendingSteps.length > 0
        ? evSectionTypeRouteMap[pendingSteps[0].sectionType]
        : evSectionTypeRouteMap[sortOrder[0]];

    void navigate(URLToNavigate);
  };

  const resetForm = () => {
    setFormState(inititalValues.formState);
  };

  const getSectionData = (section: ApplicationEvSectionType) => {
    return formState.application?.details.filter(
      sec => sec.sectionType === section,
    )[0];
  };

  return (
    <EVChargerFormStateContext.Provider
      value={{
        loading,
        formState,
        initFormStateAndNavigate,
        updateFormState,
        resetForm,
        getSectionData,
      }}
    >
      {children}
    </EVChargerFormStateContext.Provider>
  );
};
const useEVChargerFormState = () => ({
  ...useContext(EVChargerFormStateContext),
});

export { EVChargerFormStateProvider, useEVChargerFormState };
