import React, { FC } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { FirebaseProvider } from '../providers/FirebaseProvider';
import client from '../lib/apolloClient';
import Helmet from 'react-helmet';
import { NicknameChangeProvider } from '../components/nickname-change-modal';
import { FeatureFlagsProvider } from '../providers/FeatureFlagsProvider';
import Noscript from '../components/noscript';
import { LanguageProvider } from '../providers/LanguageProvider';

interface Props {
  readonly element: any;
}

const wrapRootElement: FC<Props> = ({ element }) => (
  <>
    <Helmet>
      <link
        rel="preload"
        as="font"
        href="/static/ProximaNova-Regular-e7541072ccd4922859ae2b10b9404713.ttf"
        type="font/ttf"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href="/static/ProximaNova-Bold-a8d6d32f127f63e96efd9f8f7ecfdd34.ttf"
        type="font/ttf"
        crossOrigin="anonymous"
      />
    </Helmet>
    <FirebaseProvider>
      <ApolloProvider client={client}>
        <LanguageProvider>
          <FeatureFlagsProvider>
            <NicknameChangeProvider>{element}</NicknameChangeProvider>
          </FeatureFlagsProvider>
        </LanguageProvider>
      </ApolloProvider>
    </FirebaseProvider>
    <Noscript />
  </>
);

export default wrapRootElement;
