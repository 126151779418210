import createTypography from '@material-ui/core/styles/createTypography';
import palette from './palette';

const calcLetterSpacing = (fontSizeRem: number, percentage: number) =>
  `${(fontSizeRem * (percentage / 100)).toFixed(4)}rem`;

export default createTypography(palette, {
  fontFamily: [
    'Forma-DJR-Display',
    'Untitled-Sans',
    'Roboto',
    'Helvetica',
    'Arial',
    'sans-serif',
  ].join(','),
  h1: {
    fontSize: '2.25rem',
    fontWeight: 'bold',
    letterSpacing: calcLetterSpacing(2.25, 2),
  },
  h2: {
    fontSize: '1.75rem',
    fontWeight: 'bold',
    letterSpacing: calcLetterSpacing(1.75, 2),
  },
  h3: {
    fontSize: '1.625rem',
    fontWeight: 'bold',
    letterSpacing: calcLetterSpacing(1.625, 2),
  },
  h4: {
    fontSize: '1.5rem',
    fontWeight: 500,
    letterSpacing: calcLetterSpacing(1.5, 2),
  },
  h5: {
    fontSize: '1.25rem',
    fontWeight: 500,
    letterSpacing: calcLetterSpacing(1.25, 2),
  },
  h6: {
    fontSize: '1.25rem',
    fontWeight: 500,
    letterSpacing: calcLetterSpacing(1.25, 2),
    '& p': {
      fontFamily: 'Untitled-Sans',
    },
  },
  body1: {
    fontSize: '1.25rem',
    fontWeight: 400,
    fontFamily: 'Untitled-Sans',
  },
  body2: {
    fontSize: '1rem',
    fontWeight: 400,
    fontFamily: 'Untitled-Sans',
  },
  subtitle1: {
    fontSize: '0.875rem',
    fontWeight: 400,
    fontFamily: 'Untitled-Sans',
  },
  subtitle2: {
    fontSize: '0.875rem',
    fontWeight: 700,
    fontFamily: 'Untitled-Sans',
  },
  caption: {
    fontSize: '0.875rem',
    fontWeight: 400,
    fontFamily: 'Untitled-Sans',
  },
  button: {
    fontSize: '0.875rem',
    fontWeight: 500,
    textTransform: 'none',
    fontFamily: 'Untitled-Sans',
  },
});
