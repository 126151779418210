import React from 'react';
import { Typography, Divider, List, ListItem } from '@material-ui/core';
import { BLOCKS, Block, Inline, Text } from '@contentful/rich-text-types';
import { RenderNode } from '@contentful/rich-text-react-renderer';
import {
  getColumnComponent,
  getCallOutComponent,
  getPullQuoteComponent,
  getContactComponent,
  getButtonVariable,
} from '../../../util/contentful-render-utils';
import TableWrapper from '../../contentful-table-wrapper';
import PgeButton from '../../pge-button/PGEButtons';
import ProgramCard from '../../program-card';
import MediaWrapper from '../../media-wrapper';
import WrapperImage from '../../wrapper-image';
import { isEntry, isAsset } from '../../../util/type-checking';
import { FileType } from '../../../constants';
import GallerySlider from '../../gallery-slider';

type NodeUnion = Block | Inline;

function type(n: NodeUnion): string {
  const [li] = n.content;
  const [p] = (li as NodeUnion).content;
  const [text] = (p as NodeUnion).content;
  const { value } = (text as Text) || {};
  const [, style] = value?.match(/\[\[(.*?)\]\]/) || ['', 'NORM'];

  return `type-${style}`;
}

function block(wrap: boolean): RenderNode {
  return {
    // Markup
    [BLOCKS.PARAGRAPH]: (_, txt) =>
      !txt ? null : !wrap ? txt : <Typography>{txt}</Typography>,

    [BLOCKS.HEADING_2]: (_, txt) =>
      !txt ? null : <Typography variant="h2">{txt}</Typography>,

    [BLOCKS.HEADING_3]: (_, txt) =>
      !txt ? null : <Typography variant="h3">{txt}</Typography>,

    [BLOCKS.HEADING_4]: (_, txt) =>
      !txt ? null : <Typography variant="h4">{txt}</Typography>,

    [BLOCKS.HEADING_5]: (_, txt) =>
      !txt ? null : <Typography variant="h5">{txt}</Typography>,

    [BLOCKS.HEADING_6]: (_, txt) =>
      !txt ? null : <Typography variant="h6">{txt}</Typography>,

    [BLOCKS.HR]: () => <Divider />,

    [BLOCKS.QUOTE]: (_, txt) =>
      !txt ? null : (
        <Typography
          component="blockquote"
          style={{
            margin: '0 0 1.3125rem',
            borderLeft: '6px solid rgb(229, 235, 237)',
            paddingLeft: '0.875rem',
          }}
        >
          {txt}
        </Typography>
      ),
    [BLOCKS.UL_LIST]: (node, txt) =>
      !txt ? null : <List className={type(node)}>{txt}</List>,

    [BLOCKS.OL_LIST]: (node, txt) =>
      !txt ? null : (
        <List className={type(node)} component="ol">
          {txt}
        </List>
      ),

    [BLOCKS.LIST_ITEM]: (_, txt) => (!txt ? null : <ListItem>{txt}</ListItem>),

    // Embeded
    /**
     * TODO:
     * These "middle" functions NEED to go.
     * Replace them with the actual component.
     */
    [BLOCKS.EMBEDDED_ENTRY]: (node, txt) => {
      if (isEntry(node.data.target)) {
        const { target } = node.data;

        switch (target.__typename) {
          case 'ContentfulElementColumns3': {
            return getColumnComponent(target);
          }
          case 'ContentfulElementColumns2': {
            return getColumnComponent(target);
          }
          case 'ContentfulWrapperImage': {
            return <WrapperImage {...target} />;
          }
          case 'ContentfulElementCallout': {
            return getCallOutComponent(target);
          }
          case 'ContentfulUiTableExtention': {
            return <TableWrapper {...target} />;
          }
          case 'ContentfulElementPullquote': {
            return getPullQuoteComponent(target);
          }
          case 'ContentfulElementContact': {
            return getContactComponent(target);
          }
          case 'ContentfulWrapperembedMedia': {
            return <MediaWrapper {...target} />;
          }
          case 'ContentfulElementButtonAction': {
            const { button, buttonLink } = target;
            const btnProps: any = getButtonVariable(button, buttonLink);

            return <PgeButton {...btnProps} />;
          }
          case 'ContentfulElementProgramCard': {
            return <ProgramCard {...target} />;
          }
          case 'ContentfulModuleGallery': {
            const { galleryItems } = target;
            return <GallerySlider {...target}>{galleryItems}</GallerySlider>;
          }
          default:
            return <div>{txt}</div>;
        }
      } else {
        return null;
      }
    },

    [BLOCKS.EMBEDDED_ASSET]: (node, txt) => {
      if (isAsset(node.data.target)) {
        const { file, title, description } = node.data.target;
        const url = file?.url || '';

        switch (true) {
          case FileType.IMG.test(url): {
            return <img alt={description || ''} src={url} />;
          }
          case FileType.DOC.test(url): {
            // PgeButton?
            return <a href={url}>{title || url}</a>;
          }
          default:
            return <div>{txt}</div>;
        }
      } else {
        return null;
      }
    },
  };
}

export default block;
