import React, { FunctionComponent, CSSProperties } from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import PGELogoProps from './PGELogo.types';

const logoPathStyle: CSSProperties = {
  fill: '#1473c1',
  fillOpacity: 1,
  fillRule: 'nonzero',
  stroke: 'none',
};

const PGELogo: FunctionComponent<PGELogoProps> = ({
  className,
  color,
  viewBox,
}) => {
  const style = { ...logoPathStyle, fill: color || '#FEFDF2;' };
  return (
    <SvgIcon viewBox={viewBox} className={className}>
     
      <g>
        <g>
          <path  style={style} d="M59.6,3.1c-7-7-12.4-0.1-12.4-0.1l52,39.1C99.3,42.2,66.7,10.2,59.6,3.1z"/>
          <path  style={style} d="M3.1,47.5c-7,7-0.1,12.4-0.1,12.4L42.3,7.9C42.3,7.9,10.2,40.4,3.1,47.5z"/>
          <path  style={style} d="M48.2,104.9c7,7,12.4,0.1,12.4,0.1l-52-39.1C8.5,65.8,41.1,97.8,48.2,104.9z"/>
          <path  style={style} d="M104.9,47.6L65.7,99.6c0,0,32.1-32.6,39.1-39.6C111.9,53,104.9,47.6,104.9,47.6z"/>
        </g>
        <g>
          <path  style={style} d="M33.3,41.9C33.2,41.9,33.2,41.9,33.3,41.9l-8.6,0c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.2-0.1,0.3l0.1,11.1
            l-0.1,11.1c0,0.2,0.2,0.4,0.4,0.4c0,0,0,0,0,0l4.5,0c0.1,0,0.2,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3L29.5,57l3.7,0
            c5.5,0,8.8-2.9,8.8-7.7C42,44.5,38.9,41.9,33.3,41.9z M36.9,49.4c0,1.2-0.3,2.2-1,2.8c-0.6,0.6-1.4,0.9-2.4,0.9l-4.1,0l0-7.3
            l3.9,0c0,0,0,0,0,0C35.8,45.8,36.9,47,36.9,49.4z"/>
          <path  style={style} d="M65.5,52.3C65.5,52.3,65.5,52.3,65.5,52.3l-9.6,0c-0.2,0-0.4,0.2-0.4,0.4l0,3.2c0,0.1,0,0.2,0.1,0.3
            c0.1,0.1,0.2,0.1,0.3,0.1c0,0,0,0,0,0l4.5,0c-0.2,3.2-2.1,5.1-5.3,5.1c0,0,0,0,0,0c-3.8,0-6-2.9-6-7.9c0-2.5,0.5-4.5,1.5-5.9
            c1-1.4,2.5-2.1,4.4-2.1c0,0,0,0,0,0c2.7,0,4.4,1.2,5.1,3.7c0,0.2,0.2,0.4,0.5,0.4c0,0,0,0,0,0l4.6,0c0.1,0,0.2,0,0.3-0.1
            c0.1-0.1,0.1-0.2,0.1-0.3c-1.2-5-5-7.7-10.5-7.7c0,0,0,0,0,0c-6.7,0-11.2,4.9-11.2,12c0,3.5,1.1,6.5,3.2,8.7
            c2.1,2.2,4.9,3.3,8.2,3.3c0,0,0,0,0,0c3.3,0,5.9-0.9,7.7-2.8c2.1-2.1,3.1-5.5,3.1-10c0-0.1,0-0.2-0.1-0.3
            C65.7,52.3,65.6,52.3,65.5,52.3z"/>
          <path  style={style} d="M84.6,61.3c0-0.2-0.2-0.4-0.4-0.4l-10.8,0l-0.1-6.2l9.6,0c0.2,0,0.4-0.2,0.4-0.4l0-3.2c0-0.2-0.2-0.4-0.4-0.4
            c0,0,0,0,0,0l-9.6,0l0-4.9l10.5,0c0.2,0,0.4-0.2,0.4-0.4l0-3.2c0-0.2-0.2-0.4-0.4-0.4c0,0,0,0,0,0l-14.9,0c-0.1,0-0.2,0-0.3,0.1
            s-0.1,0.2-0.1,0.3l0.1,11.1l-0.1,11.1c0,0.2,0.2,0.4,0.4,0.4c0,0,0,0,0,0l15.1,0c0.1,0,0.2,0,0.3-0.1s0.1-0.2,0.1-0.3L84.6,61.3z"
            />
        </g>
      </g>
    </SvgIcon>
  );
};

export default PGELogo;
