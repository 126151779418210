import { useStaticQuery, graphql } from 'gatsby';

const AllTwitterUserTimeline = graphql`
  {
    allTwitterStatusesUserTimelinePortlandGeneral {
      nodes {
        id_str
        entities {
          hashtags {
            indices
            text
          }
          user_mentions {
            id_str
            name
            indices
            screen_name
            id
          }
        }
      }
    }
  }
`;
export default function useSearchTweets() {
  try {
    const { allTwitterStatusesUserTimelinePortlandGeneral } = useStaticQuery(
      AllTwitterUserTimeline,
    );
    return allTwitterStatusesUserTimelinePortlandGeneral?.nodes || [];
  } catch {}
}
