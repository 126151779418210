// import globals from './globals';
import MuiAlert from './MuiAlert';
import MuiStepConnector from './MuiStepConnector';
import MuiStepIcon from './MuiStepIcon';
import MuiCssBaseline from './MuiCssBaseline';
import { Overrides } from '@material-ui/core/styles/overrides';
import { AlertClassKey } from '@material-ui/lab';

declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiAlert: AlertClassKey;
  }
}

const overrides: Overrides = {
  MuiCssBaseline,
  MuiAlert,
  MuiStepConnector,
  MuiStepIcon,
};

export default overrides;
