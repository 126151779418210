import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PGEButton from '../buttons';
import ROUTES from '../../routes';
import { navigate } from '@reach/router';
import { useTranslation } from '../../hooks/useTranslation';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
    marginBottom: '30px',
  },
  paper: {
    display: 'flex',
    justifyContent: 'center',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  buttonContainer: {
    textAlign: 'right',
  },
}));

export const BrowserNotice: FunctionComponent = () => {
  const classes = useStyles();
  const { richT, t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.paper}>
        <div>
          {richT('COOKIES_DISABLED_BROWSER_WARNING')}
          <div className={classes.buttonContainer}>
            <PGEButton onClick={() => navigate(ROUTES.HOME)}>
              {t('OK')}
            </PGEButton>
          </div>
        </div>
      </div>
    </div>
  );
};
