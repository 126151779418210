import { useContext } from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import { NotificationsContext } from '../../providers/NotificationsProvider';
import { AlertSeverity } from '../../components/alerts/Alerts';

export default () => {
  const { t } = useTranslation();
  const notificationContext = useContext(NotificationsContext);

  const setErrorNotification = (
    isOpen: boolean,
    message?: string,
    severity?: AlertSeverity,
  ) => {
    notificationContext.setState({
      isOpen,
      message: message ? message : t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
      severity: severity ? severity : 'error',
    });
    isOpen && typeof window !== 'undefined' && window.scrollTo(0, 0);
  };

  return { setErrorNotification };
};
