import React from 'react';
import { formatYoutubeUrl, formatVimeoUrl } from './pge-video.utils';

interface IVideoObj {
  url?: string;
  width?: string;
  height?: string;
}

interface IVideoProps {
  video: IVideoObj;
}

export default (props: IVideoProps) => {
  const { url, width, height } = props.video;

  if (!url) {
    return null;
  }

  const videoUrl = React.useMemo(() => {
    return /vimeo.com/gi.test(url)
      ? formatVimeoUrl(url)
      : formatYoutubeUrl(url);
  }, [url]);

  return (
    <iframe
      style={{ width, height }}
      src={videoUrl}
      frameBorder="0"
      allowFullScreen={true}
    />
  );
};
