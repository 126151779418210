import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import colors from '../../themes/main-colors';
import { HeaderVariant } from './types';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: (props: columnProps) => ({
    flexGrow: 1,
    width: '100%',
    padding: theme.spacing(6.25),
    backgroundColor: getBackgroundColor(props.backGroundColor),
    '& .MuiGrid-item > h2': {
      marginTop: '0px !important',
    },
    '& .MuiGrid-spacing-xs-3': {
      margin: theme.spacing(0),
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2.5),
    },
  }),
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  grid: {
    '& .MuiPaper-root': {
      backgroundColor: 'unset !important',

      '& .MuiCardMedia-root': {
        margin: theme.spacing(0, 0, 2.5, 0),
      },
      '& .MuiCardContent-root': {
        padding: 0,
      },
    },
    '& .pge-contenful-H2': {
      marginTop: 0,
    },
    '& .MuiGrid-spacing-xs-3': {
      marginBottom: theme.spacing(6.25),
    },
    '& .MuiGrid-item': {
      '&.grid-col': {
        padding: theme.spacing(0, 2.5),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(2.5, 2.5),
        },
      },
      '& .spacingComponent.ContentfulContentBody': {
        marginTop: theme.spacing(2),
      },
      '& .WrapperImage': {
        '& .MuiButton-root': {
          padding: 0,
          '& .MuiButton-label': {
            flexDirection: 'column',
            '& .MuiButton-endIcon': {
              marginLeft: 0,
            },
          },
        },
        [theme.breakpoints.down('sm')]: {
          marginTop: theme.spacing(2),
        },
        [theme.breakpoints.up('sm')]: {
          marginTop: theme.spacing(0),
        },
      },

      '& p.pge-contentful-body': {
        [theme.breakpoints.down('sm')]: {
          marginTop: theme.spacing(1),
        },
      },
      '& .SocialFeed > div': {
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(2, 0),
        },
      },
    },
  },
  gridStyle: {
    borderLeft: '1px solid',
    borderLeftColor: colors.lightGray1,
    [theme.breakpoints.down('sm')]: {
      borderTop: '1px solid',
      borderTopColor: colors.lightGray1,
      borderLeft: 'none',
    },
  },
  gridCol: {
    padding: theme.spacing(0, 2.5),
  },
}));

export const getBackgroundColor = (color: any | null): any | null => {
  let bgColor = colors.white;
  switch (color) {
    case 'Blue':
      bgColor = colors.riverBlue;
      break;
    case 'Grey':
      bgColor = colors.cloudGrey2;
      break;
    case 'Linen-White':
      bgColor = colors.linenWhite;
      break;
    case 'Yellow':
      bgColor = colors.lightYellow;
      break;
    default:
      bgColor = colors.white;
      break;
  }
  return bgColor;
};

interface columnProps {
  data: any;
  backGroundColor?: any;
  shortDesc?: any;
  header?: any;
  headerType?: string;
  leftNav?: any;
}

const Column = (props: columnProps) => {
  const classes = useStyles(props);
  let mdVal: any;
  if (props.leftNav) {
    mdVal = props.data.length === 1 ? 12 : 6;
  } else {
    mdVal =
      props.data.length === 1
        ? 12
        : props.data.length === 2
        ? 6
        : props.data.length === 3
        ? 4
        : 3;
  }

  const headerTag =
    !props?.headerType || !/^h[1-4]$/.test(props?.headerType)
      ? ('h2' as HeaderVariant)
      : (props?.headerType as HeaderVariant);

  const generateColumn = (comp: any, index: number) => {
    if (index === 0) {
      return (
        <Grid
          item
          xs={12}
          md={mdVal}
          key={index}
          className={`col${index + 1} grid-col`}
        >
          {comp}
        </Grid>
      );
    } else if (props.leftNav && index % 2 === 0) {
      return (
        <Grid
          item
          xs={12}
          md={mdVal}
          key={index}
          className={`col${index + 1} grid-col`}
        >
          {comp}
        </Grid>
      );
    } else {
      return (
        <Grid
          item
          xs={12}
          md={mdVal}
          key={index}
          className={`col${index + 1} ${classes.gridStyle} grid-col`}
        >
          {comp}
        </Grid>
      );
    }
  };
  return (
    <div className={`Column ${classes.root}`}>
      {(() => {
        return (
          <>
            {props.header && (
              <Typography variant={headerTag}>{props.header}</Typography>
            )}
            {props.shortDesc && (
              <Typography variant={'body1'}>{props.shortDesc}</Typography>
            )}
            <Grid
              container
              spacing={3}
              justify="flex-start"
              className={classes.grid}
            >
              {props.data.map((comp: any, index: number) => {
                return generateColumn(comp, index);
              })}
            </Grid>
          </>
        );
      })()}
    </div>
  );
};
export default Column;
