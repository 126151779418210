import React, { FC, useRef } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../../themes/main-colors';
import PgeVideoPlayer from '../pge-video-player';
import PgeButton from '../pge-button/_PgeButton';
import ImageLink from '../image-link';
import { ModulePromoItemFragment } from '../../__generated__/gatsby-types';
import { Field } from '../../contentful.d';
import { useComponentSize } from '../../util/style-utils';
import Button from '@material-ui/core/Button';
import { GetApp } from '@material-ui/icons';
import { useTranslation } from '../../hooks/useTranslation';
import { Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import { HeaderVariant } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'block',
      backgroundColor: colors.white,
      minHeight: 300,
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        minHeight: 270,
      },
    },
    detailsContainer: {
      padding: theme.spacing(2.5, 2.5, 1.75, 2.5),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2.5, 2.5, 1.875, 2.5),
      },
    },
    header: {
      marginBottom: theme.spacing(3),
      fontWeight: 'bold',
    },
    link: {
      color: colors.sparkBlue,
    },
    woLink: {
      color: colors.noirBlur,
    },
    imageVertical: {
      width: '100%',
      height: 275,
      objectFit: 'cover',
      objectPosition: ({ imagePosition }: Props) => imagePosition,
      [theme.breakpoints.down('sm')]: {
        height: 135,
      },
    },
    videoFrame: {
      '& > iframe': {
        width: '100%',
        height: 275,
        [theme.breakpoints.down('sm')]: {
          height: 135,
        },
      },
    },
    download: {
      textAlign: 'center',
    },
    button: {
      minWidth: '9ch',
      padding: theme.spacing(1, 3),
      boxSizing: 'border-box',
      fontSize: theme.spacing(2),
      borderRight: '1px solid currentColor',
      borderRadius: 0,

      '&:last-child': {
        borderRight: 'none',
      },

      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1, 0),

        '& .MuiButton-label': {
          flexDirection: 'column-reverse',
        },

        '& .MuiButton-endIcon': {
          marginLeft: theme.spacing(0),
        },
      },
    },
  }),
);

interface Props {
  layoutType?: string;
  mediaURL?: string;
  mediaType?: string;
  header?: string;
  headerType?: string;
  description?: React.ReactElement;
  link?: string;
  readonly imageLink?: ModulePromoItemFragment['imageLink'];
  readonly imagePosition?: Field['Position'];
  vimeoDownloadUrl?: string;
}

const PromoItem: FC<Props> = props => {
  const videoDivRef = useRef<HTMLDivElement>(null);
  const classes = useStyles(props);
  const { width } = useComponentSize(videoDivRef);
  const { t } = useTranslation();
  const {
    layoutType = 'vertical',
    header = '',
    headerType = '',
    description = '',
    mediaURL = '',
    mediaType = '',
    link = '',
    imageLink,
    vimeoDownloadUrl,
  } = props;

  const vimeoProps = /vimeo.com/gi.test(mediaURL)
    ? { width: `${width}px`, height: `${(width / 16) * 9}px` }
    : {};

  const headerClass = link
    ? `${classes.header} ${classes.link}`
    : `${classes.header} ${classes.woLink}`;

  const headerTag =
    !headerType || !/^h[1-4]$/.test(headerType)
      ? ('h2' as HeaderVariant)
      : (headerType as HeaderVariant);

  return (
    <div className={classes.root}>
      {mediaURL && (
        <div>
          {mediaType === 'image' && (
            <ImageLink link={imageLink} text={header}>
              <img
                className={classes.imageVertical}
                src={mediaURL}
                alt=""
                height="275"
                width="500"
              />
            </ImageLink>
          )}
          {mediaType === 'video' && (
            <div className={classes.videoFrame}>
              <PgeVideoPlayer
                video={{
                  url: `${mediaURL}`,
                  ...vimeoProps,
                }}
              />
            </div>
          )}
        </div>
      )}
      <div className={classes.detailsContainer}>
        {header && (
          <Typography variant={headerTag} className={headerClass}>
            <PgeButton link={link} theme="Text" display="Inline">
              {header}
            </PgeButton>
          </Typography>
        )}
        {description && description}
        {mediaURL && vimeoDownloadUrl && (
          <div className={classes.download}>
            <Button
              component={'a'}
              color="primary"
              size="large"
              href={vimeoDownloadUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.button}
              endIcon={<GetApp />}
            >
              {t('DOWNLOAD')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PromoItem;
