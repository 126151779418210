import React from 'react';
import { Typography } from '@material-ui/core';
import RichText, { richTextStyles } from '../../rich-text';
import ListItem from './ListItem';
import useAttrs from '../../../hooks/useAttrs';
import { StaticListProps as Props, ListOmits, HeaderVariant } from './types.d';
import useStyles from './StaticList.styles';

function StaticList({
  staticListHeader,
  staticListHeaderType,
  staticListIntroCopy,
  staticListImage,
  addItems,
  colorBackground,
  imagePosition,
  ...props
}: Props): ElementNode {
  // Vars
  const { className = '', ...attrs } = useAttrs<Props>(props, ListOmits);
  const classes = useStyles({ imagePosition, colorBackground });
  const richText = richTextStyles();

  const getSubHeaderTag = (header: HeaderVariant): HeaderVariant => {
    const headerNumber = parseInt(header.replace('h', ''));

    return `h${headerNumber + 1}` as HeaderVariant;
  };

  const headerTag =
    !staticListHeaderType || !/^h[1-4]$/.test(staticListHeaderType)
      ? ('h2' as HeaderVariant)
      : (staticListHeaderType as HeaderVariant);

  const subHeaderTag = getSubHeaderTag(headerTag);

  return (
    <article
      className={`StaticList ${classes.root_StaticList} ${className}`}
      aria-label={staticListHeader || undefined}
      {...attrs}
    >
      {staticListImage?.file?.url && (
        <picture className={classes.hero}>
          <img src={staticListImage.file.url} alt="" />
        </picture>
      )}
      <div className={classes.list}>
        {staticListHeader && (
          <header>
            <Typography variant={headerTag}>{staticListHeader}</Typography>
            <RichText className={`RichText ${richText.styles}`}>
              {staticListIntroCopy}
            </RichText>
          </header>
        )}
        {addItems?.map((item, index) => (
          <ListItem
            pos={index + 1}
            length={addItems.length}
            key={`${item?.contentful_id}${index}`}
            tabTitleHeaderType={subHeaderTag}
            {...item}
          />
        ))}
      </div>
    </article>
  );
}

export default StaticList;
