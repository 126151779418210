import createTypography from '@material-ui/core/styles/createTypography';
import palette from './palette';

export default createTypography(palette, {
  fontFamily: [
    'Forma-DJR-Display',
    'Untitled-Sans',
    'Roboto',
    'Helvetica',
    'Arial',
    'sans-serif',
  ].join(','),
  h1: {
    fontSize: '2.25rem',
    fontWeight: 'bold',   
    letterSpacing: '0.720px'
  },
  h2: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    letterSpacing: '0.480px'
  },
  h3: {
    fontSize: '1.125rem',
    fontWeight: 'bold',
    letterSpacing: '0.360px'
  },
  h4: {
    fontSize: '1.125rem',
    fontWeight: 400,    
    letterSpacing: '0.360px'
  },
  h5: {
    fontSize: '1rem',
    fontWeight: 'bold',    
    letterSpacing: '0.320px'
  },
  h6: {
    fontSize: '1rem',
    fontWeight: 400,
    letterSpacing: '0.320px',
    '& p': {
      fontFamily: 'Untitled-Sans',
    }
  },  
  body1: {
    fontSize: '1.125rem',
    fontWeight: 400,
    fontFamily: 'Untitled-Sans',  
  },
  body2: {
    fontSize: '0.875rem',
    fontWeight: 400,
    fontFamily: 'Untitled-Sans',
  },
  subtitle1: {
    fontSize: '0.875rem',
    fontWeight: 400,
    fontFamily: 'Untitled-Sans',  
  },
  subtitle2: {
    fontSize: '0.875rem',
    fontWeight: 700,
    fontFamily: 'Untitled-Sans',  
  },
  button: {
    fontSize: '0.875rem',
    fontWeight: 500,
    textTransform: 'none',
    fontFamily: 'Untitled-Sans',   
  },
});
