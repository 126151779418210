import React, {
  createContext,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react';

import NicknameChangeModal from './NicknameChangeModal';
import { NicknamingPayload } from './types';
import useUpdateAccountNickname from '../../hooks/useUpdateAccountNickname';
import useUpdateServiceAgreementNickname from '../../hooks/useUpdateServiceAgreementNickname';

type Payload = NicknamingPayload | null;

type ContextType = {
  payload: Payload;
  setNicknamingPayload: Dispatch<SetStateAction<Payload>>;
};

const NicknameChangeContext = createContext<ContextType>({
  payload: null,
  setNicknamingPayload: () => null,
});

export const useNicknameChangeContext = () => {
  return useContext(NicknameChangeContext);
};

type Props = {
  children: React.ReactNode;
};

export default function NicknameChangeProvider({ children }: Props) {
  const [payload, setNicknamingPayload] = useState<Payload>(null);
  const {
    updateAccountNickname,
    accountNicknameSaving,
  } = useUpdateAccountNickname();
  const {
    updateSANickname,
    updatingNickname,
  } = useUpdateServiceAgreementNickname();

  return (
    <NicknameChangeContext.Provider value={{ payload, setNicknamingPayload }}>
      {children}
      {payload !== null && (
        <NicknameChangeModal
          nicknameType={payload.type}
          onClose={() => {
            setNicknamingPayload(null);
          }}
          saving={updatingNickname || accountNicknameSaving}
          loading={false}
          onSubmit={async ({ nickname, removeNickname }) => {
            if (payload.type === 'ACCOUNT') {
              await updateAccountNickname(
                payload.value,
                removeNickname ? null : nickname.trim(),
              );
            } else if (payload.type === 'ADDRESS') {
              payload.value.nickName = removeNickname ? null : nickname.trim();
              await updateSANickname(payload.value);
              payload.accountsListCacheUpdate(payload.value);
            }
            setNicknamingPayload(null);
          }}
          defaultNickname={
            payload?.type === 'ACCOUNT'
              ? payload.value.description || ''
              : payload.value?.nickName || ''
          }
          accountSaStatus={
            payload?.type === 'ACCOUNT'
              ? ''
              : payload.value?.accountSaStatus || ''
          }
        />
      )}
    </NicknameChangeContext.Provider>
  );
}
