import { ComponentPropsWithoutRef } from 'react';
import { CTACardFragment } from '../../../__generated__/gatsby-types';
import { Field } from '../../../contentful.d';

export const Omits = ['aria-label', 'style', 'displayOptions'] as const;

interface Base extends CTACardFragment, ComponentPropsWithoutRef<'aside'> {
  readonly imageBorderColor?: Field['Border'];
}

export type CtaCardProps = Partial<Omit<Base, typeof Omits[number]>>;
