import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../../themes/main-colors';
import PgeVideoPlayer from '../pge-video-player';
import PgeButton, { IPGEButtonProps } from '../pge-button/PGEButtons';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

const useStyles = (backgroundColor: string) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: getBackGround(backgroundColor),
        display: 'flex',
        alignItems: 'center',
        minHeight: 360,
        [theme.breakpoints.down('sm')]: {
          display: 'block',
          width: '100%',
          '& .MuiContainer-root': {
            padding: 0,
          },
        },
      },
      fixWidthContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row-reverse',
        [theme.breakpoints.down('sm')]: {
          display: 'block',
        },
      },
      mediaDiv: {
        float: 'right',
        lineHeight: 0,
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          marginBottom: theme.spacing(2.5),
          padding: 0,
        },
        '& > iframe': {
          width: 450,
          height: 230,
          border: `10px solid ${colors.white}`,
          [theme.breakpoints.down('sm')]: {
            height: 260,
            width: '100%',
            borderBottom: `10px solid ${colors.white}`,
            borderTop: 'none',
            borderRight: 'none',
            borderLeft: 'none',
          },
        },
      },
      image: {
        width: 450,
        border: `10px solid ${colors.white}`,
        height: 230,
        objectFit: 'cover',
        [theme.breakpoints.down('sm')]: {
          height: 260,
          width: '100%',
          borderBottom: `10px solid ${colors.white}`,
          borderTop: 'none',
          borderRight: 'none',
          borderLeft: 'none',
        },
      },
      detail: {
        flex: 'auto',
        padding: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(2.5),
        },
      },
      largeHeadline: {
        fontSize: theme.spacing(4.5),
        fontFamily: 'Forma-DJR-Display',
        lineHeight: theme.typography.pxToRem(43.2),
        color: backgroundColor === 'Dark-Blue' ? colors.white : colors.noirBlur,
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(2.5),
        letterSpacing: '0.720px',
        [theme.breakpoints.down('sm')]: {
          fontSize: theme.spacing(3),
          marginBottom: theme.spacing(1.25),
          lineHeight: theme.typography.pxToRem(28.7),
          letterSpacing: '0.480px',
        },
      },
      paragraphHeadline: {
        fontWeight: 'bold',
        fontFamily: 'Forma-DJR-Display',
        lineHeight: theme.typography.pxToRem(27),
        letterSpacing: '0.400px',
        color: backgroundColor === 'Dark-Blue' ? colors.white : colors.noirBlur,
        [theme.breakpoints.down('sm')]: {
          letterSpacing: '0.320px',
        },
      },
      description: {
        fontSize: theme.typography.pxToRem(18),
        lineHeight: theme.typography.pxToRem(27),
        color: backgroundColor === 'Dark-Blue' ? colors.white : colors.noirBlur,
        fontFamily: 'Forma-DJR-Display',
        margin: theme.spacing(0),
      },
    }),
  );

const getBackGround = (color: any | null): any | null => {
  let bgColor = colors.riverBlue;
  switch (color) {
    case 'Light-Blue':
      bgColor = colors.riverBlue;
      break;
    case 'Yellow':
      bgColor = colors.lightYellow;
      break;
    case 'Dark-Blue':
      bgColor = colors.noirBlur;
      break;
    default:
      bgColor = colors.riverBlue;
      break;
  }
  return bgColor;
};

interface SplitHeaderProps {
  image?: string;
  video?: string;
  largeHeadline?: string;
  paragraphHeadline?: string;
  description?: any;
  button?: IPGEButtonProps | null;
  backgroundColor?: string;
}

export default (props: SplitHeaderProps) => {
  const {
    image = '',
    video = '',
    largeHeadline = '',
    paragraphHeadline = '',
    description = '',
    button,
    backgroundColor,
  } = {
    ...props,
  };
  const classes = useStyles(backgroundColor || '')(props);

  return (
    <>
      <div className={classes.root}>
        <Container>
          <div className={classes.fixWidthContainer}>
            <div className={(image || video) && classes.mediaDiv}>
              {image && (
                <img
                  className={classes.image}
                  alt={''}
                  src={image}
                  height="230"
                  width="450"
                />
              )}
              {video && <PgeVideoPlayer video={{ url: `${video}` }} />}
            </div>
            <div className={classes.detail}>
              {largeHeadline?.trim() && (
                <h1 className={classes.largeHeadline}>{largeHeadline}</h1>
              )}
              {paragraphHeadline?.trim() && (
                <Typography
                  variant={'h2'}
                  className={classes.paragraphHeadline}
                >
                  {paragraphHeadline}
                </Typography>
              )}
              {description && (
                <Typography variant={'body1'} className={classes.description}>
                  {description}
                </Typography>
              )}
              {button && <PgeButton {...button} />}
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
