import React, { FC } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import PowerSettingsNewOutlinedIcon from '@material-ui/icons/PowerSettingsNewOutlined';
import LocationOnOutlined from '@material-ui/icons/LocationOnOutlined';
import PaymentOutlinedIcon from '@material-ui/icons/PaymentOutlined';
import AccountCircleOutlined from '@material-ui/icons/AccountCircleOutlined';

import colors from '../../themes/main-colors';
import { useTranslation } from '../../hooks/useTranslation';

const useStyles = makeStyles(() =>
  createStyles({
    shortcutsContainer: {
      padding: 0,
      backgroundColor: colors.lightYellow,
      position: 'relative',
      display: 'flex',
    },
    shortcutButtonRoot: {
      position: 'relative',
      flex: 1,
      margin: 0,
      padding: '8px 0',
      borderRadius: 0,

      '& > span': {
        color: colors.noirBlur,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        maxHeight: 52,
        padding: 4,
      },

      '& > span > h3': {
        margin: '0 0 -10px',
        padding: 0,
        fontSize: '2.0em',
      },

      '& > span > h4': {
        textTransform: 'uppercase',
        fontWeight: 400,
        fontSize: '10px',
        margin: 0,
        padding: 0,
      },
    },
    borderRight: {
      borderRight: '1px solid white',
    },
  }),
);

import ROUTES from '../../routes';

interface ShortcutsBarProps {
  navigateToRoute: (route: string) => (e: any) => Promise<void>;
}

const ShortcutsBar: FC<ShortcutsBarProps> = ({ navigateToRoute }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Container className={classes.shortcutsContainer}>
      <Button
        className={`${classes.shortcutButtonRoot} ${classes.borderRight}`}
        onClick={navigateToRoute(ROUTES.ACCOUNT)}
      >
        <h3>
          <AccountCircleOutlined fontSize="inherit" />
        </h3>
        <h4>{t('ACCOUNT')}</h4>
      </Button>
      <Button
        className={`${classes.shortcutButtonRoot} ${classes.borderRight}`}
        onClick={navigateToRoute(ROUTES.PAYMENT)}
      >
        <h3>
          <PaymentOutlinedIcon fontSize="inherit" />
        </h3>
        <h4>{t('BILLING')}</h4>
      </Button>
      <Button
        className={`${classes.shortcutButtonRoot} ${classes.borderRight}`}
        onClick={navigateToRoute(ROUTES.START_STOP_MOVE)}
      >
        <h3>
          <PowerSettingsNewOutlinedIcon fontSize="inherit" />
        </h3>
        <h4>{t('MOVING')}</h4>
      </Button>
      <Button
        className={classes.shortcutButtonRoot}
        onClick={navigateToRoute(ROUTES.OUTAGES)}
      >
        <h3>
          <LocationOnOutlined fontSize="inherit" />
        </h3>
        <h4>{t('OUTAGE')}</h4>
      </Button>
    </Container>
  );
};

export default ShortcutsBar;
