import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../../../themes/main-colors';

interface StyleProps {
  backgroundColor?: string;
}

const getBackgroundColor = (color: string | undefined): string => {
  let bgColor = colors.white;
  switch (color) {
    case 'None':
      bgColor = colors.white;
      break;
    case 'Blue':
      bgColor = colors.riverBlue;
      break;
    case 'Yellow':
      bgColor = colors.lightYellow;
      break;
    default:
      bgColor = colors.white;
      break;
  }
  return bgColor;
};

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      width: '100%',
      display: 'flex',
      boxSizing: 'border-box',
      contain: 'content',
      position: 'relative',
      flexDirection: 'column',
      padding: theme.spacing(6.25),
      backgroundColor: ({ backgroundColor }: StyleProps) =>
        getBackgroundColor(backgroundColor),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3.75, 2.5),
      },
    },
    content: {
      display: 'flex',
      width: '100%',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'start',
      '& .MuiButtonBase-root': {
        textDecoration: 'underline',
        fontWeight: 700,
      },
    },
    header: {
      marginBottom: theme.spacing(2.5),
    },
    text: {
      padding: 0,
      textAlign: 'inherit',
      color: colors.sparkBlue,
      fontSize: theme.typography.pxToRem(20),

      '&:hover, &:focus': {
        background: 'none',
        textDecoration: 'underline',
      },
    },
  });
});

export default useStyles;
