import createPalette from '@material-ui/core/styles/createPalette';
import mainColors from './main-colors';
// A custom mainTheme for this app
export default createPalette({
  primary: {
    main: mainColors.sparkBlue,
    contrastText: mainColors.white,
  },
  secondary: {
    main: mainColors.riverBlue,
  },
  error: {
    main: mainColors.roseRed,
  },
  info: {
    main: mainColors.sparkBlue,
    contrastText: mainColors.white,
  },
  success: {
    main: mainColors.shoreGreen,
    contrastText: mainColors.white,
  },
  warning: {
    main: mainColors.orange,
    contrastText: mainColors.white,
  },
  background: {
    default: mainColors.siteBackground,
  },
  text: {
    primary: mainColors.noirBlur,
  },
});
