import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import colors from '../../themes/main-colors';

const useStyles = makeStyles(
  ({ palette, typography, breakpoints, spacing }: Theme) => {
    // Colors
    const text = palette.text.primary;

    return createStyles({
      root: {
        margin: 0,
        textAlign: 'center',

        '& figcaption': {
          wordBreak: 'break-word',
          wordWrap: 'break-word',
          overflowWrap: 'break-word',
        },
      },
      menuItem: {
        textAlign: 'center',
        alignContent: 'center',
        backgroundColor: colors.cloudGrey2,
      },
      header: {
        color: text,
        lineHeight: typography.pxToRem(40),
        [breakpoints.down('sm')]: {
          lineHeight: typography.pxToRem(28),
        },
      },
      button: {
        minWidth: '9ch',
        padding: spacing(1, 3),
        boxSizing: 'border-box',
        fontSize: spacing(2),
        borderRight: '1px solid currentColor',
        borderRadius: 0,

        '&:last-child': {
          borderRight: 'none',
        },

        [breakpoints.down('sm')]: {
          padding: spacing(1, 0),

          '& .MuiButton-label': {
            flexDirection: 'column',
          },

          '& .MuiButton-endIcon': {
            marginLeft: spacing(0),
          },
        },
      },
    });
  },
);

export default useStyles;
