import { makeStyles, Typography, createStyles, Grid } from '@material-ui/core';
import React from 'react';
import theme from '../../themes/theme';
import Modal from '../modal';
import colors from '../../themes/main-colors';
import { useTranslation } from '../../hooks/useTranslation';
import SignIn from './SignIn';

type Props = {
  isOpen: boolean;
  handleClose: () => void;
  redirectUrl?: string;
  title?: string;
  subTitle?: React.ReactChild;
  closeModal?: () => void;
};

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      fontSize: '32px',
      color: colors.noirBlur,
      marginBottom: '6px',
      textAlign: 'center',
    },
    container: {
      minWidth: 510,
      maxWidth: 510,
      padding: theme.spacing(3, 8),
      [theme.breakpoints.down('sm')]: {
        padding: '24px 24px',
        minWidth: '100%',
      },
    },
    description: {
      display: 'flex',
      justifyContent: 'center',
    },
    descriptionText: {
      fontSize: '20px',
      color: colors.noirBlur,
      [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
      },
      textAlign: 'center',
      marginBottom: '10px',
    },
  }),
);

const SignInModal = ({
  title,
  subTitle,
  isOpen,
  handleClose,
  redirectUrl,
  closeModal,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Modal
      open={isOpen}
      showCloseIcon={true}
      onClose={handleClose}
      contentWidth="90vw"
      title={
        <Typography
          variant="body1"
          className={classes.title}
          data-testid="modal-title"
        >
          {title ? title : t('CUSTOMER_SIGN_IN')}
        </Typography>
      }
    >
      <Grid className={classes.container}>
        {subTitle && (
          <Typography
            className={classes.descriptionText}
            data-testid="modal-description-text"
          >
            {subTitle}
          </Typography>
        )}

        <SignIn
          redirectUrl={redirectUrl}
          isEmbedded={true}
          closeModal={closeModal}
        />
      </Grid>
    </Modal>
  );
};

export default SignInModal;
