import {
  ApplicationSectionStatus,
  EvRebate,
  SectionErAccountInfoData,
  SectionErRebateConfirmData,
  SectionErRebateInfoData,
  StoreFileInput,
  UpdateSectionErAccountInfoInput,
  UpdateSectionErRebateConfirmInput,
  UpdateSectionErRebateInfoInput,
} from '../../../../__generated__/pgeplus-types';
import routes from '../../../../routes';
import {
  AccountInfo,
  RebateConfirm,
  RebateEligibility,
  RebateInfo,
} from './rebateOnlyForm.types';

export const getPath = (status: ApplicationSectionStatus) => {
  const isBrowser = !(typeof window === 'undefined');

  //We don't want to save navHistory when completing a section for
  //navigating users to next steps when they come back
  if (isBrowser && status !== ApplicationSectionStatus.Completed) {
    const pathname = window.location.pathname;
    const path: string =
      (Object.keys(routes) as (keyof typeof routes)[]).find(key => {
        return routes[key] === pathname;
      }) ?? '';
    return [{ path }];
  }
  return [];
};

function removeUndefinedAndEmptyStrings<T extends object>(obj: T): T {
  if (Array.isArray(obj)) {
    return obj;
  }
  return Object.entries(obj)
    .filter(([_, v]) => v !== null && v !== undefined && v !== '')
    .reduce(
      (acc, [k, v]) => ({
        ...acc,
        [k]: v === Object(v) ? removeUndefinedAndEmptyStrings(v) : v,
      }),
      {},
    ) as T;
}

function cleanObject<T extends object>(o: T): T {
  for (const k in o) {
    if (typeof o[k] === 'object' && o[k] !== null) {
      const value = (o[k] as unknown) as object;
      cleanObject(value);
      if (
        Object.values(value).every(
          (val: any) => val === null || val === undefined || val === '',
        )
      ) {
        delete o[k];
      }
    } else if (o[k] === null || o[k] === undefined) {
      delete o[k];
    }
  }
  return removeUndefinedAndEmptyStrings(o);
}

function getImageData(file: StoreFileInput | undefined) {
  if (file) {
    // @ts-ignore
    delete file?.__typename;
    return file;
  } else {
    return undefined;
  }
}

export const accountInfoERFormStateAdapter = () => ({
  toFormState: (sectionData: SectionErAccountInfoData): AccountInfo => {
    return {
      firstName: sectionData?.accountInfoInput?.preferredContact?.firstName!,
      middleName: sectionData?.accountInfoInput?.preferredContact?.middleName!,
      lastName: sectionData?.accountInfoInput?.preferredContact?.lastName!,
      phoneNumber: sectionData?.accountInfoInput?.preferredContact
        ?.phoneNumber!,
      emailAddress:
        sectionData?.accountInfoInput?.preferredContact?.emailAddress,
      relationship:
        sectionData?.accountInfoInput?.preferredContact?.relationship || '',
      isMainPerson:
        sectionData?.accountInfoInput?.useAccountContact === null
          ? undefined
          : sectionData?.accountInfoInput?.useAccountContact,
      homeType: sectionData?.accountInfoInput?.homeType!,
    };
  },
  fromFormState: (
    formData: AccountInfo,
    applictionId: string,
    sectionId: string,
    status: ApplicationSectionStatus = ApplicationSectionStatus.Pending,
  ): UpdateSectionErAccountInfoInput => {
    return {
      ...cleanObject({
        id: sectionId,

        applicationId: applictionId,
        status,
        homeType: formData.homeType,
        useAccountContact: formData.isMainPerson,
        preferredContact: {
          firstName: formData?.firstName!,
          middleName: formData?.middleName!,
          lastName: formData?.lastName!,
          phoneNumber: formData?.phoneNumber!,
          emailAddress: formData?.emailAddress,
          relationship: formData?.relationship || '',
        },
      }),
      navHistory: getPath(status),
    };
  },
});

export const rebateInfoERFormStateAdapter = () => ({
  toFormState: (sectionData: SectionErRebateInfoData): RebateInfo => {
    return {
      evPulse: {
        doYouOwnTesla: sectionData.rebateInfoInput?.evPulse?.driveTesla!,
        doYouOwnL2Charger: sectionData?.rebateInfoInput?.evPulse?.hasL2Charger!,
        vin: sectionData?.rebateInfoInput?.evPulse?.vin || '',
        vinImage: getImageData(
          sectionData?.rebateInfoInput?.evPulse?.vinImage || undefined,
        ),
      },
      smartCharging: {
        modelNumber: sectionData.rebateInfoInput?.smartCharging?.modelNumber!,
        modelNumberImage: getImageData(
          sectionData.rebateInfoInput?.smartCharging?.modelNumberImage ||
            undefined,
        ),
      },
      qualifiedProductInfo: {
        serialNumber: sectionData.rebateInfoInput?.qualifiedProductInfo
          ?.serialNumber!,
        serialNumberImage: getImageData(
          sectionData.rebateInfoInput?.qualifiedProductInfo
            ?.serialNumberImage || undefined,
        ),
      },
      chargerValidation: {
        isQualifiedProduct: sectionData.chargerValidation?.isQualifiedProduct!,
        product: sectionData.chargerValidation?.product!,
      },
      chargerInstalledOnResidentialMeter: sectionData.rebateInfoInput
        ?.chargerInstalledOnResidentialMeter!,
      smartChargingEnrollmentConfirmation: {
        willEnrollNewSmartChargingDevice: sectionData.rebateInfoInput
          ?.smartChargingEnrollmentConfirmation
          ?.willEnrollNewSmartChargingDevice!,
      },
      evPulseEnrollmentConfirmation: {
        willEnrollNewSmartChargingDevice: sectionData.rebateInfoInput
          ?.evPulseEnrollmentConfirmation?.willEnrollNewSmartChargingDevice!,
        willEnrollNewVehicle: sectionData.rebateInfoInput
          ?.evPulseEnrollmentConfirmation?.willEnrollNewVehicle!,
      },
    };
  },
  fromFormState: (
    formData: RebateInfo,
    applictionId: string,
    sectionId: string,
    status: ApplicationSectionStatus = ApplicationSectionStatus.Pending,
  ): UpdateSectionErRebateInfoInput => {
    return {
      ...cleanObject({
        id: sectionId,
        applicationId: applictionId,

        status,
        evPulse: {
          driveTesla: formData?.evPulse?.doYouOwnTesla!,
          hasL2Charger: formData?.evPulse?.doYouOwnL2Charger,
          vin: formData?.evPulse?.vin,
          vinImage: formData?.evPulse?.vinImage,
        },
        smartCharging: {
          modelNumber: formData.smartCharging?.modelNumber,
          modelNumberImage: formData.smartCharging?.modelNumberImage,
        },
        chargerInstalledOnResidentialMeter:
          formData.chargerInstalledOnResidentialMeter,
        qualifiedProductInfo: {
          ...formData?.qualifiedProductInfo,
          serialNumberImage: formData?.qualifiedProductInfo?.serialNumberImage,
        },
        smartChargingEnrollmentConfirmation: {
          willEnrollNewSmartChargingDevice:
            formData?.smartChargingEnrollmentConfirmation
              ?.willEnrollNewSmartChargingDevice,
        },
        evPulseEnrollmentConfirmation: {
          willEnrollNewSmartChargingDevice:
            formData?.evPulseEnrollmentConfirmation
              ?.willEnrollNewSmartChargingDevice,
          willEnrollNewVehicle:
            formData?.evPulseEnrollmentConfirmation?.willEnrollNewVehicle,
        },
      }),
      navHistory: getPath(status),
    };
  },
});

export const rebateConfirmERFormStateAdapter = () => ({
  toFormState: (sectionData: SectionErRebateConfirmData): RebateConfirm => {
    return {
      iqbd: {
        checkEligibility: sectionData?.rebateConfirmInput?.iqbdQualification
          ?.checkEligibility!,
        grossIncome: sectionData?.rebateConfirmInput?.iqbdQualification
          ?.grossIncome!,
        houseHoldSize: sectionData?.rebateConfirmInput?.iqbdQualification
          ?.houseHoldSize!,
        grossIncomeConfirmed: sectionData?.rebateConfirmInput?.iqbdQualification
          ?.grossIncomeConfirmed!,
      },
      rebateQualification: {
        wifiAvailable: sectionData?.rebateConfirmInput?.rebateQualification
          ?.wifiAvailable!,
        haveChargerActivated: sectionData?.rebateConfirmInput
          ?.rebateQualification?.smartCharging?.chargerActivatedInApp!,
        doDriveTesla: sectionData?.rebateConfirmInput?.rebateQualification
          ?.evPulse?.willConnectEVPulseApp!,
      },
      evPulse: {
        vin: sectionData?.rebateConfirmInput?.rebateQualification?.evPulse
          ?.vin!,
        vinImage: getImageData(
          sectionData?.rebateConfirmInput?.rebateQualification?.evPulse
            ?.vinImage || undefined,
        ),
      },
      connectTeslaToEVPulse: {
        isTeslaConnectedToEVPulse: sectionData?.rebateConfirmInput
          ?.rebateQualification?.evPulse?.connectedEvPulseApp!,
      },
      evPulseRebateAmount: sectionData?.appliedRebate?.amount!,
      appliedRebate: sectionData?.appliedRebate! as EvRebate,
      rebateEligibility: sectionData?.rebateEligibility! as RebateEligibility,
      chargerInstallInfo: {
        installationDate:
          sectionData?.rebateConfirmInput?.chargerInstallInfo?.installationDate,
        panelUpgraded:
          sectionData?.rebateConfirmInput?.chargerInstallInfo?.panelUpgraded,
        chargerCost:
          sectionData?.rebateConfirmInput?.chargerInstallInfo?.chargerCost,
        installationCost:
          sectionData?.rebateConfirmInput?.chargerInstallInfo?.installationCost,
        receiptImages: sectionData?.rebateConfirmInput?.chargerInstallInfo?.receiptImages?.map(
          image => getImageData(image)!,
        ),
      },
      chargerActivatedInAppConfirmed: sectionData?.rebateConfirmInput
        ?.rebateQualification?.wifiAvailable
        ? sectionData.rebateConfirmInput?.rebateQualification?.smartCharging
            ?.chargerActivatedInAppConfirmed!
        : sectionData?.rebateConfirmInput?.rebateQualification?.evPulse
            ?.chargerActivatedInAppConfirmed!,
      preRebateEligibility1: {
        evRebate: sectionData?.preRebateEligibility1?.evRebate!,
      },
      preRebateEligibility2: {
        evRebate: sectionData?.preRebateEligibility2?.evRebate!,
      },
      rebateInfo: sectionData?.rebateInfo,
    };
  },
  fromFormState: (
    formData: RebateConfirm,
    applictionId: string,
    sectionId: string,
    status: ApplicationSectionStatus = ApplicationSectionStatus.Pending,
  ): UpdateSectionErRebateConfirmInput => {
    return {
      ...cleanObject({
        id: sectionId,
        applicationId: applictionId,
        status: status,
        rebateQualification: {
          wifiAvailable: formData?.rebateQualification?.wifiAvailable,
          evPulse: {
            vin: formData?.evPulse?.vin,
            vinImage: formData?.evPulse?.vinImage,
            willConnectEVPulseApp: formData?.rebateQualification?.doDriveTesla,
            connectedEvPulseApp:
              formData?.connectTeslaToEVPulse?.isTeslaConnectedToEVPulse,
            ...(formData?.connectTeslaToEVPulse?.isTeslaConnectedToEVPulse !==
              undefined &&
              !formData?.connectTeslaToEVPulse?.isTeslaConnectedToEVPulse && {
                chargerActivatedInAppConfirmed:
                  formData?.chargerActivatedInAppConfirmed,
              }),
          },
          smartCharging: {
            chargerActivatedInApp:
              formData?.rebateQualification?.haveChargerActivated,
            ...(formData?.rebateQualification?.haveChargerActivated !==
              undefined &&
              !formData?.rebateQualification?.haveChargerActivated && {
                chargerActivatedInAppConfirmed:
                  formData?.chargerActivatedInAppConfirmed,
              }),
          },
        },
        confirmChargerConnectedToResidentialMeter:
          formData?.confirmChargerConnectedToResidentialMeter,
        confirmDriveElectricVehicle: formData?.confirmDriveElectricVehicle,
        agreeToTermsAndConditions: formData?.agreeToTermsAndConditions,
        chargerInstallInfo: {
          installationDate: formData.chargerInstallInfo?.installationDate,
          panelUpgraded: formData.chargerInstallInfo?.panelUpgraded,
          installationCost: formData.chargerInstallInfo?.installationCost,
          chargerCost: formData.chargerInstallInfo?.chargerCost,
          receiptImages:
            formData.chargerInstallInfo?.receiptImages || undefined,
        },
        iqbdQualification: {
          checkEligibility: formData.iqbd?.checkEligibility,
          grossIncome: formData.iqbd?.grossIncome
            ? Number(formData.iqbd?.grossIncome)
            : undefined,
          grossIncomeConfirmed: formData.iqbd?.grossIncomeConfirmed,
          houseHoldSize: formData.iqbd?.houseHoldSize
            ? Number(formData.iqbd?.houseHoldSize)
            : undefined,
        },
      }),
      navHistory: getPath(status),
    };
  },
});
