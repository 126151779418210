import { TransitionContext } from '../providers/TransitionProvider';
import { useContext } from 'react';
import { NotificationsContext } from '../providers/NotificationsProvider';
import { useTranslation } from './useTranslation';

export default function useWrapWithLoader() {
  const { setState: updateTransition } = useContext(TransitionContext);
  const notificationContext = useContext(NotificationsContext);
  const { t } = useTranslation();

  function wrapWithLoader<T extends Array<any>, R>(
    promiseFn: (...args: T) => Promise<R>,
    makeErrorMessage: (e: any) => string = () =>
      t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
  ): (...args: T) => Promise<R> {
    return async (...args: T) => {
      notificationContext.setState({ isOpen: false });
      updateTransition({ open: true });

      try {
        return await promiseFn(...args);
      } catch (e) {
        notificationContext.setState({
          isOpen: true,
          message: makeErrorMessage(e),
          severity: 'error',
        });
        typeof window !== 'undefined' && window.scrollTo(0, 0);
        throw e;
      } finally {
        updateTransition({ open: false });
      }
    };
  }

  return {
    wrapWithLoader,
  };
}
