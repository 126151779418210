import { getAccountTypeQuery } from '../queries/getAccountType.query';
import { AccountDetail, AccountType } from '../__generated__/pge-types';
import useAuth from './useAuth';
import useAuthQuery from './useAuthQuery';
import useSelectedAccountParams from './useSelectedAccountParams';

type Props = {
  skip?: boolean;
};

export default function useGetAccountType({ skip = false }: Props) {
  const { isAuthenticated } = useAuth();
  const { accountParams } = useSelectedAccountParams({
    ignoreNoAccounts: true,
  });

  const { data: accountTypeData, loading, error } = useAuthQuery<{
    getAccountDetails: Array<AccountDetail>;
  }>(getAccountTypeQuery, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    errorPolicy: 'all',
    skip: !accountParams || !isAuthenticated || skip,
  });

  const accountType =
    accountTypeData &&
    accountTypeData.getAccountDetails &&
    accountTypeData?.getAccountDetails[0]?.accountType;
  const isResidentialAccount = accountType === AccountType?.Res;
  const isCommercialAccount = accountType === AccountType?.Com;

  return {
    accountTypeData,
    isResidentialAccount,
    isCommercialAccount,
    loading,
    accountTypeError: error,
  };
}
