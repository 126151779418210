import { Tokens } from './types';
class TokenStorage {
  // eslint-disable-next-line no-empty-function
  constructor(private storage: Storage, public storageKey: string) {}

  hasTokens(): boolean {
    try {
      this.get();
      return true;
    } catch (err) {
      return false;
    }
  }

  /**
   * Checks if tokens object is expiring in the next 5 minutes
   */
  isExpiring(): boolean {
    const token = this.get();
    return Date.now() + 300000 >= token.expiresAt;
  }

  isExpired(): boolean {
    const token = this.get();
    return Date.now() >= token.expiresAt;
  }

  isValid(tokens: Tokens): boolean {
    const keys = Object.keys(tokens);
    const expectedKeys = [
      'idToken',
      'refreshToken',
      'accessToken',
      'expiresAt',
    ];
    return expectedKeys.every(key => keys.includes(key));
  }

  clear() {
    this.storage.removeItem(this.storageKey);
    if (typeof document !== 'undefined') {
      document.cookie =
        'pge-idToken=; path=/; domain=portlandgeneral.com; expires=Thu, 01 Jan 1970 00:00:00 GMT;';
    }
  }

  set(tokens: Tokens): void {
    if (!this.isValid(tokens)) {
      throw new Error('Invalid Tokens Object');
    }

    // TODO: This is a workaround to allow sharing tokens across new and old sites
    // The set cookie code should be removed as soon as the legacy site is completely phased out
    document.cookie = `pge-idToken=${encodeURI(
      tokens.idToken?.token!,
    )}; path=/; domain=portlandgeneral.com; max-age=${tokens.expiresAt / 1000}`;

    this.storage.setItem(this.storageKey, JSON.stringify(tokens));
  }

  get(): Tokens {
    try {
      const tokensString = this.storage.getItem(this.storageKey);
      const tokens = tokensString ? JSON.parse(tokensString) : {};
      if (!this.isValid(tokens)) {
        throw new Error('Invalid Tokens Object');
      }
      return tokens;
    } catch {
      throw new Error('Error retrieving token from storage');
    }
  }
}
export default TokenStorage;
