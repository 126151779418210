import React from 'react';
import { Database } from 'firebase/database';
import { database } from '../firebase';

const firebaseContext = React.createContext<{ database: Database | null }>({
  database: null,
});
const { Provider } = firebaseContext;

const FirebaseProvider = (props: any) => {
  return <Provider value={{ database }}>{props.children}</Provider>;
};

export { FirebaseProvider, firebaseContext };
