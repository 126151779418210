import React, { useState, useEffect, useMemo } from 'react';
import queryString from 'query-string';
import {
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  sendPasswordResetEmail as _sendPasswordResetEmail,
  AuthErrorCodes,
  verifyPasswordResetCode as _verifyPasswordResetCode,
  confirmPasswordReset as _confirmPasswordReset,
} from 'firebase/auth';
import { auth } from '../../firebase';
import { navigate } from 'gatsby';
import authServiceInstaller from '../../lib/authServiceInstaller';
import {
  clearSessionAndLocalStorage,
  getFromStorage,
  removeFromStorage,
  setInStorage,
} from '../../util/storage-utils';
import routes from '../../routes';
import { useLocation } from '@reach/router';
import useContentMgmt from '../useContentMgmt';
import { FirebaseError } from 'firebase/app';
import useFormState from '../useFormState';
import SignInValidationRules, {
  Model,
  createPasswordResetValidationFunction,
} from '../../components/installer-portal/auth/SignInValidation.rules';
import useTransition from '../useTransition';
import { AuthState } from '../../lib/AuthenticationService/types';
import microcopyGroupIds from '../../components/pge-plus-common/microcopyGroupIds';
import useUtil from './useUtil';
import apolloClient from '../../lib/apolloClient';

type QueryParams = {
  jobRequest?: string;
  mode?: string;
  oobCode?: string;
  continueUrl?: string;
  lang?: string;
};

const installerEmailKey = 'installerEmail';

export const INSTALLER_SIGNIN_EMAIL_ID_STORAGE_KEY = 'emailForInstallerSignIn';

const useInstallerAuth = () => {
  const [authState, setState] = useState<AuthState>(
    authServiceInstaller.getState(),
  );
  const { content } = useContentMgmt(
    microcopyGroupIds.PGE_PLUS_INSTALLER_SIGN_IN,
  );
  const { hideTransition, showTransition } = useTransition();

  const location = useLocation();

  const emailFromStorage: string | undefined = useMemo(
    () => getFromStorage(installerEmailKey),
    [],
  );
  const defaultEmail = emailFromStorage;

  const form = useFormState(
    {
      email: defaultEmail || '',
      password: '',
      rememberMe: true,
    },
    {
      validate: SignInValidationRules,
      validationContext: {
        content,
      },
    },
  );

  const passwordResetForm = useFormState(
    {
      email: '',
      password: '',
      confirmPassword: '',
    },
    {
      validate: createPasswordResetValidationFunction,
      validationContext: {
        content,
      },
    },
  );

  useEffect(() => {
    return authServiceInstaller.subscribe(setState);
  }, []);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setErrorNotification } = useUtil();
  const queryParams = queryString.parse(location.search) as QueryParams;

  const sendSignInLink = async (email: string) => {
    const baseURL = window.location.origin;
    const state = location?.state as any;
    const continueURL = Boolean(state?.redirectUrl)
      ? baseURL + state.redirectUrl
      : baseURL + routes.PGE_PLUS_INSTALLER_PORTAL_JOB_LISTING;

    const actionCodeSettings = {
      url: continueURL,
      // This URL should be the URL where you want to redirect the user after they click the sign-in link in their email.
      handleCodeInApp: true,
    };

    try {
      setIsLoading(true);
      await sendSignInLinkToEmail(auth, email, actionCodeSettings);
      setErrorNotification(
        true,
        content.get('INSTELLER_SIGN_IN_LINK_SENT'),
        'success',
      );
      setIsLoading(false);

      window.localStorage.setItem(INSTALLER_SIGNIN_EMAIL_ID_STORAGE_KEY, email);
    } catch (err) {
      setIsLoading(false);

      if (
        (err as FirebaseError)?.code === AuthErrorCodes.ADMIN_ONLY_OPERATION
      ) {
        setErrorNotification(
          true,
          content.get('INSTELLER_SIGN_IN_LINK_SENT'),
          'success',
        );
        setIsLoading(false);
        return;
      }

      setErrorMessage(content.get('GENERIC_ERROR_NOTIFICATION_MESSAGE') || '');
    }
  };

  const isSignInTokenLink = (): boolean => {
    return isSignInWithEmailLink(auth, window.location.href);
  };

  const signInWithEmailLink = async (email: string) => {
    try {
      setIsLoading(true);
      await authServiceInstaller.signInWithEmailLink(email);
      handleRedirect();
    } catch (e) {
      if ((e as FirebaseError).message === AuthErrorCodes.INVALID_OOB_CODE) {
        setErrorMessage(content.get('LOGIN_LINK_NOT_VALID') || '');
      } else {
        setErrorMessage(
          content.get('GENERIC_ERROR_NOTIFICATION_MESSAGE') || '',
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleRedirect = () => {
    if (queryParams.continueUrl) {
      void navigate(
        queryParams.continueUrl.replace(window.location.origin, ''),
      );
    } else {
      void navigate(routes.PGE_PLUS_INSTALLER_PORTAL_JOB_LISTING);
    }
  };

  //const signOut = authServiceInstaller.signOut.bind(authServiceInstaller);
  const handleSignOut = async () => {
    // Queries need to be stopped before the store is cleared, if a query is in-flight,
    // an error will be returned and we will respond with an error page
    //stopAllQueries();
    await apolloClient.stop();
    await apolloClient.clearStore();
    clearSessionAndLocalStorage();
    authServiceInstaller.signOut();
  };

  const signOut = async () => {
    await navigate(routes.PGE_PLUS_INSTALLER_PORTAL_SIGN_IN);
    await handleSignOut();
  };

  const refreshTokens = authServiceInstaller.refreshTokens.bind(
    authServiceInstaller,
  );
  const startTokenRefresher = authServiceInstaller.startTokenRefresher.bind(
    authServiceInstaller,
  );
  const stopTokenRefresher = authServiceInstaller.stopTokenRefresher.bind(
    authServiceInstaller,
  );

  const handleLogin = async (data: Model) => {
    showTransition(content.get('SUBMITTING_REQUEST') || '');
    try {
      handleRememberMe(Boolean(data.rememberMe), data.email);
      await authServiceInstaller.signInWithPassword(data.email, data.password);

      handleRedirect();
      hideTransition();
    } catch (err) {
      console.log(err);
      showErrorMessage(err as Error);
      hideTransition();
    }
  };

  const showErrorMessage = (error: Error): void => {
    switch (error.message) {
      case 'EMAIL_NOT_FOUND':
        form.setError('email', content.get('ERROR_AUTH_INVALID_DEFAULT'));
        break;
      case 'INVALID_PASSWORD':
        form.setError('password', content.get('ERROR_AUTH_INVALID_DEFAULT'));
        break;
      case 'TOO_MANY_ATTEMPTS_TRY_LATER':
        form.setError('password', content.get('ERROR_AUTH_EXCEEDED_ATTEMPTS'));
        break;
      case AuthErrorCodes.INVALID_PASSWORD:
        form.setError('password', content.get('ERROR_AUTH_INVALID_DEFAULT'));
        break;
      case AuthErrorCodes.USER_DELETED:
        form.setError('email', content.get('ERROR_AUTH_INVALID_DEFAULT'));
        break;
      case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
        form.setError('password', content.get('ERROR_AUTH_EXCEEDED_ATTEMPTS'));
        break;

      default:
        console.warn('unhandled auth exception', error);
        form.setError('password', content.get('ERROR_AUTH_UNEXPECTED'));
    }
  };

  const handleRememberMe = (rememberMe: boolean, email: string): void => {
    if (rememberMe) {
      setInStorage(installerEmailKey, email);
    } else {
      removeFromStorage(installerEmailKey);
    }
  };

  const handleLoginWithPassword = form.submit(handleLogin);

  const sendPasswordResetEmail = async (email: string) => {
    return await _sendPasswordResetEmail(auth, email);
  };

  const verifyPasswordResetCode = (actionCode: string) => {
    return _verifyPasswordResetCode(auth, actionCode);
  };
  const confirmPasswordReset = (actionCode: string, password: string) => {
    return _confirmPasswordReset(auth, actionCode, password);
  };

  return {
    ...authState,
    form,
    passwordResetForm,
    isLoading,
    authUser: auth?.currentUser,
    errorMessage,
    signInWithEmailLink,
    isSignInTokenLink,
    signOut,
    startTokenRefresher,
    stopTokenRefresher,
    refreshTokens,
    sendSignInLink,
    setErrorMessage,
    handleLoginWithPassword,
    sendPasswordResetEmail,
    verifyPasswordResetCode,
    confirmPasswordReset,
  };
};

export default useInstallerAuth;
