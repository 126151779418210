import { local } from './storage';
import AuthService, { TokenStorage } from './AuthenticationService';
import {
  ApigeeAuthAdapter,
  FirebaseAuthAdapter,
  HybridAuthAdapter,
} from './AuthenticationService/authAdapters';
import config from '../config';
import { AuthAdapter } from './AuthenticationService/types';

const apigeeAdapter = new ApigeeAuthAdapter({
  baseUrl: config.apigee.baseUrl,
  clientId: config.apigee.auth.clientId,
});

const firebaseAdapter = new FirebaseAuthAdapter();

let authAdapter: AuthAdapter = firebaseAdapter;

if (config.auth.type === 'Hybrid') {
  authAdapter = new HybridAuthAdapter(firebaseAdapter, apigeeAdapter);
}
const tokensStorage = new TokenStorage(local, 'pge-token');

export default new AuthService(authAdapter, tokensStorage);
