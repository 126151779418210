import React, { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import PgeButton, { IPGEButtonProps } from '../pge-button/PGEButtons';
import { Typography } from '@material-ui/core';
import colors from '../../themes/main-colors';
import Container from '@material-ui/core/Container';
import RichText, { richTextStyles } from '../rich-text';
import { ModuleCallToActionFragment as Fragment } from '../../__generated__/gatsby-types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
    },
    main: {
      borderRadius: theme.spacing(0.625),
    },
    default: {
      backgroundColor: colors.white,
    },
    riverBlue: {
      backgroundColor: colors.riverBlue,
    },
    cloudGrey2: {
      backgroundColor: colors.cloudGrey2,
    },
    linenWhite: {
      backgroundColor: colors.linenWhite,
    },
    lightYellow: {
      backgroundColor: colors.lightYellow,
    },
    headerStyle: {
      fontFamily: 'Forma-DJR-Display',
      color: colors.noirBlur,
      fontWeight: 'bold', // ???
    },
    descStylePrimary: {
      color: colors.noirBlur,
      fontSize: theme.spacing(2.5),
      lineHeight: theme.typography.pxToRem(27),
      '& p': {
        fontSize: '1.1rem',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(2),
        lineHeight: theme.typography.pxToRem(20),
      },
    },
    descStyleSecondary: {
      fontSize: theme.spacing(2.5),
      color: colors.noirBlur,
      lineHeight: theme.typography.pxToRem(27),
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(2),
        lineHeight: theme.typography.pxToRem(20),
      },
      '& p': {
        fontSize: '1.1rem',
      },
      '& a, & button': {
        color: 'inherit',
        fontWeight: 700,
        textDecoration: 'underline',
      },
      '& a span': {
        textDecoration: 'underline',
      },
    },
    buttonStyle: {
      marginTop: theme.spacing(2.5),
    },
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: 0,
      [theme.breakpoints.up('sm')]: {
        flexDirection: ({ imagePosition }: CtaProps) =>
          imagePosition === 'Left' ? 'row-reverse' : 'row',
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        padding: 0,
      },
    },
    secondContainer: {
      padding: '50px',
      [theme.breakpoints.down('sm')]: {
        padding: '30px',
      },
    },
    copyContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '63%',
      padding: '50px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: '30px',
      },
    },
    imageContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '37%',
      minWidth: '460px',
      minHeight: '478px',
      [theme.breakpoints.down('sm')]: {
        minWidth: '100%',
        minHeight: '229px',
      },
    },
    imageStyle: {
      objectFit: 'cover',
      width: '460px',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '229px',
      },
    },
  }),
);

interface CtaProps {
  header?: string;
  headingType?: string;
  description?: Fragment['shortDescription'];
  button?: IPGEButtonProps | null;
  bgMode?: string;
  image?: string;
  imagePosition?: string;
}

const getBgModeClass = (bgMode: string, props: CtaProps) => {
  let rootClass = '';
  const classes = useStyles(props);
  let isDefault = false;
  switch (bgMode) {
    case 'Blue':
      rootClass = `${classes.main} ${classes.riverBlue}`;
      break;
    case 'None':
      rootClass = `${classes.main} ${classes.default}`;
      break;
    case 'Grey':
      rootClass = `${classes.main} ${classes.cloudGrey2}`;
      break;
    case 'Linen-White':
      rootClass = `${classes.main} ${classes.linenWhite}`;
      break;
    case 'Yellow':
      rootClass = `${classes.main} ${classes.lightYellow}`;
      break;
    default:
      rootClass = `${classes.main} ${classes.default}`;
      isDefault = true;
      break;
  }

  const primaryHeader = classes.headerStyle;
  let descClass = `${classes.descStyleSecondary}`;

  if (
    bgMode === 'Blue' ||
    bgMode === 'Grey' ||
    bgMode === 'None' ||
    isDefault
  ) {
    descClass = classes.descStylePrimary;
  }

  return {
    rootClass: rootClass,
    primaryHeader: primaryHeader,
    descClass: descClass,
  };
};

const Cta: FC<CtaProps> = props => {
  const classes = useStyles(props);
  const richText = richTextStyles();
  const { bgMode = '', header, description, button, headingType } = {
    ...props,
  };
  const { rootClass, primaryHeader, descClass } = getBgModeClass(bgMode, props);

  let headingElementType: 'h1' | 'h2' | 'h3' | 'h4' = 'h2';

  switch (headingType) {
    case 'h1':
      headingElementType = 'h1';
      break;
    case 'h2':
      headingElementType = 'h2';
      break;
    case 'h3':
      headingElementType = 'h3';
      break;
    case 'h4':
      headingElementType = 'h4';
      break;
    default:
      headingElementType = 'h2';
  }

  return (
    <div className={`${classes.root} ${rootClass}`}>
      {props.image ? (
        <Container maxWidth="lg" className={classes.container}>
          <div className={classes.copyContainer}>
            {header && (
              <Typography
                variant={headingElementType}
                className={primaryHeader}
              >
                {header}
              </Typography>
            )}
            {description && (
              <RichText className={`${richText.styles} ${descClass}`}>
                {description}
              </RichText>
            )}
            {button && (
              <div className={classes.buttonStyle}>
                <PgeButton {...button} disableRippleEffect />
              </div>
            )}
          </div>
          <div className={classes.imageContainer}>
            <img src={props.image} className={classes.imageStyle} />
          </div>
        </Container>
      ) : (
        <Container maxWidth="lg" className={classes.secondContainer}>
          {header && (
            <Typography variant={headingElementType} className={primaryHeader}>
              {header}
            </Typography>
          )}
          {description && (
            <RichText className={`${richText.styles} ${descClass}`}>
              {description}
            </RichText>
          )}
          {button && (
            <div className={classes.buttonStyle}>
              <PgeButton {...button} disableRippleEffect />
            </div>
          )}
        </Container>
      )}
    </div>
  );
};

export default Cta;
