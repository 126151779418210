type Args<Fn> = Fn extends (...args: infer U) => any ? U : never;

type BasePayloadMaker = (...args: any) => any;
type PayloadMakers = Record<string, BasePayloadMaker>;
export type ActionCreators<T extends PayloadMakers = PayloadMakers> = {
  [K in keyof T]: (
    ...args: Args<T[K]>
  ) => { type: K; payload: ReturnType<T[K]> };
};

type ActionTypes<AC extends ActionCreators> = keyof AC;
type Actions<AC extends ActionCreators> = ReturnType<AC[keyof AC]>;

type ReducerMap<State, AC extends ActionCreators> = {
  [k in ActionTypes<AC>]: (state: State, action: ReturnType<AC[k]>) => State;
};

type Reducer<State, AC extends ActionCreators> = (
  state: State,
  action: Actions<AC>,
) => State;

export function createReducer<State, AC extends ActionCreators>(
  reducerMap: ReducerMap<State, AC>,
): Reducer<State, AC> {
  return (state: State, action: Actions<AC>) => {
    return reducerMap[action.type](state, action);
  };
}

export function makeActions<T extends PayloadMakers>(
  payloadMakers: T,
): ActionCreators<T> {
  return Object.keys(payloadMakers).reduce((acc, key) => {
    (acc as any)[key] = (...args: any) => ({
      type: key,
      payload: payloadMakers[key](...args),
    });
    return acc;
  }, {}) as any;
}
