import React, { FC, createContext, useContext, useState } from 'react';
import { AccountDetail } from '../__generated__/pge-types';
import { navigate } from 'gatsby';
import {
  AccountInfo,
  RebateConfirm,
  RebateInfo,
} from '../components/pge-plus/ev-charger/rebate-only-flow/rebateOnlyForm.types';
import {
  ApplicationEr,
  ApplicationErSectionType,
  ApplicationSection,
  ApplicationSectionStatus,
  SectionErAccountInfo,
  SectionErRebateConfirm,
  SectionErRebateInfo,
} from '../__generated__/pgeplus-types';
import {
  accountInfoERFormStateAdapter,
  rebateConfirmERFormStateAdapter,
  rebateInfoERFormStateAdapter,
} from '../components/pge-plus/ev-charger/rebate-only-flow/rebateOnlyFormState.adapter';
import routes from '../routes';

interface Props {
  children: React.ReactNode;
}

export type RebateOnlyFormState = {
  application: ApplicationEr | null;
  account: AccountDetail | null;
  accountInfo: AccountInfo;
  rebateInfo: RebateInfo;
  rebateConfirm: RebateConfirm;
};

export type RebateOnlyContextState = {
  formState: RebateOnlyFormState;
  initFormStateAndNavigate: (application: ApplicationEr) => void;
  resetForm: () => void;
  updateFormState: <T extends RebateOnlyFormState, K extends keyof T>(
    key: K,
    data: T[K],
  ) => boolean;
  getSectionData: (section: any) => any;
};

const inititalValues: RebateOnlyContextState = {
  formState: {
    application: null,
    account: null,
    accountInfo: {},
    rebateConfirm: {},
    rebateInfo: {},
  },
  initFormStateAndNavigate: () => false,
  updateFormState: () => false,
  resetForm: () => false,
  getSectionData: () => undefined,
};

const RebateOnlyFormStateContext = createContext<RebateOnlyContextState>(
  inititalValues,
);

const RebateOnlyFormStateProvider: FC<Props> = ({ children }) => {
  const [formState, setFormState] = useState<RebateOnlyFormState>(
    inititalValues.formState,
  );

  const continueWhereLeftOff = (application: ApplicationEr) => {
    if (!application) {
      throw new Error('Applicaion details not found');
    }

    const sortOrder = [
      ApplicationErSectionType.ErRebateInfo,
      ApplicationErSectionType.ErAccountInfo,
      ApplicationErSectionType.ErRebateConfirm,
    ];

    const pendingSteps = application.details
      .sort(
        (a, b) =>
          sortOrder.indexOf(a.sectionType) - sortOrder.indexOf(b.sectionType),
      )
      .filter(
        section =>
          ((section as unknown) as ApplicationSection).status ===
          ApplicationSectionStatus.Pending,
      );

    const pendingStep =
      pendingSteps?.[0]?.sectionType || ApplicationErSectionType.ErRebateInfo;
    switch (pendingStep) {
      case ApplicationErSectionType.ErRebateConfirm:
        redirectToRebateConfirm(application);
        break;
      case ApplicationErSectionType.ErAccountInfo:
        void navigate(routes.PGE_PLUS_REBATE_ONLY_CUSTOMER_INFORMATION);
        break;
      case ApplicationErSectionType.ErRebateInfo:
        void redirectToRebateInfo(application);
        break;
    }
  };

  const redirectToRebateInfo = (application: ApplicationEr) => {
    if (application.navHistory?.[0]?.path) {
      const keyTyped = application.navHistory?.[0]?.path as keyof typeof routes;
      if (routes[keyTyped]) {
        void navigate(routes[keyTyped]);
        return;
      }
    }

    const rebateInfoSection = application?.details.find(
      detail => detail.sectionType === ApplicationErSectionType.ErRebateInfo,
    ) as SectionErRebateInfo;

    if (rebateInfoSection?.data?.mustVerifyResidentialMeter) {
      void navigate(routes.PGE_PLUS_REBATE_ONLY_CONFIRM_YOUR_INFORMATION);
    } else if (rebateInfoSection?.data.rebateInfo?.existingEnrollments) {
      if (
        rebateInfoSection?.data.rebateInfo?.existingEnrollments.evPulse &&
        rebateInfoSection?.data.rebateInfo?.existingEnrollments.evPulse.length >
          0
      ) {
        void navigate(routes.PGE_PLUS_REBATE_ONLY_VERIFY_EVPULSE_REBATE, {
          state: {
            identifier:
              rebateInfoSection?.data.rebateInfo?.existingEnrollments.evPulse[0]
                .vin,
          },
        });
      } else if (
        rebateInfoSection?.data.rebateInfo?.existingEnrollments.smartCharging &&
        rebateInfoSection?.data.rebateInfo?.existingEnrollments.smartCharging
          .length > 0
      ) {
        void navigate(routes.PGE_PLUS_REBATE_ONLY_VERIFY_CHARGER_REBATE, {
          state: {
            identifier:
              rebateInfoSection?.data.rebateInfo?.existingEnrollments
                .smartCharging[0].serialNo,
          },
        });
      } else {
        void navigate(routes.PGE_PLUS_REBATE_ONLY_UPLOAD_MODEL_NUMBER);
      }
    } else {
      void navigate(routes.PGE_PLUS_REBATE_ONLY_UPLOAD_MODEL_NUMBER);
    }
  };

  const redirectToRebateConfirm = (application: ApplicationEr) => {
    if (application.navHistory?.[0]?.path) {
      const keyTyped = application.navHistory?.[0]?.path as keyof typeof routes;

      if (routes[keyTyped]) {
        void navigate(routes[keyTyped]);
        return;
      }
    }

    const rebateInfoSection = application?.details.find(
      detail => detail.sectionType === ApplicationErSectionType.ErRebateInfo,
    ) as SectionErRebateInfo;

    const rebateInfo = rebateInfoERFormStateAdapter().toFormState(
      rebateInfoSection.data,
    );

    if (
      !rebateInfo.chargerValidation?.isQualifiedProduct &&
      rebateInfo.evPulse?.vin
    ) {
      void navigate(routes.PGE_PLUS_REBATE_ONLY_ELIGIBILITY_CONFIRMATION, {
        state: {
          message: 'EV_PULSE_REBATE_OFFER_DETAILS',
          backButtonRoute: routes.PGE_PLUS_REBATE_ONLY_CUSTOMER_INFORMATION,
          nextButtonRoute: routes.PGE_PLUS_REBATE_ONLY_CONNECT_TESLA,
        },
      });
    } else {
      void navigate(routes.PGE_PLUS_REBATE_ONLY_HIGHER_REBATES_CHECK);
    }
  };

  const initFormStateAndNavigate = (application: ApplicationEr) => {
    if (!application) {
      throw new Error('Application details not found');
    }

    updateFormState('application', application);

    application?.details.map((section: any) => {
      if (section.sectionType === ApplicationErSectionType.ErAccountInfo) {
        updateFormState(
          'accountInfo',
          accountInfoERFormStateAdapter().toFormState(
            (section as SectionErAccountInfo).data,
          ),
        );
      }
      if (section.sectionType === ApplicationErSectionType.ErRebateInfo) {
        updateFormState(
          'rebateInfo',
          rebateInfoERFormStateAdapter().toFormState(
            (section as SectionErRebateInfo).data,
          ),
        );
      }
      if (section.sectionType === ApplicationErSectionType.ErRebateConfirm) {
        updateFormState(
          'rebateConfirm',
          rebateConfirmERFormStateAdapter().toFormState(
            (section as SectionErRebateConfirm).data,
          ),
        );
      }
    });

    continueWhereLeftOff(application);
  };

  const updateFormState = <T extends RebateOnlyFormState, K extends keyof T>(
    key: K,
    data: T[K],
  ) => {
    setFormState(e => ({ ...e, [key]: data }));
    return true;
  };

  const resetForm = () => {
    setFormState(inititalValues.formState);
  };

  const getSectionData = (section: any) => {
    return formState?.application?.details.filter(
      (sec: any) => sec.sectionType === section,
    )[0];
  };

  return (
    <RebateOnlyFormStateContext.Provider
      value={{
        formState,
        initFormStateAndNavigate,
        updateFormState,
        resetForm,
        getSectionData,
      }}
    >
      {children}
    </RebateOnlyFormStateContext.Provider>
  );
};

const useRebateOnlyFormState = () => ({
  ...useContext(RebateOnlyFormStateContext),
});

export { RebateOnlyFormStateProvider, useRebateOnlyFormState };
