import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import colors from '../../themes/main-colors';
import { useTranslation } from '../../hooks/useTranslation';
import { Alert, AlertTitle } from '@material-ui/lab';
import WarningIcon from '@material-ui/icons/Warning';
import Alerts from '../alerts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: `${colors.sunsetOrange} !important`,
      color: 'white',
      borderRadius: '4px',
    },
    alertTitle: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    titleWrapper: { display: 'flex', alignItems: 'center' },
    warningIcon: {
      marginRight: '12px',
      marginBottom: '0.25rem',
      color: colors.darkOrange,
    },
    titleText: {
      fontWeight: 600,
    },
    subMessage: {
      fontSize: theme.typography.pxToRem(16),
      marginTop: '0.5rem',
      textDecoration: 'underline',
      marginLeft: '36px',
    },
  }),
);

export const PlannedDownTimeWarning = (): JSX.Element => {
  const classes = useStyles();
  const { t, richT } = useTranslation();

  return (
    <div
      className={`MuiPaper-root MuiAlert-root MuiPaper-elevation0 ${classes.root}`}
      role="alert"
    >
      <div className="MuiAlert-message">
        <div className={classes.titleWrapper}>
          <WarningIcon className={classes.warningIcon} />
          <div
            className={`MuiTypography-root MuiAlertTitle-root MuiTypography-body1 MuiTypography-gutterBottom ${classes.titleText}`}
          >
            {t('PLANNED_DOWNTIME_WARNING_MESSAGE')}
          </div>
        </div>
        <div className={classes.subMessage}>
          {richT('PLANNED_DOWNTIME_WARNING_SUB_MESSAGE')}
        </div>
      </div>
    </div>
  );
};
