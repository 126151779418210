import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import colors from '../../themes/main-colors';
import TEST_IDS from '../../constants/test_ids';

const SIZE = 100;
const TIMING1 = 2;
const TIMING2 = 1.8;
const DELAY = TIMING1 / 4;

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    minHeight: SIZE,
    minWidth: SIZE,
    height: '100%',
    width: '100%',
    textAlign: 'center',

    '&:after': {
      content: '',
      display: 'inline-block',
      height: '100%',
      verticalAlign: 'middle',
      marginRight: '-0.25em',
    },
  },
  childContent: {
    position: 'absolute',
    width: '100%',
    top: 'calc(50% + 100px)',
    opacity: 0,
    transform: 'translateY(-50%)',
    animation: '$fadein 1s 1.5s linear forwards',
  },
  content: {
    boxSizing: 'border-box',
    animation: '$fadein 1s 1.5s linear forwards',
    display: 'inline-block',
    verticalAlign: 'middle',
    textAlign: 'left',
    fontSize: 0,
    opacity: 0,
  },
  loaderLineMask: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    width: SIZE / 2,
    height: SIZE,
    marginLeft: -SIZE / 2,
    marginTop: -SIZE / 2,
    overflow: 'hidden',
    transformOrigin: `${SIZE / 2}px ${SIZE / 2}px`,
    '-webkit-mask-image':
      '-webkit-linear-gradient(top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
    mixBlendMode: 'hard-light',
    opacity: 1,
  },
  loaderLine: {
    height: SIZE,
    width: SIZE,
    borderRadius: '50%',
  },
  one: {
    animation: `$rotate ${TIMING1}s infinite linear`,
    '& > $loaderLine': {
      boxShadow: `inset 0 0 0 8px ${colors.noirBlur}`,
    },
  },
  two: {
    animation: `$rotate ${TIMING2}s ${DELAY}s infinite linear`,
    '& > $loaderLine': {
      boxShadow: `inset 0 0 0 8px ${colors.sparkBlue}`,
    },
  },
  three: {
    animation: `$rotate ${TIMING1}s ${DELAY * 2}s infinite linear`,
    '& > $loaderLine': {
      boxShadow: `inset 0 0 0 8px ${colors.noirBlur}`,
    },
  },
  four: {
    animation: `$rotate ${TIMING2}s ${DELAY * 3 -
      (TIMING1 - TIMING2) / 2}s infinite linear`,
    '& > $loaderLine': {
      boxShadow: `inset 0 0 0 8px ${colors.sparkBlue}`,
    },
  },
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
  '@keyframes fadein': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}));

type Props = {
  children?: React.ReactNode;
};

export default function ColorBlendSpinner({ children }: Props) {
  const classes = useStyles();
  return (
    <div className={classes.root} data-testid={TEST_IDS.LOADING_SPINNER}>
      <div className={classes.content}>
        <div className={clsx(classes.loaderLineMask, classes.one)}>
          <div className={classes.loaderLine}></div>
        </div>
        <div className={clsx(classes.loaderLineMask, classes.two)}>
          <div className={classes.loaderLine}></div>
        </div>
        <div className={clsx(classes.loaderLineMask, classes.three)}>
          <div className={classes.loaderLine}></div>
        </div>
        <div className={clsx(classes.loaderLineMask, classes.four)}>
          <div className={classes.loaderLine}></div>
        </div>
      </div>
      <div className={classes.childContent}>{children}</div>
    </div>
  );
}
