type Props<T> = Omit<
  T,
  'contentful_id' | '__typename' | 'style' | 'entryName' | 'key'
>;

function useAttrs<T>(props: any, extra?: ReadonlyArray<string>): Props<T> {
  'contentful_id' in props && delete props.contentful_id;
  '__typename' in props && delete props.__typename;
  'style' in props && delete props.style;
  'entryName' in props && delete props.entryName;
  'key' in props && delete props.key;
  'anchorLabel' in props && delete props.anchorLabel;

  extra?.forEach(item => {
    item in props && delete props[item];
  });

  return props as Props<T>;
}

export default useAttrs;
