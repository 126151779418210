import React, { FC } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../../themes/main-colors';
import PgeVideoPlayer from '../pge-video-player';
import Container from '@material-ui/core/Container';
import PgeButton from '../pge-button/_PgeButton';
import { PgeButtonProps } from '../pge-button/types.d';
import ImageLink from '../image-link';
import RichText, { richTextStyles } from '../rich-text';
import {
  HeadlineVariant,
  BackgroundColor,
  MessagingProps as Props,
} from './types.d';
import { Typography } from '@material-ui/core';
import { isMobile } from '../../util/style-utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      padding: theme.spacing(6.25),
      gap: '10px',
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3.75, 2.5),
      },
    },
    rootContainer: {
      display: 'flex',
      gap: '20px',
      flexDirection: 'column',
    },
    root: {
      display: 'flex',
      gap: '50px',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        width: '100%',
      },
    },
    mediaDiv: {
      width: '50%',
      float: 'left',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: theme.spacing(2.5),
        padding: 0,
      },
      '& > iframe': {
        width: '100%',
        height: 275,
        borderRadius: 5,
      },
    },
    alignLeft: {
      flexDirection: 'row',
      '& .media': {
        [theme.breakpoints.down('sm')]: {
          paddingRight: 0,
        },
      },
    },
    alignRight: {
      flexDirection: 'row-reverse',
      '& .media': {
        [theme.breakpoints.down('sm')]: {
          paddingLeft: 0,
        },
      },
    },
    image: {
      width: '100%',
      border: `10px solid ${colors.white}`,
      objectFit: 'cover',
      height: 275,
      objectPosition: ({ imagePosition }: Props) => imagePosition,
    },
    details: {
      width: '50%',
      textAlign: 'left',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    mainHeading: {
      fontWeight: 'bold',
    },
    paragraphHeadline: {
      '& > a': {
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
    description: {
      marginTop: theme.spacing(1.25),
      marginBottom: theme.spacing(2.5),
      '& p > a .MuiButton-label': {
        textDecoration: 'underline',
      },
    },
  }),
);

const MessageBlock: FC<Props> = ({
  largeHeadline,
  largeHeadlineType = 'h2',
  paragraphHeadline,
  paragraphHeadlineType = 'h2',
  paragraphCopy,
  image,
  video,
  imageLink,
  button,
  buttonLink,
  colorBackground = 'None',
  imageAlignment,
  imagePosition,
}: Props) => {
  const richText = richTextStyles();
  const classes = useStyles({ imagePosition });

  let direction = '';

  if (
    (imageAlignment === 'Left' && image) ||
    (imageAlignment === 'Left' && video)
  ) {
    direction = `${classes.root} ${classes.alignLeft}`;
  }
  if (
    (imageAlignment === 'Right' && image) ||
    (imageAlignment === 'Right' && video)
  ) {
    direction = `${classes.root} ${classes.alignRight}`;
  }

  const getComponent = (
    elementType: HeadlineVariant,
    defaultType: HeadlineVariant,
    isStepDown: boolean,
  ): HeadlineVariant => {
    switch (elementType) {
      case 'h1':
        return isStepDown ? 'h2' : 'h1';
      case 'h2':
        return isStepDown ? 'h3' : 'h2';
      case 'h3':
        return isStepDown ? 'h4' : 'h3';
      case 'h4':
        return isStepDown ? 'h5' : 'h4';
      default:
        return defaultType;
    }
  };

  const getBackgroundColor = (color: BackgroundColor): string => {
    switch (color) {
      case 'None':
        return colors.white;
      case 'Blue':
        return colors.riverBlue;
      case 'Grey':
        return colors.cloudGrey2;
      case 'Yellow':
        return colors.lightYellow;
      case 'Linen-White':
        return colors.linenWhite;
      default:
        return colors.white;
    }
  };

  return (
    <section
      className={classes.main}
      style={{ backgroundColor: getBackgroundColor(colorBackground) }}
    >
      <Container disableGutters className={classes.rootContainer}>
        {largeHeadline && (
          <Typography
            variant={'body1'}
            component={getComponent(largeHeadlineType, 'h2', false)}
            className={classes.mainHeading}
          >
            {largeHeadline}
          </Typography>
        )}
        <div className={direction}>
          <div
            className={
              (image || video) &&
              `media align-${imageAlignment} ${classes.mediaDiv}`
            }
          >
            {image?.file?.url && (
              <ImageLink link={imageLink} text={paragraphHeadline}>
                <img
                  className={classes.image}
                  src={image.file.url}
                  alt=""
                  height="275"
                  width="500"
                />
              </ImageLink>
            )}
            {video && (
              <PgeVideoPlayer
                video={{ url: video?.url ?? video?.vimeoEmbedUrl }}
              />
            )}
          </div>
          <div className={classes.details}>
            {paragraphHeadline && (
              <Typography
                variant={'h2'}
                component={
                  largeHeadline
                    ? getComponent(largeHeadlineType, 'h3', true)
                    : getComponent(paragraphHeadlineType, 'h2', false)
                }
                className={classes.paragraphHeadline}
              >
                <PgeButton link={buttonLink} theme="Text" display="Inline">
                  {paragraphHeadline}
                </PgeButton>
              </Typography>
            )}
            {paragraphCopy && (
              <RichText className={`${richText.styles} ${classes.description}`}>
                {paragraphCopy}
              </RichText>
            )}
            {button && (
              <PgeButton
                data-testid="button"
                link={buttonLink}
                theme={button?.buttonType as PgeButtonProps['theme']}
                fullWidth={isMobile() ? true : false}
              >
                {button?.buttonText}
              </PgeButton>
            )}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default MessageBlock;
