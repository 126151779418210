import InputAdornment from '@material-ui/core/InputAdornment';
import React, { FunctionComponent, useState } from 'react';

import { useTranslation } from '../../hooks/useTranslation';
import TextField from '../text-field';
import { TextFieldProps } from '../text-field/TextField';
import { Typography } from '@material-ui/core';

const PasswordTextField: FunctionComponent<
  TextFieldProps | any
> = React.forwardRef(
  ({ defaultShow, ...props }: any, ref?: React.Ref<HTMLInputElement>) => {
    const { t } = useTranslation();
    const [passwordIsVisible, togglePasswordVisibility] = useState(
      defaultShow || false,
    );

    const isText = passwordIsVisible && Boolean(props.value);

    return (
      <TextField
        autoFocus={props.autoFocus}
        type={isText ? 'text' : 'password'}
        name={props.name || 'password'}
        label={t('PASSWORD')}
        margin={'normal'}
        style={{ width: '100%' }}
        variant={'outlined'}
        ref={ref}
        inputProps={{
          maxLength: 16,
          'data-testid': 'passwordField',
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              data-testid={'password-visibility'}
              onClick={() => togglePasswordVisibility(!passwordIsVisible)}
              style={{ cursor: 'pointer' }}
              position={'end'}
            >
              <Typography variant="body2" color="primary">
                {passwordIsVisible ? t('HIDE') : t('SHOW')}
              </Typography>
            </InputAdornment>
          ),
        }}
        {...props}
      />
    );
  },
);

export default PasswordTextField;
