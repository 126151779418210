import React, { FC, useState } from 'react';
import { Button } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Overlay from '../../pge-overlay/_Overlay';
import { usePathContext } from '../../../providers/PathProvider';
import useLink from '../../../hooks/useLink';
import { isOverlay } from '../../../util/type-checking';
import { FileType, LinkType } from '../../../constants';
import { ImageLinkProps as Props } from './types.d';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:hover, &:focus': {
        opacity: 0.8,
      },
      padding: theme.spacing(0),
      width: '100%',
    },
    span: {
      width: 1,
      height: 1,
      position: 'absolute',
      overflow: 'hidden',
    },
  }),
);

const ImageLink: FC<Props> = ({ children, link, text }) => {
  if (!children) {
    return null;
  }

  const href: string = useLink(link);
  const overlay = isOverlay(link) ? link : null;
  const isButton = link === undefined || !!overlay;
  const isAnchor = !isButton && !!href;

  if (!href) {
    return <>{children}</>;
  }

  const externalLink: boolean = LinkType.HTTP.test(href);
  const documentLink: boolean = FileType.DOC.test(href);
  const imageLink: boolean = FileType.IMG.test(href);
  const isBlank: boolean = externalLink || documentLink || imageLink;
  const { resolve } = usePathContext();
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  return (
    <>
      <Button
        className={`imageLink ${classes.root}`}
        component={isAnchor ? 'a' : 'button'}
        href={isAnchor ? resolve(href) : undefined}
        target={isBlank ? '_blank' : undefined}
        rel={isBlank ? 'noopener noreferrer' : undefined}
        onClick={overlay ? () => setOpen(true) : undefined}
      >
        {children}
        <span className={classes.span}>{text || 'Learn More'}</span>
      </Button>
      {overlay && (
        <Overlay
          content={overlay.content}
          open={open}
          setOpen={setOpen}
          entryName={overlay.entryName}
          statusAlert={overlay.statusAlert}
        />
      )}
    </>
  );
};

export default ImageLink;
