export const MaterialIcons = [
  'http',
  'Plus',
  'Minus',
  'Download',
  'Show',
  'Hide',
  'Next',
  'Prev',
] as const;

export const CustomIcons = ['pdf', 'xlsx', 'docx', 'pptx'] as const;

export const AllIcons = [...MaterialIcons, ...CustomIcons] as const;

export enum IconFile {
  HTTP = '/static/icon_external_link.svg',
  PDF = '/static/icon_PDF.svg',
  XLSX = '/static/icon_excel.svg',
  DOCX = '/static/icon_word.svg',
  PPTX = '/static/icon_powerpoint.svg',
}

export type Icon = {
  Type: typeof AllIcons[number];
  File: keyof typeof IconFile;
  Material: typeof MaterialIcons[number];
  Custom: typeof CustomIcons[number];
};
