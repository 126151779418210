import gql from 'not-graphql-tag';

export const getAccountTypeQuery = gql`
  query getAccountDetailsAccountType($params: AccountDetailParams!) {
    getAccountDetails(params: $params) {
      accountNumber
      accountType
    }
  }
`;

export const checkSMBAccountQuery = gql`
  query getAccountDetails($params: AccountDetailParams!) {
    getAccountDetails(params: $params) {
      accountNumber
      pgeEnergyTracker {
        isSMBAccount
      }
    }
  }
`;
