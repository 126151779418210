import React from 'react';
import {
  makeStyles,
  Theme,
  createStyles,
  useTheme,
} from '@material-ui/core/styles';
import Tabs, { TabProps } from '../tabs/Tabs';
import colors from '../../themes/main-colors';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PGEAccordion from '../accordion/';
import { Field } from '../../contentful.d';
import { Grid, Typography } from '@material-ui/core';
import { HeaderVariant } from './types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: ({ backgroundColor }: TabsetProps) =>
        getBackgroundColor(backgroundColor),
    },
    mainContent: {
      flexDirection: 'column',
      padding: theme.spacing(6.25),
      gap: '20px',
      '& .MuiTypography-body1': {
        margin: 0,
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2.5),
      },
    },
    headerTitle: {
      fontSize: theme.spacing(4.5),
      fontWeight: theme.spacing(12.5),
      color: colors.noirBlur,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
      },
    },
    descText: {
      fontSize: theme.spacing(2.5),
      color: colors.noirBlur,
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(2),
      },
    },
    image: {
      width: '100%',
      height: 275,
      objectFit: 'cover',
      display: 'block',
      objectPosition: ({ imagePosition }: TabsetProps) => imagePosition,
      [theme.breakpoints.down('sm')]: {
        height: 260,
      },
    },
  }),
);

interface TabsetProps {
  tabData: TabProps;
  header?: string;
  headerType?: string;
  tabsetImage?: any;
  intro?: string;
  readonly imagePosition?: Field['Position'];
  backgroundColor?: string;
}

const getBackgroundColor = (color: string | undefined): string => {
  let bgColor = colors.riverBlue;
  switch (color) {
    case 'Blue':
      bgColor = colors.riverBlue;
      break;
    case 'Grey':
      bgColor = colors.cloudGrey2;
      break;
    case 'Yellow':
      bgColor = colors.lightYellow;
      break;
    default:
      bgColor = colors.riverBlue;
      break;
  }
  return bgColor;
};

export default (props: TabsetProps) => {
  const classes = useStyles(props);
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const length: number = props?.tabData?.tabs?.length;

  let component: React.ReactNode = <></>;
  if (matches) {
    if (length < 2) {
      component = <Tabs {...props.tabData} />;
    } else {
      const accordionItems: any = props?.tabData?.tabs?.map((item: any) => {
        return {
          tabTitle: item?.label,
          tabBody: item?.data,
          tabTitleIcon: item?.icon,
        };
      });
      component = (
        <PGEAccordion
          accordionItems={accordionItems}
          isChildOfTabset={true}
          accordionHeaderType={props?.headerType}
        />
      );
    }
  } else {
    component = <Tabs {...props.tabData} tabHeight={'80px'} />;
  }

  const headerTag =
    !props?.headerType || !/^h[1-4]$/.test(props?.headerType)
      ? ('h2' as HeaderVariant)
      : (props?.headerType as HeaderVariant);
  return (
    <div className={classes.root}>
      {props.tabsetImage && (
        <img
          className={classes.image}
          src={props.tabsetImage?.file?.url}
          alt={''}
        />
      )}
      <Grid container className={classes.mainContent}>
        {props.header?.trim() && (
          <Grid item>
            <Typography variant={headerTag}>{props.header}</Typography>
          </Grid>
        )}
        {props.intro && <Grid item>{props.intro}</Grid>}
        <Grid item>{component}</Grid>
      </Grid>
    </div>
  );
};
