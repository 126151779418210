import SiteSearchAPIConnector from '@elastic/search-ui-site-search-connector';

const connector = new SiteSearchAPIConnector({
  documentType: 'page',
  engineKey: 'bKZ6hcyKzG_K1tZzRw15',
});

const searchConfig = {
  searchQuery: {
    result_fields: {
      title: {
        snippet: {
          size: 100,
          fallback: true,
        },
      },
      body: {
        snippet: {
          size: 200,
          fallback: true,
        },
      },
      url: { raw: {} },
    },
  },
  autocompleteQuery: {
    results: {
      resultsPerPage: 5,
      result_fields: {
        title: {
          snippet: {
            size: 100,
            fallback: true,
          },
        },
        url: {
          raw: {},
        },
      },
    },
    suggestions: {
      types: {
        documents: {
          fields: ['title'],
        },
      },
      size: 4,
    },
  },
  apiConnector: connector,
  hasA11yNotifications: true,
};

export default searchConfig;
