import { Content } from '../contentful.d';
import { OverlayLinkFragment as OverlayLink } from '../__generated__/gatsby-types';

/**
 * General
 */

export function isType<T>(arg?: T, prop?: keyof T): arg is T {
  if (!arg || !prop) {
    return false;
  }

  return arg[prop] !== undefined;
}

/**
 * Specific
 */

export function isEntry(arg?: unknown): arg is Content['Entry'] {
  return (arg as Content['Entry'])?.__typename !== undefined;
}

export function isAsset(arg?: unknown): arg is Content['Asset'] {
  return (arg as Content['Asset'])?.file !== undefined;
}

export function isRefLink(arg?: unknown): arg is Content['RefLink'] {
  return (arg as Content['RefLink'])?.slug !== undefined;
}

export function isOverlay(arg?: unknown): arg is OverlayLink {
  return (arg as OverlayLink)?.__typename === 'ContentfulPageOverlay';
}
