import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  ThemeProvider,
} from '@material-ui/core/styles';
import MUIModal, { ModalProps } from '@material-ui/core/Modal';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import theme from '../../themes/theme';

type Props = {
  className?: string;
  onClose?: () => void;
  open: boolean;
  children?: React.ReactNode;
  title?: React.ReactChild;
  showCloseIcon?: boolean;
  cardContentPadding?: string;
  contentWidth?: string;
} & Omit<ModalProps, 'children' | 'title'>;

// eslint-disable-next-line @typescript-eslint/no-shadow
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    card: {
      position: 'relative',
      maxWidth: (props: {
        cardContentPadding: string | undefined;
        contentWidth?: string;
      }) => (props.contentWidth ? props.contentWidth : 'unset'),
      '&:focus': {
        outline: 'none',
      },
    },
    closeIcon: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    titleArea: {
      paddingRight: () => theme.spacing(3),
    },
    emptyTitleArea: {
      paddingTop: 27,
    },
    content: (props: { cardContentPadding: string | undefined }) => ({
      padding: props.cardContentPadding
        ? props.cardContentPadding
        : theme.spacing(3),
    }),
  }),
);

export default function Modal({
  title,
  children,
  className,
  onClose,
  open,
  cardContentPadding,
  showCloseIcon = true,
  contentWidth,
  ...rest
}: Props) {
  const titleElement =
    title === undefined || React.isValidElement(title) ? (
      title
    ) : (
      <Typography variant={'body1'}>{title}</Typography>
    );

  const classes = useStyles({ cardContentPadding,contentWidth });
  const hasTitle = titleElement !== undefined;

  return (
    <ThemeProvider theme={theme}>
      <MUIModal
        open={open}
        className={clsx(classes.modal, className)}
        onClose={onClose}
        {...rest}
      >
        <Card className={classes.card}>
          {showCloseIcon && (
            <IconButton
              className={classes.closeIcon}
              onClick={onClose}
              data-testid="close-btn"
            >
              <Close />
            </IconButton>
          )}
          <CardContent className={classes.content}>
            <div
              className={hasTitle ? classes.titleArea : classes.emptyTitleArea}
            >
              {titleElement}
            </div>
            {children}
          </CardContent>
        </Card>
      </MUIModal>
    </ThemeProvider>
  );
}
