import gql from 'not-graphql-tag';
import { AccountCustomer } from '../__generated__/pge-types';
import useAuthQuery from './useAuthQuery';
import compact from 'lodash/compact';
import { useEffect, useContext, useMemo } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { setInStorage } from '../util/storage-utils';
import { updatePersonIDForMedallia } from '../lib/gtmService';
import { featureFlagsContext } from '../providers/FeatureFlagsProvider';
import useAccountsStore from './useAccountsStore';

const getAccountInfoFragment = gql`
  fragment accountInfo on AccountCustomer {
    uid
    prefLanguage
    email
    personName
    personId
    timestamp
    encryptedPersonId
    accountMeta {
      totalAccounts
      hasInactiveAccounts
    }
    lastConfirmedDate
    groups {
      groupName
      groupId
      numberOfAccounts
      isDefault
      type
      isDefaultAccountExists
      isLoggedOnUserAutoGroup
      defaultAccount {
        accountNumber
        encryptedAccountNumber
        encryptedPersonId
      }
    }
    contactDetails {
      contactType
      contactValue
    }
  }
`;

export const getAccountInfoWithParams = gql`
  query getAccountInfo($params: GetAccountInfoParams) {
    getAccountInfo(params: $params) {
      ...accountInfo
    }
  }
  ${getAccountInfoFragment}
`;

/**
 * A hook used to get basic customer information for a logged in user. To see what fields
 * are available, check the `getAccountCustomer` query defined in the
 * `src/hooks/useAccountCustomer`.
 *
 * To use, just call in your functional component, it will return some information
 * about the logged in customer.  The value of `loading` is the `loading` value returned
 * by Apollo's `useQuery`. The `customer` and other data, in some cases, can be
 * `undefined` if the response has not been received yet.
 *
 * Example: `const { customer, groups, defaultGroup, loading } = useAccountCustomer()`;
 *
 * TODO: GLOBAL ERRORS - error needs to be used to redirect to a global error page
 */
export default function useAccountCustomer() {
  const apolloClient = useApolloClient();
  const { isClosedAccountsEnabled, loading: contextLoading } = useContext(
    featureFlagsContext,
  );
  const { data, loading, error, refetch } = useAuthQuery<{
    getAccountInfo: AccountCustomer;
  }>(getAccountInfoWithParams, {
    variables: {
      params: {
        removeInactiveAccounts: isClosedAccountsEnabled,
      },
    },
    skip: contextLoading,
  });
  const { setHasInActiveAccounts } = useAccountsStore();
  useEffect(() => {
    setHasInActiveAccounts(
      Boolean(data?.getAccountInfo?.accountMeta?.hasInactiveAccounts),
    );
  }, [data?.getAccountInfo?.accountMeta?.hasInactiveAccounts]);

  const customer = data?.getAccountInfo;
  const totalAccounts = data?.getAccountInfo?.accountMeta?.totalAccounts;
  const groups = useMemo(() => compact(customer?.groups), [customer]);
  const defaultGroup = useMemo(() => groups.find((grp: any) => grp.isDefault), [
    groups,
  ]);
  const defaultAccountGroup = useMemo(
    () =>
      groups.find(
        (grp: any) => grp.isDefaultAccountExists && grp.type !== 'Virtual',
      ),
    [groups],
  );

  const personId = customer?.personId;

  useEffect(() => {
    if (personId) {
      setInStorage('gtmpId', personId);
      updatePersonIDForMedallia(personId);
    }
  }, [personId]);

  function writeCustomerCache(queryData: Partial<AccountCustomer>) {
    apolloClient.cache.writeQuery({
      query: getAccountInfoWithParams,
      data: {
        getAccountInfo: {
          ...customer,
          ...queryData,
        },
        variables: {
          params: {
            removeInactiveAccounts: isClosedAccountsEnabled,
          },
        },
      },
    });
  }

  return {
    customer,
    groups,
    defaultGroup,
    defaultAccountGroup,
    error,
    loading,
    writeCustomerCache,
    refetch,
    totalAccounts,
  };
}
