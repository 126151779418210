import { useContext } from 'react';

import { TransitionContext } from '../providers/TransitionProvider';

export default () => {
  const transitionContext =
    typeof window !== 'undefined'
      ? useContext(TransitionContext)
      : { state: {} };
  const { setState } = transitionContext;

  const showTransition = (message: string) => {
    setState({
      message,
      open: true,
    });
  };

  const hideTransition = () => {
    setState({
      message: '',
      open: false,
    });
  };

  return {
    showTransition,
    hideTransition,
  };
};
