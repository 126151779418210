import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import colors from '../../themes/main-colors';
import { useTranslation } from '../../hooks/useTranslation';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    documentListHeader: {
      color: colors.noirBlur,
      lineHeight: theme.typography.pxToRem(40),
      paddingBottom: theme.spacing(2.5),
      fontWeight: 'bold',
      letterSpacing: '0.720px',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(3),
        lineHeight: theme.typography.pxToRem(28),
      },
    },
    documentListDesc: {
      fontSize: theme.spacing(2.5),
      color: colors.noirBlur,
      paddingBottom: theme.spacing(2.5),
      lineHeight: theme.typography.pxToRem(24),
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(2),
        lineHeight: theme.typography.pxToRem(20),
      },
    },
    root: {
      padding: theme.spacing(0.25, 0.5),
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      border: `1px solid ${colors.grey3}`,
      borderRadius: 'unset',
      boxShadow: 'none',
    },
    input: {
      fontWeight: 400,
      marginLeft: theme.spacing(1),
      flex: 1,
      fontSize: theme.spacing(2.5),
      color: colors.noirBlur,
      '& ::placeholder': {
        opacity: 1,
      },
    },
    iconButton: {
      padding: theme.spacing(1.25),
    },
    listRoot: {
      marginTop: theme.spacing(2.5),
    },
    listRow: {
      display: 'table',
      paddingBottom: theme.spacing(1.875),
    },
    itemText: {
      fontWeight: 400,
      fontSize: theme.spacing(2.5),
      paddingRight: theme.spacing(0.625),
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(2),
      },
    },
    pdfIconStyle: {
      width: theme.typography.pxToRem(16),
    },
  }),
);

interface IitemProps {
  title: string;
  url: string;
  src?: string;
  target?: string;
}

interface IDocumentList {
  lists: IitemProps[];
  header: string;
  headerType: string;
  intro: string;
}

const DocumentList = (props: IDocumentList) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { lists = [], header = '', intro = '', headerType = '' } = { ...props };
  const [searchTerm, setSearchTerm] = React.useState('');
  const [searchResults, setSearchResults] = React.useState<IitemProps[]>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  React.useEffect(() => {
    const results = lists.filter(
      (listItem: IitemProps) =>
        listItem['title'] &&
        listItem['title'].toLowerCase().includes(searchTerm),
    );
    setSearchResults(results);
  }, [searchTerm]);

  let headerElementType: 'h1' | 'h2' | 'h3' | 'h4' = 'h2';

  switch (headerType) {
    case 'h1':
      headerElementType = 'h1';
      break;
    case 'h2':
      headerElementType = 'h2';
      break;
    case 'h3':
      headerElementType = 'h3';
      break;
    case 'h4':
      headerElementType = 'h4';
      break;
    default:
      headerElementType = 'h2';
  }

  return (
    <>
      {props.header && (
        <Typography
          variant={headerElementType}
          className={classes.documentListHeader}
        >
          {props.header}
        </Typography>
      )}
      {props.intro && (
        <div className={classes.documentListDesc}>{props.intro}</div>
      )}
      <Paper component="form" className={classes.root}>
        <InputBase
          className={classes.input}
          placeholder="Filter Documents"
          value={searchTerm}
          onChange={handleChange}
        />
        <IconButton
          type="submit"
          className={classes.iconButton}
          aria-label="search"
        >
          <SearchIcon />
        </IconButton>
      </Paper>
      <div className={classes.listRoot}>
        {searchResults.length > 0 &&
          searchResults.map((item: IitemProps, index: number) => (
            <li className={classes.listRow} key={index}>
              <a
                className={classes.itemText}
                href={item.url}
                target={item.target}
              >
                {item.title}
                {item?.src && (
                  <>
                    {' '}
                    <img
                      src={item?.src}
                      alt={item?.title || ''}
                      className={classes.pdfIconStyle}
                    />
                  </>
                )}
              </a>
            </li>
          ))}
      </div>
    </>
  );
};
export default DocumentList;
