import React, { useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../themes/main-colors';
import MobileNavigationControls from './MobileNavigationControls';
import SubLinkGroup from './SubLinkGroup';
import useSelectedAccountParams from '../../hooks/useSelectedAccountParams';
import useAuthQuery from '../../hooks/useAuthQuery';
import { getRooftopSolarQuery } from '../rooftop-solar/rooftopSolar.query';
import { cloneDeep } from 'lodash';

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      background: colors.navBarBackground,
      color: colors.white,
    },
    linksList: {
      background: colors.riverBlue,
      width: '100%',
      padding: 0,
      margin: 0,
      flexGrow: 3,
      position: 'relative',
    },
  }),
);

type MobileNavigationMenuProps = {
  menuOpen: boolean;
  toggleMenu: () => void;
  navigateToRoute: (route: string) => (e: any) => Promise<any>;
  navigation: any;
  closeModal?: () => void;
};

const MobileNavigationMenu = ({
  menuOpen,
  toggleMenu,
  navigateToRoute,
  navigation,
  closeModal,
}: MobileNavigationMenuProps) => {
  const classes = useStyles();

  // START: Temporary hack for hiding rooftop solar link
  const { accountParams } = useSelectedAccountParams();
  const [isHideRooftopSolar, setIsHideRooftopSolar] = useState<boolean>(true);

  const { data: solarData } = useAuthQuery(getRooftopSolarQuery, {
    variables: {
      params: {
        accountNumberList: [accountParams],
      },
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function,no-empty-function
    onError: () => {},
    skip: !accountParams,
  });

  useEffect(() => {
    setIsHideRooftopSolar(
      solarData?.getAccountDetails[0]?.premiseInfo[0]?.rooftopSolar
        ?.applicationDetails === null ||
        solarData?.getAccountDetails[0]?.premiseInfo[0]?.rooftopSolar
          ?.applicationDetails === undefined,
    );
  }, [solarData]);

  const updatedNavigation = isHideRooftopSolar && cloneDeep(navigation);
  const indexOfRooftopSolarLink = navigation.menus[0].groups[2].links.findIndex(
    (x: any) => x?.url === '/secure/rooftop-solar',
  );
  if (indexOfRooftopSolarLink !== -1 && isHideRooftopSolar) {
    updatedNavigation.menus[0].groups[2].links.splice(
      indexOfRooftopSolarLink,
      1,
    );
  }

  const navigationMenu = isHideRooftopSolar ? updatedNavigation : navigation;
  // END: Temporary hack for hiding rooftop solar link

  return (
    <Drawer
      open={menuOpen}
      anchor="left"
      classes={{ paper: classes.paper }}
      ModalProps={{ onBackdropClick: toggleMenu }}
    >
      <MobileNavigationControls
        toggleMenu={toggleMenu}
        navigateToRoute={navigateToRoute}
        closeModal={closeModal}
      />
      <Container className={classes.linksList}>
        <List component="ul" disablePadding>
          {(navigationMenu?.menus || []).map((menu: any) => (
            <SubLinkGroup
              key={menu?.label}
              section={menu}
              level={1}
              navigateToRoute={navigateToRoute}
              parentLevels={null}
              toggleMenu={toggleMenu}
            />
          ))}
        </List>
      </Container>
    </Drawer>
  );
};

export default MobileNavigationMenu;
