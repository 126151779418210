import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import colors from '../../themes/main-colors';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: 'inherit',
    width: '100%',
    wordBreak: 'break-word',
    padding: 0,
  },
  header: {
    fontSize: theme.spacing(1.75),
    color: colors.noirBlur,
    fontWeight: 500,
  },
  desc: {
    color: colors.noirBlur,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(1.75),
    },
    '& > div > *': {
      margin: 0,
    },
    '& .MuiTypography-body1': {
      fontSize: theme.spacing(1.75),
    },
    '& .MuiTypography-h6': {
      fontWeight: 400,
      fontSize: theme.spacing(1.75),
      fontFamily: 'Untitled-Sans',
    },
  },
}));

interface INotesProps {
  noteHeading?: string;
  noteCopy?: any;
}

const Note = (props: INotesProps) => {
  const classes = useStyles();

  return (
    <Container className={classes.root}>
      {props.noteHeading && (
        <Typography variant="body1" className={classes.header}>
          {props.noteHeading}
        </Typography>
      )}
      {props.noteCopy && <div className={classes.desc}>{props.noteCopy}</div>}
    </Container>
  );
};
export default Note;
