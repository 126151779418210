import React, { useEffect } from 'react';
import useAuth from '../../hooks/useAuth';

export const CheckCookiesEnabled = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { signOut } = useAuth();

  useEffect(() => {
    if (!navigator.cookieEnabled) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      signOut();
    }
  }, []);

  return <>{children}</>;
};
