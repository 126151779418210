import React, { useContext, useEffect, useState } from 'react';
import { LanguageContext } from '../../providers/LanguageProvider';
import { ComponentMap, RealTimeContentProps, MetaData } from './types.d';
import { ElementCta } from './components';
import useContentMgmt from '../../hooks/useContentMgmt';
import Backdrop from '../backdrop';

const componentMap: ComponentMap = {
  elementCta: ElementCta,
  default: null,
};

const RealTimeContent = ({ entryId }: RealTimeContentProps) => {
  const { language } = useContext(LanguageContext);
  const { contentfulClient } = useContentMgmt();

  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getData = async () => {
    try {
      const entry = await contentfulClient.getEntry(entryId, {
        locale: language,
        include: 2,
      });
      setData(entry);
    } catch (e) {
      console.error('error', e);
    }
  };

  useEffect(() => {
    void (async () => {
      await getData();
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <Backdrop forceOpen />;
  }

  return (
    <>
      {data?.fields?.contentEntries.map((entry: any) => {
        const contentfulId = entry?.sys?.id;
        const contentType: string = entry?.sys?.contentType?.sys?.id;
        const anchorId: string | undefined = entry?.fields?.anchorLabel
          ? `_${contentfulId}`
          : undefined;
        const metaData: MetaData = {
          contentfulId: contentfulId,
          contentType: contentType,
          anchorId: anchorId,
        };

        const Component = componentMap[contentType] || componentMap.default;
        if (Component) {
          return (
            <Component entry={entry} metaData={metaData} key={contentfulId} />
          );
        }
        return null;
      })}
    </>
  );
};

export default RealTimeContent;
