import { useApolloClient } from '@apollo/react-hooks';
import gql from 'not-graphql-tag';
import { SELECTED_ACCOUNT_PARAMS } from '../lib/apolloClient';
import { setInStorage, removeFromStorage } from '../util/storage-utils';
import {
  AccountParams,
  AccountDetailList,
  AccountDetailListParams,
} from '../__generated__/pge-types';
import useAccountCustomer from './useAccountCustomer';
import usePgeQuery from '../hooks/usePgeQuery';
import { useEffect } from 'react';
import { navigate, useLocation, useMatch } from '@reach/router';
import ROUTES from '../routes';
import { getAccountsList } from '../components/account-dropdown/queries';
import AccountList, {
  makeParams,
} from '../components/account-summary/multi-accounts/accountListReducer';
import isNil from 'lodash/isNil';
import compact from 'lodash/compact';
import useSelectedGroupId from './useSelectedGroupId';
import { ApolloClient } from 'apollo-client';

export type AccountIdentifier = {
  accountNumber: string;
  encryptedAccountNumber: string;
  encryptedPersonId?: string | null;
  automaticSelection?: boolean;
};

export type StoredAccountIdentifier =
  | {
      accountNumber: string;
      encryptedAccountNumber: string;
      encryptedPersonId: string | null;
      selectedGroupId: string;
      automaticSelection: boolean;
      __typename: 'StoredAccountIdentifier';
    }
  | {
      selectedGroupId: string;
      accountNumber: null;
      encryptedAccountNumber: null;
      encryptedPersonId: null;
      automaticSelection: boolean;
      __typename: 'MissingAccountIdentifier';
    };

const getSelectedAccountParams = gql`
  {
    selectedAccountParams @client {
      accountNumber
      encryptedPersonId
      encryptedAccountNumber
      selectedGroupId
      automaticSelection
    }
  }
`;

function useDefaultAccountParams() {
  const { loading, defaultAccountGroup, customer } = useAccountCustomer();

  // If our custgomer has no default account group or no default account
  // Navigate to error
  useEffect(() => {
    if (
      customer &&
      customer?.accountMeta &&
      customer?.accountMeta?.totalAccounts > 0 &&
      (!defaultAccountGroup || !defaultAccountGroup?.defaultAccount)
    ) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.ERROR);
    }
  }, [customer, defaultAccountGroup]);

  if (loading || !customer) {
    return { loading };
  }
  const group = defaultAccountGroup;

  return {
    loading: false,
    defaultGroup: group,
    defaultAccount: group?.defaultAccount,
  };
}

export function useAccountParamSetters() {
  const client = useApolloClient();
  const { setSelectedGroupId } = useSelectedGroupId();

  function writeData(dataToSet: StoredAccountIdentifier | null) {
    client.writeData({ data: { selectedAccountParams: dataToSet } });
    if (dataToSet === null) {
      removeFromStorage(SELECTED_ACCOUNT_PARAMS);
    } else {
      setInStorage(SELECTED_ACCOUNT_PARAMS, JSON.stringify(dataToSet));
    }
  }

  function setSelectedAccountParams(
    selectedGroupId: string,
    params: AccountIdentifier,
  ) {
    const dataToSet: StoredAccountIdentifier = {
      accountNumber: params.accountNumber,
      encryptedAccountNumber: params.encryptedAccountNumber,
      encryptedPersonId: params.encryptedPersonId || null,
      automaticSelection: params.automaticSelection || false,
      selectedGroupId,
      __typename: 'StoredAccountIdentifier',
    };
    writeData(dataToSet);
  }

  function setSelectedAccountGroup(selectedGroupId: string) {
    setSelectedGroupId(selectedGroupId);
    writeData({
      encryptedPersonId: null,
      encryptedAccountNumber: null,
      accountNumber: null,
      selectedGroupId,
      automaticSelection: false,
      __typename: 'MissingAccountIdentifier',
    });
  }

  function clearSelectedAccountParams() {
    writeData(null);
  }

  return {
    setSelectedAccountParams,
    setSelectedAccountGroup,
    clearSelectedAccountParams,
  };
}

function isMissingAccountData(
  value: StoredAccountIdentifier | null | undefined,
) {
  return !value || value.__typename === 'MissingAccountIdentifier';
}

function fetchAccountsForGroup(client: ApolloClient<unknown>, groupId: string) {
  return client.query<
    { getAccountDetailList: AccountDetailList },
    { params: AccountDetailListParams }
  >({
    query: getAccountsList,
    variables: {
      params: makeParams(groupId, AccountList.initialState),
    },
  });
}

type Args = {
  ignoreNoAccounts?: boolean;
};

/**
 * A hook used to get the selected accounts params that can be passed to a
 * `getAccountDetails` graphql query to retrieve `AccountDetail` information.
 *
 * The logic to get either the default account parameters if the user hasnt selected
 * an account, or to get the user's selected parameters is handled for you here.
 *
 * You can also get the selected accounts `encryptedAccountNumber` if that information
 * is necessary to make a graphql call.
 *
 * `accountParams` Example: ```
 *   const { accountParams } = useSelectedAccountParams()`;
 *
 *   const { loading, data } = useAuthQuery(getAccountDetails, {
 *     variables: {
 *       params: {
 *         accountNumberList: [accountParams],
 *       },
 *     },
 *     skip: !accountParams,
 *   })
 *
 *   const accountDetail = data?.getAccountDetails?.[0];
 * ```
 *
 * `encryptedAccountNumber` Example ```
 *   const { encryptedAccountNumber } = useSelectedAccountParams()`;
 *
 *   // You can now use the encryptedAccountNumber to make a graphql call.
 *   // Be sure to make sure the encryptedAccountNumber is defined.
 *   const { loading, data } = useAuthQuery(myQuery, {
 *     variables: {
 *       params: {
 *         encryptedAccountNumber,
 *       },
 *     },
 *     skip: !encryptedAccountNumber,
 *   })
 * ```
 */
export default function useSelectedAccountParams({
  ignoreNoAccounts = false,
}: Args = {}) {
  const { customer } = useAccountCustomer();
  const hasNoAccounts = customer && customer.accountMeta?.totalAccounts === 0;
  const {
    setSelectedAccountGroup,
    setSelectedAccountParams,
    clearSelectedAccountParams,
  } = useAccountParamSetters();
  const client = useApolloClient();

  // If the user has no accounts, but we are asking for the selected account
  // that probably indicates the currently viewed page should not be shown
  // to a user with no accounts, so lets navigate them to the Account page
  const accountPageMatch = useMatch(ROUTES.ACCOUNT);
  const { pathname } = useLocation();
  const pgePlusChooseAccountPage =
    pathname === ROUTES.PGE_PLUS_EV_CHARGERS_INELIGIBLE;
  const ocpaFormPage = pathname === ROUTES.OREGON_CONSUMER_PRIVACY_ACT;

  useEffect(() => {
    if (
      hasNoAccounts &&
      !ignoreNoAccounts &&
      !accountPageMatch &&
      !pgePlusChooseAccountPage &&
      !ocpaFormPage
    ) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      navigate(ROUTES.ACCOUNT);
    }
  }, [hasNoAccounts, ignoreNoAccounts]);

  const { data } = usePgeQuery<{
    selectedAccountParams: null | StoredAccountIdentifier;
  }>(getSelectedAccountParams);

  const value = data?.selectedAccountParams;

  const encryptedAccountNumber = value?.encryptedAccountNumber || undefined;
  const encryptedPersonId = value?.encryptedPersonId || undefined;
  const accountNumber = value?.accountNumber || undefined;

  const accountParams: AccountParams | undefined =
    accountNumber && encryptedPersonId
      ? {
          accountNumber,
          encryptedPersonId,
        }
      : undefined;

  const { defaultGroup, defaultAccount } = useDefaultAccountParams();
  const loading = isMissingAccountData(value) && !hasNoAccounts;

  // If there is no value, or just the default group id without account information,
  // select the default
  useEffect(() => {
    if (
      (!value ||
        (isMissingAccountData(value) &&
          defaultGroup?.groupId === value?.selectedGroupId)) &&
      defaultGroup &&
      defaultAccount &&
      !hasNoAccounts
    ) {
      setSelectedAccountParams(defaultGroup.groupId, {
        accountNumber: defaultAccount.accountNumber!,
        encryptedAccountNumber: defaultAccount.encryptedAccountNumber!,
        encryptedPersonId: defaultAccount.encryptedPersonId!,
        automaticSelection: !value,
      });
    }
  }, [value, defaultGroup, defaultAccount]);

  // If there is no account information selected (but there is group information),
  // load some accounts of the group, and select the first one.  This happens when
  // the user selects a group (not an account).
  useEffect(() => {
    if (
      !isNil(value) &&
      isMissingAccountData(value) &&
      defaultGroup &&
      value?.selectedGroupId !== defaultGroup?.groupId &&
      !hasNoAccounts
    ) {
      fetchAccountsForGroup(client, value?.selectedGroupId)
        .then(({ data: resultData }) => {
          const accounts = compact(resultData?.getAccountDetailList?.accounts);
          if (accounts.length > 0) {
            const account = accounts[0];
            setSelectedAccountParams(value.selectedGroupId, account);
          } else {
            return navigate(ROUTES.ERROR);
          }
        })
        .catch(() => {
          return navigate(ROUTES.ERROR);
        });
    }
  }, [value?.selectedGroupId, defaultGroup]);

  // If the groupId is not an id of any of the groups,
  // clear params. This could happen when a user deletes
  // a group.
  useEffect(() => {
    if (customer && !isNil(value?.selectedGroupId)) {
      const ids = customer.groups?.map(grp => grp?.groupId);
      if (!ids?.includes(value?.selectedGroupId)) {
        clearSelectedAccountParams();
      }
    }
  }, [customer, value?.selectedGroupId]);

  return {
    encryptedAccountNumber,
    encryptedPersonId,
    accountNumber,
    accountParams,
    selectedGroupId: value?.selectedGroupId,
    automaticSelection: value?.automaticSelection || !value,
    selectedAccountParams: data,
    setSelectedAccountParams,
    setSelectedAccountGroup,
    clearSelectedAccountParams,
    loading,
  };
}
