import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import ProgramCard from '../../program-card';
import RichText, { richTextStyles } from '../../rich-text';
import useAttrs from '../../../hooks/useAttrs';
import { CardLayoutProps as Props, Omits, HeaderVariant } from './types.d';
import useStyles from './CardLayout.styles';
import colors from '../../../themes/main-colors';

const getBackgroundColor = (color: any | null): any | null => {
  let bgColor = colors.white;
  switch (color) {
    case 'Blue':
      bgColor = colors.riverBlue;
      break;
    case 'Grey':
      bgColor = colors.cloudGrey2;
      break;
    case 'Linen-White':
      bgColor = colors.linenWhite;
      break;
    case 'Yellow':
      bgColor = colors.lightYellow;
      break;
    default:
      bgColor = colors.white;
      break;
  }
  return bgColor;
};

function CardLayout({
  promoHeader,
  promoHeaderType,
  promoIntroCopy,
  addItems,
  columns,
  pageTemplate,
  bgColor,
  ...props
}: Props): ElementNode {
  // Default props
  const _columns = columns || '1';
  const _hasLeftNav = pageTemplate === 'Left Nav';

  // Vars
  const richText = richTextStyles();
  const { className = '', ...attrs } = useAttrs<Props>(props, Omits);
  const classes = useStyles({
    hasLeftNav: _hasLeftNav,
    bgColor: getBackgroundColor(bgColor),
  });
  const getColumnWidth = (index: number) => {
    return _hasLeftNav
      ? _columns === '1'
        ? 12
        : index < 2
        ? 6
        : 12
      : _columns === '2'
      ? 6
      : _columns === '1'
      ? 12
      : 4;
  };

  const headerTag =
    !promoHeaderType || !/^h[1-4]$/.test(promoHeaderType)
      ? ('h2' as HeaderVariant)
      : (promoHeaderType as HeaderVariant);

  return (
    <section
      className={`CardLayout ${classes.root} ${className}`}
      aria-label={promoHeader || undefined}
      {...attrs}
    >
      {promoHeader && (
        <Typography variant={headerTag}>{promoHeader}</Typography>
      )}
      <RichText className={`RichText ${richText.styles}`}>
        {promoIntroCopy}
      </RichText>
      <div className={`${classes.container} col-${_columns}`}>
        {addItems?.map((item, index) => (
          <Grid
            item
            xs={12}
            md={getColumnWidth(index)}
            key={`item-card-${index}`}
          >
            <ProgramCard
              column={_columns}
              key={`${item?.contentful_id}${index}`}
              fullWidth={getColumnWidth(index) === 12}
              {...item}
            />
          </Grid>
        ))}
      </div>
    </section>
  );
}

export default CardLayout;
