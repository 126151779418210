import { FunctionComponent } from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import { useSitewideTranslations } from '../../queries/translations/sitewideTranslations.query';

// This "component" simply loads sitewide translations by being placed in the page wrapper

const TranslationLoader: FunctionComponent = () => {
  useTranslation(useSitewideTranslations());
  return null;
};

export default TranslationLoader;
