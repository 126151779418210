import { useMutation } from '@apollo/react-hooks';
import {
  Mutation,
  MutationUpdateServiceAgreementNicknameArgs,
  PremiseInfo,
} from '../__generated__/pge-types';
import { updateServiceAgreementNickname } from '../queries/account.query';
import { NickNameAddressPayload } from '../components/nickname-change-modal/types';

export default function() {
  const [update, { loading: updatingNickname }] = useMutation<
    Mutation,
    MutationUpdateServiceAgreementNicknameArgs
  >(updateServiceAgreementNickname);

  async function updateSANickname(payload: NickNameAddressPayload) {
    await update({
      variables: {
        payload: {
          encryptedId: payload.encryptedSaId,
          nickname: payload.nickName,
        },
      },
    });
  }
  return {
    updateSANickname,
    updatingNickname,
  };
}
