export enum Animate {
  AUTO = 'AUTO',
  NEXT = 'NEXT',
  PREV = 'PREV',
  SKIP = 'SKIP',
}

export type Action = {
  Animate: keyof typeof Animate;
};
