import Grid from '@material-ui/core/Grid';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import colors from '../../themes/main-colors';
import ROUTES from '../../routes';
import { useTranslation } from '../../hooks/useTranslation';
import useAccountForm from '../../hooks/useAccountForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      // alignItems: 'center',
      width: '100%',
      marginTop: theme.spacing(2),
      padding: '8px 0',
    },
    colorTextPrimary: {
      color: colors.noirBlur,
    },
    listItem: {
      padding: '6px 8px',
    },
  }),
);

export interface QuickLoginHintProps {
  isForgotPassword?: boolean;
}
export const QuickLoginHint = (props: QuickLoginHintProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { form, handleSubmit, handleForgotPassword } = useAccountForm({
    redirectUrl: ROUTES.QUICK_LOGIN_PASSWORD,
  });
  return (
    <>
      <Grid
        item
        xs={12}
        className={classes.root}
        style={
          props.isForgotPassword ? { backgroundColor: colors.lightYellow } : {}
        }
      >
        <ul>
          <li className={classes.listItem}>
            <Typography
              component={'div'}
              variant={'body2'}
              className={classes.colorTextPrimary}
            >
              {t('ONE_TIME_LOGIN_TIP_1')}
            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography
              component={'div'}
              variant={'body2'}
              className={classes.colorTextPrimary}
            >
              {t('ONE_TIME_LOGIN_TIP_2')}
            </Typography>
          </li>
          <li className={classes.listItem}>
            <Typography
              component={'div'}
              variant={'body2'}
              className={classes.colorTextPrimary}
            >
              {t('ONE_TIME_LOGIN_TIP_3')}
            </Typography>
          </li>
        </ul>
      </Grid>
    </>
  );
};

export default QuickLoginHint;
