import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../../themes/main-colors';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      fontSize: theme.typography.pxToRem(20),
      lineHeight: theme.typography.pxToRem(27),
      padding: 0,
      '& > p': {
        margin: 0,
      },
    },
    contactName: {
      color: colors.noirBlur,
      fontWeight: 'bold',
    },
    info: {
      color: colors.sparkBlue,
      fontWeight: 'bold',
      '& > a': {
        textDecoration: 'underline',
      },
    },
  }),
);

interface contactProps {
  contactName?: string;
  contactPhoneNumber?: string;
  contactEmailAddress?: string;
  contactCopy?: React.ReactNode;
}

export default (props: contactProps) => {
  const classes = useStyles();
  const {
    contactName,
    contactEmailAddress,
    contactPhoneNumber,
    contactCopy,
  } = props;

  const phoneNumber = (
    <a href={`tel:+1 ${contactPhoneNumber}`}>{contactPhoneNumber}</a>
  );

  const emailAddress = (
    <a href={`mailto:${contactEmailAddress}`}>{contactEmailAddress}</a>
  );

  return (
    <Container className={classes.root}>
      {contactCopy && <div>{contactCopy}</div>}
      {contactName && <p className={classes.contactName}>{contactName}</p>}
      {(contactPhoneNumber || contactEmailAddress) && (
        <ul>
          {contactPhoneNumber && (
            <li className={classes.info}>{phoneNumber}</li>
          )}
          {contactEmailAddress && (
            <li className={classes.info}>{emailAddress}</li>
          )}
        </ul>
      )}
    </Container>
  );
};
