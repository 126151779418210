import { useTranslation } from '../hooks/useTranslation';
import { parsePhoneNumber } from './format';
import {
  Query,
  AccountLookUpType,
  QueryValidateAccountExistsArgs,
} from '../__generated__/pge-types';
import { validateAccountExists as validateAccountExistsQuery } from '../queries/validateAccountExists.query';
import { verifyRoutingNumber as verifyRoutingNumberQuery } from '../queries/payment.query';
import { ApolloClient } from 'apollo-client';
import { parse, isPast, isBefore, differenceInYears } from 'date-fns';
const { t } = useTranslation();

export const DIGIT_REGEX = /(?=.*\d)/;
export const LOWERCASE_REGEX = /(?=.*[a-z])/;
export const UPPERCASE_REGEX = /(?=.*[A-Z])/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d$@$!%*#?&]{8,16}$/;
export const PIN_REGEX = /^\d{4}$/;
export const ALPHA_NUMERIC_REGEX = /^(?=.*[\w\d])([\s\S]+)$/;
const BIRTHDAYPATTERN = /\d{2}\/\d{2}\/\d{4}/;
const MINDATESTRING = '01/01/1901';
const MINDATE = parse(MINDATESTRING, 'MM/dd/yyyy', new Date(0));
export const PHONE_REGEX = /^\d{10}$/;
export const hasValidDigit = (password: string): boolean =>
  new RegExp(DIGIT_REGEX).test(password);
export const hasValidLowerCase = (password: string): boolean =>
  new RegExp(LOWERCASE_REGEX).test(password);
export const hasValidUpperCase = (password: string): boolean =>
  new RegExp(UPPERCASE_REGEX).test(password);
export const isValidPassword = (password: string): boolean =>
  new RegExp(PASSWORD_REGEX).test(password);
export const hasValidLength = (password: string): boolean =>
  password.length > 7 && password.length < 17;

export const EMAIL_REGEX = /^[a-zA-Z0-9_%+-]+(?:\.[a-zA-Z0-9._%+-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])$/;
export const isValidEmail = (email: string, submit?: boolean): boolean => {
  if ((!submit && !email.length) || EMAIL_REGEX.test(email)) {
    return true;
  }
  return false;
};

export const isValidBillMethod = (method: string): boolean => {
  console.log(method);
  return method === 'PAPERLESS' || method === 'MAIL';
};

export const isValidPin = (pin: string): boolean =>
  new RegExp(PIN_REGEX).test(pin);

export const isAtLeastEighteenYearsOfAge = (dob: string) => {
  const birthDate = parse(dob, 'MM/dd/yyyy', new Date());
  return differenceInYears(new Date(), birthDate) >= 18;
};

export const validateEmail = (email = ''): string | null => {
  if (email.trim().length < 1) {
    return t('BLANK_EMAIL');
  }
  if (!isValidEmail(email)) {
    return t('ERROR_AUTH_BAD_EMAIL');
  }
  return null;
};

export const validateEmailCoCustomer = (email = ''): string | null => {
  if (email.trim().length < 1) {
    return t('BLANK_EMAIL_CO_CUSTOMER');
  }
  if (!isValidEmail(email)) {
    return t('ERROR_AUTH_BAD_EMAIL');
  }
  return null;
};

export const validatePassword = (password = ''): string | null => {
  if (!password.length) {
    return t('ERROR_AUTH_PASSWORD_REQUIRED');
  }
  if (!isValidPassword(password) || password.length < 8) {
    return t('ERROR_AUTH_INVALID_PASSWORD_RULES');
  }
  return null;
};

export const validatePhone = (phoneNumber = ''): string | null => {
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
  if (parsedPhoneNumber.length < 10) {
    return t('ERROR_PHONE_REQUIRED');
  }
  return null;
};

export const validateAccountNumber = (accountNumber = ''): string | null => {
  if (accountNumber.length < 10) {
    return t('ERROR_ACCOUNT_NUMBER_REQUIRED');
  }
  return null;
};
export const validateSSN = (ssn = ''): string | null => {
  if (!ssn) {
    return t('ERROR_SSN_REQUIRED');
  }
  const invalidPrefixes = new RegExp(/^(?!0000)\d{4}$/);
  if (!invalidPrefixes.test(ssn)) {
    return t('ERROR_SSN_REQUIRED');
  }
  return null;
};

export const validateSSNCoCustomer = (ssn = ''): string | null => {
  if (!ssn) {
    return t('ERROR_SSN_REQUIRED_CO_CUSTOMER');
  }
  const invalidPrefixes = new RegExp(/^(?!0000)\d{4}$/);
  if (!invalidPrefixes.test(ssn)) {
    return t('ERROR_SSN_REQUIRED_CO_CUSTOMER');
  }
  return null;
};

export const validateFullSSN = (ssn = ''): string | null => {
  if (!ssn) {
    return `${t('ENTER_THE')} ${t('SOCIAL_SECURITY_NUMBER')}.`;
  }

  const invalidPrefixes = new RegExp(
    /^(?!666|000|9\d{2})\d{3}-?(?!00)\d{2}-?(?!0{4})\d{4}$/,
  );

  if (!invalidPrefixes.test(ssn)) {
    return `${t('INVALID_SSN')}.`;
  }

  return null;
};

export const validateDriversLicense = (driversLicense = ''): string | null => {
  if (driversLicense.length < 4) {
    return t('ERROR_DL_STATEID_REQUIRED');
  }
  return null;
};
export const validatePinCode = (pin = ''): string | null => {
  if (pin.length > 0 && pin.length < 4) {
    return t('ERROR_PIN_CODE_REQUIRED');
  }
  return null;
};
export const validatePhoneNumber = (phoneNumber = ''): string | null => {
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
  if (parsedPhoneNumber.length < 1) {
    return t('ERROR_PHONE_REQUIRED');
  }
  if (parsedPhoneNumber.length < 10) {
    return t('ERROR_PHONE_LENGTH');
  }
  return null;
};
export const validatePhoneNumberLength = (phoneNumber = ''): string | null => {
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
  if (parsedPhoneNumber && parsedPhoneNumber.length < 10) {
    return t('ERROR_PHONE_LENGTH');
  }
  return null;
};

export const validatePhoneRequired = (phoneNumber = ''): string | null => {
  const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
  if (!phoneNumber || parsedPhoneNumber.length < 10) {
    return t('ERROR_PHONE_LENGTH');
  }
  return null;
};

export const validateEmailOrPhone = (emailPhone: string | null = '') => {
  if (!emailPhone) {
    return t('ERROR_EMAIL_OR_PHONE');
  }
  if (EMAIL_REGEX.test(emailPhone)) {
    return null;
  } else if (PHONE_REGEX.test(emailPhone)) {
    return null;
  } else {
    return t('ERROR_VALID_EMAIL_OR_PHONE');
  }
};

export const validateCanadianZipCode = (zipCode: string): string | null => {
  if (!zipCode || !zipCode.length) {
    return t('ERROR_ENTER_ZIP_CODE');
  }

  if (zipCode.length < 7) {
    return t('ERROR_CANADIAN_ZIP_CODE_LENGTH');
  }

  const caZipCodeRegex = /^[a-zA-Z][0-9][a-zA-Z]\s[0-9][a-zA-Z][0-9]/;
  if (zipCode && !caZipCodeRegex.test(zipCode)) {
    return t('ERROR_ZIP_CODE_INVALID');
  }

  return null;
};

export const validateZipCode = (zipCode: string): string | null => {
  if (!zipCode || !zipCode.length) {
    return t('ERROR_ENTER_ZIP_CODE');
  }
  if (zipCode.length < 5) {
    return t('ERROR_ZIP_CODE_LENGTH');
  }

  const zipRegex = /(^[0-9]{5})$/;
  if (zipCode && !zipRegex.test(zipCode)) {
    return t('ERROR_ZIP_CODE_INVALID');
  }
  return null;
};
export const validatePostalCode = (postalCode: string): string | null => {
  if (!postalCode || !postalCode.length) {
    return t('ERROR_POSTAL_CODE_REQUIRED');
  }
  if (postalCode.length < 6) {
    return t('ERROR_POSTAL_CODE_LENGTH');
  }

  const provinceRegex = /(^[a-zA-Z][0-9][a-zA-Z][0-9][a-zA-Z][0-9])$/;
  if (postalCode && !provinceRegex.test(postalCode)) {
    return t('ERROR_POSTAL_CODE_INVALID');
  }
  return null;
};

export const validateBirthdate = (dob: string) => {
  if (!dob || dob === '') {
    return t('DATE_OF_BIRTH_REQUIRED');
  }
  if (!BIRTHDAYPATTERN.test(dob)) {
    return t('DATE_OF_BIRTH_INVALID');
  }

  const date = parse(dob, 'MM/dd/yyyy', new Date(0));
  if (!isPast(date)) {
    return t('DATE_OF_BIRTH_IN_PAST');
  }
  if (isBefore(date, MINDATE)) {
    return t('DATE_OF_BIRTH_TOO_OLD');
  }
  return null;
};

export const validatePersonDOBGretherThanMinAge = (
  dob: string | undefined,
  minAge: number,
) => {
  if (!dob || dob === '') {
    return t('DATE_OF_BIRTH_REQUIRED');
  }
  if (!BIRTHDAYPATTERN.test(dob)) {
    return t('DATE_OF_BIRTH_INVALID');
  }

  const date = parse(dob, 'MM/dd/yyyy', new Date(0));
  if (!isPast(date)) {
    return t('DATE_OF_BIRTH_IN_PAST');
  }
  if (isBefore(date, MINDATE)) {
    return t('DATE_OF_BIRTH_TOO_OLD');
  }
  if (!isMinimumAge(dob, minAge)) {
    return t('MIN_AGE_VALIDATION') + minAge + t('YEARS');
  }
  return null;
};

export const validateAmount = (amount: string): string | null => {
  const unFormattedAmount = Boolean(amount)
    ? amount
        .toString()
        .replace('$', '')
        .replace(',', '')
    : '';
  if (!unFormattedAmount) {
    return t('ERROR_PAYMENT_AMOUNT_REQUIRED');
  }
  if (Number(unFormattedAmount) === 0 || Number(unFormattedAmount) > 300000) {
    return t('ERROR_INVALID_PAYMENT_AMOUNT_NEW');
  }
  return null;
};

export const validateNameField = (name: string, value: any): string | null => {
  return !value?.length || name === value
    ? `${t('ENTER_THE')} ${name?.toLowerCase()}.`
    : null;
};

export const validateFullZipCode = (zipCode: string): string | null => {
  if (!zipCode || !zipCode.length) {
    return t('ERROR_ENTER_ZIP_CODE');
  }

  if (zipCode.length < 5) {
    return t('ERROR_ZIP_CODE_INVALID');
  }

  if (zipCode.length > 5 && zipCode.length < 10) {
    return t('ERROR_ZIP_CODE_INVALID');
  }

  const zipRegex = /(^[0-9]{5})$/;
  const zipFullRegex = /(^[0-9]{5}-[0-9]{4})$/;

  if (zipCode.length === 5) {
    if (zipCode && !zipRegex.test(zipCode)) {
      return t('ERROR_ZIP_CODE_INVALID');
    }
  }

  if (zipCode.length === 10) {
    if (zipCode && !zipFullRegex.test(zipCode)) {
      return t('ERROR_ZIP_CODE_INVALID');
    }
  }

  return null;
};

export const validateNameFieldStrictCasing = (
  name: string,
  value: any,
): string | null => {
  return !value?.length || name === value ? `${t('ENTER_THE')} ${name}.` : null;
};

export const validateMinMaxLength = (
  name: string,
  value: any,
): string | null => {
  return name === value || value?.length < 4 || value?.length > 22
    ? t('ID_NUMBER_MIN_MAX_ERROR')
    : null;
};

export const validateNicknameField = (
  name: string,
  value: any,
): string | null => {
  if (!value?.length) {
    return null;
  }
  if (name === value) {
    return `${t('ENTER')} ${name.toLowerCase()}`;
  }
  return null;
};

export const validateRoutingNumber = async (
  routingNumber: string,
  bankAccount: string,
  apolloClient: ApolloClient<any>,
): Promise<string | null> => {
  if (!routingNumber?.length) {
    return new Promise(resolve => resolve(t('ERROR_ROUTING_NUMBER_REQUIRED')));
  }
  if (routingNumber.length && routingNumber === bankAccount) {
    return t('ROUTING_CANNOT_MATCH_ACCOUNT');
  }
  if (routingNumber.length < 9) {
    return new Promise(resolve => resolve(t('ERROR_ROUTING_NUMBER_LENGTH')));
  }

  const results = await apolloClient.query({
    query: verifyRoutingNumberQuery,
    variables: {
      payload: {
        routingNumber: routingNumber,
      },
    },
    fetchPolicy: 'network-only',
  });

  const result = Boolean(results?.data?.verifyRoutingNumber?.valid);
  if (!Boolean(result)) {
    return t('ROUTING_NUMBER_INVALID');
  } else {
    return null;
  }
};

export const validateBankAccount = (bankAccount: string): string | null => {
  if (!bankAccount?.length) {
    return t('ERROR_BANK_ACCOUNT_REQUIRED');
  }
  if (bankAccount.length < 4) {
    return t('ERROR_BANK_ACCOUNT_LENGTH');
  }
  return null;
};

export const validateConfirmBankAccount = (
  originalBankAccount: string,
  confirmBankAccount: string,
): string | null => {
  if (!confirmBankAccount?.length) {
    return t('ERROR_CONFIRM_BANK_ACCOUNT_REQUIRED');
  }
  if (confirmBankAccount.length < 4) {
    return t('ERROR_CONFIRM_BANK_ACCOUNT_LENGTH');
  }

  if (
    originalBankAccount?.length &&
    confirmBankAccount !== originalBankAccount
  ) {
    return t('BANK_ACCOUNT_NUMBERS_DO_NOT_MATCH');
  }
  return null;
};

export const nameOfFactory = <T>() => (name: keyof T) => name;

export async function validateAccountExists(
  value: string,
  lookUpType: AccountLookUpType,
  apolloClient: ApolloClient<any>,
): Promise<null | string> {
  const results = await apolloClient.query<
    Query,
    QueryValidateAccountExistsArgs
  >({
    query: validateAccountExistsQuery,
    variables: {
      payload: {
        value,
        lookUpType,
      },
    },
    fetchPolicy: 'network-only',
  });

  const result = results?.data?.validateAccountExists!.result;

  if (!result) {
    let errorMessage: string = t('ACCOUNT_NOT_FOUND');
    if (lookUpType === AccountLookUpType.Phone) {
      errorMessage = `${errorMessage} ${t('CALL_FOR_ASSISTANCE')} ${t(
        'RESIDENTIAL_CUSTOMER_SERVICE_NUMBER',
      )} ${t('BETWEEN_THE_HOURS')}`;
    }
    return errorMessage;
  }
  return null;
}

export const validateCustomGroupName = (groupName = ''): string | null => {
  if (!groupName) {
    return t('ACCOUNT_GROUP_NAME_REQUIRED');
  }
  const letters = /^[0-9a-zA-Z-_ ]+$/;
  if (!letters.test(groupName)) {
    return t('GROUP_NAME_INVALID_CHAR');
  }
  if (groupName.length > 50) {
    return t('GROUP_NAME_LENGTH_EXCEED');
  }
  return null;
};

export const validateAlphabetCharacters = (
  name = '',
  value: any,
): string | null => {
  const regex = /^[a-zA-Z, \-]+$/;
  if (value && !regex.test(value)) {
    return `${t('THE')} ${name?.toLowerCase()} ${t(
      'CONTAINS_INVALID_CHARACTERS',
    )}`;
  }
  return null;
};

export const validateAlphabetCharactersWithAmp = (
  name = '',
  value: any,
): string | null => {
  const regex = /^[a-zA-Z,& \-]+$/;
  if (value && !regex.test(value)) {
    return `${t('THE')} ${name?.toLowerCase()} ${t(
      'CONTAINS_INVALID_CHARACTERS',
    )}`;
  }
  return null;
};

export const validPhoneExt = (phoneExt: any): string | null => {
  const letters = /^[0-9]+$/;
  if (phoneExt && !letters.test(phoneExt)) {
    return t('INVALID_CHARACTERS');
  }
  return null;
};

export const validateAlternatePhone = (
  phoneExt: any,
  altPhone: any,
): string | null => {
  if (phoneExt && !altPhone) {
    return t('ERROR_PHONE_REQUIRED');
  }
  if (altPhone && altPhone.length < 10) {
    return t('ERROR_PHONE_REQUIRED');
  }
  return null;
};

export const validateReasonForChange = (
  newValue: string | null,
  oldValue: string | null,
  reasonForChange: any,
): string | null => {
  if (oldValue !== newValue && !reasonForChange) {
    return t('REQUIRED_CHANGE_REASON');
  }
  return null;
};

export const validateExistsAndAlphabetCharacters = (
  name: string,
  value: any,
): string | null => {
  return (
    validateNameField(name, value) || validateAlphabetCharacters(name, value)
  );
};

export const validateAddressField = (value: any): string | null => {
  if (!value) {
    return `${t('ENTER')} ${t('STREET_ADDRESS').toLowerCase()}`;
  }
  const validCharactersRegex = /^[a-zA-Z0-9&,.\- ]+$/;
  if (value && !validCharactersRegex.test(value)) {
    return `${t('THE')} ${t('STREET_ADDRESS').toLowerCase()} ${t(
      'CONTAINS_INVALID_CHARACTERS',
    )}`;
  }
  return null;
};

export const validateValidCharacters = (
  name: string,
  value: any,
): string | null => {
  const invalidCharacters = new RegExp(
    /[\<\>\{\}\"\/\|;:,~!?%\^\=&\*\]\(\)\\\[\_]/gim,
  );
  if (value && invalidCharacters.test(value)) {
    return `${t('THE')} ${name} ${t('CONTAINS_INVALID_CHARACTERS')}`;
  }
  return null;
};

export const validateAddressLookup = (
  name: string,

  value: any,
): string | null => {
  const result = !value?.length
    ? `${t('SERVICE_STREET_ADDRESS_ERROR')}`
    : validateValidCharacters(name, value);
  return result;
};
export const validateRequiredAndValidCharacters = (
  name: string,
  value: any,
): string | null => {
  const result =
    validateNameFieldStrictCasing(name, value) ||
    validateValidCharacters(name, value);

  return result;
};
export const validateStateIdOrDiversLicense = (
  name: string,
  value: any,
): string | null => {
  const existingCheckResult = validateRequiredAndValidCharacters(name, value);

  if (existingCheckResult) {
    return existingCheckResult;
  }

  return name === value || value?.length < 4 || value?.length > 19
    ? t('ID_NUMBER_MIN_MAX_ERROR')
    : null;
};

export const validateFullITIN = (itin: string = '') => {
  if (!itin) {
    return `${t('ENTER_THE')} ${t('ITIN')}.`;
  }

  const invalidPrefixes = new RegExp(
    /^(9\d{2})([ \-]?)([7]\d|[8]\d)([ \-]?)(\d{4})$/,
  );

  if (!invalidPrefixes.test(itin)) {
    return `${t('INVALID_ITIN')}.`;
  }

  return null;
};

export const validateITIN = (itin: string) => {
  if (!itin) {
    return t('FORGOT_PASSWORD_LAST_FOUR_ITIN_ERROR_MESSAGE');
  }
  const invalidPrefixes = new RegExp(/^(?!0000)\d{4}$/);
  if (!invalidPrefixes.test(itin)) {
    return t('FORGOT_PASSWORD_LAST_FOUR_ITIN_ERROR_MESSAGE');
  }
  return null;
};

export const validateITINCustomRule = (_: string, itin: string) => {
  if (!itin) {
    return `${t('ENTER_THE')} ${t('ITIN')}.`;
  }
  const customRuleForITINRegex = new RegExp(
    '^9(?:5[0-9]|88|[9][0-2]|9[4-9])(?:-)?(?:5[0-9]|6[0-5]|88|[9][0-2]|[9][4-9])(?:-)?(?:[0-9]{4})$',
    '',
  );
  if (!customRuleForITINRegex.test(itin)) {
    return `${t('INVALID_ITIN')}.`;
  }
  return null;
};

export const validateLastFourDigits = (name: string, value: any) => {
  const invalidValues = new RegExp(/^(?!0000)\d{4}$/);
  if (!value || !invalidValues.test(value)) {
    return `${t('ENTER_THE_LAST_FOUR_DIGITS_OF_YOUR')} ${name}.`;
  }

  return null;
};

export const validateLastFourDigitsCoCustomer = (name: string, value: any) => {
  const validValues = new RegExp(/^[a-z0-9]{4}$/i);
  if (!value || !validValues.test(value)) {
    return `${t('ENTER_THE_LAST_CHARS_DIGITS_OF_THEIR')} ${name}.`;
  }

  return null;
};

export const validateLastFourDigitsCustomer = (name: string, value: any) => {
  const validValues = new RegExp(/^[a-z0-9]{4}$/i);
  if (!value || !validValues.test(value)) {
    return `${t('ENTER_THE_LAST_FOUR_CHARS_OF_YOUR')} ${name}.`;
  }

  return null;
};

export const validatePTREnrollment = (values: any): string | null => {
  if (
    values.peakTimeEnroll &&
    !Boolean(values.peakTimeEmail) &&
    !Boolean(values.peakTimePhone)
  ) {
    return t('PTR_OPTIONS_ENROLL_ADD_ONE_CONTACT');
  }

  return null;
};

export const scrollToErrors = () => {
  const offsetTop = document.getElementsByClassName(
    'MuiFormHelperText-contained Mui-error',
  )[0]?.parentElement?.offsetTop;

  if (offsetTop) {
    window.scrollTo({
      top: offsetTop - 100,
      behavior: 'smooth',
    });
  }
};

export const isMinimumAge = (dob: string, minAge: number) => {
  const birthDate = parse(dob, 'MM/dd/yyyy', new Date());
  return differenceInYears(new Date(), birthDate) >= minAge;
};
