import {
  createStyles,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from '@material-ui/core';
  
  import { Close } from '@material-ui/icons';
import React from 'react';
import { FormState } from '../../hooks/useFormState.types';
import { useTranslation } from '../../hooks/useTranslation';
import MFAVerification from './MFAVerification';
  
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      title: {
        textAlign: 'center',
      },
      closeBtn: {
        position: 'absolute',
        top: 24,
        right: 24,
        padding: 0,
      },
      popupContent: {
        padding: '0px',
      },
      popupContentArea: {
        textAlign: 'center',
        padding: theme.spacing(1, 3),
        [theme.breakpoints.down('sm')]: {
          padding: `${theme.spacing(2, 5)} !important`,
        },
      },
    }),
  );
  
  interface Props {
    open: boolean;
    onClose: () => void;
    twoStepVerificationForm: FormState<{
      mfaCode: string;
      phoneHint: string;
    }>;
    handleResendOneTimeCode: () => void;
    handleTwoFactorSubmit:() => void;
    title?: string;
  }
  
  const MFAVerificationModal = ({ open, onClose, title, handleTwoFactorSubmit, handleResendOneTimeCode, twoStepVerificationForm }: Props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
      <>
        <Dialog
          open={open}
          onClose={onClose}        
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          scroll="body"
          disableScrollLock
        >
          <DialogTitle>
            <Grid className={classes.title}>
              <Typography variant={'h2'} gutterBottom>
              {title ? title : t('CUSTOMER_SIGN_IN')}
              </Typography>
              <IconButton
                aria-label="close"
                color="inherit"
                onClick={onClose}
                className={classes.closeBtn}
              >
                <Close />
              </IconButton>
            </Grid>
          </DialogTitle>
          <DialogContent className={classes.popupContent}>
            <Paper className={classes.popupContentArea}>
            <form
              id={'mfa-verification-form'}
              noValidate
              onSubmit={handleTwoFactorSubmit}
            >
              <MFAVerification
                form={twoStepVerificationForm}
                handleResendOneTimeCode={handleResendOneTimeCode}
                isCompactMode={false}
              />
            </form>
            </Paper>
          </DialogContent>
        </Dialog>
      </>
    );
  };
  export default MFAVerificationModal;

