import React, { FC } from 'react';
import {
  Typography,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  useMediaQuery,
  makeStyles,
  Theme,
  createStyles,
} from '@material-ui/core';
import PgeButton from '../pge-button/PGEButtons';
import colors from '../../themes/main-colors';
import { ContentProps as Props, UseHeading, RegExArray } from './types.d';

type StyleProps = {
  bordered: boolean;
  componentBackgroundColor: string | undefined;
  tableHeader: ReadonlyArray<string | null | undefined> | undefined;
};

const getComponentBackgroundColor = (color: string | undefined) => {
  switch (color) {
    case 'None':
      return colors.white;
    case 'Light-Blue':
      return colors.riverBlue;
    case 'Grey':
      return colors.cloudGrey2;
    case 'Linen-White':
      return colors.linenWhite;
    case 'Yellow':
      return colors.lightYellow;
    default:
      return colors.white;
  }
};

const useStyles = makeStyles(
  ({ spacing, typography, breakpoints, palette }: Theme) =>
    createStyles({
      contentRoot: {
        background: ({ componentBackgroundColor }: StyleProps) =>
          getComponentBackgroundColor(componentBackgroundColor),
        padding: spacing(6.25),
        [breakpoints.down('sm')]: {
          padding: spacing(2.5),
        },
      },

      contentListHeader: {
        color: palette.text.primary,
        paddingBottom: spacing(2),
        lineHeight: typography.pxToRem(40),
        fontWeight: 300,

        [breakpoints.down('sm')]: {
          paddingTop: spacing(2.5),
          lineHeight: typography.pxToRem(28),
        },
      },

      contentListDesc: {
        color: colors.noirBlur,
        paddingBottom: spacing(2),
        lineHeight: typography.pxToRem(24),
        '& >p, >h6': {
          fontSize: '20px !important',
        },
        [breakpoints.down('sm')]: {
          fontSize: spacing(2),
          lineHeight: typography.pxToRem(20),
        },
      },

      tableContainer: {
        '&.MuiPaper-elevation1': {
          boxShadow: 'none',
          background: 'none',
          [breakpoints.up('sm')]: {
            border: ({ bordered }: StyleProps) =>
              bordered ? 0 : `solid 1px ${colors.grey2}`,
          },
          [breakpoints.down('sm')]: {
            border: 0,
          },
        },
        '& .MuiTableRow-root': {
          border: `solid 1px ${colors.grey2}`,

          [breakpoints.down('sm')]: {
            border: ({ bordered }: StyleProps) =>
              bordered ? 0 : `solid 1px ${colors.grey2}`,
          },

          [breakpoints.up('sm')]: {
            '& td:nth-child(2)': {
              width: ({ tableHeader }: StyleProps) =>
                tableHeader ? '' : '100%',
            },
            border: 0,
            '&.None:nth-of-type(even)': {
              background: colors.white,
            },
            '&.Grey:nth-of-type(even)': {
              background: colors.cloudGrey2,
            },
            '&.LightBlue:nth-of-type(even)': {
              background: colors.riverBlue,
            },
            '&:nth-of-type(odd)': {
              background: colors.white,
            },
          },
        },

        '& .MuiTableBody-root:nth-child(even)': {
          '& .MuiTableRow-root': {
            [breakpoints.down('sm')]: {
              '&.None': {
                background: colors.white,
              },
              '&.Grey': {
                background: colors.cloudGrey2,
              },
              '&.LightBlue': {
                background: colors.riverBlue,
              },
            },
          },
        },

        '& .MuiTableBody-root:nth-child(odd)': {
          '& .MuiTableRow-root': {
            [breakpoints.down('sm')]: {
              '&': {
                background: colors.white,
              },
            },
          },
        },

        '& .MuiTableBody-root::before': {
          content: '\'\'',
          display: 'block',
          height: ({ bordered }: StyleProps) => (bordered ? 0 : spacing(2)),

          [breakpoints.up('sm')]: {
            content: 'none',
          },
        },

        '& th, & td': {
          padding: spacing(1.5),
          wordWrap: 'anywhere',
          overflowWrap: 'anywhere',

          [breakpoints.up('sm')]: {
            padding: spacing(2),
          },
        },

        '& th': {
          fontSize: spacing(2.25),
          fontWeight: 700,
          backgroundColor: palette.text.primary,
          color: palette.common.white,
          minWidth: 118,
          [breakpoints.down('md')]: {
            minWidth: 'auto',
            whiteSpace: 'nowrap',
          },
        },

        '& td .MuiTypography-body1': {
          fontSize: spacing(2.25),
          [breakpoints.down('sm')]: {
            fontSize: spacing(2),
          },
        },

        '& .MuiTableBody-root': {
          [breakpoints.down('sm')]: {
            borderBottom: ({ bordered }: StyleProps) =>
              bordered ? `1px solid ${colors.noirBlurLight}` : 0,
          },
          '& tr td:first-child': {
            boxShadow: ({ bordered }: StyleProps) =>
              bordered ? '0px 0px 0px 0px rgba(219,219,219,0.75)' : '',
            [breakpoints.down('sm')]: {
              borderBottom: 0,
            },
            [breakpoints.up('sm')]: {
              whiteSpace: 'nowrap',
            },
          },
          '& tr:first-child td': {
            [breakpoints.down('sm')]: {
              paddingBottom: ({ bordered }: StyleProps) => (bordered ? 0 : 12),
            },
          },
        },
      },

      tableHeader: {
        borderRadius: 0,
        opacity: 1,
      },

      buttonStyle: {
        marginTop: spacing(3.75),

        [breakpoints.down('sm')]: {
          paddingBottom: spacing(2.5),
        },
      },
    }),
);

const useHeading: UseHeading = heading => {
  const span: number = heading.startsWith('--')
    ? Number(heading.slice(2, 3))
    : 1;
  const text: string = heading.replace(/--\d\s*|{{.+}}/g, '').trim();
  const stylesMatch: RegExArray | null = heading.match(/{{(.+)}}/);
  const styles: Record<string, string> = {};

  if (stylesMatch !== null) {
    const [, stylesGroup]: RegExArray = stylesMatch;
    const [, value = 'auto']: RegExArray =
      stylesGroup.match(/width:(\d+%)/) || [];

    styles['width'] = value;
  }

  return { span, text, styles };
};

const ContentList: FC<Props> = ({
  introHeading,
  introHeadingType,
  introCopy,
  tableHeader,
  rows,
  componentBackgroundColor,
  button,
  columnBackground,
  bordered = false,
}) => {
  const classes = useStyles({
    bordered,
    componentBackgroundColor,
    tableHeader,
  });
  const matches = useMediaQuery('(min-width:768px)');

  let introHeadingVariant: 'h1' | 'h2' | 'h3' | 'h4' = 'h2';

  switch (introHeadingType) {
    case 'h1':
      introHeadingVariant = 'h1';
      break;
    case 'h2':
      introHeadingVariant = 'h2';
      break;
    case 'h3':
      introHeadingVariant = 'h3';
      break;
    case 'h4':
      introHeadingVariant = 'h4';
      break;
    default:
      introHeadingVariant = 'h2';
  }

  const getColumnBackgroundColor = (color: string | undefined) => {
    switch (color) {
      case 'None':
        return 'None';
      case 'LightBlue':
        return 'LightBlue';
      case 'Grey':
        return 'Grey';
      default:
        return 'None';
    }
  };

  const Component: FC = () => {
    if (matches) {
      return (
        <TableContainer
          className={classes.tableContainer}
          component={Paper}
          square
        >
          <Table>
            {tableHeader && (
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  {tableHeader.map((headerElm, index) => {
                    if (!headerElm) {
                      return null;
                    }

                    const { span, text, styles } = useHeading(headerElm);

                    return (
                      <TableCell
                        key={index}
                        style={styles}
                        colSpan={span}
                        scope="col"
                      >
                        {text}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            )}
            {rows && (
              <TableBody>
                {rows.map((row, index) => (
                  <TableRow
                    key={index}
                    classes={{
                      root: getColumnBackgroundColor(columnBackground),
                    }}
                  >
                    {row.map((cellData, cellDataIndex) => (
                      <TableCell key={cellDataIndex}>{cellData}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      );
    }

    return (
      <TableContainer
        className={classes.tableContainer}
        component={Paper}
        square
      >
        <Table aria-label="customized table">
          {rows?.map((value, key) => (
            <TableBody key={key}>
              {value?.map((column, index) => {
                const { span, text } = useHeading(tableHeader?.[index] ?? '');

                return (
                  <TableRow
                    key={index}
                    classes={{
                      root: getColumnBackgroundColor(columnBackground),
                    }}
                  >
                    {text && (
                      <TableCell component="th" scope="row" rowSpan={span}>
                        {text}
                      </TableCell>
                    )}
                    <TableCell>{column}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          ))}
        </Table>
      </TableContainer>
    );
  };

  return (
    <div className={classes.contentRoot}>
      {introHeading && (
        <Typography
          variant={introHeadingVariant}
          className={classes.contentListHeader}
        >
          {introHeading}
        </Typography>
      )}
      {introCopy && <div className={classes.contentListDesc}>{introCopy}</div>}
      <Component />
      {button && (
        <div className={classes.buttonStyle}>
          <PgeButton {...button} />
        </div>
      )}
    </div>
  );
};

export default ContentList;
