import { navigate } from 'gatsby';
import { useMemo, useEffect, useState } from 'react';

import ROUTES from '../routes';
import {
  getFromStorage,
  setInStorage,
  removeFromStorage,
} from '../util/storage-utils';
import useTransition from './useTransition';
import { useTranslation } from './useTranslation';
import useFormState from './useFormState';
import useAuth from './useAuth';

import validationRules, {
  Model,
  MFAModel,
} from '../components/sign-in-form/SignInForm.rules';
import usePrevious from './usePrevious';
import {
  AuthErrorCodes,
  MultiFactorResolver,
  getMultiFactorResolver,
  PhoneMultiFactorGenerator,
  MultiFactorError,
  PhoneAuthProvider,
  getAuth,
  MultiFactorInfo,
} from 'firebase/auth';
import app, { auth } from '../firebase';
import { useRecaptcha } from '../hooks/useRecaptcha';

// keeping these exports outside of the useSignInForm hook since it will be used for the useRegistrationForm() hook as well
export const isValidPassword = (password: string): boolean =>
  new RegExp(
    '^(?=.*[A-Za-zd$?@?$?!?%?#???&?)])(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]){8,16}',
  ).test(password);

export interface FormData {
  [key: string]: string;
}

type CustomMultiFactorInfo = MultiFactorInfo & {
  phoneNumber: string;
};

const handleRememberMe = (rememberMe: boolean, email: string): void => {
  if (rememberMe) {
    setInStorage('userEmail', email);
  } else {
    removeFromStorage('userEmail');
  }
};

export default (props: {
  redirectUrl?: string;
  newEmail?: string | undefined;
  previousEmail?: string | undefined;
  closeModal?: () => void;
}) => {
  const authService = useAuth();
  const { t } = useTranslation();
  const { redirectUrl, newEmail, previousEmail, closeModal } = props;
  const onSuccessUrl = redirectUrl || ROUTES.ACCOUNT;
  const [invalidPasswordCount, setPasswordInvalidCount] = useState(0);
  const [isTwoStepRequired, setIsTwoStepRequired] = useState<boolean>(false);
  const [twoStepVerificationDetails, setTwoVerificationDetails] = useState<{
    verificationId: string;
    resolver: MultiFactorResolver;
  } | null>(null);
  const [twoStepVerificationError, setTwoStepVerificationError] = useState<
    MultiFactorError
  >();

  const recaptcha = useRecaptcha('sign-in');

  const emailFromStorage: string | undefined = useMemo(
    () => getFromStorage('userEmail'),
    [],
  );
  let defaultEmail = emailFromStorage;

  if (newEmail) {
    defaultEmail = newEmail;
  }

  if (previousEmail) {
    defaultEmail = previousEmail;
  }
  const defaultPersistLogin = useMemo(
    () => Boolean(getFromStorage('persistLogin')),
    [],
  );
  const form = useFormState(
    {
      email: defaultEmail || '',
      password: '',
      rememberMe: true,
      persistLogin: defaultPersistLogin,
    },
    {
      validate: validationRules,
      validationContext: {
        t,
      },
    },
  );
  const twoStepVerificationForm = useFormState({
    mfaCode: '',
    phoneHint: '',
  });
  const { hideTransition, showTransition } = useTransition();

  const navigateForgotPassword = (showNotification = false) => {
    handleRememberMe(form.values.rememberMe, form.values.email);

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    navigate(ROUTES.FORGOT_PASSWORD, {
      state: {
        redirectUrl,
        showNotification,
      },
    });
  };

  useEffect(() => {
    if (invalidPasswordCount > 2) {
      navigateForgotPassword(true);
      closeModal && void closeModal();
    }
  }, [invalidPasswordCount]);
  const values = form.values;
  const previousValues = usePrevious(values);

  useEffect(() => {
    if (previousValues !== null) {
      if (previousValues.rememberMe !== form.values.rememberMe) {
        handleRememberMe(form.values.rememberMe, values.email);
      }
    }
  });

  const showErrorMessage = (error: Error): void => {
    switch (error.message) {
      case 'EMAIL_NOT_FOUND':
        form.setError('email', t('ERROR_AUTH_INVALID_DEFAULT'));
        break;
      case 'INVALID_PASSWORD':
        form.setError('password', t('ERROR_AUTH_INVALID_DEFAULT'));
        break;
      case 'TOO_MANY_ATTEMPTS_TRY_LATER':
        form.setError('password', t('ERROR_AUTH_EXCEEDED_ATTEMPTS'));
        break;
      case AuthErrorCodes.INVALID_PASSWORD:
        form.setError('password', t('ERROR_AUTH_INVALID_DEFAULT'));
        break;
      case AuthErrorCodes.USER_DELETED:
        form.setError('email', t('ERROR_AUTH_INVALID_DEFAULT'));
        break;
      case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
        form.setError('password', t('ERROR_AUTH_EXCEEDED_ATTEMPTS'));
        break;
      case AuthErrorCodes.INVALID_CODE:
        twoStepVerificationForm.setError(
          'mfaCode',
          t('TWO_STEP_LOGIN_ERROR_INVALID_CODE'),
        );
        break;
      case AuthErrorCodes.CODE_EXPIRED:
        twoStepVerificationForm.setError(
          'mfaCode',
          t('TWO_STEP_LOGIN_ERROR_CODE_EXPIRED'),
        );
        break;
      default:
        console.warn('unhandled auth exception', error);
        handleBackToLogin();
        form.setError('password', t('ERROR_AUTH_UNEXPECTED'));
    }
  };

  const handleAuthErrors = (err: Error): void => {
    if (err.message === AuthErrorCodes.MFA_REQUIRED) {
      setTwoStepVerificationError(err as MultiFactorError);
      void handleMFARequest(err as MultiFactorError);
      return;
    }
    showErrorMessage(err);
    setPasswordInvalidCount(invalidPasswordCount + 1);
    hideTransition();
  };

  const onLoginSuccess = (url: string, state = {}) => {
    hideTransition();
    return navigate(url, { replace: true, state });
  };

  const handleLogin = async (data: Model) => {
    showTransition(t('SUBMITTING_REQUEST'));

    try {
      handleRememberMe(Boolean(data.rememberMe), data.email);
      await authService.signInWithPassword(data.email, data.password);
      return onLoginSuccess(onSuccessUrl, { showTwoStepAuthInfoBanner: true });
    } catch (err) {
      return handleAuthErrors(err as Error);
    }
  };

  const handleMFARequest = async (error: MultiFactorError) => {
    try {
      const resolver = getMultiFactorResolver(getAuth(app), error);

      const phoneHint = (resolver?.hints?.[0] as CustomMultiFactorInfo)
        ?.phoneNumber;

      if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
        const phoneInfoOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session,
        };

        const phoneAuthProvider = new PhoneAuthProvider(auth);

        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptcha!,
        );
        void twoStepVerificationForm.setValue('phoneHint', phoneHint);
        setTwoVerificationDetails({ verificationId, resolver });
        setIsTwoStepRequired(true);
        hideTransition();
        return true;
      }
    } catch (e) {
      form.setError('password', t('ERROR_AUTH_UNEXPECTED'));
      hideTransition();
    }
  };

  const handleLoginWithTwoStep = async (data: MFAModel) => {
    if (!twoStepVerificationDetails) {
      twoStepVerificationForm.setError('mfaCode', t('ERROR_AUTH_UNEXPECTED'));
      return;
    }
    showTransition(t('SUBMITTING_REQUEST'));

    try {
      await authService.signInWithMultiFactorAuth(
        twoStepVerificationDetails?.verificationId,
        twoStepVerificationDetails?.resolver,
        data.mfaCode,
      );
      return onLoginSuccess(onSuccessUrl);
    } catch (err) {
      showErrorMessage(err as Error);
      hideTransition();
      return;
    }
  };

  const handleBackToLogin = () => {
    setIsTwoStepRequired(false);
    setTwoVerificationDetails(null);
  };

  const handleResendOneTimeCode = async () => {
    if (twoStepVerificationError) {
      showTransition(t('SUBMITTING_REQUEST'));
      await handleMFARequest(twoStepVerificationError);
    }
  };

  const handleSubmit = form.submit(handleLogin);

  const handleTwoFactorSubmit = twoStepVerificationForm.submit(
    handleLoginWithTwoStep,
  );

  return {
    isTwoStepRequired,
    handleSubmit,
    handleTwoFactorSubmit,
    handleResendOneTimeCode,
    handleBackToLogin,
    form,
    twoStepVerificationForm,
    handleForgotPassword: navigateForgotPassword,
    invalidPasswordCount,
  };
};
