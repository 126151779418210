import { convertValidationRules } from '../../hooks/useFormState';
import { validateEmail } from '../../util/form-validation';

export type Model = {
  email: string;
  password: string;
  rememberMe: boolean;
  persistLogin: boolean;
};

export type MFAModel = {
  mfaCode: string;
  phoneHint: string;
};

type AdditionalContext = {
  t: (translation: string) => string;
};

function validateNonEmptyPassword(value: string, context: AdditionalContext) {
  if (!value.length) {
    return context.t('ERROR_AUTH_PASSWORD_REQUIRED');
  }
  return null;
}

export default convertValidationRules<Model, AdditionalContext>(() => ({
  email: validateEmail,
  password: validateNonEmptyPassword,
}));
