import React from 'react';
import { ComponentProps } from '../types.d';
import CTACard from '../../cta-card';
import CTA from '../../call-to-action';
import {
  getButtonVariable,
  useStyles,
} from '../../../util/contentful-render-utils';

const ElementCta = ({ entry, metaData }: ComponentProps) => {
  const classes = useStyles();

  const { anchorId } = metaData;

  const mappedEntry = {
    button: {
      buttonText: entry?.fields?.button?.fields?.buttonText,
    },
    buttonLink: entry?.fields?.buttonLink?.fields?.slug,
    heading: entry?.fields?.heading,
    shortDescription: entry?.fields?.shortDescription,
    displayOptions: entry?.fields?.displayOptions,
    image: {
      file: { url: entry?.fields?.image?.fields?.file?.url },
    },
    mobileImage: {
      file: { url: entry?.fields?.image?.fields?.file?.url },
    },
    imageBorderColor: entry?.fields?.imageBorderColor,
  };

  if (mappedEntry.imageBorderColor) {
    return (
      <div id={anchorId}>
        <CTACard {...mappedEntry} />
      </div>
    );
  }

  const btnProps = getButtonVariable(
    mappedEntry?.button,
    mappedEntry?.buttonLink,
  );
  return (
    <div id={anchorId} className={classes.spacing}>
      <CTA
        header={mappedEntry?.heading}
        description={mappedEntry?.shortDescription}
        bgMode={mappedEntry?.displayOptions}
        image={mappedEntry?.image?.file?.url}
        button={btnProps}
      />
    </div>
  );
};
export default ElementCta;
