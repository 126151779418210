import React, { useRef } from 'react';
//import Helmet from 'react-helmet';
import { FacebookProvider, Page } from 'react-facebook';
import useStyles from './SocialFeed.styles';
import { useComponentSize } from '../../../util/style-utils';
import TwitterFeed from '../../twitter-embed';
//import InstagramFeed from '../../instagram-embed';
import socialFeedConfig from '../../../config/socialFeedConfig';

const SocialFeedType = {
  Twitter: 'Twitter',
  Facebook: 'Facebook',
  Instagram: 'Instagram',
} as const;

interface SocialFeedBase {
  anchorId?: string | undefined;
  mention?: string | null | undefined;
  hashtag?: string | null | undefined;
  entryName?: string;
  feedType?: typeof SocialFeedType[keyof typeof SocialFeedType];
  limit?: number;
  backgroundColor?: string | null | undefined;
  isFromColumn?: boolean;
}

function SocialFeed({
  anchorId,
  hashtag,
  entryName,
  mention,
  feedType,
  limit,
  backgroundColor,
  isFromColumn = false,
}: SocialFeedBase): ElementNode {
  const $div = useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const { width } = useComponentSize($div);
  let calculatedHorizontalMargin = 0;

  let _color = 'None';
  if (!isFromColumn) {
    _color = backgroundColor || 'None';
  }

  if (width > 550) {
    calculatedHorizontalMargin = Math.trunc((width - 550) / 2);
  }

  let setScroll = false;

  let component;
  switch (feedType) {
    case SocialFeedType.Twitter: {
      setScroll = true;
      component = (
        <TwitterFeed userMentions={mention} hashtag={hashtag} limit={limit} />
      );
      break;
    }
    case SocialFeedType.Facebook: {
      component = (
        <FacebookProvider appId={socialFeedConfig.FacebookAppId}>
          <Page
            href="https://www.facebook.com/PortlandGeneralElectric/"
            tabs="timeline"
            width={width - 8}
            style={{ maxWidth: '550px' }}
          />
        </FacebookProvider>
      );
      break;
    }
    case SocialFeedType.Instagram: {
      setScroll = true;
      //component = <InstagramFeed limit={limit} />;
      component = <></>;
      break;
    }
    default: {
      component = <></>;
    }
  }

  return (
    <>
      {/* <Helmet>
        <script async defer src="https://www.instagram.com/embed.js" />
      </Helmet> */}
      <aside
        id={isFromColumn ? undefined : anchorId}
        aria-label={entryName || undefined}
        className="SocialFeed"
      >
        <div className={`${classes.card} bkg-${_color}`} ref={$div}>
          <div
            className={setScroll ? classes.scroll : undefined}
            style={{
              margin: `0 ${calculatedHorizontalMargin}px`,
            }}
          >
            {component}
          </div>
        </div>
      </aside>
    </>
  );
}

export default SocialFeed;
