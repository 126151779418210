import { useContext } from 'react';
import {
  AccountsContext,
  AccountsContextActionType,
} from '../providers/AccountsProvider';
import { setInStorage } from '../util/storage-utils';

const useAccountsStore = () => {
  const { state: customerAccountState, dispatch } = useContext(AccountsContext);

  const setHasInActiveAccounts = (status: boolean) => {
    dispatch({
      type: AccountsContextActionType.SET_HAS_INACTIVE_ACCOUNTS,
      hasInactiveAccounts: status,
    });
    setInStorage('hasInactiveAccounts', String(status));
  };

  const setAccountDisconnectedStatus = (
    reconnectInprogress: boolean,
    accountDisconnected: boolean,
    eligibleForReconnet: boolean,
  ) => {
    dispatch({
      type: AccountsContextActionType.SET_ACCOUNT_DISCONNECTED_STATUS,
      isReconnectInProgress: reconnectInprogress,
      isAccountDisonnected: accountDisconnected,
      isAccountEligibleForReconnect: eligibleForReconnet,
    });
  };

  return {
    customerAccountState,
    setHasInActiveAccounts,
    setAccountDisconnectedStatus,
  };
};
export default useAccountsStore;
