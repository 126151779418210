import React, { FunctionComponent, useContext } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from '../../hooks/useTranslation';
import { FormState } from '../../hooks/useFormState.types';
import EmailTextField from '../email-text-field';
import { Model } from '../../hooks/useQuickLogin';
import { Grid, InputAdornment, Tooltip } from '@material-ui/core';
import QuickLoginStatusMessage from './QuickLoginStatusMessage';
import QuickLoginHint from './QuickLoginHint';
import InfoIcon from '@material-ui/icons/Info';
import PGEButton from '../buttons/PGE-Button';
import colors from '../../themes/main-colors';

const useTooltipStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      backgroundColor: colors.linenWhite,
      color: colors.noirBlur,
      maxWidth: 360,
      height: 'auto',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #27788C',
      padding: '1em',
    },
  }),
);
const useStyles = makeStyles((theme: Theme) => ({
  cardActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(2),
  },
  submit: {
    backgroundColor: colors.orange,
    borderColor: colors.orange,
    '&:hover': {
      backgroundColor: colors.orange,
      borderColor: colors.orange,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));

export interface QuickLoginFormProps {
  form: FormState<Model>;
  handleSubmit: (e?: any) => Promise<void>;
  handleBack: () => Promise<void>;
  successMessage: boolean;
  isForgotPassword?: boolean;
}

export const defaultFormValues: Model = {
  email: '',
};

const QuickLoginForm: FunctionComponent<QuickLoginFormProps> = (
  props: QuickLoginFormProps,
) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const tooltipClasses = useTooltipStyles();
  const { form, handleSubmit, successMessage, handleBack } = props;
  return (
    <>
      <div style={{ justifyContent: 'flex-start', marginTop: '1em' }}>
        <Typography
          component={'div'}
          variant={'body2'}
          className={classes.title}
        >
          {props.isForgotPassword
            ? t('QUICK_LOGIN_FORGOT_PASSWORD_TITLE_MESSAGE')
            : t('QUICK_LOGIN_TITLE_MESSAGE')}
        </Typography>
        <form noValidate>
          <EmailTextField
            data-testid="onetime-login-email"
            name="email"
            label={t('REGISTERED_EMAIL_ADDRESS')}
            variant="outlined"
            autoComplete={t('EMAIL_ADDRESS')}
            style={{ width: '100%' }}
            {...form.props('email')}
            InputProps={
              !props.isForgotPassword
                ? {
                    endAdornment: (
                      <InputAdornment position={'end'}>
                        {
                          <Tooltip
                            title={<QuickLoginHint />}
                            classes={tooltipClasses}
                          >
                            <InfoIcon />
                          </Tooltip>
                        }
                      </InputAdornment>
                    ),
                  }
                : null
            }
          />
          {props.isForgotPassword && (
            <Grid container>
              <QuickLoginHint isForgotPassword={props.isForgotPassword} />
            </Grid>
          )}

          <Grid container className={classes.cardActions}>
            <Grid item>
              <PGEButton
                className={classes.submit}
                type={'submit'}
                variant={'contained'}
                color={'primary'}
                onClick={form.submit(
                  async values => await handleSubmit(),
                  console.log,
                )}
              >
                {t('SEND')}
              </PGEButton>
            </Grid>
          </Grid>
        </form>
      </div>
    </>
  );
};

export default QuickLoginForm;
