import { useApolloClient } from '@apollo/react-hooks';
import gql from 'not-graphql-tag';
import { SELECTED_GROUP_ID } from '../lib/apolloClient';
import { setInStorage } from '../util/storage-utils';
import useAccountCustomer from './useAccountCustomer';
import usePgeQuery from '../hooks/usePgeQuery';
import { useEffect } from 'react';
import isNil from 'lodash/isNil';
import { navigate } from '@reach/router';
import ROUTES from '../routes';

const getSelectedGroupId = gql`
  {
    selectedGroupId @client
  }
`;

function useDefaultGroupId() {
  const {
    customer,
    groups,
    defaultGroup,
    loading,
    totalAccounts,
  } = useAccountCustomer();

  useEffect(() => {
    if (customer && groups.length === 0 && totalAccounts) {
      // tslint:disable-next-line:no-floating-promises
      void navigate(ROUTES.ERROR);
    }
  }, [customer, groups]);

  if (loading || !customer) {
    return { loading };
  }

  const defaultOrFirst = customer && !defaultGroup ? groups[0] : defaultGroup;

  return {
    loading: false,
    defaultGroupId: defaultOrFirst?.groupId!,
    customer,
  };
}

export function useSetSelectedGroupId() {
  const client = useApolloClient();
  function setSelectedGroupId(grpId: string | null) {
    const dataToSet = { selectedGroupId: grpId };

    client.writeData({ data: dataToSet });
    setInStorage(SELECTED_GROUP_ID, grpId);
  }
  return { setSelectedGroupId };
}

/**
 *
 * Pulls the selected group id from the cache if it has been set, otherwise sets the selected
 * group id to the default using AccountCustomer data.
 *
 */
export default function useSelectedGroupId() {
  const client = useApolloClient();
  const { data } = usePgeQuery<{ selectedGroupId: string | undefined | null }>(
    getSelectedGroupId,
  );
  const { setSelectedGroupId } = useSetSelectedGroupId();

  const { loading, defaultGroupId, customer } = useDefaultGroupId();

  const storedGroupId = data?.selectedGroupId;

  // If the groupId is not an id of any of the groups,
  // clear it. This could happen if the group was deleted.
  useEffect(() => {
    if (customer && !isNil(storedGroupId)) {
      const ids = customer.groups?.map(grp => grp?.groupId);
      if (!ids?.includes(storedGroupId)) {
        setSelectedGroupId(null);
      }
    }
  }, [customer, storedGroupId]);

  useEffect(() => {
    if (!storedGroupId && defaultGroupId) {
      setSelectedGroupId(defaultGroupId);
    }
  }, [storedGroupId, defaultGroupId]);

  return {
    selectedGroupId: data?.selectedGroupId || defaultGroupId,
    setSelectedGroupId,
    loading,
  };
}
