import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Shadow } from '../../../constants';
import colors from '../../../themes/main-colors';
import { StaticListProps as Props } from './types.d';

const getBackgroundColor = (color: string | undefined): string => {
  let bgColor = colors.riverBlue;
  switch (color) {
    case 'Grey':
      bgColor = colors.cloudGrey2;
      break;
    case 'Yellow':
      bgColor = colors.lightYellow;
      break;
    case 'Blue':
    default:
      bgColor = colors.riverBlue;
      break;
  }
  return bgColor;
};

const useStyles = makeStyles((theme: Theme) => {
  const { palette } = theme;

  // Colors
  const { white } = palette.common;
  const text = palette.text.primary;
  const darkGrey = palette.grey[700];

  return createStyles({
    // Static List
    root_StaticList: {
      boxSizing: 'border-box',
      contain: 'content',
      marginTop: theme.spacing(2),
      overflow: 'hidden',
      color: text,
      backgroundColor: ({ colorBackground }: Props) =>
        getBackgroundColor(colorBackground),
    },

    hero: {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      overflow: 'hidden',
      height: '275px',

      '&::before': {
        display: 'block',
        width: '100%',
        paddingTop: '22%',
        content: '""',
      },

      '& img': {
        width: '100%',
        position: 'absolute',
        height: '275px',
        objectFit: 'cover',
        objectPosition: ({ imagePosition }: Props) => imagePosition,
      },
    },

    list: {
      padding: theme.spacing(2),
      counterReset: 'list-item 1',

      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(6),
      },

      '& header': {
        counterIncrement: 'list-item -1',
      },
    },

    // List Item
    root_ListItem: {
      boxSizing: 'border-box',
      contain: 'content',
      display: 'flex',
      padding: theme.spacing(4, 2),
      marginTop: theme.spacing(3),
      overflow: 'hidden',
      backgroundColor: white,

      '&::before': {
        flex: 'auto',
        maxWidth: '40px',
        counterIncrement: 'list-item',
        content: 'counter(list-item)',
        font: `700 ${theme.spacing(6)}px / 1 ${theme.typography.fontFamily}`,
        color: colors.noirBlur,
        textAlign: 'center',
        overflow: 'hidden',
        [theme.breakpoints.up('md')]: {
          maxWidth: '80px',
          fontSize: theme.spacing(7.5),
        },
      },

      '&.withImage': {
        '&::before': {
          flex: '0 1px',
          content: 'unset',
        },
      },
    },

    icon: {
      display: 'flex',
      flex: 'auto',
      maxWidth: '40px',
      justifyContent: 'center',
      alignItems: 'start',
      overflow: 'hidden',

      [theme.breakpoints.up('md')]: {
        maxWidth: '80px',
      },

      '& img': {
        width: '100%',
      },
    },

    content: {
      flex: 1,
      padding: theme.spacing(1, 0.5, 0, 2),
      overflow: 'hidden',

      '& .RichText': {
        '& hr': {
          backgroundColor: darkGrey,
        },
      },
    },
  });
});

export default useStyles;
