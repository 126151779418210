/** @jsx jsx */
import { jsx, css } from '@emotion/core';

import React, {
  FunctionComponent,
  useState,
  useContext,
  Fragment,
} from 'react';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import clsx from 'clsx';
import PGELogo from '../../logos/PGELogo';
import EnergyBand from '../../../static/white-energy-band-footer.svg';
import EnergyBandMobile from '../../../static/white-energy-band-footer-mobile.svg';
import TwitterXIcon from '../../../static/twitterX.svg';
import { useTranslation } from '../../../hooks/useTranslation';
import { usePromotionContext } from '../../../providers/PromotionProvider';
import ROUTES from '../../../routes';
import colors from '../../../themes/main-colors';

import { graphql, useStaticQuery, Link, navigate } from 'gatsby';
import TEST_IDS from '../../../constants/test_ids';
import { LanguageContext } from '../../../providers/LanguageProvider';
import { Grid } from '@material-ui/core';

interface StyleProps {
  promotion: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: '#fff',
      flex: 1,
    },
    backgrounds: {
      backgroundColor: colors.noirBlur,
    },
    promotionBackground: {
      backgroundColor: '#f0f0f0',
      width: '100%',
    },
    footerViewport: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      alignSelf: 'center',
      width: '90%',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    logo: {
      fontSize: '3.5rem',
    },
    energyBandContainer: {
      display: 'flex',
      backgroundColor: colors.noirBlur,
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(2),
      },
    },
    promotionEnergyBandContainer: {
      display: 'flex',
      '& > svg': {
        width: '100%',
        '& g g path': {
          stroke: colors.skyBlue,
        },
      },
    },
    sectionsContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      flexDirection: 'column',
      alignItems: 'space-between',
      [theme.breakpoints.up('md')]: {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      height: '100%',
      width: '100%',
    },
    mobileWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      paddingTop: theme.spacing(3),
    },
    mobilePromotionWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
    },
    section: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      margin: theme.spacing(0, 4, 0, 4),
      minWidth: 120,
      [theme.breakpoints.up('md')]: {
        justifyContent: 'flex-start',
        flexDirection: 'row',
        margin: 0,
      },
    },
    menuContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    corporateContainer: (props: StyleProps) => ({
      display: 'flex',
      height: '100%',
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(1),
      },
      [theme.breakpoints.up('md')]: {
        width: props.promotion ? 'auto' : '80%',
      },
    }),
    divider: {
      flex: 0,
      height: '100%',
      width: '100%',
      backgroundColor: '#FFFFFF',
      margin: theme.spacing(0, 0, 0, 0),
      [theme.breakpoints.up('md')]: {
        flex: 1,
        maxHeight: 'calc(100% - 24px)',
        minHeight: '50px',
        minWidth: '1px',
        maxWidth: '1px',
        margin: theme.spacing(2, 4, 2, 4),
      },
    },
    menuTitle: {
      minHeight: '0',
      margin: theme.spacing(2, 0, 1, 0),
      wordWrap: 'break-word',
      fontFamily: 'Untitled-Sans',
      fontWeight: 700,
      [theme.breakpoints.up('md')]: {
        minHeight: '25px',
        marginBottom: theme.spacing(1),
        wordWrap: 'break-word',
      },
    },
    menuLink: {
      minHeight: '8px',
      marginBottom: theme.spacing(0.5),
      whiteSpace: 'nowrap',
      color: '#FFF',
    },
    socialBlock: (props: StyleProps) => ({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '12px',
      [theme.breakpoints.up('md')]: {
        width: props.promotion ? 'auto' : '20%',
      },
      [theme.breakpoints.down('md')]: {
        paddingBottom: '20px',
      },
    }),
    miniDivider: {
      flex: 1,
      minWidth: '1px',
      maxWidth: '1px',
      backgroundColor: '#FFF',
      margin: theme.spacing(0, 0.5, 0, 0.5),
      [theme.breakpoints.up('md')]: {
        margin: theme.spacing(0.5, 1.5, 1, 1.5),
      },
    },
    miniPromotionDivider: {
      flex: 1,
      height: '25px',
      minWidth: '1px',
      maxWidth: '1px',
      backgroundColor: '#006DBA',
      margin: theme.spacing(0, 1.5, 0, 1.5),
      [theme.breakpoints.down(314)]: {
        margin: theme.spacing(0, 0.75, 0, 0.75),
        height: '80%',
      },
    },
    moreLinks: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      marginBottom: theme.spacing(2),
      fontWeight: 700,
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    link: {
      color: 'inherit',
      textDecoration: 'underline',
      '&:hover': {
        textDecoration: 'underline',
      },
      fontWeight: 400,
    },
    promoLink: {
      color: '#27788C',
      textDecoration: 'underline',
    },
    headerPlaceholder: {
      height: 49,
      [theme.breakpoints.down('xs')]: {
        height: 0,
      },
    },
  }),
);

const PgeFooter: FunctionComponent = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const smallView = useMediaQuery(theme.breakpoints.down('xs'));
  const [moreLinksExpanded, setMoreLinksExpanded] = useState<boolean>(false);
  const { promotion, noFooter } = usePromotionContext();
  const { language } = useContext(LanguageContext);
  const classes = useStyles({ promotion });
  const isBrowser = !(typeof window === 'undefined');
  const isOutages =
    isBrowser && window.location.pathname.replace(/\/$/, '') === ROUTES.OUTAGES;
  const isOutagesMobileApp =
    isBrowser &&
    window.location.pathname.replace(/\/$/, '') === ROUTES.OUTAGES_MOBILE_APP;
  const isPGEServiceAreaMap =
    isBrowser &&
    window.location.pathname.replace(/\/$/, '') === ROUTES.PGE_SERVICE_AREA_MAP;
  const isFullSurveyPage =
    isBrowser &&
    window.location.pathname.replace(/\/$/, '') === ROUTES.HOME_ENERGY_ANALYSIS;

  const isPGEPLUSGetStarted =
    isBrowser &&
    window.location.pathname.replace(/\/$/, '') === ROUTES.PGE_PLUS_GET_STARTED;

  if (
    isOutages ||
    isOutagesMobileApp ||
    isPGEServiceAreaMap ||
    isFullSurveyPage ||
    isPGEPLUSGetStarted
  ) {
    return null;
  }

  const transformMenuData = (results: any) => {
    return (
      results.allContentfulFooter.nodes.find(
        (node: any) => node.node_locale === language,
      ) || results.allContentfulFooter.nodes?.nodes[0]
    );
  };

  const menuData = transformMenuData(
    useStaticQuery(graphql`
      query getFooterContentQuery {
        allContentfulFooter {
          nodes {
            node_locale
            policyLinks {
              title
              url
            }
            socialLinks {
              type
              url
            }
            sections {
              contentful_id
              title
              links {
                title
                url
              }
            }
          }
        }
      }
    `),
  );

  const toParagraphs = (...nodes: any) => {
    let key = 0;
    const children = nodes.reduce(
      (result: any, node: any) =>
        result.concat(
          node.split('\n').map((paragraph: string) => (
            <span key={`title-${++key}`}>
              {paragraph}
              <br />
            </span>
          )),
        ),
      [],
    );
    return <span>{children}</span>;
  };

  const generateSocial = (nodes: { type: string; url: string }[]) => {
    const children: Array<JSX.Element> = [];
    nodes.forEach((node: { type: string; url: string }) => {
      children.push(generateSocialItem(node));
    });
    return (
      <div key={'social-block'} className={classes.socialBlock}>
        {children}
      </div>
    );
  };

  const generateSocialItem = (social: { type: string; url: string }) => {
    const renderIcon = (type: any) => {
      switch (type) {
        case 'facebook':
          return <FacebookIcon titleAccess={type} />;
        case 'instagram':
          return <InstagramIcon titleAccess={type} />;
        case 'twitter':
          return <TwitterXIcon />;
        case 'linkedin':
          return <LinkedInIcon titleAccess={type} />;
      }
    };

    return (
      <IconButton
        color={'inherit'}
        size={'small'}
        href={social.url}
        target="_blank"
        key={social.type}
        css={{ position: 'static' }}
        aria-label={social.type}
      >
        {renderIcon(social.type)}
      </IconButton>
    );
  };

  const buildLinkElement = (link: { title?: string; url?: string }) => {
    if (!link?.title) {
      return null;
    }

    const title = link.title.trim();
    if (!link?.url) {
      return <Fragment>{title}</Fragment>;
    }

    const url = link.url.trim();
    const isExternal = url.indexOf('http') === 0;
    const isTelephone = url.indexOf('tel') === 0;
    if (isExternal || isTelephone) {
      return (
        <a
          className={classes.link}
          href={url}
          aria-label={title}
          rel={isExternal ? 'noreferrer noopener' : undefined}
          target={isExternal ? '_blank' : '_self'}
        >
          {title}
        </a>
      );
    }

    return (
      <Link className={classes.link} to={url}>
        {title}
      </Link>
    );
  };

  const generateBusiness = (links: { title: string; url: string }[]) => {
    const children: Array<JSX.Element | null> = [];

    links.forEach((link: { title: string; url: string }, index: number) => {
      children.push(
        link.title ? (
          <Typography variant={'body2'} key={index}>
            {buildLinkElement(link)}
          </Typography>
        ) : null,
      );

      if (index + 1 < links.length) {
        children.push(
          <div
            key={`business-divider-${index}`}
            className={classes.miniDivider}
          />,
        );
      }
    });

    return (
      <Box className={classes.corporateContainer}>
        {children.filter(Boolean)}
      </Box>
    );
  };

  const renderMenus = (
    section: { title: string; links: { title: string; url: string }[] },
    index: number,
  ) => {
    const elements: Array<JSX.Element | null> = [];
    const titleElement = toParagraphs(
      !!section.title ? section.title.replace('\\n', '\n') : '',
    );

    elements.push(
      titleElement ? (
        <div key={`menuContainer-${index}`} className={classes.menuContainer}>
          {section.title ? (
            <Typography
              component={'div'}
              variant={'subtitle2'}
              className={classes.menuTitle}
            >
              {titleElement}
            </Typography>
          ) : (
            <div className={classes.headerPlaceholder} />
          )}
          {section.links.map((link, linkIndex) => {
            return (
              <div
                key={`subtitle-${index}-${linkIndex}`}
                className={classes.menuLink}
              >
                {!link.title ? null : (
                  <Typography variant={'caption'}>
                    {buildLinkElement(link)}
                  </Typography>
                )}
              </div>
            );
          })}
        </div>
      ) : null,
    );

    return elements.filter(Boolean);
  };

  const renderSections = (
    sections: {
      contentful_id: string;
      title: string;
      links: { title: string; url: string }[];
    }[],
  ) => {
    let elements: Array<JSX.Element> = [];
    if (isMobile) {
      const COMPANY_SECTION_CTFL_ID = '2uNcXPQj6Px8CyUr3jSjpR'; // English title = 'COMPANY'
      const CUSTOMER_SERVICE_SECTION_CTFL_ID = '5z79gpdHe2YnQ9bsVCsQ6e'; // English title = 'CUSTOMER_SERVICE'
      const { custServiceSection, outagesSection, moreLinks } = sections.reduce(
        (acc: any, section) => {
          if (section?.contentful_id === COMPANY_SECTION_CTFL_ID) {
            acc.custServiceSection = section;
          } else if (
            section.title &&
            ![
              COMPANY_SECTION_CTFL_ID,
              CUSTOMER_SERVICE_SECTION_CTFL_ID,
            ].includes(section?.contentful_id)
          ) {
            acc.outagesSection = section;
          } else {
            acc.moreLinks.push(section);
          }
          return acc;
        },
        {
          custServiceSection: null,
          outagesSection: null,
          moreLinks: [],
        },
      );
      elements = [
        ...[custServiceSection, outagesSection].map(
          (section: any, index: number) => (
            <div key={`footer-section-${index}`} className={classes.section}>
              {renderMenus(section, index)}
            </div>
          ),
        ),
        <div key="footer-section-more-links">
          <Box display="flex" marginLeft={4}>
            <Typography
              className={classes.moreLinks}
              onClick={() => setMoreLinksExpanded(!moreLinksExpanded)}
              variant={'subtitle1'}
            >
              {t('MORE_LINKS')}
            </Typography>
            {moreLinksExpanded ? (
              <ExpandLessIcon
                onClick={() => setMoreLinksExpanded(!moreLinksExpanded)}
              />
            ) : (
              <ExpandMoreIcon
                onClick={() => setMoreLinksExpanded(!moreLinksExpanded)}
              />
            )}
          </Box>
          <Collapse in={moreLinksExpanded}>
            {moreLinks.map((section: any, index: number) => (
              <div
                key={`footer-section-${index + 2}`}
                className={classes.section}
              >
                {renderMenus(section, index)}
              </div>
            ))}
          </Collapse>
        </div>,
      ];
    } else {
      elements = sections.reduce(
        (
          acc: any,
          section: { title: string; links: { title: string; url: string }[] },
          index: number,
        ) => {
          acc.push(
            <Grid
              item
              key={`footer-section-${index}`}
              className={classes.section}
            >
              {renderMenus(section, index)}
            </Grid>,
          );
          return acc;
        },
        [],
      );
    }

    return (
      <Box
        key={'footer-rendered-sections'}
        display={'flex'}
        flexDirection={'column'}
        css={{
          [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
            minWidth: '70%',
          },
        }}
      >
        {isMobile ? (
          elements
        ) : (
          <Fragment>
            <div
              className={clsx(classes.backgrounds, classes.sectionsContainer)}
            >
              <Grid
                container
                justify={'space-between'}
                wrap="nowrap"
                spacing={2}
              >
                {elements.slice(0, 3)}
              </Grid>
              <Grid item>
                <div key="divider" className={classes.divider} />
              </Grid>
              <Grid container justify="space-around" wrap="nowrap" spacing={2}>
                {elements.slice(3)}
              </Grid>
            </div>
            <Box
              className={clsx(classes.backgrounds)}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              mt={2}
              mb={2}
            >
              {generateBusiness(menuData.policyLinks)}
              {generateSocial(menuData.socialLinks)}
            </Box>
          </Fragment>
        )}
      </Box>
    );
  };

  return (
    <div
      className={classes.root}
      data-testid={TEST_IDS.PGE_FOOTER}
      data-swiftype-index="false"
    >
      {!promotion && (
        <Fragment>
          <div className={clsx(classes.backgrounds, classes.mobileWrapper)}>
            {renderSections(menuData.sections)}
          </div>
          {isMobile && (
            <Box
              className={clsx(classes.backgrounds)}
              display={'flex'}
              justifyContent={'space-between'}
              alignItems={'center'}
              flexDirection={'column'}
            >
              {generateBusiness(menuData.policyLinks)}
              {generateSocial(menuData.socialLinks)}
            </Box>
          )}
        </Fragment>
      )}
      {promotion && (
        <Fragment>
          <div
            className={`${classes.promotionBackground} ${classes.mobilePromotionWrapper}`}
          >
            {!noFooter && (
              <Fragment>
                <Box className={`${classes.footerViewport}`} mt={1} mb={1}>
                  <Box className={classes.corporateContainer}>
                    <Typography variant={'subtitle2'}>
                      <Link
                        className={classes.promoLink}
                        to={ROUTES.PRIVACY_POLICY}
                      >
                        PRIVACY POLICY
                      </Link>
                    </Typography>
                    <div className={classes.miniPromotionDivider} />
                    <Typography variant={'subtitle2'}>
                      <Link
                        className={classes.promoLink}
                        to={ROUTES.LEGAL_NOTICES}
                      >
                        LEGAL NOTICES
                      </Link>
                    </Typography>
                    {!isMobile && (
                      <Fragment>
                        <div className={classes.miniPromotionDivider} />
                        <Typography variant={'subtitle2'}>
                          <Link className={classes.promoLink} to={ROUTES.HOME}>
                            portlandgeneral.com
                          </Link>
                        </Typography>
                      </Fragment>
                    )}
                  </Box>
                  {isMobile && (
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      marginTop={'20px'}
                    >
                      <Typography variant={'subtitle2'}>
                        <Link className={classes.promoLink} to={ROUTES.HOME}>
                          portlandgeneral.com
                        </Link>
                      </Typography>
                    </Box>
                  )}
                  <div key={'social-block'} className={classes.socialBlock}>
                    <IconButton
                      aria-label={t('NAVIGATE_HOME')}
                      onClick={async (e: any) => {
                        e.preventDefault();
                        await navigate(ROUTES.HOME);
                      }}
                    >
                      <PGELogo
                        className={classes.logo}
                        color="#006DBA"
                        viewBox="0 0 108 108"
                      />
                    </IconButton>
                  </div>
                </Box>
              </Fragment>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default PgeFooter;
