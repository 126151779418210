import React, { FunctionComponent } from 'react';

import { useTranslation } from '../../hooks/useTranslation';
import TextField, { TextFieldProps } from '../text-field/TextField';

const EmailTextField: FunctionComponent<
  TextFieldProps | any
> = React.forwardRef((props: any, ref?: React.Ref<HTMLInputElement>) => {
  const { t } = useTranslation();
  return (
    <TextField
      name={'email'}
      type={'email'}
      margin={'normal'}
      ref={ref}
      label={props.label || t('EMAIL')}
      style={props.style || { width: '100%' }}
      variant={'outlined'}
      error={!!props.error}
      helperText={props.helperText || props.error}
      {...props}
      inputProps={{
        'data-testid': 'emailField',
        maxLength: 50,
        ...props.inputProps,
      }}
    />
  );
});

export default EmailTextField;
