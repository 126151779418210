import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone';

import { useTranslation } from '../../hooks/useTranslation';
import colors from '../../themes/main-colors';
import { Typography } from '@material-ui/core';
import ROUTES from '../../routes';
import TextLink from '../text-link';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    backgroundColor: colors.errorRed,
    display: 'flex',
    borderRadius: 4,
    margin: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
  },
  errorIcon: {
    width: 48,
    alignItems: 'flex-start',
    marginTop: '18px',
    '& path:first-of-type': {
      fill: '#520b02',
    },
    '& path:last-of-type': {
      fill: colors.white,
    },
  },
  warningText: {
    padding: theme.spacing(2, 2, 2, 0),
    color: colors.white,
    flexGrow: 8,
    '& p': { margin: 0 },
    '& a': {
      '&:active': {
        color: colors.white,
        borderBottom: 'none',
      },
      color: colors.white,
    },
  },
}));

type ReconnectNoticeProps = {
  isEligibleForReconnect: boolean;
};

const ReconnectNotice = ({ isEligibleForReconnect }: ReconnectNoticeProps) => {
  const { t, richT } = useTranslation();
  const classes = useStyles();
  return (
    <Box className={classes.wrapper}>
      <ErrorTwoToneIcon className={classes.errorIcon} />
      <div className={classes.warningText}>
        {isEligibleForReconnect ? (
          <Typography variant={'body1'}>
            {t('RECONNECT_ELIGIBLE_NOTICE')}
            <TextLink to={ROUTES.RECONNECT_SERVICE_START}>
              {t('SEE_YOUR_PAYMENT_OPTIONS')}
            </TextLink>
          </Typography>
        ) : (
          <Typography variant={'body1'}>
            {richT('RECONNECT_INELIGIBLE_NOTICE', {
              PGE_CUSTOMER_SERVICE_NUMBER: t('PGE_CUSTOMER_SERVICE_NUMBER'),
              CUSTOMER_SERVICE_NUMBER: t('CALL_FOR_ASSISTANCE_NUMBER'),
            })}
          </Typography>
        )}
      </div>
    </Box>
  );
};

export default ReconnectNotice;
