import React from 'react';
import useAccountsStore from '../../hooks/useAccountsStore';
import ReconnectNotice from '../reconnect-notice/ReconnectNotice';

const AccountDisconnectedBanner = () => {
  const { customerAccountState } = useAccountsStore();
  const isReconnectInProgress = customerAccountState?.isReconnectInProgress;
  const isAccountDisonnected = customerAccountState?.isAccountDisonnected;
  const isAccountEligibleForReconnect =
    customerAccountState?.isAccountEligibleForReconnect;
  return (
    <>
      {!isReconnectInProgress && isAccountDisonnected && (
        <ReconnectNotice
          isEligibleForReconnect={isAccountEligibleForReconnect}
        />
      )}
    </>
  );
};

export default AccountDisconnectedBanner;
