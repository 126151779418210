import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import colors from '../../themes/main-colors';
import Grid from '@material-ui/core/Grid';
import RichText, { richTextStyles } from '../rich-text';
import { HeaderVariant } from './types';

interface IProps {
  accordionItems: any;
  accordionHeader?: string;
  accordionHeaderType?: string;
  accordionIntroCopy?: any;
  colorBackground?: string;
  isChildOfTabset?: boolean;
}

export const getBackgroundColor = (color: any | null): any | null => {
  let bgColor = colors.riverBlue;
  switch (color) {
    case 'Grey':
      bgColor = colors.lightGray2;
      break;
    case 'Blue':
    default:
      bgColor = colors.riverBlue;
      break;
  }
  return bgColor;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: IProps) => ({
      width: '100%',
      padding: props?.isChildOfTabset ? 0 : theme.spacing(6.25),
      backgroundColor: props?.isChildOfTabset
        ? 'transparent'
        : getBackgroundColor(props?.colorBackground),
      '& > div.MuiPaper-root': {
        margin: theme.spacing(2.5, 0),
        [theme.breakpoints.down('sm')]: {
          margin: theme.spacing(2, 0),
        },
        boxShadow: 'unset',
        '& > div > div:nth-child(1)': {
          display: 'block',
        },
        '& > div.icon > div:nth-child(1)': {
          display: 'flex',
          '& > div > div > img': {
            marginRight: theme.typography.pxToRem(10),
            width: theme.typography.pxToRem(33.32),
            height: theme.typography.pxToRem(28),
          },
        },
      },
      '& > div:before': {
        height: '0',
      },
      '& .MuiAccordionSummary-root': {
        minHeight: theme.spacing(8.75),
      },
      [theme.breakpoints.down('sm')]: {
        padding: props?.isChildOfTabset ? 0 : theme.spacing(2.5),
      },
    }),
    accordian: {
      padding: theme.spacing(2.5, 3.75),

      '& .MuiAccordionSummary-root': {
        padding: 0,
      },
    },
    gridXs1: {
      flexGrow: 0,
      maxWidth: '5.333333%',
      flexBasis: '5.333333%',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '12.333333%',
        flexBasis: '12.333333%',
      },
    },
    gridXs11: {
      flexGrow: 0,
      maxWidth: '94.666667%',
      flexBasis: '94.666667%',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '87.666667%',
        flexBasis: '87.666667%',
      },
    },
    tabTitle: {
      fontSize: '24px',
      fontFamily: 'Forma-DJR-Display',
    },
    accordianSummaryContainer: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        flexWrap: 'nowrap',
        gap: '16px',
      },
    },
    accordionDetails: {
      '&.MuiAccordionDetails-root': {
        padding: theme.spacing(2.5),
      },
      '& img': {
        width: 'inherit',
        [theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
      '& .RichText': {
        width: '100%',
      },
      '& .RichText p a': {
        [theme.breakpoints.down('sm')]: {
          wordBreak: 'break-all',
        },
      },
    },
  }),
);
const PGEAccordion = (props: IProps): React.ReactElement => {
  const classes = useStyles(props);
  const richText = richTextStyles();
  const {
    accordionItems,
    accordionIntroCopy,
    accordionHeader,
    accordionHeaderType,
  } = props;
  const initialItems: any = [...accordionItems];
  const [expands, setExpands] = React.useState<Array<boolean>>([
    ...initialItems?.fill(false),
  ]);
  // Handle Expand and Collapse of Accordion
  const handleChange = (index: number) => (
    event: React.ChangeEvent<{}>,
    isExpanded: boolean,
  ): void => {
    const data: Array<boolean> = [...expands];
    data[index] = isExpanded;

    setExpands(data);
  };

  const mainHeaderTag =
    !accordionHeaderType || !/^h[1-4]$/.test(accordionHeaderType)
      ? ('h2' as HeaderVariant)
      : (accordionHeaderType as HeaderVariant);

  const accordion = accordionItems?.map(
    (panel: any, index: number): React.ReactElement => {
      return (
        <Accordion
          data-testid={index}
          expanded={expands[index]}
          onChange={handleChange(index)}
          key={index}
          className={classes.accordian}
        >
          <AccordionSummary
            data-testid={`panel${index}bh-header`}
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}bh-content`}
            id={`panel${index}bh-header`}
            className={panel.tabTitleIcon ? 'icon' : ''}
          >
            <Grid container className={classes.accordianSummaryContainer}>
              {panel.tabTitleIcon && (
                <Grid item xs={1} className={classes.gridXs1}>
                  {panel?.tabTitleIcon?.file && (
                    <img
                      src={panel?.tabTitleIcon?.file?.url}
                      alt=""
                      width="30"
                      height="30"
                    />
                  )}
                  {panel?.tabTitleIcon?.$$typeof && <>{panel?.tabTitleIcon}</>}
                </Grid>
              )}
              {(panel?.tabTeaser || panel.tabTitle) && (
                <Grid
                  item
                  xs={panel?.tabTitleIcon ? 11 : 12}
                  className={panel?.tabTitleIcon ? classes.gridXs11 : ''}
                >
                  <Typography variant={'body1'} className={classes.tabTitle}>
                    {panel.tabTitle}
                  </Typography>

                  {panel?.tabTeaser && (
                    <Typography variant={'body2'}>
                      {panel?.tabTeaser}
                    </Typography>
                  )}
                </Grid>
              )}
            </Grid>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            {panel?.tabBody?.raw ? (
              <RichText className={`RichText ${richText.styles}`}>
                {panel?.tabBody}
              </RichText>
            ) : (
              panel?.tabBody
            )}
          </AccordionDetails>
        </Accordion>
      );
    },
  );
  return (
    <div className={classes.root}>
      {accordionHeader && (
        <Typography variant={mainHeaderTag}>{accordionHeader}</Typography>
      )}
      {accordionIntroCopy && (
        <RichText className={`RichText ${richText.styles}`}>
          {accordionIntroCopy}
        </RichText>
      )}
      {accordion}
    </div>
  );
};

export default PGEAccordion;
