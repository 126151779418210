import { useMutation } from '@apollo/react-hooks';
import gql from 'not-graphql-tag';
import { useContext, useEffect, useState } from 'react';
import {
  ApplicationEvSectionType,
  ApplicationSectionStatus,
  RebateIneligibilityCode,
  SectionEvRebatesData,
  UpdateSectionEvRebatesInput,
  ValidationResult,
} from '../../__generated__/pgeplus-types';
import {
  RebateEligibility,
  RebateEligibility as RebateEligibilityFormTypes,
} from '../../components/pge-plus-form/evcharger.types';
import { useEVChargerFormState } from '../../providers/EVChargerFormStateProvider';
import { NotificationsContext } from '../../providers/NotificationsProvider';
import { useTranslation } from '../useTranslation';

const updateSectionEVRebatesMutation = gql`
  mutation UpdateSectionEVRebates($input: UpdateSectionEVRebatesInput!) {
    updateSectionEVRebates(input: $input) {
      validation {
        errors {
          path
          reason
        }
        success
      }
      section {
        data {
          rebateEligibility {
            eligible
            ineligibilityReason
            rebate {
              ... on RebateSmartCharging {
                amount
                charger
                iqdb
                panelUpgrade
              }
              ... on RebateEVPulse {
                amount
              }
              amount
            }
          }
        }
      }
    }
  }
`;

export type CheckRebateEligibilityType = {
  isEligibleForIQDB: boolean;
  eligibleForSmartChargingRebate: boolean;
  vin: string | undefined;
  serialNumber: string | undefined;
  hasBothRebatesClaimed: boolean;
};

export type UpdateSectionEVRebatesMutationTypes = (
  formData: RebateEligibility,
  saveAndComeBack?: boolean,
  status?: ApplicationSectionStatus,
) => Promise<{
  isEligible?: boolean | undefined;
  higherRebate?: boolean | undefined;
  panelAmount?: number | undefined;
  chargerAmount?: number | undefined;
  validationError?: boolean | undefined;
  amount?: number | undefined;
}>;

export type evUpdateRebateResponse = {
  validation?: ValidationResult;
  section?: {
    data?: {
      rebateEligibility?: {
        eligible: boolean;
        ineligibilityReason?: RebateIneligibilityCode;
        rebate?: {
          iqdb?: boolean;
          panelUpgrade?: number;
          charger?: number;
          amount?: number;
        };
      };
    };
  };
};
export type evRebateEligibilityType =
  | {
      isEligible?: boolean;
      higherRebate?: boolean;
      panelAmount?: number;
      chargerAmount?: number;
      validationError?: boolean;
      amount: number;
    }
  | undefined;
export const getValue = (val: any) =>
  Boolean(val) ? val === 'Yes' || val === 'Sí' : undefined;

const getChargerForExistingVehicleValue = (val: string | undefined) => {
  if (val === 'Yes' || val === 'Sí') {
    return false;
  }
  if (val === 'No') {
    return true;
  }
  return undefined;
};

export const useRebateEligibility = () => {
  const [evRebateEligibility, setEvRebateEligibility] = useState<
    evRebateEligibilityType
  >();
  const { t } = useTranslation();

  const notificationContext = useContext(NotificationsContext);
  const setNotificationMessage = (
    message: string | React.ReactNode = '',
    severity: 'success' | 'error' = 'success',
  ) => {
    notificationContext.setState({
      isOpen: true,
      message,
      severity,
    });
    window.scrollTo(0, 0);
  };

  const {
    formState,
    formState: { application },
    updateFormState,
  } = useEVChargerFormState();

  const [checkRebateEligibility, setCheckRebateEligibility] = useState<
    CheckRebateEligibilityType
  >({
    isEligibleForIQDB: false,
    eligibleForSmartChargingRebate: false,
    hasBothRebatesClaimed: false,
    vin: undefined,
    serialNumber: undefined,
  });

  const rebateInfo = application?.details.find(
    section => section.sectionType === ApplicationEvSectionType.EvRebates,
    //@ts-ignore
  ) as SectionEvRebates;
  const rebateData = rebateInfo?.data as SectionEvRebatesData;
  const rebateId = rebateInfo?.id;
  const applicationId = application?.id || '';

  const [updateSectionEVRebates] = useMutation<{
    updateSectionEVRebates: evUpdateRebateResponse;
  }>(updateSectionEVRebatesMutation, {
    context: {
      pgePlus: true,
    },
    fetchPolicy: 'no-cache',
  });

  const updateSectionEVRebatesEligibility = async (
    formData: RebateEligibilityFormTypes,
    saveAndComeBack: boolean = false,
    status: ApplicationSectionStatus = ApplicationSectionStatus.Pending,
  ) => {
    const input: UpdateSectionEvRebatesInput = {
      applicationId,
      id: rebateId,
      evPulse: {
        chargerForExistingVehicle: getChargerForExistingVehicleValue(
          formData?.chargerForExistingVehicle,
        ),
        connectEVPulseApp: getValue(formData?.isOwnTesla),
        continueWithoutRebate: getValue(formData?.continueWithoutRebate),
        vin: formData?.vin,
        vinImage: formData?.vinImage,
      },
      iqdb: {
        checkEligibility: getValue(formData?.isNeedRebateInfo),
        grossIncome: formData?.annualHouseholdIncome
          ? Number(formData?.annualHouseholdIncome)
          : undefined,
        grossIncomeConfirmed: formData?.isAnnnualIncomeChecked === 'true',
        houseHoldSize: formData?.houseHoldSize
          ? Number(formData?.houseHoldSize)
          : undefined,
      },
      saveAndComeBack: saveAndComeBack,
      smartCharging: {
        chargerForExistingVehicle: getChargerForExistingVehicleValue(
          formData?.chargerForExistingVehicle,
        ),
        continueWithoutRebate: getValue(formData?.continueWithoutRebate),
        wifiAvailable: getValue(formData?.isHaveWifi),
        activateChargerInApp: getValue(formData?.isCommitToExtendWifi),
      },
      status,
      confirmed: true,
    };

    const { data } = await updateSectionEVRebates({
      variables: {
        input,
      },
    });

    if (data?.updateSectionEVRebates?.validation?.success) {
      const evRebateResponse: evRebateEligibilityType = {
        isEligible:
          data?.updateSectionEVRebates?.section?.data?.rebateEligibility
            ?.eligible || false,
        chargerAmount:
          data?.updateSectionEVRebates?.section?.data?.rebateEligibility?.rebate
            ?.charger || 0,
        panelAmount:
          data?.updateSectionEVRebates?.section?.data?.rebateEligibility?.rebate
            ?.panelUpgrade || 0,
        higherRebate:
          data?.updateSectionEVRebates?.section?.data?.rebateEligibility?.rebate
            ?.iqdb || false,
        validationError: false,
        amount: data?.updateSectionEVRebates?.section?.data?.rebateEligibility
          ?.eligible
          ? // this was earlier set to total amount (rebate.amount), but this has been changed to only display the charger amount as per UAT defect D105
            data?.updateSectionEVRebates?.section?.data?.rebateEligibility
              ?.rebate?.charger ||
            data?.updateSectionEVRebates?.section?.data?.rebateEligibility
              ?.rebate?.amount ||
            0
          : 0,
      };
      setEvRebateEligibility(evRebateResponse);
      return evRebateResponse;
    } else {
      setNotificationMessage(
        t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY'),
        'error',
      );
      return {
        validationError: true,
      };
    }
  };

  useEffect(() => {
    if (rebateData?.availability?.iqdbEnroll) {
      checkRebateEligibility.isEligibleForIQDB = true;
    }

    if (rebateData?.availability?.smartChargingRebate) {
      checkRebateEligibility.eligibleForSmartChargingRebate = true;
    } else {
      checkRebateEligibility.hasBothRebatesClaimed = true;
    }

    if (rebateData?.existingEnrollments?.evPulse?.length !== 0) {
      checkRebateEligibility.vin =
        rebateData?.existingEnrollments?.evPulse?.[0]?.vin;
    }

    if (rebateData?.existingEnrollments?.smartCharging?.length !== 0) {
      checkRebateEligibility.serialNumber =
        rebateData?.existingEnrollments?.smartCharging?.[0]?.serialNo;
    }

    setCheckRebateEligibility(checkRebateEligibility);
  }, [rebateData]);

  return {
    checkRebateEligibility,
    updateSectionEVRebatesEligibility,
    evRebateEligibility,
  };
};
