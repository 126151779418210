import { PageTextContentType } from '../../../hooks/useContentMgmt';
import { convertValidationRules } from '../../../hooks/useFormState';
import { isValidPassword, validateEmail } from '../../../util/form-validation';

export type Model = {
  email: string;
  password: string;
  rememberMe: boolean;
};

type AdditionalContext = {
  content: PageTextContentType;
};

export default convertValidationRules<Model, AdditionalContext>(context => {
  return {
    email: validateEmail,
    password: () => {
      if (!context.values.password.length) {
        return context.content.get('ERROR_AUTH_PASSWORD_REQUIRED');
      }
    },
  };
});

export type PasswordResetModel = {
  email: string;
  password: string;
  confirmPassword: string;
};
export const createPasswordResetValidationFunction = convertValidationRules<
  PasswordResetModel,
  AdditionalContext
>(context => {
  const { values, content } = context;

  return {
    email: validateEmail,
    password: () => {
      if (!values.password.length) {
        return content.get('ERROR_AUTH_PASSWORD_REQUIRED');
      }
      console.log(isValidPassword(values.password));
      console.log(content.get('ERROR_AUTH_INVALID_PASSWORD_RULES'));

      /* if (!isValidPassword(values.password)) {
      return content.get('ERROR_AUTH_INVALID_PASSWORD_RULES');
    } */
      return null;
    },
  };
});
