import React, { useEffect } from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
  WithStyles,
  useTheme,
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import useMediaQuery from '@material-ui/core/useMediaQuery';

interface IProps {
  content?: JSX.Element;
  statusAlert?: any;
  onOverlay?: any;
  show: boolean;
  entryName?: any;
}
const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),

      '& figure': {
        margin: 0,
      },
    },
    closeButton: {
      alignSelf: 'flex-end',
      color: theme.palette.grey[500],
    },
    paperWidthXl: {
      width: '100%',
      maxWidth: 1920,
    },
  });

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    overflow: 'auto',

    '& figure': {
      margin: 0,

      '& > div': {
        padding: 0,
      },
    },
  },
}))(MuiDialogContent);

const Overlay = (props: IProps) => {
  const { content, statusAlert, show, entryName } = props;
  const [open, setOpen] = React.useState(show);
  useEffect(() => {
    setOpen(show);
  }, [show]);
  const handleClose = () => {
    setOpen(false);
    props.onOverlay(false);
  };

  const theme = useTheme();
  const classes = makeStyles(styles)();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      maxWidth="xl"
      onClose={handleClose}
      aria-label={entryName}
      open={open}
      fullScreen={fullScreen}
      classes={{ paperWidthXl: classes.paperWidthXl }}
      disableScrollLock
    >
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {statusAlert}
        {content}
      </DialogContent>
    </Dialog>
  );
};

export default Overlay;
