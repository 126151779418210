import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../../../themes/main-colors';

interface StyleProps {
  isSingleColumn: boolean;
  bgColor: string;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    // Program Card
    root: (props: StyleProps) => ({
      width: '100%',
      display: 'flex',
      boxSizing: 'border-box',
      contain: 'content',
      marginTop: theme.spacing(3),
      position: 'relative',
      flexDirection: 'column',
      backgroundColor: props.bgColor,
    }),
    cardContainer: (props: StyleProps) => ({
      flexDirection: props.isSingleColumn ? 'row' : 'column',
      padding: theme.spacing(3, 2.5, 3.75, 2.5),
      gap: '24px',
      '& p': {
        margin: '0 !important',
      },
      '& .sub-header': {
        fontWeight: '700 !important',
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    }),
    // Card Content
    content: {
      display: 'flex',
      width: '100%',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'start',
      gap: '24px',
    },

    // Badge
    badgeContainer: {
      display: 'flex',
      height: theme.spacing(5.5),
      '&.type-MostPopular': {
        backgroundColor: colors.emerald,
      },

      '&.type-New': {
        backgroundColor: colors.coconutCream,
      },

      '&.type-SoldOut, &.type-Full': {
        backgroundColor: colors.grey2,
      },
    },
    badge: {
      width: theme.spacing(17),
      height: 'auto',
      top: 0,
      left: 0,
      textAlign: 'center',
      alignContent: 'center',
      textTransform: 'uppercase',

      '&.type-MostPopular': {
        color: colors.white,
        background: colors.shoreGreen,
      },

      '&.type-New': {
        color: colors.noirBlur,
        background: colors.electricYellow,
      },

      '&.type-SoldOut, &.type-Full': {
        color: colors.white,
        background: colors.grey3,
      },
    },

    image: {
      width: theme.spacing(11.25),
      height: theme.spacing(11.25),
      overflow: 'hidden',

      '& img': {
        display: 'block',
        width: '100%',
        height: '100%',
      },
    },

    // Main CTA
    cta: {
      alignSelf: 'stretch',
      '& .MuiButton-label': {
        textAlign: 'center',
      },
      '.col-2 &, .col-3 &': {
        [theme.breakpoints.up('sm')]: {
          marginTop: 'auto',
          alignSelf: 'start',
        },
      },

      '.col-1 &': {
        [theme.breakpoints.up('sm')]: {
          marginTop: 0,
          alignSelf: 'center',

          '&.with-Content': {
            alignSelf: 'start',
          },
        },
      },
    },

    // Supporting CTA
    support: {
      alignSelf: 'stretch',

      [theme.breakpoints.up('sm')]: {
        alignSelf: 'start',
      },
    },
  });
});

export default useStyles;
