import { useCallback, useRef, useState } from 'react';
import pickBy from 'lodash/pickBy';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import includes from 'lodash/includes';
import zipObject from 'lodash/zipObject';
import objValues from 'lodash/values';
import keys from 'lodash/keys';
import {
  InputChangeEvent,
  ChangeEvent,
  BaseFormModel,
  UseFormStateArgs,
  FormState,
  InternalFormState,
  FieldErrors,
  ValidationPhase,
  ValidationRules,
  ValidateFunction,
  FieldError,
} from './useFormState.types';
import uniq from 'lodash/uniq';

function isEvent(e: any): e is InputChangeEvent {
  if (e && e.target) {
    return true;
  }
  return false;
}

function getInputValue(e: InputChangeEvent): string | boolean {
  if (e.target.type === 'checkbox') {
    return (e.target as HTMLInputElement).checked;
  }
  return e.target.value;
}

type CancelFunction = () => void;

function getEventValue<V>(e: ChangeEvent<V>): V {
  if (isEvent(e)) {
    return (getInputValue(e) as any) as V;
  }
  return e && typeof e === 'object' && (e as any).value !== undefined
    ? (e as { value: V }).value
    : (e as V);
}

function useRerender() {
  const setter = useState<{}>()[1];
  return useCallback(() => setter({}), [setter]);
}

export default function useFormState<
  Model extends BaseFormModel = BaseFormModel,
  ValidationContext = {}
>(
  defaultValues: Model,
  {
    validate = () => ({}),
    validationContext: passedContext,
    connectedFields = {},
  }: UseFormStateArgs<Model, ValidationContext> = {},
): FormState<Model> {
  const validationContext = useRef(passedContext);
  validationContext.current = passedContext;
  const rerender = useRerender();
  const cancelValidation = useRef<CancelFunction | null>(null);

  const state = useRef<InternalFormState<Model>>({
    values: defaultValues,
    errors: {},
  });

  function updateState(newState: Partial<FormState<Model>>): void {
    state.current = {
      ...state.current,
      ...newState,
    };
    rerender();
  }

  function getAllFields<K extends keyof Model>(field: K): Array<keyof Model> {
    if (connectedFields) {
      const connected = connectedFields[field];
      if (connected !== undefined) {
        return uniq([field, ...(connected as Array<keyof Model>)]);
      }
    }
    return [field];
  }

  async function validateValues(
    values: Model,
    phase: ValidationPhase,
    fields?: Array<keyof Model>,
  ): Promise<FieldErrors<Model>> {
    if (cancelValidation.current !== null) {
      cancelValidation.current();
    }
    let cancelled = false;
    cancelValidation.current = () => {
      cancelled = true;
    };
    // Clear the errors that will be updated so that out of date errors do not show
    updateState({
      errors:
        fields === undefined
          ? {}
          : mapValues(state.current.errors, (value, key) =>
              includes(fields, key) ? undefined : value,
            ),
    });
    const fullErrors = await Promise.resolve(
      validate(values, {
        ...validationContext.current!,
        fields,
        phase,
        values,
      }),
    );
    const fieldErrors =
      fields !== undefined ? pick(fullErrors, fields) : fullErrors;

    const remainingErrors =
      fields !== undefined ? omit(state.current.errors, fields) : {};

    // pickBy is used Remove empty and undefined errors
    const errors = pickBy(
      { ...remainingErrors, ...fieldErrors },
      Boolean,
    ) as FieldErrors<Model>;

    if (!cancelled) {
      updateState({ errors });
    }
    cancelValidation.current = null;
    return errors;
  }

  async function setValue<V>(name: keyof Model, value: V) {
    const newValues = { ...state.current.values, [name]: value };
    updateState({ values: newValues });

    const erroredFields = getAllFields(name).filter(
      field => state.current.errors[field] !== undefined,
    );

    // Only check for errors on change when the field is currently in error
    if (erroredFields.length > 0) {
      await validateValues(newValues, 'change', erroredFields);
    }
  }

  async function showError(name: keyof Model) {
    const nonErroredFields = getAllFields(name).filter(
      field =>
        state.current.errors[field] === undefined &&
        (name === field || Boolean(state.current.values[field])),
    );
    // No need to add an error if its already there
    if (nonErroredFields.length > 0) {
      await validateValues(state.current.values, 'blur', nonErroredFields);
    }
  }

  const props = {
    async onChange(e: InputChangeEvent) {
      await setValue(e.target.name, getInputValue(e));
    },
    async onBlur(e: InputChangeEvent) {
      await showError(e.target.name);
    },
  };

  function getProps(name: keyof Model) {
    return {
      async onChange(e: ChangeEvent<Model[keyof Model]>) {
        await setValue(name, getEventValue(e));
      },
      async onBlur() {
        await showError(name);
      },
      value: state.current.values[name],
      error: state.current.errors[name] !== undefined,
      helperText: state.current.errors[name],
    };
  }

  function getCheckProps(name: keyof Model) {
    return {
      async onChange(e: ChangeEvent<boolean>) {
        await setValue(name, getEventValue(e));
      },
      checked: state.current.values[name] as boolean,
    };
  }

  function submit(
    handler: (model: Model) => void,
    errHandler?: (errors: FieldErrors<Model>) => void,
  ) {
    return async (event?: any) => {
      if (event && event.preventDefault) {
        event.preventDefault();
      }
      const values = state.current.values;

      const errors = await validateValues(values, 'submit');
      if (!isEmpty(errors)) {
        // invalid case
        if (errHandler !== undefined) {
          return errHandler(errors);
        }
      } else {
        return handler(values);
      }
    };
  }

  function setError(name: keyof Model, error: FieldError) {
    updateState({
      errors: {
        ...state.current.errors,
        [name]: error,
      },
    });
  }

  function reset(values?: Model) {
    if (cancelValidation.current !== null) {
      cancelValidation.current();
    }
    updateState({
      errors: {},
      values: values !== undefined ? values : defaultValues,
    });
  }

  return {
    values: state.current.values,
    setValue,
    errors: state.current.errors,
    setError,
    onChange: props.onChange,
    onBlur: props.onBlur,
    props: getProps,
    checkProps: getCheckProps,
    submit,
    reset,
  };
}

// Converts an object where each value is a promise to a promise where each value is
// the resolved value
async function allValues<
  V extends Record<string, Promise<any>>,
  R extends Record<string, any>
>(obj: V): Promise<R> {
  return zipObject(keys(obj), await Promise.all(objValues(obj))) as R;
}

/**
 * Creates a validate function that selectively runs validation rules based on phase
 * @param {ValidationRules} rules - a rules function that takes in the context
 *                (BaseValidationContext & AdditionalContext) and returns an
 *                object of validator functions where keys represent field names.
 * @returns {ValidationFunction} a validation function that can be passed to `validate`
 * in `useFormState`.
 */

export function createValidateFunction<Model, ValidationContext = {}>(
  rules: ValidationRules<Model, ValidationContext>,
): ValidateFunction<Model, ValidationContext> {
  return (values, context) => {
    const phaseRules = rules(context);
    const chosenRules =
      context.fields === undefined
        ? phaseRules
        : pick(phaseRules, context.fields);

    return allValues(
      mapValues(chosenRules, (rule, key) =>
        Promise.resolve(
          rule !== undefined && rule !== null
            ? rule(values[key as keyof Model], context)
            : null,
        ),
      ),
    );
  };
}
// Converts old style validation rules toa ValidationFunction.
// See examples in `src/components/residential-form/ResidentialForm.rules.ts`
//
// Will not validate fields that are not in `context.fields`.
// Alias createValidateFunction for backwards compatibility
export const convertValidationRules = createValidateFunction;
