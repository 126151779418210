import { QueryHookOptions } from '@apollo/react-hooks';
import { OperationVariables } from 'apollo-client';
import { DocumentNode } from 'graphql';
import usePgeQuery from './usePgeQuery';
import useAuth from './useAuth';

/**
 * Used instead of `useQuery` to make requests that require authentication.
 *
 * Handles waiting until the token has finished being validated/refreshed before
 * making the request.
 */
export default function useAuthQuery<
  TData = any,
  TVariables = OperationVariables
>(query: DocumentNode, options: QueryHookOptions<TData, TVariables> = {}) {
  const { isAuthenticated } = useAuth();
  return usePgeQuery<TData, TVariables>(query, {
    ...options,
    skip: !isAuthenticated || options.skip,
  });
}
