import { createElement, Fragment, ExoticComponent } from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import block from './block';
import inline from './inline';
import { RichTextProps as Props, Wrap } from './types.d';
import { Document } from '@contentful/rich-text-types';

type Element = Exclude<Wrap, 'fragment' | 'none'> | ExoticComponent;

function RichText({ children, wrap, override, ...props }: Props): ElementNode {
  // Default props
  const _wrap = wrap || 'fragment';
  const _override = override || {};

  if (!children) {
    return null;
  }

  // Vars
  const baseWrap = _wrap === 'fragment' && !!props.className ? 'div' : _wrap;
  const element: Element =
    baseWrap === 'fragment' || baseWrap === 'none' ? Fragment : baseWrap;
  const options: Options = {
    renderNode: {
      ...block(_wrap !== 'none'),
      ...inline(),
    },
    ..._override,
  };

  // Differentiate between gatsby-source-contentful content retrieved for static page generation vs. content
  // retrieved at runtime using the Contentful JavaScript client. Ex: BroadcastCommunication.tsx
  const isGatsbyStaticBuild = children?.hasOwnProperty('raw');
  const renderedRichText = isGatsbyStaticBuild
    ? renderRichText(children, options)
    : documentToReactComponents(children as Document, options);

  return createElement(element, { ...props }, renderedRichText);
}

export default RichText;
