export function getLayout(inputArray: any, chunk_size: number = 2) {
  const result = inputArray.reduce(
    (resultArray: any, item: any, index: any) => {
      const chunkIndex = Math.floor(index / chunk_size);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    },
    [],
  );

  return result;
}
