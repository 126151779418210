import React, { FC, HTMLAttributes, ReactNode } from 'react';
import { Link } from 'gatsby';
import { usePathContext } from '../../providers/PathProvider';

interface BaseProps extends HTMLAttributes<HTMLAnchorElement> {
  slug?: string;
  linkText?: ReactNode;
}

type Props = Exclude<BaseProps, 'href' | 'target' | 'rel'>;

const SlugLink: FC<Props> = ({ slug, linkText = '', ...attrs }) => {
  if (!slug) {
    return <>{linkText}</>;
  }

  const { resolve } = usePathContext();
  const extLink = /^(\/\/|mailto:|tel:)/.test(slug);
  const extPage = /^https?:/.test(slug);
  const reactNode = linkText || slug;

  return extLink || extPage ? (
    <a
      href={slug}
      target={extPage ? '_blank' : undefined}
      rel={extPage ? 'noreferrer noopener' : undefined}
      {...attrs}
    >
      {reactNode}
    </a>
  ) : (
    <Link to={resolve(slug)} {...attrs}>
      {reactNode}
    </Link>
  );
};

export default SlugLink;
