import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import colors from '../../themes/main-colors';
import { navigate } from '@reach/router';

import Overlay from '../pge-overlay';
import {
  getStatusAlertComponent,
  getBodyComponent,
} from '../../util/contentful-render-utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    primary: {
      color: colors.white,
      backgroundColor: colors.sparkBlue,
      borderRadius: `${theme.spacing(5)}px`,
      fontSize: theme.spacing(2.3325),
      padding: theme.spacing(1.625, 4),
      lineHeight: theme.typography.pxToRem(18),
      '&:hover': {
        color: colors.white,
        backgroundColor: colors.noirBlurLight,
        '& .MuiButton-startIcon': {
          color: colors.lightGray2,
        },
      },
      '&:disabled': {
        color: colors.cloudGrey2,
        backgroundColor: colors.grey3,
        '& .MuiButton-startIcon': {
          color: colors.lightGray2,
        },
      },
      [theme.breakpoints.down('sm')]: {
        borderRadius: `${theme.spacing(5)}px`,
        fontSize: theme.spacing(2.25),
        padding: theme.spacing(1.625, 4),
        width: '100%',
      },
    },

    secondary: {
      color: colors.sparkBlue,
      backgroundColor: colors.white,
      borderRadius: `${theme.spacing(5)}px`,
      fontSize: theme.spacing(2.3325),
      border: `2px solid ${colors.sparkBlue}`,
      padding: theme.spacing(1.625, 4),
      lineHeight: theme.typography.pxToRem(18),
      '&:hover': {
        color: colors.noirBlurLight,
        borderColor: colors.noirBlurLight,
        backgroundColor: colors.white,
      },
      '&:disabled': {
        color: colors.grey3,
        backgroundColor: colors.white,
        borderColor: colors.grey3,
        '& .MuiButton-startIcon': {
          color: colors.lightGray2,
        },
      },
      [theme.breakpoints.down('sm')]: {
        borderRadius: `${theme.spacing(5)}px`,
        fontSize: theme.spacing(2.25),
        padding: theme.spacing(1.625, 4),
        width: '100%',
      },
    },

    emphasis: {
      color: colors.navBarBackground,
      backgroundColor: colors.orange,
      borderRadius: `${theme.spacing(5)}px`,
      fontSize: theme.spacing(2.3325),
      fontWeight: 'bold',
      padding: theme.spacing(1.625, 4),
      lineHeight: theme.typography.pxToRem(18),
      '&:hover': {
        color: colors.navBarBackground,
        backgroundColor: colors.crail,
      },
      '&:disabled': {
        color: colors.cloudGrey2,
        backgroundColor: colors.grey3,
        '& .MuiButton-startIcon': {
          color: colors.lightGray2,
        },
      },
      [theme.breakpoints.down('sm')]: {
        borderRadius: `${theme.spacing(5)}px`,
        fontSize: theme.spacing(2.25),
        padding: theme.spacing(1.625, 4),
        width: '100%',
      },
    },

    backCancel: {
      color: colors.white,
      backgroundColor: colors.darkGray2,
      borderRadius: `${theme.spacing(5)}px`,
      fontSize: theme.spacing(2.3325),
      padding: theme.spacing(1.625, 4),
      lineHeight: theme.typography.pxToRem(18),
      '&:hover': {
        backgroundColor: colors.darkGray2,
      },
      '&:disabled': {
        color: colors.lightGray2,
        backgroundColor: colors.silverSand,
        '& .MuiButton-startIcon': {
          color: colors.lightGray2,
        },
      },
      [theme.breakpoints.down('sm')]: {
        borderRadius: `${theme.spacing(5)}px`,
        fontSize: theme.spacing(2.25),
        padding: theme.spacing(1.625, 4),
        width: '100%',
      },
    },

    Outline: {
      color: colors.noirBlur,
      backgroundColor: colors.navBarBackground,
      borderRadius: `${theme.spacing(5)}px`,
      fontSize: theme.spacing(2.3325),
      border: `2px solid ${colors.noirBlur}`,
      padding: theme.spacing(1.625, 4),
      lineHeight: theme.typography.pxToRem(18),
      '&:hover, &:focus ': {
        border: `2px solid ${colors.noirBlur}`,
        backgroundColor: colors.linenWhite,
        color: colors.noirBlur,
      },
      '&:disabled': {
        color: colors.cornflower,
        backgroundColor: colors.modalBackgroundColor,
        '& .MuiButton-startIcon': {
          color: colors.cornflower,
        },
      },
      [theme.breakpoints.down('sm')]: {
        borderRadius: `${theme.spacing(5)}px`,
        fontSize: theme.spacing(2.25),
        width: '100%',
      },
    },

    btnIconWhite: {
      '& .MuiButton-startIcon': {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0.625),
        color: colors.white,
      },
    },

    btnIconOther: {
      '& .MuiButton-startIcon': {
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(0.625),
        color: colors.sparkBlue,
        [theme.breakpoints.down('sm')]: {
          color: colors.white,
        },
      },
    },
    mobileDesign: {
      '& > a.MuiButtonBase-root': {
        cursor: 'pointer',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
      },
    },
  }),
);

export interface IPGEButtonProps {
  buttonMode: string;
  text?: string;
  icon?: JSX.Element | null;
  onClick?: any;
  buttonLink?: any;
  target?: string | null;
  disableRippleEffect?: boolean;
}

const getButtonModeClass = (buttonMode: string) => {
  let rootClass = '';
  const classes = useStyles();
  switch (buttonMode) {
    case 'Primary':
      rootClass = classes.primary;
      break;
    case 'Secondary':
      rootClass = classes.secondary;
      break;
    case 'Emphasis':
      rootClass = classes.emphasis;
      break;
    case 'Back/Cancel':
      rootClass = classes.backCancel;
      break;
    case 'Outline':
      rootClass = classes.Outline;
      break;
    default:
      rootClass = '';
      break;
  }

  if (
    buttonMode === 'Primary' ||
    buttonMode === 'Emphasis' ||
    buttonMode === 'Outline' ||
    buttonMode === 'Back/Cancel'
  ) {
    rootClass = `${rootClass} ${classes.btnIconWhite}`;
  }
  if (buttonMode === 'Secondary') {
    rootClass = `${rootClass} ${classes.btnIconOther}`;
  }

  return {
    rootClass: rootClass,
  };
};

export default (props: IPGEButtonProps) => {
  const classes = useStyles();
  const {
    buttonMode = '',
    text,
    icon,
    onClick,
    buttonLink,
    target,
    disableRippleEffect,
  } = {
    ...props,
  };
  let component;
  const { rootClass } = getButtonModeClass(buttonMode);
  const [open, setOpen] = React.useState(false);

  if (buttonLink) {
    const { content, entryName, statusAlert } = buttonLink;

    const setOverlay = (status: any): any => {
      setOpen(status);
    };
    const statusComponent: any = statusAlert
      ? getStatusAlertComponent(statusAlert)
      : null;
    const contentBody = content ? getBodyComponent(content) : null;
    component = (
      <Overlay
        onOverlay={(status: any) => setOverlay(status)}
        content={contentBody}
        show={open}
        entryName={entryName}
        statusAlert={statusComponent}
      />
    );
  }

  return (
    <div className={text ? `button ${classes.mobileDesign}` : ''}>
      {buttonLink
        ? text && (
            <>
              <Button
                size={'large'}
                className={rootClass}
                startIcon={icon ? icon : null}
                onClick={(e: any) => setOpen(true)}
                disableRipple={disableRippleEffect ? true : false}
              >
                {text}
              </Button>
              {component}
            </>
          )
        : text &&
          (target ? (
            <Button
              size={'large'}
              className={rootClass}
              startIcon={icon ? icon : null}
              href={onClick}
              target={target}
              rel={target === '_blank' ? 'noreferrer noopener' : undefined}
              disableRipple={disableRippleEffect ? true : false}
            >
              {text}
            </Button>
          ) : (
            <Button
              size={'large'}
              className={rootClass}
              startIcon={icon ? icon : null}
              href={onClick}
              onClick={async (e: any) => {
                e.preventDefault();
                await navigate(onClick);
              }}
              disableRipple={disableRippleEffect ? true : false}
            >
              {text}
            </Button>
          ))}
    </div>
  );
};
