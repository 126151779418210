import { createStyles, makeStyles } from '@material-ui/core/styles';
import TextField, {
  FilledTextFieldProps,
  OutlinedTextFieldProps,
  StandardTextFieldProps,
} from '@material-ui/core/TextField';
import React, { useState } from 'react';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Visibility from '@material-ui/icons/Visibility';
import DelayedTextMask from '../delayed-text-mask';
import { InputAdornment, Typography } from '@material-ui/core';

export interface CommonInputProps {
  icon?: object;
  showTooltip?: boolean;
  adornment?: any;
}

export interface CustomStandardTextFieldProps
  extends CommonInputProps,
    StandardTextFieldProps {}

export interface CustomFilledTextFieldProps
  extends CommonInputProps,
    FilledTextFieldProps {}

export interface CustomOutlinedTextFieldProps
  extends CommonInputProps,
    OutlinedTextFieldProps {}

export type TextFieldProps =
  | CustomStandardTextFieldProps
  | CustomFilledTextFieldProps
  | CustomOutlinedTextFieldProps;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.67)',
      },
      background: '#fff',
    },
  }),
);

const TextFieldWrapped = React.forwardRef(
  (props: TextFieldProps | any, ref?: React.Ref<HTMLInputElement>) => {
    const classes = useStyles();
    const [textIsVisible, toggleTextVisibility] = useState<boolean>(false);
    const [shrink, setShrink] = useState<boolean>(false);
    const InputProps = {
      ...props.InputProps,
      ...(props.delayedTextMask && {
        inputComponent: DelayedTextMask,
        endAdornment: (
          <InputAdornment
            data-testid={
              props.endAdornmentDataTestId
                ? props.endAdornmentDataTestId
                : 'text-visibility'
            }
            onClick={() => toggleTextVisibility(!textIsVisible)}
            style={{ cursor: 'pointer' }}
            position={'end'}
          >
            <Typography variant="body2" color="primary">
              {textIsVisible ? (
                <Visibility data-testid="visibility-on" />
              ) : (
                <VisibilityOff data-testid="visibility-off" />
              )}
            </Typography>
          </InputAdornment>
        ),
      }),
    };
    const inputProps = {
      ...props.inputProps,
      ...(props.delayedTextMask && {
        show: textIsVisible,
        numbersOnly: props.numbersOnly,
      }),
    };
    const handleFocus = (e: any) => {
      setShrink(true);
      props?.onFocus && props?.onFocus(e);
    };
    const handleBlur = (e: any) => {
      props?.onBlur && props?.onBlur(e);
      if (e.target.value) {
        setShrink(true);
      } else {
        setShrink(false);
      }
    };

    return !Boolean(props.delayedTextMask) ? (
      <TextField
        onPaste={props.onPaste || props.onChange}
        style={props.style || { width: '100%' }}
        classes={{
          root: classes.root,
          ...props.classes,
        }}
        {...props}
        ref={ref}
        variant={props.variant ? props.variant : 'outlined'}
      />
    ) : (
      <TextField
        onPaste={props.onPaste || props.onChange}
        style={props.style || { width: '100%' }}
        classes={{
          root: classes.root,
          ...props.classes,
        }}
        {...props}
        ref={ref}
        variant={props.variant ? props.variant : 'outlined'}
        InputProps={InputProps}
        inputProps={inputProps}
        InputLabelProps={{ shrink: Boolean(props?.value) || shrink }}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
    );
  },
);

export default TextFieldWrapped;
