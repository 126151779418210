import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../../themes/main-colors';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

const getBackgroundColor = (color: string | undefined) => {
  switch (color) {
    case 'None':
      return colors.white;
    case 'Blue':
      return colors.riverBlue;
    case 'Grey':
      return colors.cloudGrey2;
    case 'Yellow':
      return colors.lightYellow;
    case 'Linen-White':
      return colors.linenWhite;
    default:
      return colors.white;
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: pullQuoteProps) => ({
      display: 'flex',
      backgroundColor: getBackgroundColor(props.bgColor),
      minHeight: 452,
      borderRadius: 5,
      opacity: theme.spacing(0.1),
      alignItems: 'center',
      padding: theme.spacing(5),
      '& .MuiContainer-root': {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
          display: 'block',
        },
      },
      [theme.breakpoints.down('sm')]: {
        display: 'block',
        width: '100%',
        padding: theme.spacing(2.5, 0),
        minHeight: 307,
      },
    }),
    statement: {
      fontSize: theme.spacing(3),
      lineHeight: theme.typography.pxToRem(28),
      marginBottom: theme.spacing(3.75),
      wordBreak: 'break-word',
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.spacing(2.25),
        marginBottom: theme.spacing(2.5),
        lineHeight: theme.typography.pxToRem(20),
      },
    },
    attribution: {
      fontWeight: 700,
    },
    imageContainer: {
      float: 'left',
      marginRight: theme.spacing(7.5),
      lineHeight: 0,
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginBottom: theme.spacing(2.5),
        marginRight: theme.spacing(0),
      },
    },
    detail: {
      display: 'flex',
      justifyContent: 'center',
      flexFlow: 'column',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        display: 'block',
      },
    },
    detailsWOImage: {
      width: '100%',
    },
    image: {
      border: `10px solid ${colors.white}`,
      maxWidth: 330,
      height: 300,
      objectFit: 'contain',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        objectFit: 'cover',
      },
    },
  }),
);
interface pullQuoteProps {
  image?: string;
  statement?: string;
  attribution?: string;
  bgColor?: string;
}
export default (props: pullQuoteProps) => {
  const classes = useStyles(props);
  const { statement = '', attribution = '', image = '', bgColor = '' } = {
    ...props,
  };
  return (
    <div className={classes.root}>
      <Container>
        {image && (
          <div className={classes.imageContainer}>
            <img
              className={classes.image}
              src={image}
              height="300"
              width="300"
            />
          </div>
        )}
        <div
          className={
            image
              ? classes.detail
              : `${classes.detail} ${classes.detailsWOImage}`
          }
        >
          {statement && (
            <Typography variant={'body1'} className={classes.statement}>
              {statement}
            </Typography>
          )}
          {attribution && (
            <Typography variant={'body2'} className={classes.attribution}>
              {attribution}
            </Typography>
          )}
        </div>
      </Container>
    </div>
  );
};
