import React from 'react';
import { useTranslation } from '../../hooks/useTranslation';
// eslint-disable-next-line import/no-unassigned-import
import './navigation.css';
import useAccountCustomer from '../../hooks/useAccountCustomer';
import colors from '../../themes/main-colors';
import { usePromotionContext } from '../../providers/PromotionProvider';
import LanguageToggle from '../language-toggle';
import { Button, Theme, createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttons: {
      color: colors.white,
      background: 'transparent',
      borderColor: colors.white,
      borderRadius: theme.spacing(3.75),
      minWidth: '100px',
      borderWidth: '2px',
      height: theme.spacing(4.5),
      fontWeight: 'bold',
      fontSize: '18px',
      '&:hover': {
        color: colors.buttonColor,
        backgroundColor: colors.navBarBackground,
        borderColor: colors.buttonColor,
      },
      '&:active': {
        color: colors.buttonColor,
        backgroundColor: colors.navBarBackground,
        borderColor: colors.buttonColor,
      },
    },
    controls: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

export const NavigationUser = ({ signOut }: any) => {
  const { t } = useTranslation();
  const { customer: user } = useAccountCustomer();
  const classes = useStyles();
  const { promotion } = usePromotionContext();

  return (
    <div className="user-logged-in">
      {user !== undefined && (
        <div className={`item${promotion ? ' promotion' : ''}`}>
          {user.email}
        </div>
      )}
      <div className={`item ${classes.controls}`}>
        <LanguageToggle />
        <Button
          data-testid={'sign-in-route-button'}
          variant={'outlined'}
          color={promotion ? 'secondary' : 'primary'}
          onClick={() => signOut()}
          style={{ whiteSpace: 'nowrap' }}
          className={classes.buttons}
        >
          {t('SIGN_OUT')}
        </Button>
      </div>
    </div>
  );
};
