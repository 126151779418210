import gql from 'graphql-tag';

export const getRooftopSolarQuery = gql`
  query getAccountDetails($params: AccountDetailParams!) {
    getAccountDetails(params: $params) {
      accountNumber
      premiseInfo {
        encryptedPremiseId
        addressLine1
        city
        rooftopSolar {
          applicationDetails {
            status
            submitDate
            statusDate
          }
        }
      }
    }
  }
`;
