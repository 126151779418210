// @ts-nocheck
import config from './config/config';

import { initializeApp, FirebaseOptions } from 'firebase/app';

import { getAuth, Auth } from 'firebase/auth';
import { getDatabase, Database } from 'firebase/database';
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
  AppCheck,
} from 'firebase/app-check';

const firebaseConfig: FirebaseOptions = {
  apiKey: config.firebase.key,
  databaseURL: config.firebase.databaseURL,
  projectId: config.firebase.projectId,
  appId: config.firebase.appId,
};

let app: FirebaseApp;
let auth: Auth;
let appCheck: AppCheck;
let database: Database;

if (typeof window !== 'undefined') {
  // Initialize Firebase
  app = app || initializeApp(firebaseConfig);

  //Initialize app check
  appCheck =
    appCheck ||
    initializeAppCheck(app, {
      provider: new ReCaptchaEnterpriseProvider(
        config.firebase.reCaptchaSiteKey,
      ),
      isTokenAutoRefreshEnabled: true, // Optional argument. If true, the SDK automatically refreshes App Check // tokens as needed.
    });

  // Initialize Firebase Authentication and get a reference to the service
  auth = auth || getAuth(app);

  database = database || getDatabase(app);
}

export { auth, database };

export default app;
