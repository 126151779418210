import gql from 'not-graphql-tag';

export const updateAccountDetails = gql`
  mutation updateAccountDetails($payload: AccountDetailsInput!) {
    updateAccountDetails(payload: $payload) {
      success
      message
    }
  }
`;

export const postAccountGroup = gql`
  mutation createAccountGroup($payload: CreateAccountGroupInput!) {
    createAccountGroup(payload: $payload) {
      success
      message
      code
      errorReason
      group {
        groupName
        groupId
        numberOfAccounts
        isDefault
        type
        isDefaultAccountExists
        isLoggedOnUserAutoGroup
        defaultAccount {
          accountNumber
          encryptedAccountNumber
          encryptedPersonId
        }
      }
    }
  }
`;

export const updateServiceAgreementNickname = gql`
  mutation updateServiceAgreementNickname(
    $payload: ServiceAgreementNicknameInput!
  ) {
    updateServiceAgreementNickname(payload: $payload) {
      success
      message
    }
  }
`;

export const getContactDetails = gql`
  query getAccountInfo {
    getAccountInfo {
      contactDetails {
        encryptedContactId
        contactType
        contactValue
        isPrimary
      }
    }
  }
`;
