import PageLayout from './src/layouts/Page';
import InstallerLayout from './src/layouts/Installer';
import './src/styles/fonts.css';

const shouldUpdateScroll = context => {
  if (
    context &&
    context.routerProps &&
    context.routerProps.location &&
    context.routerProps.location.hash
  ) {
    // preserve scroll position for anchor links
    return true;
  }
  window.scrollTo(0, 0);
  return false;
};

export const wrapPageElement = pageData => {
  if (pageData.props.location.pathname.startsWith('/installer/')) {
    return <InstallerLayout {...pageData} />;
  }
  return <PageLayout {...pageData} />;
};

export { default as wrapRootElement } from './src/layouts/Root';
export { shouldUpdateScroll };
