/**
 * TODO:
 * Refactor a better Overlay.
 */

import React, { FC, Dispatch, SetStateAction, ReactElement } from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
  useTheme,
  Dialog,
  DialogContent,
  IconButton,
  useMediaQuery,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { OverlayLinkFragment } from '../../__generated__/gatsby-types';
import RichText, { richTextStyles } from '../rich-text';
import { getStatusAlertComponent } from '../../util/contentful-render-utils';

interface Base extends OverlayLinkFragment {
  readonly open: boolean;
  readonly setOpen: Dispatch<SetStateAction<Base['open']>>;
  readonly html?: ReactElement;
}

export type Props = Partial<
  Omit<Base, '__typename' | 'slug' | 'contentful_id'>
>;

const contentStyles = (theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      overflow: 'auto',

      '& figure': {
        margin: 0,

        '& > div': {
          padding: 0,
        },
      },
      '& .RichText': {
        whiteSpace: 'pre-wrap',
      },
    },
  });

const dialogStyles = (theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    closeButton: {
      alignSelf: 'flex-end',
      color: theme.palette.grey[500],
    },
  });

const Content = withStyles(contentStyles)(DialogContent);

const Overlay: FC<Props> = ({
  content,
  statusAlert,
  open,
  entryName,
  html,
  setOpen,
}) => {
  if (!setOpen) {
    return null;
  }

  const theme = useTheme();
  const classes = makeStyles(dialogStyles)();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const richText = richTextStyles();

  const closeDialog = () => setOpen(false);

  return (
    <Dialog
      maxWidth="xl"
      onClose={closeDialog}
      aria-label={entryName || undefined}
      open={!!open}
      fullScreen={fullScreen}
      classes={{ paperWidthXl: classes.root }}
      disableScrollLock
    >
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={closeDialog}
      >
        <Close />
      </IconButton>
      <Content>
        {getStatusAlertComponent(statusAlert)}
        {html || (
          <RichText className={`RichText ${richText.styles}`}>
            {content}
          </RichText>
        )}
      </Content>
    </Dialog>
  );
};

export default Overlay;
