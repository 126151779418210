import { Tokens, AuthAdapter } from '../../../AuthenticationService/types';
import FirebaseAuthAdapter from '../FirebaseApiAdapter';
import ApigeeAuthAdapter from '../ApigeeAuthAdapter';
import { MultiFactorResolver } from 'firebase/auth';

class HybridAuthAdapter implements AuthAdapter {
  /**
   * Authenticates user with password and stores tokens in storage
   */
  constructor(
    private firebaseAdapter: FirebaseAuthAdapter,
    private apigeeAdapter: ApigeeAuthAdapter, // eslint-disable-next-line no-empty-function
  ) {}

  async signInWithPassword(email: string, password: string): Promise<Tokens> {
    const firebaseTokens = await this.firebaseAdapter.signInWithPassword(
      email,
      password,
    );
    const apigeeTokens = await this.apigeeAdapter.signInWithCustomToken(
      firebaseTokens.idToken?.token!,
    );

    return this.buildToken({ firebaseTokens, apigeeTokens });
  }

  async signInWithEmailLink(email: string): Promise<Tokens> {
    const firebaseTokens = await this.firebaseAdapter.signInWithEmailLink(
      email,
    );
    const apigeeTokens = await this.apigeeAdapter.signInWithCustomToken(
      firebaseTokens.idToken?.token!,
    );

    return this.buildToken({ firebaseTokens, apigeeTokens });
  }

  /**
   * Authenticates a user with a custom token and stores token in
   * storage
   */
  async signInWithCustomToken(token: string): Promise<Tokens> {
    const firebaseTokens = await this.firebaseAdapter.signInWithCustomToken(
      token,
    );
    const apigeeTokens = await this.apigeeAdapter.signInWithCustomToken(
      firebaseTokens.idToken?.token!,
    );

    return this.buildToken({ firebaseTokens, apigeeTokens });
  }

  async signInWithMultiFactorAuth(): Promise<Tokens> {
    //This is not supported in the current implementation
    throw new Error('Method Not Supported');
  }

  async refreshTokens(refreshToken: string) {
    const firebaseTokens = await this.firebaseAdapter.refreshTokens();
    const apigeeTokens = await this.apigeeAdapter.refreshTokens(refreshToken);
    return this.buildToken({ firebaseTokens, apigeeTokens });
  }

  /**
   * Builds a hybrid apigee/firebase token
   */
  buildToken(response: {
    firebaseTokens: Tokens;
    apigeeTokens: Tokens;
  }): Tokens {
    return {
      accessToken: response.apigeeTokens.accessToken,
      idToken: response.firebaseTokens.idToken,
      refreshToken: response.firebaseTokens.refreshToken,
      expiresAt: response.apigeeTokens.expiresAt,
    };
  }
}

export default HybridAuthAdapter;
