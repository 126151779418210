import { AuthAdapterInstallers } from '../../types';
import { Tokens } from '../../../AuthenticationService/types';
import { signInWithEmailLink, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../../../firebase';
import config from '../../../../config';
import { FirebaseAuthAdapterBase } from '../../../AuthenticationService/authAdapters/FirebaseApiAdapter/FirebaseAuthAdapterBase';

class FirebaseAuthAdapter extends FirebaseAuthAdapterBase
  implements AuthAdapterInstallers {
  async signInWithPassword(email: string, password: string): Promise<Tokens> {
    try {
      auth.tenantId = config.firebase.installerTenentId;
      const resp = await signInWithEmailAndPassword(auth, email, password);
      return await this.buildToken(resp.user);
    } catch (err) {
      throw this.getError(err);
    }
  }

  async signInWithEmailLink(email: string): Promise<Tokens> {
    try {
      auth.tenantId = config.firebase.installerTenentId;
      const resp = await signInWithEmailLink(auth, email, window.location.href);
      return await this.buildToken(resp.user);
    } catch (err) {
      throw this.getError(err);
    }
  }

  async signInWithCustomToken(token: string): Promise<Tokens> {
    //This is not supported in the current implementation
    throw new Error('Method Not Supported');
  }

  async refreshTokens(): Promise<Tokens> {
    try {
      auth.tenantId = config.firebase.installerTenentId;
      await auth.currentUser?.getIdToken(true); //Force Refresh
      return await this.buildToken(auth.currentUser!);
    } catch (err) {
      throw this.getError(err);
    }
  }
}

export default FirebaseAuthAdapter;
