import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import RelatedList from './RelatedList';
import colors from '../../themes/main-colors';

export interface RelatedProps {
  entryName?: string;
  relatedLinksHeader?: string;
  relatedLinksIntroCopy?: JSX.Element;
  headerIntroAlignment?: boolean;
  referenceLinks?: any;
  distributeAcrossColumns?: string;
  relatedLinksHeaderType?: string;
  backgroundColor?: string;
  legacySupplementalLinks?: JSX.Element;
}

const getBackgroundColor = (backgroundColor: string) => {
  switch (backgroundColor) {
    case 'None':
      return colors.white;
    case 'Blue':
      return colors.riverBlue;
    case 'Grey':
      return colors.cloudGrey2;
    case 'Yellow':
      return colors.lightYellow;
    case 'Linen-White':
      return colors.linenWhite;
    default:
      return colors.cloudGrey2;
  }
};

const useStyles = (backgroundColor: string) =>
  makeStyles((theme: Theme) =>
    createStyles({
      root: {
        backgroundColor: getBackgroundColor(backgroundColor),
        borderRadius: theme.typography.pxToRem(5),
        padding: theme.spacing(3.75, 5),
        '& .MuiTypography-h1': {
          marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(2.5),
        },
        '& ul > li > p.pge-contentful-body > a': {
          color: colors.sparkBlue,
        },
      },
      heading: {
        marginBottom: theme.spacing(1.25),
      },
      pbLg: {
        [theme.breakpoints.up('sm')]: {
          paddingBottom: theme.typography.pxToRem(40),
        },
        paddingBottom: theme.typography.pxToRem(30),
      },

      pbSm: {
        [theme.breakpoints.up('sm')]: {
          paddingBottom: theme.typography.pxToRem(25),
        },
        paddingBottom: theme.typography.pxToRem(16),
      },
      pbMd: {
        [theme.breakpoints.up('sm')]: {
          paddingBottom: theme.typography.pxToRem(30),
        },
        paddingBottom: theme.typography.pxToRem(20),
        borderRadius: theme.spacing(0.625),
      },
    }),
  );

const Related = (props: RelatedProps): JSX.Element => {
  const {
    relatedLinksHeader,
    relatedLinksIntroCopy,
    headerIntroAlignment,
    referenceLinks,
    distributeAcrossColumns,
    legacySupplementalLinks,
    backgroundColor,
    relatedLinksHeaderType,
  } = props;

  const classes = useStyles(backgroundColor || '')(props);

  let relatedHeadingElementType: 'h1' | 'h2' | 'h3' | 'h4' = 'h2';

  switch (relatedLinksHeaderType) {
    case 'h1':
      relatedHeadingElementType = 'h1';
      break;
    case 'h2':
      relatedHeadingElementType = 'h2';
      break;
    case 'h3':
      relatedHeadingElementType = 'h3';
      break;
    case 'h4':
      relatedHeadingElementType = 'h4';
      break;
    default:
      relatedHeadingElementType = 'h2';
  }

  let related;

  const columnCount = distributeAcrossColumns
    ? parseInt(distributeAcrossColumns)
    : 2;
  if (referenceLinks) {
    const columns: Array<JSX.Element> = [];
    for (const property of referenceLinks) {
      columns.push(<RelatedList list={property} />);
    }
    const grids = (
      <React.Fragment>
        {headerIntroAlignment === false && (
          <Grid item xs={12} md={6}>
            {relatedLinksHeader && (
              <Typography
                className={classes.heading}
                variant={relatedHeadingElementType}
              >
                {relatedLinksHeader}
              </Typography>
            )}
            {relatedLinksIntroCopy && <>{relatedLinksIntroCopy}</>}
          </Grid>
        )}
        <Grid item xs={12} md={headerIntroAlignment === false ? 6 : 12}>
          <Grid container>
            {columns.map((item, index) => (
              <Grid
                item
                xs={12}
                md={columnCount === 3 ? 4 : columnCount === 2 ? 6 : 12}
                key={index}
              >
                {item}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </React.Fragment>
    );
    related = (
      <>
        {headerIntroAlignment === true && (
          <React.Fragment>
            {relatedLinksHeader && (
              <Typography
                className={classes.heading}
                variant={relatedHeadingElementType}
              >
                {relatedLinksHeader}
              </Typography>
            )}
            {relatedLinksIntroCopy && <>{relatedLinksIntroCopy}</>}
          </React.Fragment>
        )}
        <Grid container>{grids}</Grid>
      </>
    );
  } else {
    related = (
      <>
        {relatedLinksHeader && (
          <Typography
            className={classes.heading}
            variant={relatedHeadingElementType}
          >
            {relatedLinksHeader}
          </Typography>
        )}
        {relatedLinksIntroCopy && <>{relatedLinksIntroCopy}</>}
        {legacySupplementalLinks}
      </>
    );
  }

  return <div className={classes.root}>{related}</div>;
};

export default Related;
