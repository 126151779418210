import { useEffect, useState } from 'react';
import { ApplicationVerifier, RecaptchaVerifier } from 'firebase/auth';
import { auth } from '../firebase';

export function useRecaptcha(componentId: string) {
  const [recaptcha, setRecaptcha] = useState<ApplicationVerifier>();

  useEffect(() => {
    //Suppressing recaptcha verifier on jest environments as this operation is supported only on browser
    if (
      process.env.NODE_ENV !== 'test' &&
      document.getElementById(componentId)
    ) {
      const recaptchaVerifier = new RecaptchaVerifier(
        componentId,
        {
          size: 'invisible',
          callback: () => true,
        },
        auth,
      );

      setRecaptcha(recaptchaVerifier);

      return () => {
        recaptchaVerifier.clear();
      };
    }
  }, [componentId]);

  return recaptcha;
}
