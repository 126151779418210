import { Tokens, AuthAdapter } from '../../types';

// eslint-disable-next-line import/no-unassigned-import
import 'url-search-params-polyfill';

type ApigeeAuthAdapterOptions = {
  baseUrl: string;
  clientId: string;
};

/**
 * Handles custom Apigee Token endpoints
 *
 */
class ApigeeAuthAdapter implements AuthAdapter {
  // eslint-disable-next-line no-empty-function
  constructor(private options: ApigeeAuthAdapterOptions) {}

  async signInWithPassword(): Promise<Tokens> {
    //This is not supported in the current implementation
    throw new Error('Method Not Supported');
  }

  async signInWithMultiFactorAuth(): Promise<Tokens> {
    //This is not supported in the current implementation
    throw new Error('Method Not Supported');
  }

  /**
   * generates a apigee token
   * @param {string} token a custom token to be exchanged for an apigee token
   * @returns {Tokens} a tokens obj containing apigee tokens
   */
  async signInWithCustomToken(token: string): Promise<Tokens> {
    const response = await this.handleResponse(
      await fetch(
        `${this.options.baseUrl}/pg-token-implicit/token?${new URLSearchParams({
          client_id: this.options.clientId,
          response_type: 'token',
          redirect_uri: '',
        })}`,
        {
          method: 'POST',
          headers: {
            idp_access_token: token,
          },
        },
      ),
    );
    const tokens = this.buildToken(response);
    return tokens;
  }

  async refreshTokens(refreshToken: string): Promise<Tokens> {
    const response = await this.handleResponse(
      await fetch(
        `${
          this.options.baseUrl
        }/pg-token-implicit/refresh?${new URLSearchParams({
          client_id: this.options.clientId,
          response_type: 'token',
          redirect_uri: '',
        })}`,
        {
          method: 'POST',
          headers: {
            idp_refresh_token: refreshToken,
          },
        },
      ),
    );
    const tokens = this.buildToken(response);
    return tokens;
  }

  protected buildToken(response: { [key: string]: any }): Tokens {
    return {
      idToken: null,
      refreshToken: null,
      accessToken: {
        token: response.access_token,
        payload: response,
      },
      expiresAt: parseInt(response.expires_at) * 1000, // convert from seconds to milliseconds
    };
  }
  protected async handleResponse(response: Response) {
    //TODO: Create Standard Errors
    if (!response.ok) {
      const error = new Error('Http error');
      try {
        const body = await response.json();
        if (body.fault.string) {
          error.message = body.fault.string;
        }
      } catch (e) {
        throw error;
      }
      throw error;
    }
    return response.json();
  }
}

export default ApigeeAuthAdapter;
