/**  These language slugs match the values coming from c2m as a string via getAccountInfo.prefLanguage as well as the keys in contentful */
export enum PreferredLanguages {
  ENGLISH = 'ENGLISH',
  ARABIC = 'ARABIC',
  BURMESE = 'BURMESE',
  CAMBODIAN = 'CAMBODIAN',
  CHINESE_CANTONESE = 'CHINESE_CANTONESE',
  CHINESE_MANDARIN = 'CHINESE_MANDARIN',
  FARSI = 'FARSI',
  JAPANESE = 'JAPANESE',
  KOREAN = 'KOREAN',
  LAOTIAN = 'LAOTIAN',
  ROHINGYA = 'ROHINGYA',
  ROMANIAN = 'ROMANIAN',
  RUSSIAN = 'RUSSIAN',
  SOMALI = 'SOMALI',
  SPANISH = 'SPANISH',
  SWAHILI = 'SWAHILI',
  VIETNAMESE = 'VIETNAMESE',
  OTHER = 'OTHER',
}

export enum C2MPreferredLanguages {
  Arabic = 'ARA',
  Burmese = 'BUR',
  Cambodian = 'CAM',
  ChineseMandarin = 'CMN',
  English = 'ENG',
  Farsi = 'FAS',
  Japanese = 'JPN',
  Korean = 'KOR',
  Laotian = 'LAO',
  Other = 'OTH',
  Rohingya = 'RHG',
  Romanian = 'RON',
  Russian = 'RUS',
  Somali = 'SOM',
  Spanish = 'SPN',
  Swahili = 'SWA',
  Vietnamese = 'VIE',
  ChineseCantonese = 'YUE',
}
