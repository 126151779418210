import React, { createContext, useEffect, useReducer } from 'react';
import { ContactInfo } from '../components/contact-info-form/ContactInfoForm.types';
import useAuth from '../hooks/useAuth';
import { getFromStorage } from '../util/storage-utils';

export interface AccountsContextState {
  hasInactiveAccounts: boolean;
  isReconnectInProgress: boolean;
  isAccountDisonnected: boolean;
  isAccountEligibleForReconnect: boolean;
}

export enum AccountsContextActionType {
  SET_HAS_INACTIVE_ACCOUNTS = 'SET_HAS_INACTIVE_ACCOUNTS',
  SET_ACCOUNT_DISCONNECTED_STATUS = 'SET_ACCOUNT_DISCONNECTED_STATUS',
}

export type AccountsContextAction =
  | {
      type: AccountsContextActionType.SET_HAS_INACTIVE_ACCOUNTS;
      hasInactiveAccounts: boolean;
    }
  | {
      type: AccountsContextActionType.SET_ACCOUNT_DISCONNECTED_STATUS;
      isReconnectInProgress: boolean;
      isAccountDisonnected: boolean;
      isAccountEligibleForReconnect: boolean;
    };

interface IAccountsContext {
  state: AccountsContextState;
  dispatch: (action: AccountsContextAction) => void;
}

const initialState: IAccountsContext = {
  state: {
    hasInactiveAccounts: Boolean(getFromStorage('hasInactiveAccounts')),
    isReconnectInProgress: false,
    isAccountDisonnected: false,
    isAccountEligibleForReconnect: false,
  },
  dispatch: () => {
    return;
  },
};

export const AccountsContext = createContext<IAccountsContext>(initialState);

const { Provider } = AccountsContext;

const reducer = (
  state: AccountsContextState,
  action: AccountsContextAction,
): AccountsContextState => {
  switch (action.type) {
    case AccountsContextActionType.SET_HAS_INACTIVE_ACCOUNTS:
      return {
        ...state,
        hasInactiveAccounts: action.hasInactiveAccounts,
      };
    case AccountsContextActionType.SET_ACCOUNT_DISCONNECTED_STATUS:
      return {
        ...state,
        isReconnectInProgress: action.isReconnectInProgress,
        isAccountDisonnected: action.isAccountDisonnected,
        isAccountEligibleForReconnect: action.isAccountEligibleForReconnect,
      };
    default:
      return state;
  }
};

export const AccountsContextProvider = (props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState.state);
  const { isAuthenticated } = useAuth();
  useEffect(() => {
    if (!isAuthenticated) {
      dispatch({
        type: AccountsContextActionType.SET_ACCOUNT_DISCONNECTED_STATUS,
        isReconnectInProgress: false,
        isAccountDisonnected: false,
        isAccountEligibleForReconnect: false,
      });
    }
  }, [isAuthenticated]);
  return <Provider value={{ state, dispatch }}>{props.children}</Provider>;
};
