import React, { FC, useMemo } from 'react';
import { getLayout } from './PromoGroup.utils';
import { Grid, Typography } from '@material-ui/core/';
import colors from '../../themes/main-colors';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import PromoItem from '../promo-item/';
import Container from '@material-ui/core/Container';
import { HeaderVariant, PromoItem as PromoItemType } from './types';

const getBackgroundColor = (color: any | null): any | null => {
  let bgColor = colors.white;
  switch (color) {
    case 'Blue':
      bgColor = colors.riverBlue;
      break;
    case 'Grey':
      bgColor = colors.cloudGrey2;
      break;
    case 'Linen-White':
      bgColor = colors.linenWhite;
      break;
    case 'Yellow':
      bgColor = colors.lightYellow;
      break;
    default:
      bgColor = colors.white;
      break;
  }
  return bgColor;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: Props) => ({
      backgroundColor: getBackgroundColor(props.bgColor),
      padding: props.leftNav ? theme.spacing(6.25) : theme.spacing(3.75),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3.75, 2.5),
      },
      '& .MuiGrid-grid-md-4,.MuiGrid-grid-md-6': {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
          flex: 1,
        },
      },
    }),
    container: {
      padding: theme.spacing(0),
    },
    header: {
      marginBottom: theme.spacing(4),
    },
    promoItemContianer: {
      gap: '20px',
    },
  }),
);

interface Props {
  promoItems: PromoItemType[];
  leftNav?: boolean;
  header?: string;
  headerType?: string;
  desc?: React.ReactElement;
  bgColor?: any;
}

const PromoGroup: FC<Props> = props => {
  const classes = useStyles(props);
  const perColumn = props.leftNav ? 2 : 3;
  const promoArrayList: any = useMemo(
    () => getLayout(props.promoItems, perColumn),
    [props.promoItems, perColumn],
  );

  const generateColumn = (item: any, index: number, columnWidth: number) => {
    let mdValue: any = 6;
    if (props.leftNav) {
      mdValue = columnWidth === 1 ? 12 : 6;
    } else {
      if (columnWidth === 2) {
        mdValue = 6;
      } else {
        mdValue = columnWidth === 1 ? 12 : 4;
      }
    }

    return (
      <Grid item xs={12} md={mdValue} key={index}>
        <PromoItem
          layoutType={mdValue === 12 ? 'horizontal' : 'vertical'}
          mediaURL={item?.mediaURL}
          mediaType={item?.mediaType}
          header={item?.header}
          headerType={item?.headerType}
          description={item?.description}
          link={item?.link}
          imageLink={item?.imageLink}
          imagePosition={item?.imagePosition}
          vimeoDownloadUrl={item?.vimeoDownloadUrl}
        />
      </Grid>
    );
  };

  const generateRow = (rowArray: any, length: number) => {
    const rowObject = rowArray.map((rowData: any, index: number) => {
      return generateColumn(rowData, index, length);
    });
    return rowObject;
  };

  const headerTag =
    !props?.headerType || !/^h[1-4]$/.test(props?.headerType)
      ? ('h2' as HeaderVariant)
      : (props?.headerType as HeaderVariant);

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        {props.header && (
          <Typography variant={headerTag} className={classes.header}>
            {props.header}
          </Typography>
        )}
        {props.desc && <div>{props.desc}</div>}
        <Grid
          container
          justify="flex-start"
          className={classes.promoItemContianer}
        >
          {promoArrayList.map((rowArray: any) => {
            return generateRow(rowArray, rowArray.length);
          })}
        </Grid>
      </Container>
    </div>
  );
};

export default PromoGroup;
