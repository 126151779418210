import React, { createContext, useEffect, useState } from 'react';
import { Theme, ThemeProvider as MuiThemeProvider } from '@material-ui/core';
import routes from '../routes';
import secureTheme from '../themes/theme';
import publicTheme from '../themes/theme-public';

const ThemeContext = createContext<Theme>(publicTheme);

// Oct 17, 2024: Brand Typography updates for the CMS generated public pages negatively impacted
// several of the IT owned application pages. Continue to use the pre-update theme for these pages.
const directories = [
  '/secure/',
  '/auth/',
  '/change-email/',
  `${routes.GUEST_PAY}/`,
  '/installer/',
  `${routes.OUTAGES}/`,
  `${routes.OUTAGES_MOBILE_APP}/`,
  `${routes.REGISTRATION}/`,
  `${routes.SSM_HOME}/`,
  `${routes.QUICK_PAY}/`,
  `${routes.QUICK_VIEWBILL}/`,
  '/start-stop-move/',
  '/supply-and-renewables/',
];

const pages = [
  routes.GUEST_PAY,
  '/change-email',
  routes.OUTAGES,
  routes.OUTAGES_MOBILE_APP,
  routes.REGISTRATION,
  routes.SSM_HOME,
  '/supply-and-renewables',
  '/add-person-verify-access-token',
  '/client-program-promo',
  routes.QUICK_PAY,
  routes.QUICK_VIEWBILL,
  '/storminfo',
  '/user-management',
];

export const ThemeProvider: React.FC = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState<Theme>(publicTheme);

  const pathName = !(typeof window === 'undefined')
    ? window.location.pathname
    : '';

  useEffect(() => {
    if (directories.some(directory => pathName.startsWith(directory))) {
      setCurrentTheme(secureTheme);
      return;
    }

    const pathNameWithoutTrailingSlash = pathName.replace(/\/$/, '');
    if (pages.some(page => pathNameWithoutTrailingSlash === page)) {
      setCurrentTheme(secureTheme);
      return;
    }

    setCurrentTheme(publicTheme);
  }, [pathName]);

  return (
    <ThemeContext.Provider value={currentTheme}>
      <MuiThemeProvider theme={currentTheme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
