type Properties = { [k: string]: number | string };
type Grid = (x: string, y: string) => Properties;
type Cell = (x: 'Row' | 'Column', y: number, z: number) => Properties;

export const grid: Grid = (rows, columns) => ({
  ' display': '-ms-grid',
  display: 'grid',
  msGridRows: rows,
  msGridColumns: columns,
  grid: `${rows}/${columns}`,
});

export const cell: Cell = (dir, start, end) => ({
  [`msGrid${dir}`]: start,
  [`msGrid${dir}Span`]: end - start,
  [`grid${dir}`]: `${start}/${end}`,
});
