import React, { FC, useEffect, useRef } from 'react';
import { Typography } from '@material-ui/core';
import RichText from '../../rich-text';
import { SlideProps as Props } from './types.d';
import useStyles from './GallerySlider.styles';
import { useSliderContext } from './GallerySlider';

const Caption: FC<Props> = ({
  imageHeader,
  imageCaption,
  downloadFile,
  num,
}) => {
  const { context } = useSliderContext();
  const classes = useStyles({});
  const refCaption = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { current } = refCaption;

    if (current) {
      if (current.dataset.id === `${context.position}`) {
        current.style.opacity = '1';
        current.style.transition = '.4s .6s';
        current.style.pointerEvents = 'auto';
      } else {
        current.style.opacity = '0';
        current.style.transition = '.1s 0s';
        current.style.pointerEvents = 'none';
      }
    }
  }, [context]);

  return (
    <div
      className={`GalleryCaption ${classes.caption}`}
      data-id={num}
      ref={refCaption}
    >
      {imageHeader && (
        <Typography variant="body1" style={{ fontWeight: 700 }}>
          {imageHeader}
        </Typography>
      )}
      <RichText>{imageCaption}</RichText>
      {downloadFile && (
        <a href={downloadFile.file?.url} download>
          Download
        </a>
      )}
    </div>
  );
};

export default Caption;
