import React, { useEffect, useState, useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import PGEButton from '../buttons';
import { makeStyles } from '@material-ui/core/styles';
import OtpInputBox from '../otp-input-box';
import colors from '../../themes/main-colors';
import { BaseFormModel } from '../../hooks/useFormState.types';
import { useTranslation } from '../../hooks/useTranslation';
import { NotificationsContext } from '../../providers/NotificationsProvider';

type Props = {
  form: BaseFormModel;
  handleResendOneTimeCode: () => void;
  isCompactMode?: boolean | undefined;
};

const useStyles = makeStyles(theme => ({
  submit: {
    backgroundColor: colors.orange,
    borderColor: colors.orange,
    '&:hover': {
      backgroundColor: colors.orange,
      borderColor: colors.orange,
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  retry: {
    marginRight: theme.spacing(1),
    border: 'none !important',
  },
  info: {
    marginTop: theme.spacing(1),
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
    },
  },
  signInBottom: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '.5em 0',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      margin: '1em 0',
    },
  },
}));

const MFAVerification = ({ form, handleResendOneTimeCode, isCompactMode }: Props) => {
  const classes = useStyles();
  const { t, richT } = useTranslation();
  const [retryThreshold, setRetryThreshold] = useState<number>(30);
  const notificationContext = useContext(NotificationsContext);

  const [otp, setOtp] = useState<string>('');
  let retryInterval: NodeJS.Timer;

  useEffect(() => {
    form.setError('mfaCode', null);
  }, [otp]);

  useEffect(() => {
    //Enable retry in 30 seconds
    retryInterval = setInterval(() => {
      if (retryThreshold > 0) {
        setRetryThreshold(threshold => {
          if (threshold === 1) {
            clearInterval(retryInterval);
          }
          return threshold - 1;
        });
      }
    }, 1000);
  }, []);

  const resendVerificationCode = async () => {
    await handleResendOneTimeCode();
    notificationContext.setState({
      isOpen: true,
      message: richT('TWO_STEP_AUTH_RESEND_CODE_SUCCESS', {
        CUSTOMER_PHONE_NUMBER: form.values.phoneHint,
      }),
      severity: 'success',
    });
    window.scrollTo(0, 0);
  };

  return (
    <Grid container spacing={1} className={classes.container}>
      <Grid item>
        <Typography className={classes.info}>
          {richT('TWO_STEP_AUTH_LOGIN_OTP_SENT', {
            CUSTOMER_PHONE_NUMBER: form.values.phoneHint.replace('+', ''),
          })}
        </Typography>
      </Grid>
      <Grid item>
        <OtpInputBox
          error={form.errors.mfaCode}
          value={otp}
          valueLength={6}
          onChange={e => {
            setOtp(e);
            form.setValue('mfaCode', e);
          }}
          isCompactMode={isCompactMode}
        />
      </Grid>
      <Grid item>
        <Grid container className={classes.signInBottom}>
          <Grid item>
            <PGEButton
              className={classes.retry}
              color={'secondary'}
              variant={'outlined'}
              disabled={retryThreshold !== 0}
              data-testid="resend-mfa-code"
              onClick={resendVerificationCode}
            >
              {t('TWO_STEP_AUTH_RESEND_CODE')}
            </PGEButton>
          </Grid>
          <Grid item className={classes.buttonContainer}>
            <PGEButton
              id="sign-in-submit-btn"
              type={'submit'}
              variant={'contained'}
              color={'primary'}
              className={classes.submit}
              disabled={otp.length !== 6}
            >
              {t('VERIFY')}
            </PGEButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MFAVerification;
