import {
  ApplicationSectionStatus,
  SectionEvAccountInfoData,
  SectionEvChargerSelectionData,
  SectionEvInstallData,
  SectionEvPanelInfoData,
  SectionEvRebatesData,
  TimeFrame,
  UpdateSectionEvAccountInfoInput,
  UpdateSectionEvChargerSelectionInput,
  UpdateSectionEvInstallInput,
  UpdateSectionEvPanelInfoInput,
  UpdateSectionEvRebatesInput,
} from '../../../__generated__/pgeplus-types';
import { getValue } from '../../../hooks/pge-plus/useRebatesEligibility';
import {
  ContactInfo,
  Installation,
  RebateEligibility,
  SelectCharger,
  UploadPhotos,
} from '../../pge-plus-form/evcharger.types';

export const contactInfoFormStateAdapter = () => ({
  toFormState: (sectionData: SectionEvAccountInfoData): ContactInfo => {
    return {
      email: sectionData.preferredContact?.emailAddress,
      firstName: sectionData.preferredContact?.firstName!,
      lastName: sectionData.preferredContact?.lastName!,
      middleName: sectionData.preferredContact?.middleName || '',
      phoneNumber: sectionData.preferredContact?.phoneNumber!,
      userRelationship: sectionData.preferredContact?.relationship!,
      homeType: sectionData.homeType!,
      isOwnHome: sectionData.isOwner === null ? undefined : sectionData.isOwner,
      isMainPerson:
        sectionData.useAccountContact === null
          ? undefined
          : sectionData.useAccountContact,
    };
  },
  fromFormState: (
    formData: ContactInfo,
    applictionId: string,
    sectionId: string,
  ): UpdateSectionEvAccountInfoInput => {
    return {
      id: sectionId,
      applicationId: applictionId,
      status: ApplicationSectionStatus.Completed,
      homeType: formData.homeType,
      isOwner: formData.isOwnHome,
      useAccountContact: formData.isMainPerson,
      preferredContact: {
        firstName: formData.firstName,
        middleName: formData.middleName,
        lastName: formData.lastName,
        emailAddress: formData.email,
        phoneNumber: formData.phoneNumber,
        relationship: formData.userRelationship,
      },
    };
  },
});

export const uploadPhotosFormStateAdapter = () => ({
  toFormState: (sectionData: SectionEvPanelInfoData): UploadPhotos => {
    return {
      panelImage: sectionData.panelImage!,
      amperageImage: sectionData.amperageImage!,
      panelLocationImage: sectionData.panelLocationImage!,

      extendedInstallDistance: sectionData.installDistance
        ?.extendedInstallDistance!,
      extendedInstallDistanceImage:
        sectionData.installDistance?.extendedInstallDistanceImage || '',

      mainBreakerAmperage: sectionData.breakerInfo?.mainBreakerAmperage!,
      doubleBreakerAvailable: sectionData.breakerInfo?.doubleBreakerAvailable!,

      chargerCost: sectionData.chargerCost!,
      receiptImage: sectionData.receiptImage!,
    };
  },
  fromFormState: (
    formData: UploadPhotos,
    applictionId: string,
    sectionId: string,
    saveAndComeBack: boolean = false,
  ): UpdateSectionEvPanelInfoInput => {
    return {
      id: sectionId,
      applicationId: applictionId,
      status: ApplicationSectionStatus.Completed,
      saveAndComeBack,
      breakerInfo: {
        mainBreakerAmperage: formData.mainBreakerAmperage || null,
        doubleBreakerAvailable: formData.doubleBreakerAvailable || null,
      },

      installDistance: {
        extendedInstallDistance: formData.extendedInstallDistance,
        extendedInstallDistanceImage: formData.extendedInstallDistanceImage,
      },

      amperageImage: formData.amperageImage,
      panelImage: formData.panelImage,
      panelLocationImage: formData.panelLocationImage,

      chargerCost: Number(formData.chargerCost),
      receiptImage: formData.receiptImage,
    };
  },
});

export const selectChargerFormStateAdapter = () => ({
  toFormState: (sectionData: SectionEvChargerSelectionData): SelectCharger => {
    return {
      chargerId: sectionData.productId!,
      suppliedByCustomer: sectionData.suppliedByCustomer,
    };
  },
  fromFormState: (
    formData: SelectCharger,
    applictionId: string,
    sectionId: string,
    saveAndComeBack: boolean = false,
  ): UpdateSectionEvChargerSelectionInput => {
    return {
      id: sectionId,
      applicationId: applictionId,
      status: ApplicationSectionStatus.Completed,
      productId: formData.chargerId,
      saveAndComeBack,
    };
  },
});

const getRebatesSectionOptionDataValue = (value: any) =>
  value === true ? 'Yes' : value === false ? 'No' : undefined;

export const evRebatesFormStateAdapter = () => ({
  toFormState: (sectionData: SectionEvRebatesData): RebateEligibility => {
    return {
      isNeedRebateInfo: getRebatesSectionOptionDataValue(
        sectionData?.iqdb?.checkEligibility,
      ),
      houseHoldSize: sectionData?.iqdb?.houseHoldSize
        ? `${sectionData?.iqdb?.houseHoldSize}`
        : undefined,
      annualHouseholdIncome: sectionData?.iqdb?.grossIncome
        ? `${sectionData?.iqdb?.grossIncome}`
        : undefined,

      isAnnnualIncomeChecked: sectionData?.iqdb?.grossIncomeConfirmed
        ? 'true'
        : undefined,
      isHaveWifi: getRebatesSectionOptionDataValue(
        sectionData?.smartCharging?.wifiAvailable,
      ),
      isOwnTesla: getRebatesSectionOptionDataValue(
        sectionData?.evPulse?.connectEVPulseApp,
      ),
      isCommitToExtendWifi: getRebatesSectionOptionDataValue(
        sectionData?.smartCharging?.activateChargerInApp,
      ),
      chargerForExistingVehicle:
        getRebatesSectionOptionDataValue(
          sectionData?.evPulse?.chargerForExistingVehicle,
        ) ||
        getRebatesSectionOptionDataValue(
          sectionData?.smartCharging?.chargerForExistingVehicle,
        ),

      continueWithoutRebate:
        getRebatesSectionOptionDataValue(
          sectionData?.evPulse?.continueWithoutRebate,
        ) ||
        getRebatesSectionOptionDataValue(
          sectionData?.smartCharging?.continueWithoutRebate,
        ),
      vin: sectionData?.evPulse?.vin || undefined,

      vinImage: sectionData?.evPulse?.vinImage || undefined,
    };
  },
  fromFormState: (
    formData: RebateEligibility,
    applictionId: string,
    sectionId: string,
  ): UpdateSectionEvRebatesInput => {
    return {
      id: sectionId,
      applicationId: applictionId,
      status: ApplicationSectionStatus.Completed,
      evPulse: {
        chargerForExistingVehicle: getValue(
          formData?.chargerForExistingVehicle,
        ),
        connectEVPulseApp: getValue(formData?.isOwnTesla),
        continueWithoutRebate: getValue(formData?.continueWithoutRebate),
        vin: formData?.vin,
        vinImage: formData?.vinImage,
      },
      iqdb: {
        checkEligibility: getValue(formData?.isNeedRebateInfo),
        grossIncome: formData?.annualHouseholdIncome
          ? Number(formData?.annualHouseholdIncome)
          : undefined,
        grossIncomeConfirmed: formData?.isAnnnualIncomeChecked === 'true',
        houseHoldSize: formData?.houseHoldSize
          ? Number(formData?.houseHoldSize)
          : undefined,
      },
      smartCharging: {
        chargerForExistingVehicle: getValue(
          formData?.chargerForExistingVehicle,
        ),
        continueWithoutRebate: getValue(formData?.continueWithoutRebate),
        wifiAvailable: getValue(formData?.isHaveWifi),
        activateChargerInApp: getValue(formData?.isCommitToExtendWifi),
      },
    };
  },
});

export const installationFormStateAdapter = () => ({
  toFormState: (sectionData: SectionEvInstallData): Installation => {
    return {
      urgency: sectionData.schedulePreference?.urgency!,
      dayOfWeek: sectionData.schedulePreference?.dayOfWeek!,
      // TODO this is deprecated will be remove
      timeFrame: sectionData.schedulePreference?.timeFrame!,
      preferredServiceTime: sectionData.schedulePreference
        ?.preferredServiceTime!,

      panelLocation: sectionData.panelLocation?.location!,
      comment: sectionData.panelLocation?.comment!,
      installLocation: sectionData.chargerInstallPreference?.installLocation!,

      installerRecommendPanelDistance: sectionData.chargerInstallPreference
        ?.installerRecommendPanelDistance!,
      distanceToPanel: sectionData.chargerInstallPreference?.distanceToPanel!,
      panelSideLocation: sectionData.chargerInstallPreference
        ?.panelSideLocation!,
    };
  },
  fromFormState: (
    formData: Installation,
    applictionId: string,
    sectionId: string,
    saveAndComeBack: boolean = false,
  ): UpdateSectionEvInstallInput => {
    return {
      id: sectionId,
      applicationId: applictionId,
      status: ApplicationSectionStatus.Completed,
      saveAndComeBack,

      schedulePreference: {
        urgency: formData.urgency,
        dayOfWeek: formData.dayOfWeek,
        // TODO this is deprecated will be remove
        timeFrame: [TimeFrame.Morning],
        preferredServiceTime: formData.preferredServiceTime,
      },
      panelLocation: {
        comment: formData.comment,
        location: formData.panelLocation,
      },
      chargerInstallPreference: {
        distanceToPanel: formData.distanceToPanel,
        installLocation: formData.installLocation,
        installerRecommendPanelDistance: !!formData.installerRecommendPanelDistance,
        panelSideLocation: formData.panelSideLocation,
      },
    };
  },
});
