import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../../../themes/main-colors';

const useStyles = (imageRatio: number, _border: string, buttonType: string) =>
  makeStyles((theme: Theme) => {
    const { paper } = theme.palette.background;

    return createStyles({
      // CTA Card
      root: {
        display: 'flex',
        boxSizing: 'border-box',
        contain: 'content',
        paddingTop: theme.spacing(4),
        marginTop: theme.spacing(2),
        overflow: 'hidden',
        flexDirection: 'column',
        flexWrap: 'wrap',
        borderRadius: '5px',

        '&.bkg-DarkBlue': {
          backgroundColor: colors.noirBlur,
        },

        '&.bkg-Yellow': {
          backgroundColor: colors.lightYellow,
        },

        [theme.breakpoints.up('sm')]: {
          flexDirection: 'row',
          alignItems: 'center',
        },

        '& .RichText': {
          width: '100%',
          padding: theme.spacing(2, 4, 4),
          order: 3,
          color: `${_border === 'DarkBlue' ? colors.white : colors.noirBlur}`,

          '& a, & button': {
            color: `${
              _border === 'DarkBlue' ? colors.white : colors.sparkBlue
            }`,
            fontWeight: 700,
            textDecoration: 'underline',
          },

          '& a span': {
            textDecoration: 'underline',
          },

          '& hr': {
            backgroundColor: paper,
          },
        },
      },

      heading: {
        width: '100%',
        padding: theme.spacing(0, 4),
        color: `${_border === 'DarkBlue' ? colors.white : colors.noirBlur}`,

        [theme.breakpoints.up('sm')]: {
          flex: 1,
        },
      },

      cta: {
        margin: theme.spacing(2, 'auto', 0),
        order: 1,

        [theme.breakpoints.up('sm')]: {
          alignSelf: 'start',
          margin: theme.spacing(0, 3, 0, 'auto'),
        },
        ...(buttonType === 'Outline' && {
          backgroundColor: 'unset',
          borderColor: `${
            _border === 'DarkBlue' ? colors.white : colors.noirBlur
          }`,
          color: `${_border === 'DarkBlue' ? colors.white : colors.noirBlur}`,
        }),
      },

      expand: () => ({
        '&.isExpanded': {
          paddingTop: `${imageRatio}%`,

          '&::after': {
            opacity: 0,
            transition: '.2s',
          },

          [theme.breakpoints.up('sm')]: {
            paddingTop: 0,
          },
        },
      }),

      image: {
        display: 'flex',
        width: '100%',
        height: 'auto',
        paddingTop: 400,
        position: 'relative',
        marginTop: theme.spacing(2.5),
        order: 2,
        overflow: 'hidden',
        justifyContent: 'center',
        fontSize: 0,
        transform: 'translateZ(0)',
        transition: 'padding .5s',

        [theme.breakpoints.up('sm')]: {
          marginTop: theme.spacing(4),
          paddingTop: 0,
        },

        '&::after': {
          display: 'block',
          width: '100%',
          height: '40%',
          position: 'absolute',
          bottom: 0,
          content: '""',
          zIndex: 1,
          background: 'linear-gradient(transparent, #fff) no-repeat',

          [theme.breakpoints.up('sm')]: {
            content: 'none',
          },
        },

        '& picture': {
          display: 'block',
          width: '100%',
        },

        '& img': {
          display: 'block',
          width: '100%',
          height: 'auto',
          position: 'absolute',
          top: 0,
          left: 0,

          [theme.breakpoints.up('sm')]: {
            position: 'relative',
          },
        },
      },

      showMore: {
        position: 'absolute',
        bottom: theme.spacing(2),
        zIndex: 2,

        '.isExpanded &': {
          opacity: 0,
          transition: 'opacity .2s',
        },

        [theme.breakpoints.up('sm')]: {
          'button&': { display: 'none' },
        },
      },
    });
  });

export default useStyles;
