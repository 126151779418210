import React, { useRef } from 'react';
import Map from '../embed-map/Map';
import PgeVideoPlayer from '../pge-video-player/PgeVideoPlayer';
import RichText from '../rich-text';
import useStyles from './MediaWrapper.styles';
import { MediaWrapperProps as Props } from './types.d';
import { useComponentSize } from '../../util/style-utils';
import Button from '@material-ui/core/Button';
import DownloadIcon from '../../images/iconActionDownload.svg';
import { useTranslation } from '../../hooks/useTranslation';
import { Grid, Typography } from '@material-ui/core';

function MediaWrapper({
  embedMediaHeader,
  embedMediaHeaderType,
  // embedMediaAltText,
  embedMediaCaption,
  url,
  googleMapUrl,
  esri,
  vimeoEmbedUrl,
  vimeoDownloadUrl,
}: Props): ElementNode {
  const classes = useStyles();
  const videoDivRef = useRef<HTMLDivElement>(null);
  const { width } = useComponentSize(videoDivRef);
  const { t } = useTranslation();

  let mediaHeaderType: 'h1' | 'h2' | 'h3' | 'h4' = 'h2';

  switch (embedMediaHeaderType) {
    case 'h1':
      mediaHeaderType = 'h1';
      break;
    case 'h2':
      mediaHeaderType = 'h2';
      break;
    case 'h3':
      mediaHeaderType = 'h3';
      break;
    case 'h4':
      mediaHeaderType = 'h4';
      break;
    default:
      mediaHeaderType = 'h2';
  }

  return (
    <figure className={classes.root} ref={videoDivRef}>
      {embedMediaHeader && (
        <Typography variant={mediaHeaderType} className={classes.header}>
          {embedMediaHeader}
        </Typography>
      )}
      {googleMapUrl && <Map src={googleMapUrl} />}
      {esri && <Map src={esri} />}
      {url && (
        <div>
          <PgeVideoPlayer
            video={{
              url: url,
              width: `${width}px`,
              height: `${(width / 16) * 9}px`,
            }}
          />
        </div>
      )}
      {vimeoEmbedUrl && (
        <PgeVideoPlayer
          video={{
            url: vimeoEmbedUrl,
            width: `${width}px`,
            height: `${(width / 16) * 9}px`,
          }}
        />
      )}
      <RichText wrap="figcaption">{embedMediaCaption}</RichText>
      {vimeoEmbedUrl && vimeoDownloadUrl && (
        <Grid item xs={12} className={classes.menuItem}>
          <Button
            component={'a'}
            color="primary"
            size="large"
            href={vimeoDownloadUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.button}
            endIcon={<img src={`${DownloadIcon}`} />}
          >
            {t('DOWNLOAD')}
          </Button>
        </Grid>
      )}
    </figure>
  );
}

export default MediaWrapper;
