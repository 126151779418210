/* tslint:disable:no-implicit-dependencies */
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Properties } from 'csstype';
import { Shadow } from '../../../constants';
import { WrapperImageProps } from './types.d';
import colors from '../../../themes/main-colors';

interface Props extends WrapperImageProps {
  readonly imageHeight?: number;
  readonly imageRatio?: number;
}
const img: Properties = {
  display: 'block',
  width: '100%',
  maxWidth: 'max-content',
  margin: 'auto',
  fontSize: 0,
  height: '100%',
};

const useStyles = makeStyles(
  ({ palette, breakpoints, spacing, typography }: Theme) => {
    // Colors
    const text = palette.text.primary;
    const lightGrey = palette.grey[100];
    const grey = palette.grey[300];
    const { paper } = palette.background;

    return createStyles({
      root: {
        marginTop: spacing(4),

        [breakpoints.up('sm')]: {
          marginTop: spacing(8),
        },

        '& img': { ...img },
      },

      heading: {
        margin: 0,
        color: text,
        fontSize: spacing(3),
        lineHeight: typography.pxToRem(28),
        fontWeight: 'bold',
        fontFamily: 'Forma-DJR-Display',
        letterSpacing: '0.720px',
        [breakpoints.up('sm')]: {
          gridColumn: 'span 1',
          alignSelf: 'end',
          fontSize: spacing(4.5),
          lineHeight: typography.pxToRem(40),

          '.align-Center &': {
            textAlign: 'center',
          },
        },
      },

      introCopy: {
        marginTop: spacing(3),

        [breakpoints.up('sm')]: {
          display: 'flex',
          flexFlow: 'column wrap',
          gridColumn: 'span 1',
        },
      },
      expand: ({ imageRatio = 300 }: Props) => ({
        '&.isExpanded': {
          paddingTop: `${imageRatio}%`,

          '&::after': {
            opacity: 0,
            transition: '.2s',
          },

          [breakpoints.up('sm')]: {
            paddingTop: 0,
          },
        },
      }),
      image: {
        display: 'flex',
        width: '100%',
        height: 'auto',
        paddingTop: 400,
        position: 'relative',
        marginTop: spacing(2.5),
        order: 2,
        overflow: 'hidden',
        justifyContent: 'center',
        backgroundColor: paper,
        fontSize: 0,
        transform: 'translateZ(0)',
        transition: 'padding .5s',

        [breakpoints.up('sm')]: {
          marginTop: spacing(4),
          paddingTop: 0,
        },

        '&::after': {
          display: 'block',
          width: '100%',
          height: '40%',
          position: 'absolute',
          bottom: 0,
          content: '""',
          zIndex: 1,
          background: 'linear-gradient(transparent, #fff) no-repeat',

          [breakpoints.up('sm')]: {
            content: 'none',
          },
        },

        '& picture': {
          display: 'block',
          width: '100%',
        },

        '& img': {
          display: 'block',
          width: '100%',
          height: 'auto',
          position: 'absolute',
          top: 0,
          left: 0,

          [breakpoints.up('sm')]: {
            position: 'relative',
          },
        },
      },
      showMore: {
        position: 'absolute',
        bottom: spacing(2),
        zIndex: 2,

        '.isExpanded &': {
          opacity: 0,
          transition: 'opacity .2s',
        },

        [breakpoints.up('sm')]: {
          'button&': { display: 'none' },
        },
      },
      imageContainer: {
        display: 'flex',
        marginTop: spacing(1.5),

        [breakpoints.up('sm')]: {
          '.align-Center &': {
            marginTop: spacing(2),
          },

          '.align-Left &, .align-Right &': {
            gridRow: '1 / 3',
            margin: 0,
          },

          '.align-Left &': {
            gridColumn: '1 / 2',
          },

          '.align-Right &': {
            gridColumn: '2 / 3',
          },
        },
      },

      overlayImage: {
        width: '100%',
        marginTop: spacing(1.5),

        '& img': {
          ...img,
          borderRadius: spacing(0.875),
          boxShadow: Shadow.ONE,
        },
      },

      caption: {
        color: text,
        fontSize: spacing(2),

        [breakpoints.up('sm')]: {
          display: 'flex',
          flexFlow: 'column wrap',
        },

        '& p': {
          marginTop: spacing(2),
        },

        '& img': {
          maxWidth: 600,
          margin: spacing(2, 'auto', 0),
        },
      },

      buttonContainer: {
        display: 'flex',
        padding: 0,
        margin: spacing(3, 0, 0),
        position: 'relative',
        justifyContent: 'center',
        height: 80,
        '&.expanded': {
          borderLeft: `2px solid ${colors.cloudGrey2}`,
          borderTop: `2px solid ${colors.cloudGrey2}`,
          borderRight: `2px solid ${colors.cloudGrey2}`,
        },
      },

      menuItem: {
        textAlign: 'center',
        alignContent: 'center',
        backgroundColor: colors.cloudGrey2,
        '&.expanded': {
          backgroundColor: 'white',
        },
      },

      button: {
        minWidth: '9ch',
        padding: spacing(1, 3),
        boxSizing: 'border-box',
        fontSize: spacing(2),
        borderRight: '1px solid currentColor',
        borderRadius: 0,
        '&:hover': {
          backgroundColor: colors.cloudGrey2,
        },

        '&:last-child': {
          borderRight: 'none',
        },

        '&.expanded': {
          fontWeight: 600,
          '&:hover': {
            backgroundColor: colors.white,
          },
        },

        '& .MuiButton-endIcon': {
          marginLeft: spacing(2.5),
        },

        [breakpoints.down('sm')]: {
          padding: spacing(1, 0),

          '& .MuiButton-label': {
            flexDirection: 'column',
          },

          '& .MuiButton-endIcon': {
            marginLeft: spacing(0),
          },
        },
      },

      expandContainer: {
        padding: spacing(0),
      },

      longDescriptionContainer: {
        background: colors.white,
        borderColor: colors.cloudGrey2,
        borderWidth: '0px 2px 2px 2px',
        borderRadius: spacing(0),
        borderStyle: 'solid',
        marginTop: spacing(-0.25),
        boxShadow: 'unset',
      },

      longDescription: {
        padding: spacing(2.5),
        textAlign: 'left',

        '& img': {
          marginTop: spacing(2),
        },
      },
    });
  },
);

export default useStyles;
