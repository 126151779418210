import React, { FunctionComponent, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import Container from '@material-ui/core/Container';
import colors from '../../themes/main-colors';
import { Check, KeyboardArrowRight } from '@material-ui/icons';
import {
  LanguageContext,
  SupportedLanguages,
} from '../../providers/LanguageProvider';
import { useTranslation } from '../../hooks/useTranslation';
import { List } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    linksList: {
      background: colors.riverBlue,
      width: '100%',
      padding: 0,
      margin: 0,
      flexGrow: 3,
    },
    listLevel1: {
      color: colors.noirBlur,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      paddingRight: theme.spacing(2),
      fontWeight: 'bold',
    },
    listItem: {
      padding: theme.spacing(2),
      borderBottom: 'none',
      fontSize: '16px',
    },
  }),
);

const MobileLanguageSelect: FunctionComponent<{}> = ({}) => {
  const classes = useStyles();
  const [sectionExpanded, toggleSection] = useState<boolean>(false);
  const { handleLanguageChange, language } = React.useContext(LanguageContext);
  const { t } = useTranslation();

  const onClickHeading = (): void => {
    toggleSection(state => !state);
  };

  return (
    <List component="ul" disablePadding>
      <Container className={classes.linksList}>
        <ListItem
          button
          onClick={onClickHeading}
          classes={{ root: classes.listItem }}
        >
          <span className={classes.listLevel1}>
            {t('LANGUAGE')}
            <KeyboardArrowRight />
          </span>
        </ListItem>
        <Collapse in={sectionExpanded}>
          <ListItem
            button
            onClick={() =>
              handleLanguageChange(SupportedLanguages.English, true, true)
            }
            classes={{ root: classes.listItem }}
          >
            <span className={classes.listLevel1}>
              {t('ENGLISH_LABEL')}{' '}
              {language === SupportedLanguages.English ? (
                <Check fontSize="small" />
              ) : null}
            </span>
          </ListItem>
          <ListItem
            button
            onClick={() =>
              handleLanguageChange(SupportedLanguages.Spanish, true, true)
            }
            classes={{ root: classes.listItem }}
          >
            <span className={classes.listLevel1}>
              {t('SPANISH_LABEL')}{' '}
              {language === SupportedLanguages.Spanish ? (
                <Check fontSize="small" />
              ) : null}
            </span>
          </ListItem>
        </Collapse>
      </Container>
    </List>
  );
};

export default MobileLanguageSelect;
