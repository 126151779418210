import Box from '@material-ui/core/Box';
import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import mainColors from '../../themes/main-colors';
import clsx from 'clsx';

export type Props = {
  value: string;
  valueLength: number;
  error: string;
  onChange: (value: string) => void;
  isCompactMode: boolean | undefined;
};

const RE_DIGIT = new RegExp(/^\d+$/);

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(3, 0),
  },
  input: {
    background: 'transparent',
    border: '1px solid ' + mainColors.boxShadowBlack,
    fontSize: '22px',
    borderRadius: '5px',
    color: mainColors.noirBlur,
    outline: 'transparent',
  },
  responsiveInputs: {
    width: '60px',
    padding: '16.5px 22px',
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: '40px',
      padding: '7px 12px',
      marginRight: '9px',
    },
  },
  smallInputs: {
    width: '50px',
    padding: '10px 0 10px 17px',
    marginRight: theme.spacing(1.75),
  }
}));

export default function OtpInputBox({
  value,
  valueLength,
  error,
  onChange,
  isCompactMode
}: Props) {
  const styles = useStyles();

  const valueItems = useMemo(() => {
    const valueArray = value.split('');
    const items: Array<string> = [];

    for (let i = 0; i < valueLength; i++) {
      const char = valueArray[i];

      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }

    return items;
  }, [value, valueLength]);

  const focusToNextInput = (target: HTMLElement) => {
    const nextElementSibling = target.nextElementSibling as HTMLInputElement | null;

    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };
  const focusToPrevInput = (target: HTMLElement) => {
    const previousElementSibling = target.previousElementSibling as HTMLInputElement | null;

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };
  const inputOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number,
  ) => {
    const target = e.target;
    let targetValue = target.value.trim();
    const isTargetValueDigit = RE_DIGIT.test(targetValue);

    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }

    const nextInputEl = target.nextElementSibling as HTMLInputElement | null;

    // only delete digit if next input element has no value
    if (!isTargetValueDigit && nextInputEl && nextInputEl.value !== '') {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : ' ';

    const targetValueLength = targetValue.length;

    if (targetValueLength === 1) {
      const newValue =
        value.substring(0, idx) + targetValue + value.substring(idx + 1);

      onChange(newValue.trim());

      if (!isTargetValueDigit) {
        return;
      }

      focusToNextInput(target);
    } else if (targetValueLength === valueLength) {
      onChange(targetValue.trim());

      target.blur();
    }
  };
  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const target = e.target as HTMLInputElement;

    if (key === 'ArrowRight' || key === 'ArrowDown') {
      e.preventDefault();
      return focusToNextInput(target);
    }

    if (key === 'ArrowLeft' || key === 'ArrowUp') {
      e.preventDefault();
      return focusToPrevInput(target);
    }

    const targetValue = target.value;

    // keep the selection range position
    // if the same digit was typed
    target.setSelectionRange(0, targetValue.length);

    if (e.key !== 'Backspace' || targetValue !== '') {
      return;
    }

    focusToPrevInput(target);
  };
  const inputOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    const { target } = e;

    // keep focusing back until previous input
    // element has value
    const prevInputEl = target.previousElementSibling as HTMLInputElement | null;

    if (prevInputEl && prevInputEl.value === '') {
      return prevInputEl.focus();
    }

    target.setSelectionRange(0, target.value.length);
  };

  return (
    <>
      <Box className={styles.container} data-testid="otp-input-box">
        {valueItems.map((digit, idx) => (
          <input
            key={idx}
            type="text"
            inputMode="numeric"
            autoComplete="one-time-code"
            pattern="\d{1}"
            maxLength={valueLength}
            className={clsx(styles.input, isCompactMode && styles.smallInputs, !isCompactMode && styles.responsiveInputs)}
            value={digit}
            onChange={e => inputOnChange(e, idx)}
            onKeyDown={inputOnKeyDown}
            onFocus={inputOnFocus}
          />
        ))}
      </Box>
      <Box color={mainColors.errorRed}>{error}</Box>
    </>
  );
}
