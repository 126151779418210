export enum Shadow {
  NONE = 'none',
  ONE = `0 1px 3px -2px rgba(0,0,40,.12),
    0 3px 6px 0 rgba(0,0,40,.16)`,
  TWO = `0 1px 3px -2px rgba(0,0,40,.12),
    0 4px 8px 0 rgba(0,0,40,.16)`,
  THREE = `0 2px 4px -2px rgba(0,0,40,.12),
    0 6px 10px 0 rgba(0,0,40,.16)`,
  FOUR = `0 2px 4px -2px rgba(0,0,40,.12),
    0 8px 12px 0 rgba(0,0,40,.16)`,
}
