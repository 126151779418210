import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import RelatedList from '../related/RelatedList';
import colors from '../../themes/main-colors';

export interface TocProps {
  colorBackground?: string;
  distributeAcrossColumns?: string;
  tocHeader?: string;
  tocHeaderType?: string;
  tocImage?: any;
  tocIntroCopy?: JSX.Element;
  addTocItems?: any;
}

const getBackgroundColor = (color: string | undefined) => {
  switch (color) {
    case 'None':
      return colors.white;
    case 'Blue':
      return colors.riverBlue;
    case 'Grey':
      return colors.cloudGrey2;
    case 'Yellow':
      return colors.lightYellow;
    case 'Linen-White':
      return colors.linenWhite;
    default:
      return colors.white;
  }
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: TocProps) => ({
      backgroundColor: getBackgroundColor(props?.colorBackground),
      borderRadius: theme.typography.pxToRem(5),
      '& a': {
        fontWeight: 700,
      },
      '& li': {
        fontSize: theme.spacing(2.5),
        fontFamily: 'Forma-DJR-Display',
      },
    }),
    data: {
      padding: theme.spacing(3.75, 5),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2.5),
      },
    },
    image: {
      width: '100%',
      height: theme.spacing(34.375),
      borderRadius: theme.spacing(0.625, 0.625, 0, 0),
      objectFit: 'cover',
      [theme.breakpoints.down('sm')]: {
        height: theme.spacing(16.875),
      },
    },
    pbLg: {
      [theme.breakpoints.up('sm')]: {
        paddingBottom: theme.typography.pxToRem(40),
      },
      paddingTop: theme.typography.pxToRem(10),
      paddingBottom: theme.typography.pxToRem(30),
    },

    pbSm: {
      [theme.breakpoints.up('sm')]: {
        paddingBottom: theme.typography.pxToRem(25),
      },
      paddingBottom: theme.typography.pxToRem(16),
    },
    pbMd: {
      [theme.breakpoints.up('sm')]: {
        paddingBottom: theme.typography.pxToRem(30),
      },
      paddingBottom: theme.typography.pxToRem(20),
      borderRadius: theme.spacing(0.625),
    },
  }),
);

const Toc = (props: TocProps): JSX.Element => {
  const classes = useStyles(props);
  const {
    tocImage,
    distributeAcrossColumns,
    tocHeader,
    tocHeaderType,
    tocIntroCopy,
    addTocItems,
  } = props;

  let toc;

  let tocHeadingType: 'h1' | 'h2' | 'h3' | 'h4' = 'h2';

  switch (tocHeaderType) {
    case 'h1':
      tocHeadingType = 'h1';
      break;
    case 'h2':
      tocHeadingType = 'h2';
      break;
    case 'h3':
      tocHeadingType = 'h3';
      break;
    case 'h4':
      tocHeadingType = 'h4';
      break;
    default:
      tocHeadingType = 'h2';
  }

  const columnCount = distributeAcrossColumns
    ? parseInt(distributeAcrossColumns)
    : 2;
  if (addTocItems) {
    const columns: Array<JSX.Element> = [];
    for (const property of addTocItems) {
      columns.push(<RelatedList list={property} componentType="toc" />);
    }
    const grids = (
      <React.Fragment>
        <Grid item xs={12} md={12}>
          <Grid container>
            {columns.map((item, index) => (
              <Grid
                item
                xs={12}
                md={columnCount === 3 ? 4 : columnCount === 2 ? 6 : 12}
                key={index}
              >
                {item}
              </Grid>
            ))}
          </Grid>
        </Grid>
      </React.Fragment>
    );
    toc = (
      <>
        {tocImage && (
          <img
            className={classes.image}
            src={tocImage?.file?.url}
            alt={tocHeader}
            height="275"
            width="1200"
          />
        )}
        <div className={classes.data}>
          {tocHeader && (
            <Typography
              variant={tocHeadingType}
              style={{ paddingBottom: '10px' }}
            >
              {tocHeader}
            </Typography>
          )}
          {tocIntroCopy && <>{tocIntroCopy}</>}
          <Grid container className={classes.pbLg}>
            {grids}
          </Grid>
        </div>
      </>
    );
  }

  return <div className={classes.root}>{toc}</div>;
};

export default Toc;
