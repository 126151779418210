/** @jsx jsx */
import { jsx } from '@emotion/core';

import { FunctionComponent, Fragment } from 'react';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { navigate } from 'gatsby';
import Grid from '@material-ui/core/Grid';

import { useTranslation } from '../../../hooks/useTranslation';
import ROUTES from '../../../routes';
import colors from '../../../themes/main-colors';
import PGELogo from '../../logos/PGELogo';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    toolBar: {
      minHeight: 64,
      background: '#3D5265',
      opacity: 0.9,
      display: 'flex',
    },
    promotionToolBar: {
      opacity: 0.9,
      display: 'flex',
      justifyContent: 'space-between',
    },
    logoButton: {
      padding: '5px 21px 5px 2px',
    },
    logo: {
      height: 54,
      width: 54,
      '& .angles': {
        fill: 'white',
      },
      '& .letters': {
        fill: 'white',
      },
    },
    buttons: {
      color: colors.white,
      background: 'transparent',
      borderColor: colors.white,
      borderRadius: theme.spacing(3.75),
      minWidth: '100px',
      borderWidth: '2px',
      height: theme.spacing(4.5),
      fontWeight: 'bold',
      fontSize: '18px',
      '&:hover': {
        color: colors.buttonColor,
        backgroundColor: colors.navBarBackground,
        borderColor: colors.buttonColor,
      },
      '&:active': {
        color: colors.buttonColor,
        backgroundColor: colors.navBarBackground,
        borderColor: colors.buttonColor,
      },
    },
  }),
);

interface FullSurveyHeaderProps {
  title: string;
  isMobileView: boolean;
}

const FullSurveyHeader: FunctionComponent<FullSurveyHeaderProps> = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Fragment>
      <div className={classes.root}>
        <AppBar
          position="fixed"
          elevation={0}
          color="transparent"
          css={{ backgroundColor: colors.noirBlur, padding: '0.25rem' }}
        >
          <Toolbar className={classes.promotionToolBar}>
            <Grid item>
              <IconButton
                edge="end"
                aria-label="home"
                className={classes.logoButton}
                onClick={() => navigate('/')}
              >
                <PGELogo
                  viewBox="0 0 108 108"
                  color="#fff"
                  className={classes.logo}
                />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h3" style={{ color: colors.white }}>
                {props.title}
              </Typography>
            </Grid>

            <Grid item>
              {props.isMobileView ? (
                <CloseIcon
                  onClick={() => navigate(ROUTES.MY_ENERGY_USE)}
                  style={{ color: colors.white }}
                />
              ) : (
                <Button
                  variant={'outlined'}
                  color={'primary'}
                  onClick={() => navigate(ROUTES.MY_ENERGY_USE)}
                  style={{ whiteSpace: 'nowrap' }}
                  className={classes.buttons}
                  data-testid={'close-button'}
                >
                  {t('CLOSE')}
                </Button>
              )}
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    </Fragment>
  );
};

export default FullSurveyHeader;
