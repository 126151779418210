import { useStaticQuery, graphql } from 'gatsby';
import {
  Translations,
  // getTranslationsByLocale,
  getTranslationsFromFieldsQuery,
} from '../../hooks/useTranslation';

export const useSitewideTranslations = (): Translations => {
  const data = useStaticQuery(graphql`
    query translations {
      allContentfulTranslationField {
        nodes {
          node_locale
          slug
          text {
            text
          }
        }
      }
    }
  `);
  return getTranslationsFromFieldsQuery(
    data.allContentfulTranslationField.nodes,
  );
};
