import React, { FC } from 'react';
import SvgMap from '../../svg-map';
import { useSliderContext } from './GallerySlider';
import { Animate, Action } from '../../../constants';
import { NavBtnProps as Props } from './types.d';
import useStyles from './GallerySlider.styles';

const NavBtn: FC<Props> = ({ use, children }: Props) => {
  if (!children) {
    return null;
  }

  const type = use.toUpperCase() as Action['Animate'];
  const { dispatch } = useSliderContext();
  const classes = useStyles({});

  return (
    <button
      className={`${classes.arrow} graphical`}
      type="button"
      onClick={() => dispatch({ type: Animate[type] })}
    >
      <SvgMap use={use} />
      <span>{children}</span>
    </button>
  );
};

export default NavBtn;
