interface Link {
  readonly slug?: string;
  readonly media?: {
    readonly file?: {
      readonly url?: string;
    };
  };
}

function useLink(arg?: Link | string): string {
  if (typeof arg === 'string') {
    return arg;
  }

  if ((arg as Link)?.slug) {
    return (arg as Link).slug || '';
  }

  return (arg as Link)?.media?.file?.url || '';
}

export default useLink;
