import React, { useState, useEffect } from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import { Typography } from '@material-ui/core';
import useSearchTweets from '../../../hooks/useSearchTweets';
import socialFeedConfig from '../../../config/socialFeedConfig';

interface TwitterFeedBase {
  userMentions?: string | null | undefined;
  hashtag?: string | null | undefined;
  limit?: number;
}
const loader = <Typography variant="h4">Loading...</Typography>;

function TwitterFeed({
  userMentions,
  hashtag,
  limit,
}: TwitterFeedBase): ElementNode {
  const searchTweets: any = useSearchTweets();
  const [tweets, setTweets] = useState([]);

  useEffect(() => {
    if (Array.isArray(searchTweets) && searchTweets?.length > 0) {
      const count = limit ?? socialFeedConfig.FeedLimit;
      const userMentionsHashtagResults: any = searchTweets?.filter(
        (node: any) => {
          const { user_mentions, hashtags } = node?.entities;

          const isUserMention = user_mentions?.some(
            (user_mention: any) => user_mention?.screen_name === userMentions,
          );

          if (!isUserMention) {
            return hashtags?.some((hash: any) => hash?.text === hashtag);
          }
          return true;
        },
      );

      setTweets(
        userMentionsHashtagResults?.length
          ? userMentionsHashtagResults?.slice(0, +count)
          : searchTweets?.slice(0, +count),
      );
    }
  }, [userMentions, hashtag]);

  return (
    <>
      {tweets.map((tweet: any, index: number) => (
        <TwitterTweetEmbed
          key={index}
          tweetId={tweet.id_str}
          placeholder={loader}
          onLoad={(tweetWidgetEl: HTMLDivElement) => {
            tweetWidgetEl.style.maxWidth = '800px';
          }}
        />
      ))}
    </>
  );
}

export default TwitterFeed;
