import React, { FunctionComponent } from 'react';
import Container from '@material-ui/core/Container';
import PgeButton, { IPGEButtonProps } from '../pge-button/PGEButtons';
// eslint-disable-next-line import/no-unassigned-import
import './styles/hero.css';

import TEST_IDS from '../../constants/test_ids';
import { useTranslation } from '../../hooks/useTranslation';
import { makeStyles } from '@material-ui/core';
import { useIsMobile } from '../../util/style-utils';
import useAuth from '../../hooks/useAuth';
import colors from '../../themes/main-colors';
import energyBandDarkBlue from '../../images/energy-band-dark-blue.svg';
import energyBandLightBlue from '../../images/energy-band-light-blue.svg';

interface LandingHeroImageProps {
  heading: string;
  image?: string;
  copy?: string;
  button?: IPGEButtonProps | null;
  backgroundColor?: string;
  backgroundImage?: string;
}

const useStyles = (isDarkBackground: boolean) =>
  makeStyles(theme => ({
    fullCard: {
      display: 'flex',
      minHeight: '360px',
      padding: 0,
      justifyContent: 'space-between',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      },
    },
    copyContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '68%',
      padding: '50px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: '30px 20px 60px 20px',
      },
    },
    imageContainer: {
      display: 'flex',
      justifyContent: 'center',
      width: '32%',
      minHeight: '320px',
      minWidth: '402px',
      [theme.breakpoints.down('sm')]: {
        minWidth: '100%',
        minHeight: '230px',
      },
    },
    container: {
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
      '& h1': {
        color: isDarkBackground ? colors.white : 'inherit',
        [theme.breakpoints.down('sm')]: {
          fontSize: '24px',
          lineHeight: '28.8px',
        },
      },
      '& h2': {
        color: isDarkBackground ? colors.white : 'inherit',
      },
      '& p': {
        color: isDarkBackground ? colors.white : 'inherit',
        [theme.breakpoints.down('sm')]: {
          fontSize: '18px',
          lineHeight: '23px',
        },
      },
    },
    gridContainer: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
    },
    signIn: {
      textShadow: 'none',
    },
    card: {
      height: '100%',
      minWidth: 275,
      display: 'flex',
      flexDirection: 'column',
      padding: '1em 2em',
    },
    imageStyle: {
      objectFit: 'cover',
      width: '402px',
      height: '425px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        height: '230px',
      },
    },
  }));
export const LandingHeroImage: FunctionComponent<LandingHeroImageProps> = ({
  image,
  heading,
  copy,
  button,
  backgroundColor,
  backgroundImage,
}) => {
  const { t } = useTranslation();
  const isDarkBackground = backgroundColor === 'Dark-Blue';
  const classes = useStyles(isDarkBackground)();
  const isMobile = useIsMobile();

  const { isAuthenticated } = useAuth();

  const getBackGround = (color: any | null): any | null => {
    let bgColor = colors.riverBlue;
    switch (color) {
      case 'Dark-Blue':
        bgColor = colors.noirBlur;
        break;
      case 'Light-Blue':
        bgColor = colors.riverBlue;
        break;
      case 'Yellow':
        bgColor = colors.electricYellow;
        break;
      default:
        bgColor = colors.riverBlue;
        break;
    }
    return bgColor;
  };

  const getBackGroundImage = (color: any | null): any | null => {
    let bgImage = 'none';
    switch (color) {
      case 'Dark-Blue':
        bgImage = `url(${energyBandDarkBlue})`;
        break;
      case 'Light-Blue':
        bgImage = `url(${energyBandLightBlue})`;
        break;
      default:
        break;
    }
    return bgImage;
  };

  return (
    <>
      <div
        className={'hero-container'}
        data-testid={TEST_IDS.LANDING_HERO_HEADER}
        style={{
          backgroundImage: getBackGroundImage(backgroundImage),
          backgroundSize: isMobile ? 'auto 15%' : 'auto 20%',
          backgroundRepeat: 'repeat-x',
          backgroundPositionY: isMobile
            ? 'calc(100% - 12px)'
            : 'calc(100% - 55px)',
          backgroundColor: getBackGround(backgroundColor),
          ...(!isAuthenticated && { height: 'unset' }),
        }}
      >
        <Container
          maxWidth="lg"
          className={`hero-content ${classes.fullCard} ${classes.container}`}
        >
          <div className={`${classes.copyContainer}`}>
            {heading?.trim() && <h1>{heading}</h1>}
            {copy?.trim() && <p>{copy}</p>}
            {button && <PgeButton {...button} />}
          </div>
          <div className={classes.imageContainer}>
            <img src={image} alt={''} className={classes.imageStyle} />
          </div>
        </Container>
      </div>
    </>
  );
};
