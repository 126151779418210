import { useEffect, useState } from 'react';

const events = [
  'mousemove',
  'keydown',
  'wheel',
  'DOMMouseScroll',
  'mouseWheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'MSPointerDown',
  'MSPointerMove',
  'visibilitychange',
];

/**
 * creates an idle timer with warning and timeout threshold
 * @param { number } warningThreshold time in seconds before
 *                   trigging warning state defaults to 1500 (25 mins)
 * @param { number } timeoutThreshold time in seconds before
 *                    triggering timeout state change defaults 1800 (30 mins)
 */
const useIdleTimer = (
  warningThreshold: number = 25 * 60,
  timeoutThreshold: number = 30 * 60,
) => {
  let idleInterval: NodeJS.Timer;
  const [isRunning, setIsRunning] = useState(false);
  const initState = {
    idleTime: Math.floor(new Date().getTime() / 1000),
    idleTimeoutWarning: false,
    idleTimeout: false,
    warningThreshold,
    timeoutThreshold,
  };
  const [state, setState] = useState(initState);

  const handleIdleInterval = () =>
    setState(prevState => ({
      ...prevState,
      idleTimeoutWarning:
        prevState.warningThreshold < 0 ||
        Math.floor(new Date().getTime() / 1000) >
          prevState.idleTime + warningThreshold,
      idleTimeout:
        prevState.timeoutThreshold < 0 ||
        Math.floor(new Date().getTime() / 1000) >
          prevState.idleTime + timeoutThreshold,
      warningThreshold: prevState.warningThreshold - 1,
      timeoutThreshold: prevState.timeoutThreshold - 1,
    }));

  const startIdleTimer = () => {
    setState({
      ...initState,
      idleTime: Math.floor(new Date().getTime() / 1000),
    });
    setIsRunning(true);
  };

  const stopIdleTimer = () => setIsRunning(false);

  const resetIdleTimer = () => setState({ ...initState });

  const handleEvent = () => {
    // Do not reset the timer if we've hit a warning or timeout
    setState(prevState => ({
      ...prevState,
      idleTime:
        prevState.idleTimeout || prevState.idleTimeoutWarning
          ? prevState.idleTime
          : Math.floor(new Date().getTime() / 1000),
      warningThreshold,
      timeoutThreshold,
    }));
  };

  const tearDown = () => {
    clearInterval(idleInterval);
    events.forEach(event => {
      window.removeEventListener(event, handleEvent);
    });
  };

  const init = () => {
    idleInterval = setInterval(handleIdleInterval, 1000);
    events.forEach(event => {
      window.addEventListener(event, handleEvent);
    });
  };

  useEffect(() => {
    if (isRunning) {
      init();
    } else {
      tearDown();
    }
    return () => tearDown();
  }, [isRunning]);

  return {
    ...state,
    isRunning,
    startIdleTimer,
    stopIdleTimer,
    resetIdleTimer,
  };
};
export default useIdleTimer;
