import React from 'react';
import { navigate } from '@reach/router';
import ROUTES from '../../routes';

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  constructor(props: {}) {
    super(props);
  }

  static getDerivedStateFromError(error: object) {
    return { hasError: true };
  }

  async componentDidCatch(error: object, errorInfo: object) {
    await navigate(ROUTES.ERROR);
    this.setState({
      hasError: false,
    });
  }

  render() {
    if (this.state.hasError) {
      return false;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
