import { ComponentPropsWithoutRef } from 'react';
import { ProgramCardFragment } from '../../../__generated__/gatsby-types';
import { Field } from '../../../contentful.d';
import { Button } from '../../../constants';
import { Variant } from '@material-ui/core/styles/createTypography';

export const Omits = [
  'aria-label',
  'style',
  'promoLink',
  'videowrappedImage',
  'overrideThumbnail',
  'promoLInk',
] as const;

interface Base
  extends ProgramCardFragment,
    ComponentPropsWithoutRef<'article'> {
  readonly programBadge?: Field['Badge'];
  readonly colorBackground?: Exclude<Field['Background'], 'Blue'>;
  readonly column?: Field['Column'];
  readonly ctaButton?: {
    readonly buttonType?: Button['Theme'];
    readonly buttonText?: string;
  };
  readonly fullWidth?: boolean;
}

export type ProgramCardProps = Partial<Omit<Base, typeof Omits[number]>>;

type HeaderVariant = Extract<Variant, 'h1' | 'h2' | 'h3' | 'h4'>;
