import React, { FC } from 'react';

const CerosExperience: FC<{ embedCode: string }> = ({ embedCode }) => {
  // Strip the script tag from the embed code pulled from Contentful
  // and add it to the dom manually to:
  //    1) overcome issues with the script not loading correctly in
  //       preview mode.
  //    2) prevent adding it multiple times if there is more than
  //       one Ceros experience on the page.
  // Example embed code:
  //    <div...><iframe...></iframe></div><script...></script>

  // Get the div portion of the embed code
  const embedCodeWithoutScriptTag = embedCode.substring(
    embedCode.indexOf('<div'),
    embedCode.indexOf('/div>') + 5,
  );
  // Get the script source from the embed code
  const scriptTag = embedCode.substring(
    embedCode.indexOf('<script'),
    embedCode.indexOf('</script>') + 9,
  );
  const scriptSource = scriptTag.substring(
    scriptTag.indexOf('src="') + 5,
    scriptTag.indexOf('.js"') + 3,
  );

  // If the script isn't already in the DOM, append it
  if (typeof window !== 'undefined') {
    const scriptAdded = Boolean(
      document.querySelector('script[src="' + scriptSource + '"]'),
    );
    if (!scriptAdded) {
      const newScriptTag = document.createElement('script');
      newScriptTag.src = scriptSource;
      document.body.appendChild(newScriptTag);
    }
  }
  return (
    <div dangerouslySetInnerHTML={{ __html: embedCodeWithoutScriptTag }} />
  );
};
export default CerosExperience;
