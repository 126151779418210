import {
  Grid,
  FormControlLabel,
  createStyles,
  makeStyles,
  Checkbox,
  Box,
} from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import React, { useRef, useState } from 'react';
import useAccountForm from '../../hooks/useAccountForm';
import { useTranslation } from '../../hooks/useTranslation';
import ROUTES from '../../routes';
import colors from '../../themes/main-colors';
import PGEButton from '../buttons';
import EmailField from '../email-text-field';
import { HowWeUseYourPhoneNumberTooltip } from '../how-we-use-your-phone-number-tooltip';
import PasswordField from '../password-text-field';
import MFAVerificationModal from '../sign-in-form/MFAVerificationModal';
import Link from '../text-link';
import { CheckBox } from '@material-ui/icons';

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      width: '100%',
      background: '#e7f0f8',
      minHeight: '145px',
    },
    section: {
      maxWidth: '1180px',
      margin: '0 auto',
      display: 'flex',
      paddingRight: '155px',
    },
    item: {
      width: '100%',
      textAlign: 'center',
      marginTop: '10px',
      justifyContent: 'space-around',
    },
    subItemsSection: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      minHeight: '42px',
      minWidth: '415px',
      position: 'absolute',
      bottom: 0,
    },
    tooltipLink: {
      cursor: 'pointer',
      display: 'inline',
      color: colors.sparkBlue,
      borderBottom: `2px dotted ${colors.sparkBlue}`,
      textDecoration: 'none',
    },
    signInBlock: {
      height: '56px',
      display: 'flex',
      marginTop: '30px',
      justifyContent: 'end',
    },
    submit: {
      backgroundColor: colors.orange,
      borderColor: colors.orange,
      '&:hover': {
        backgroundColor: colors.orange,
        borderColor: colors.orange,
      },
      width: '144px',
      height: '45px',
    },
    fields: {
      background: 'transparent',
      '& > div': {
        background: 'white',
      },
    },
    checkBox: {
      '& .MuiFormControlLabel-label': {
        fontSize: '16px',
      },
    },
  }),
);

export const MegaMenuSignInSection = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    form,
    handleSubmit,
    handleForgotPassword,
    isTwoStepRequired,
    handleBackToLogin,
    handleTwoFactorSubmit,
    twoStepVerificationForm,
    handleResendOneTimeCode,
  } = useAccountForm({});
  const anchorRef = useRef<any>();
  const [
    showHowWeUsePhoneAnchor,
    setShowHowWeUsePhoneAnchor,
  ] = useState<HTMLElement | null>(null);

  const openHowWeUsePhoneTooltip = () => {
    setShowHowWeUsePhoneAnchor(anchorRef.current);
  };

  const closeHowWeUsePhoneTooltip = () => {
    setShowHowWeUsePhoneAnchor(null);
  };

  return (
    <Grid className={classes.container} style={{ position: 'relative' }}>
      <form id={'login-form'} noValidate onSubmit={handleSubmit}>
        <Grid container className={classes.section} direction="row" spacing={2}>
          <Grid
            className={classes.item}
            xs={5}
            style={{ paddingRight: '10px' }}
          >
            <EmailField
              className={classes.fields}
              autoFocus
              autoComplete="username"
              inputProps={{ maxLength: 50 }}
              {...form.props('email')}
            />
            <Box className={classes.subItemsSection}>
              <Box>
                <span className={classes.checkBox}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={form.onChange}
                        checked={form.values.rememberMe}
                        name={'rememberMe'}
                        color={'primary'}
                        className="checkboxInput"
                      />
                    }
                    label={t('REMEMBER_ME')}
                  />
                </span>
              </Box>
            </Box>
          </Grid>
          <Grid className={classes.item} xs={5} style={{ paddingLeft: '10px' }}>
            <PasswordField
              className={classes.fields}
              autoComplete="current-password"
              {...form.props('password')}
            />
            <Box className={classes.subItemsSection}>
              <Box>
                <Link
                  plain
                  to={ROUTES.FORGOT_PASSWORD}
                  variant={'body2'}
                  onClick={e => {
                    e.preventDefault();
                    handleForgotPassword();
                  }}
                >
                  {t('FORGOT_PASSWORD')}
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid className={`${classes.item} ${classes.signInBlock}`} xs={2}>
            <PGEButton
              id="sign-in-submit-btn"
              type={'submit'}
              variant={'contained'}
              color={'primary'}
              className={classes.submit}
              disabled={!isEmpty(form.errors)}
            >
              {t('SIGN_IN')}
            </PGEButton>
          </Grid>
        </Grid>
      </form>
      {isTwoStepRequired && (
        <MFAVerificationModal
          open={isTwoStepRequired}
          onClose={handleBackToLogin}
          handleResendOneTimeCode={handleResendOneTimeCode}
          twoStepVerificationForm={twoStepVerificationForm}
          handleTwoFactorSubmit={handleTwoFactorSubmit}
        />
      )}
    </Grid>
  );
};
