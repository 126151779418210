import {
  AccountSort,
  Direction,
  Operator,
  AccountDetailListParams,
  AccountStatusType,
  AccountRelationship,
} from '../../../__generated__/pge-types';
import { PageSize } from '../../pagination/types';
import { makeActions, createReducer } from '../../../util/reducer-utils';

export type AccountListState = {
  page: number;
  query: string;
  pageSize: PageSize;
  sortBy: AccountSort;
  sortOrder: Direction;
  accountStatus?: string;
  userAccountRelFilter?: AccountRelationship[]
};

const initialState: AccountListState = {
  page: 1,
  pageSize: 5,
  query: '',
  sortBy: AccountSort.Default,
  sortOrder: Direction.Asc,
  accountStatus: 'ACTIVE',
  userAccountRelFilter: []
};

const actions = makeActions({
  setPage: (page: number) => ({ page }),
  setPageSize: (pageSize: PageSize) => ({ pageSize }),
  setQuery: (query: string) => ({ query, page: 1 }),
  setSortBy: (sortBy: AccountSort) => ({ sortBy }),
  setSortOrder: (sortOrder: Direction) => ({ sortOrder }),
  setRelationshipType: ((userAccountRelFilter: AccountRelationship[]) => ({ userAccountRelFilter })),
  resetParams: ({
    sortOrder = initialState.sortOrder,
    sortBy = initialState.sortBy,
    page = initialState.page,
    query = initialState.query,
    pageSize = initialState.pageSize,
  } = {}) => ({ sortOrder, sortBy, page, query, pageSize }),
});

const reducer = createReducer<AccountListState, typeof actions>({
  setPage: (state, { payload: { page } }) => ({ ...state, page }),
  setPageSize: (state, { payload: { pageSize } }) => ({ ...state, pageSize }),
  setQuery: (state, { payload: { query, page } }) => ({
    ...state,
    query,
    page,
  }),
  setSortBy: (state, { payload: { sortBy } }) => ({ ...state, sortBy }),
  setSortOrder: (state, { payload: { sortOrder } }) => ({
    ...state,
    sortOrder,
  }),
  setRelationshipType: (state, { payload: { userAccountRelFilter } }) => ({ ...state, userAccountRelFilter }),
  resetParams: (state, { payload }) => {
    return { ...state, ...payload };
  },
});

export default {
  initialState,
  reducer,
  actions,
};

export function makeParams(
  groupId: string,
  { query, pageSize, page, sortBy, sortOrder, accountStatus, userAccountRelFilter }: AccountListState,
): AccountDetailListParams {
  return {
    groupId,
    filter: {
      filterBy: query,
      operator: Operator.Startswith,
    },

    paging:
      pageSize !== 'All'
        ? {
            limit: pageSize,
            offset: (page - 1) * pageSize,
          }
        : undefined,
    sort: {
      sort: sortBy,
      direction: sortOrder,
    },
    ...((userAccountRelFilter && userAccountRelFilter?.length > 0) ? {userAccountRelFilter: userAccountRelFilter} : {}),
    accountStatus:
      accountStatus?.toLowerCase() === 'active'
        ? AccountStatusType.Active
        : accountStatus?.toLowerCase() === 'inactive'
        ? AccountStatusType.Inactive
        : AccountStatusType.All,
  };
}
