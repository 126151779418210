/** @jsx jsx */
import { css, jsx } from '@emotion/core';

import { Button } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';
import { navigate } from 'gatsby';
import { FC } from 'react';
import useInstallerAuth from '../../../hooks/pge-plus/useInstallerAuth';
import { useTranslation } from '../../../hooks/useTranslation';
import colors from '../../../themes/main-colors';
import PGELogo from '../../logos/PGELogo';

export const Navigation: FC = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { signOut, authUser, isAuthenticated } = useInstallerAuth();

  const isBrowser = !(typeof window === 'undefined');

  return (
    <div data-swiftype-index="false">
      {
        <div className="app-bar">
          <Container
            css={{
              [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
                maxWidth: '100%',
                padding: '0 4.23% 0.875rem 4.23% !important',
              },
            }}
          >
            <div
              css={css`
                flex: 3 1;
                width: 50%;
              `}
            >
              {isBrowser && (
                <IconButton
                  edge="start"
                  aria-label={t('NAVIGATE_HOME')}
                  onClick={() => navigate('/')}
                >
                  <PGELogo
                    className="logo"
                    color="#fff"
                    viewBox="0 0 108 108"
                  />
                </IconButton>
              )}
            </div>

            {isAuthenticated && (
              <div
                css={css`
                  flex: 3 1;
                  width: 50%;
                  flex: 1 1;
                  color: #fff;
                  text-align: right;

                  display: flex;
                  flex: 2 !important;
                  justify-content: flex-end;

                  & .MuiButton-outlinedPrimary {
                    margin-left: ${theme.spacing(2)};
                  }
                `}
              >
                <div className="user-logged-in">
                  <div className={'item'}>{authUser?.email}</div>
                  <div className="item">
                    <Button
                      data-testid={'sign-in-route-button'}
                      variant={'outlined'}
                      css={{
                        color: colors.white,
                        background: 'transparent',
                        borderColor: colors.white,
                        borderRadius: theme.spacing(3.75),
                        minWidth: '100px',
                        borderWidth: '2px',
                        height: theme.spacing(4.5),
                        fontWeight: 'bold',
                        fontSize: '18px',
                        '&:hover': {
                          color: colors.buttonColor,
                          backgroundColor: colors.navBarBackground,
                          borderColor: colors.buttonColor,
                        },
                        '&:active': {
                          color: colors.buttonColor,
                          backgroundColor: colors.navBarBackground,
                          borderColor: colors.buttonColor,
                        },
                        '& span': {
                          fontSize: '14px',
                        },
                      }}
                      onClick={signOut}
                    >
                      {t('SIGN_OUT')}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </Container>
        </div>
      }
    </div>
  );
};
