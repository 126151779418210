import {
  Dialog,
  DialogTitle,
  IconButton,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Close from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { CustomerLanguagePrefResponse } from '../../__generated__/pge-types';
import useAccountCustomer from '../../hooks/useAccountCustomer';
import useAuth from '../../hooks/useAuth';
import { useTranslation } from '../../hooks/useTranslation';
import useWrapWithLoader from '../../hooks/useWrapWithLoading';
import apolloClient from '../../lib/apolloClient';
import {
  LANGUAGE_PREFERENCE_CHANGED,
  SupportedLanguages,
  getLanguage,
} from '../../providers/LanguageProvider';
import { C2MPreferredLanguages } from '../../util/languageUtil';
import { getFromStorage, removeFromStorage } from '../../util/storage-utils';
import PGEButton from '../buttons';
import useUtil from '../need-more-time-to-pay/useUtil';
import { updateLanguagePreference } from './LanguagePreferencePopup.query';

const useStyles = makeStyles(() =>
  createStyles({
    closeBtn: {
      position: 'absolute',
      top: 10,
      right: 10,
      padding: 0,
    },
  }),
);

export default function LanguagePreferencePopup() {
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  const { wrapWithLoader } = useWrapWithLoader();
  const { setErrorNotification } = useUtil();
  const { customer } = useAccountCustomer();
  const classes = useStyles();
  const [showLanguagePreferencePopup, setShowLanguagePreferencePopup] =
    useState<boolean>(false);

  const handleClose = () => {
    setShowLanguagePreferencePopup(false);
    removeFromStorage(LANGUAGE_PREFERENCE_CHANGED);
  };

  useEffect(() => {
    const isLanguagePreferenceChanged = Boolean(
      getFromStorage(LANGUAGE_PREFERENCE_CHANGED),
    );
    if (isLanguagePreferenceChanged) {
      setShowLanguagePreferencePopup(true);
    } else {
      setShowLanguagePreferencePopup(false);
    }
  }, []);

  const getFormattedLanguage = () => {
    switch (getLanguage()) {
      case SupportedLanguages.English:
        return C2MPreferredLanguages.English;
      case SupportedLanguages.Spanish:
        return C2MPreferredLanguages.Spanish;
      default:
        return C2MPreferredLanguages.English;
    }
  };

  async function persistLangaugePreference() {
    const { data, errors } = await apolloClient.mutate<{
      updateLanguagePreference: CustomerLanguagePrefResponse;
    }>({
      mutation: updateLanguagePreference,
      variables: {
        payload: {
          email: customer?.email || '',
          prefLang: getFormattedLanguage(),
        },
      },
    });

    if (errors && errors.length > 0) {
      return Promise.reject('Mutation failed');
    }
    return data?.updateLanguagePreference;
  }

  const handleClick = wrapWithLoader(async (shouldUpdate: boolean) => {
    try {
      if (isAuthenticated && shouldUpdate) {
        const result = await persistLangaugePreference();
        if (result?.success) {
          handleClose();
        }
      } else if (!shouldUpdate) {
        handleClose();
      }
    } catch (e) {
      // close popup for better visiblity of the error
      handleClose();
      window.scrollTo(0, 0);
      setErrorNotification(true);
    }
  });

  return (
    <Dialog
      open={showLanguagePreferencePopup}
      onClose={handleClose}
      data-testid="alert-dialog"
    >
      <DialogTitle>
        <IconButton
          aria-label="close"
          color="inherit"
          onClick={handleClose}
          className={classes.closeBtn}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {getLanguage() === SupportedLanguages.Spanish && (
            <Typography variant="body1" data-testid="alert-dialog-description">
              {t('LANGUAGE_PREFERENCE_POPUP_MESSAGE')}
            </Typography>
          )}
          {isAuthenticated && (
            <Typography variant="body1" data-testid="alert-dialog-question">
              {t('SAVE_YOUR_LANGUAGE_PREFERENCE')}
            </Typography>
          )}
        </DialogContentText>
      </DialogContent>
      {isAuthenticated && (
        <DialogActions>
          <PGEButton
            size="large"
            color="secondary"
            variant="outlined"
            onClick={() => void handleClick(true)}
            data-testid="yes"
          >
            {t('YES')}
          </PGEButton>
          <PGEButton
            size="large"
            onClick={() => void handleClick(false)}
            data-testid="no"
          >
            {t('NO')}
          </PGEButton>
        </DialogActions>
      )}
    </Dialog>
  );
}
