import {
  Collapse,
  IconButton,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import MuiAlert from '@material-ui/lab/Alert';
import MuiAlertTitle from '@material-ui/lab/AlertTitle';
import React, { forwardRef } from 'react';
import colors from '../../../src/themes/main-colors';
import { useIsMobile } from '../../util/style-utils';
import ROUTES from '../../routes';

import { NotificationsContext } from '../../providers/NotificationsProvider';

export type NotificationSeverity = 'error' | 'warning' | 'info' | 'success';

export type NotificationVariant = 'filled' | 'outlined' | 'standard';

interface NotificationsProps {
  severity?: NotificationSeverity;
  variant?: NotificationVariant;
  title?: string;
  message?: React.ReactNode;
  isOpen?: boolean;
  alertStyle?: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    alert: {
      margin: '1em 1em 1em 1em',
      '& > .MuiAlert-message > p': {
        margin: 0,
      },
    },
    icon: { paddingTop: '13px' },
    iconMobile: { paddingTop: '8px' },
    action: { alignItems: 'start' },
    actionMobile: { alignItems: 'start', paddingTop: '6px' },
    message: { fontSize: '1.2rem', fontWeight: 700 },
    messageMobile: { fontSize: '1rem', fontWeight: 700 },

    filledSuccess: { backgroundColor: colors.shoreGreen },
    filledWarning: { backgroundColor: colors.warning },
    filledError: {
      backgroundColor: colors.roseRed,
      '& a': {
        color: '#FFF',
        textDecoration: 'none',
        fontWeight: 'bold',
      },
    },
    filledInfo: {
      backgroundColor: colors.info,
      '& a': {
        color: '#FFF',
        fontWeight: 'bold',
      },
    },

    successIcon: {
      '& path': {
        transform: 'scale(1.25) translateX(-2.5px) translateY(-2.5px)',
      },
      fill: colors.darkGreen,
      fontSize: 'inherit',
      background: colors.white,
      borderRadius: '50%',
      transform: 'scale(1.2)',
    },
    errorIcon: {
      '& path': {
        transform: 'scale(1.25) translateX(-2.5px) translateY(-2.5px)',
      },
      fill: colors.darkRed,
      fontSize: 'inherit',
      background: colors.white,
      borderRadius: '50%',
      transform: 'scale(1.2)',
    },
    warningIcon: {
      '& path': {
        transform: 'scale(1.25) translateX(-2.5px) translateY(-2.5px)',
      },
      fill: colors.darkOrange,
      fontSize: 'inherit',
      background: colors.white,
      clipPath: 'polygon(50% 6%, 100% 91%, 0 91%)',
      transform: 'scale(1.3)',
    },
    infoIcon: {
      '& path': {
        transform: 'scale(1.25) translateX(-2.5px) translateY(-2.5px)',
      },
      fill: colors.darkBlue,
      fontSize: 'inherit',
      background: colors.white,
      borderRadius: '50%',
      transform: 'scale(1.2)',
    },
  }),
);

const Notifications = forwardRef<HTMLElement, NotificationsProps>(
  (props, ref) => {
    const classes = useStyles();

    const isMobile = useIsMobile();
    return (
      <NotificationsContext.Consumer>
        {({ state, setState }) => {
          const s = props.isOpen ? props : state;

          const alertStyleOverride = s.alertStyle ? s.alertStyle : '';

          let alertClass;
          if (s.severity === 'success') {
            alertClass = `${classes.filledSuccess} ${classes.alert} ${alertStyleOverride} `;
          } else if (s.severity === 'error') {
            alertClass = `${classes.filledError} ${classes.alert} ${alertStyleOverride}`;
          } else if (s.severity === 'warning') {
            alertClass = `${classes.filledWarning} ${classes.alert} ${alertStyleOverride}`;
          } else {
            // its info!
            alertClass = `${classes.filledInfo} ${classes.alert} ${alertStyleOverride}`;
          }

          let messageClass;
          if (isMobile) {
            messageClass = classes.messageMobile;
          } else {
            messageClass = classes.message;
          }

          let iconClass;
          if (isMobile) {
            iconClass = classes.iconMobile;
          } else {
            iconClass = classes.icon;
          }

          let actionClass;
          if (isMobile) {
            actionClass = classes.actionMobile;
          } else {
            actionClass = classes.action;
          }

          return (
            <Collapse in={s.isOpen} style={{ zIndex: 0 }}>
              <MuiAlert
                className={alertClass}
                classes={{
                  icon: iconClass,
                  action: actionClass,
                  message: messageClass,
                }}
                ref={ref}
                severity={s.severity}
                variant={s.variant}
                iconMapping={{
                  success: <CheckCircleIcon className={classes.successIcon} />,
                  error: <ErrorIcon className={classes.errorIcon} />,
                  warning: <WarningIcon className={classes.warningIcon} />,
                  info: <InfoIcon className={classes.infoIcon} />,
                }}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size={isMobile ? 'small' : 'medium'}
                    onClick={() => setState({ isOpen: false })}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                {s.title && <MuiAlertTitle>{s.title}</MuiAlertTitle>}
                {s.message}
              </MuiAlert>
            </Collapse>
          );
        }}
      </NotificationsContext.Consumer>
    );
  },
);

export default Notifications;
