import React, { FC } from 'react';
import { Icon, MaterialIcons } from '../../../constants';
import { SvgIcon } from '@material-ui/core';
import { SvgMapProps as Props, Sprite } from './types.d';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@material-ui/icons/RemoveCircleOutlineRounded';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import VisibilityOffRoundedIcon from '@material-ui/icons/VisibilityOffRounded';
// import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
// import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';

const sprite: Sprite = {
  // Material
  http: <OpenInNewRoundedIcon />,
  Plus: <AddCircleOutlineRoundedIcon />,
  Minus: <RemoveCircleOutlineRoundedIcon />,
  Download: <GetAppRoundedIcon />,
  Show: <VisibilityRoundedIcon />,
  Hide: <VisibilityOffRoundedIcon />,
  Next: <ArrowForwardIosRoundedIcon />,
  Prev: <ArrowBackIosRoundedIcon />,

  // Custom
  pdf: (
    <image
      width="22"
      y="1"
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsSAAALEgHS3X78AAAB+klEQVRIDWP8//8/AxwoiQswMDAcYGBg0GcgHjQy3HvZgFM1yAI4VhRb8Cc+/NG3F8//f/36lSD+fvb0//9aCr//GaitQTEHCaP74P/34xcZ/vPyEe18phvXGDhiQ/8wfP+2lOHeywQMeQwfkWA4CPzT0GL4sXg1CwM7ewSDkvgCghaQA0CW/OyaxM7AwBDPoCTeQJYFzHt3MXAEezFwutkwMH7+hCH/19mN4U98yksGBgaUYCLegtMnGH6s3cbwX0oGHO7YwO+MXHEGBgZ5siz4Jy1DUA22+CM+Dnj5wL5gOnOS4Z8UYctggIVYhaAw5nC1YfinrsnwnwjfwADRPmB8+oThn5kF0QbDAFE+AKUatuoShl+tPQxMp08wsE6bABYHRTYoif5T1wL7ECtAKypQiocfB/b+/12QCcYgub+hvmD6T3L0/2+3boLVgOT+6SrD9YDkkc3E6QOQK0Fp/+ekWeDI/ff0CcPv2CSGf5NnMTBvWAPOE4xfPoPVguIFJ8DlA5BrQa78uXTh/3/Whv+xFYAgH4IwshzRPgC5lr26BMwG+QJbGv9rSjjScVoAijScEUcCoEphhw8MQwuwFcXEAlBuJ2RBIsvieVgVEgIgPSwb14DNQFaLmoruvVzA0NEcxelu60qOD36n5ewGm4EEUCt9agMGBgYADi9qJQnXFaQAAAAASUVORK5CYII="
    />
  ),
  docx: (
    <image
      width="30"
      x="-3"
      y="-3"
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAADl0lEQVRoge2ZS28bVRiGnzMZk5sncdy00LjkYpdmcBq3ZlqJmwQLUBdUldggsSFrKrFkifgJsCmsgoQESLBgw4askFBAbGJHqAjVbhN6y8L4QmKncexkDotxbCf2UBvHmbaaZ+PxfMdn3vd85ztnZgwuLi4uTiK61fHAm/NjPcJjCEUaUmIARn5hbuywr6N23MPrP6ne3tvTChimVAxFyLCEKHAMJFJ2LvK/aMvA6JV5bbvkOYdphiXKjFCkgbxjgOiTgBCSLuttwNbA8Ftfj5i7uzNCSkOCAdLY3hY6SAUhEEiOXG0TbA2YOztZqNfYeblMfZb/v5Z/Wb2qvdosoHSg5yh5xS7wuBiwxTXgNJ3vA22w8r63rfbBzwsPbfPkZyAS8rN47TIAH38R45Pvrldji9cuEwn52dmVjL39DcXSLgAfzUX58N1ZcvltJt/5trobtzKi7fLQDPx5+x+2KsKmnx2unu/vVQlPjgCg9gien/RVY9PjVrtYIuP8rUR5x+T6SpaL+nH0iZqB6HN+1J7a5hYJ+oknMkDNaKzyfQ/HaqAmzIeoaH7hzCgAha0yAJHTfgA8qsLUmAbAcjJDt2lpFYpXhAz2q5w6Psjd1CbGtGXgtz9SvHEhQCRoGZg6qfGUao1LLJHe148jNWAJqY2kPmHN9Yu6ZeCHX+8CMBscQRGiOn1SuSL30w8OVWwzWsrAjTvrFLbKePs96OPDxBMZxp/2srFZ4vuf/+LTD15koE8lFNDqCjjd0I9jNWBKye+3cgDo4z4uVEZ/KZFhvVBiZW0DsJbcPQPxI5j/0MZOHE+kefnsCfSJYe6nLQN7oxxPZgkFhpgN+qtTaOlGYwYcqwGAWGVETweGOBeyCnY5mbU+b1qxs8ERQoGhfee6TRsZsAT5vL3V/WCpkoG95TI84UMb8HDv701SuWJDH47eC91a22C9UEIIa6lM5YrcS20C1nw3peTUiUGgcQPrJi1nQEpYvpnltfPPAPtXmfyDMqtr+er0idsYcLQGAOLJdN1x5kAsWz2OJRsLuFvYPqlrl7489NuwjR/fa6t9fcZWr2pNtT75zwOHieM18CjiGnCax8XAol3AtogVVfUffLkLQqcD03ZLYSe01eHolXmtXOw5IwUzplQM6/U6BtDXyu/zC3POGmhK3R8cEhEGZoCXgGMHmz6aBpoixdClr0KmkFFhEkXI80A0vzB3sjvXc3FxcXGKfwFIDEHh8af+wQAAAABJRU5ErkJggg=="
    />
  ),
  xlsx: (
    <image
      width="30"
      x="-3"
      y="-3"
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAADpElEQVRoge2Zf2hVZRjHP++5u8ctp86mi41Q29TZflj3nsjGJl6VVoaTLf1HiszGEiESQZZpg8GIQJuKYRLZH00tS1BbzbnRRMWpaeyPaeQiC6QoRtvwKmy13fP2x8WT695zPWfnXu/E84EL932f933e5/ue9+F9z3vAxcXFJZmIRDn21ftyQrrQAA2JJkG73NCVE+9xUpw6CNQHUgZCA/kSJRwsogDwhUJk3tkuUTNlS0B+bemkVO/QE7oiCwSyEND6Q0ENPKkJiu+umAoo3lw8VfGohVKggdSkQBMMzpOghGczYavPFqYChNfbL5H/leMw2PKjlfLurSKRQna2VH5VFs2mOAvp3iCkKDWz3RcCYuEKSDaO9wE7fF151Phfcawqou7/3G4TiwfnCbxUspraZZuM8ubDW2m9fMIoZzyUwfGNzUycMBGAxradNHUeiGOo0bEs4NB3X1Llr2TOI7MBeD1QTduVdnSpA7AuUGMEf633Gp9dOBThI9qSsLJMYmF5CYX0ENta3zfKudNzWTg3vLfkZOSw6qkXAZBS8l7LNkZCI44Cs4qtJL74yyVO/niKJY8HAKhZVM3pnjO8sXQ9aooKQEv3cS79+n3U/uMiiRtP7ODvkX8AKH60iPKiZ1k2/3kAbg7dZEfbLrsuHWFbwG8Dv9PUud8o11VsQRFhN3s69tJ3qz9+0VnA9Iw2v85vevBKU9NofvMIWZOzjLqrf/Sw+qOX0XXddLAZ/hljjZNvqo5FjXVM+4BH8UTUTUmbjOpRx+LOEWPaiTeWbzBm/69bfUxLzyQ7I5vqhWvZc3Kvab9xkcTaLD8rtbDjoeEhGprfNWyvlr3CzMyZdl06wpYANUXlnYotCBFejgfPf86pq6c59/N5w/728rfiH2UMbC2hmkXV5E5/DIDgYJBPO5sA2NW+m2fyFqAIhZK8BTxXVE7blfaI/kndiWdn5bG2bI1R/vjMJ9wYDALQ8+dPfPtDh2GrfWET6anpjgKziqUnoAiFuhVb8Xq8APQGe/ni4uFRbT7o+JClBUvwKB6mpWeyfvE6trc2jmqTiCS2JECXOmv2vRazzfW+6/jrn7biLq48OO8D8SCpSTxecQUkm/tFwFkzg2kSy+HhhyMvd5mHA9FmR2In2HKYX1s6SZ0wOPf21Xr4JzTA0vV6d0NXcgVE484PHEIoBVLKQqAERn/ggHEqwMxvYb0/T9GFT6D7kOJJwNfd0JWdoPFcXFxcksS/44wNWFr5JHcAAAAASUVORK5CYII="
    />
  ),
  pptx: (
    <image
      width="30"
      x="-3"
      y="-3"
      xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAAC+ElEQVRoge2ZTUgUYRjHf+/srB+pYLqiLipIpqZgbhNRJBF0KDLs1CFEulSnoi55kg5Bl+hi0slTFIXQKVBECCLqYB+HhBApkkpcv7INRdPZmbeDH6Xu4szOuJs4PxgY3o//8/zn/ZiXGfDw8PBIJWKrhL81lQZ9uqEh0ABNgBbsGQ26HUd1KiCPo46lF1WhoEkhNKAGSYhoNH/rHs9fbBmYbArk6Itp+/HJGiS1gBYGDchY0zAJia8Q18DXxrLdfiNaiyI1lpLU9CjVKFJBJi/BzYhrwC/1aRR3g8k7lxK1/lq0djbEqnA5xS3jaLyK7WIgLo53IadEm9sQkQnElwGUobdgGrb6p9wAahoyULJ0VRzA9/whzM1Y7p7UKWTuO4zReJloc1vMeplXhHGiBRSfZc3kGjh0GhkoATUtbhuZV4RZddCypu0pVNw9ujHo4m+MiREW3r9g9ul9zOlxu7Jr9crrYLDfUltXRkCkZaCWVJB19iIFHX34Cssc6cncQsttHS3iXx2tiMws/JX1ZDacAcWHkltATssNInevWkvgwU0nKTgzMNf7aPXeGP9O9rkrAKSHjjlKyg6ubaOLH/th2YDIzt20ffTCrY2F+gLq49u24rq2C6mle1fvzR/hhDREZMJ+3IQiLeOvqENk7MJfGSLn/PXV8vmXzxLSE8MDtvs4MhBo791Qpn/6wGxXu20tMT2GMvTOdj/X1oD5c4K5vifMdt1DLszb6iumx5aOEDbPQeDQwNS1U2DomDMRjKnN573yphtZXre6z4vIOGJ4YOnJJ5A8ODSgf7Y3Z5XBfstvWMuarqqlgKQep2Pu/XGw+obeeSMQbkz825TTc08sdt4IOMFbAzFI6gh4ayAG28OA4FW8qrhTSBf+vPUfd4FqHJgWrZ2uf7e2JTjZFMjRDX+lMGXt8r+AlStjk64ABHvCqTUQi39/cAA1plBqhZRHEOSvb/tfGoiFBDFysmSPqkZDUooQUA+Egj3h4q2I5+Hh4ZE6/gA6jdZ5Bri27QAAAABJRU5ErkJggg=="
    />
  ),
};

const SVGIcon: FC<Props> = ({ use, ...props }) => {
  if (!use) {
    return null;
  }

  if (MaterialIcons.includes(use as Icon['Material'])) {
    return sprite[use];
  }

  return <SvgIcon {...props}>{sprite[use]}</SvgIcon>;
};

export default SVGIcon;
