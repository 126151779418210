import { Tokens, AuthAdapter } from '../../types';
import {
  signInWithEmailAndPassword,
  signInWithCustomToken as signInWithCustomFirebaseToken,
  PhoneMultiFactorGenerator,
  PhoneAuthProvider,
  MultiFactorResolver,
  User,
} from 'firebase/auth';

import { auth } from '../../../../firebase';
import { FirebaseAuthAdapterBase } from './FirebaseAuthAdapterBase';

class FirebaseAuthAdapter extends FirebaseAuthAdapterBase
  implements AuthAdapter {
  async signInWithPassword(email: string, password: string): Promise<Tokens> {
    try {
      const resp = await signInWithEmailAndPassword(auth, email, password);
      return await this.buildToken(resp.user);
    } catch (err) {
      throw this.getError(err);
    }
  }

  async signInWithCustomToken(token: string): Promise<Tokens> {
    try {
      const resp = await signInWithCustomFirebaseToken(auth, token);
      return await this.buildToken(resp.user);
    } catch (err) {
      throw this.getError(err);
    }
  }

  async refreshTokens(): Promise<Tokens> {
    try {
      await auth.currentUser?.getIdToken(true); //Force Refresh
      return await this.buildToken(auth.currentUser!);
    } catch (err) {
      throw this.getError(err);
    }
  }

  async signInWithMultiFactorAuth(
    verificationId: string,
    resolver: MultiFactorResolver,
    verificationCode: string,
  ) {
    const credentials = PhoneAuthProvider.credential(
      verificationId,
      verificationCode,
    );
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(
      credentials,
    );

    try {
      const resp = await resolver.resolveSignIn(multiFactorAssertion);
      return await this.buildToken(resp.user);
    } catch (err) {
      throw this.getError(err);
    }
  }
}

export default FirebaseAuthAdapter;
