import useAuth from '../../hooks/useAuth';
import SessionTimeoutDialog from './SessionTimeoutDialog';
import React, { useEffect } from 'react';
import useIdleTimer from './useIdleTimer';
import ROUTES from '../../routes';

const SessionTimeout = () => {
  const {
    isAuthenticated,
    signOut,
    refreshTokens,
    startTokenRefresher,
    stopTokenRefresher,
  } = useAuth();
  const {
    idleTimeoutWarning,
    idleTimeout,
    startIdleTimer,
    resetIdleTimer,
    stopIdleTimer,
    isRunning,
  } = useIdleTimer();

  const handleRefresh = async () => {
    await refreshTokens();
    resetIdleTimer();
  };

  const handleSignout = async () => {
    await signOut('/');
    stopIdleTimer();
    resetIdleTimer();
  };
  const isBrowser = !(typeof window === 'undefined');
  const isOutagesMobileApp =
    isBrowser &&
    window.location.pathname.replace(/\/$/, '') === ROUTES.OUTAGES_MOBILE_APP;
  const isPGEPLUSGetStarted =
    isBrowser &&
    window.location.pathname.replace(/\/$/, '') === ROUTES.PGE_PLUS_GET_STARTED;

  useEffect(() => {
    if (isAuthenticated) {
      //Reset the timer when we sign-in, to help in the case of auto sign-out to update the idle time to now
      if (!isRunning) {
        resetIdleTimer();
      }
      // Keep tokens refreshed if we're not idle
      if (!idleTimeoutWarning && !idleTimeout) {
        startTokenRefresher();
      }
      // Stop refreshing token if we hit our idle limites
      if (idleTimeoutWarning || idleTimeout) {
        stopTokenRefresher();
      }
      // Signout if we hit our idle timeout limit
      if (idleTimeout && !isOutagesMobileApp && !isPGEPLUSGetStarted) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        void handleSignout();
      }
    } else {
      // If we're signed out don't track idle time
      stopIdleTimer();
      resetIdleTimer();
    }
  }, [isAuthenticated, idleTimeoutWarning, idleTimeout]);
  useEffect(() => {
    if (isAuthenticated && !isRunning) {
      // Start our idle timer if we're authenticate
      startIdleTimer();
    }
  }, [isAuthenticated]);
  return (
    <SessionTimeoutDialog
      onAccept={handleRefresh}
      onDecline={signOut}
      open={
        isAuthenticated &&
        idleTimeoutWarning &&
        !isOutagesMobileApp &&
        !isPGEPLUSGetStarted
      }
    />
  );
};
export default SessionTimeout;
