import React, { forwardRef } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button, { ButtonProps } from '@material-ui/core/Button';
import colors from '../../themes/main-colors';
import clsx from 'clsx';

const useStyles = makeStyles(theme => {
  const getBorderColor = (color?: PGEButtonColor) =>
    color === 'secondary' ? theme.palette.primary.main : '#fff';
  return {
    outlinedButton: (props: {
      variant?: PGEButtonVariant;
      color?: PGEButtonColor;
    }) => ({
      color: getBorderColor(props.color),
      borderColor: getBorderColor(props.color),
      '&:hover': {
        backgroundColor:
          props.color === 'secondary'
            ? colors.buttonHoverSecondary
            : colors.buttonHoverPrimary + 'B2',
      },
      '&:active': {
        backgroundColor:
          props.color === 'secondary'
            ? colors.buttonActiveSecondary
            : colors.focusedVisible + 'B2',
      },
    }),
    primaryButton: (props: {
      variant?: PGEButtonVariant;
      color?: PGEButtonColor;
    }) => {
      // Generate Outlines
      if (props.variant === 'outlined') {
        return {
          color: colors.buttonHoverPrimary,
          borderColor: colors.buttonHoverPrimary,
          backgroundColor: colors.white,
          borderWidth: '2px',
          '&:hover': {
            borderColor:
              props.color === 'secondary'
                ? colors.buttonHoverPrimary
                : getBorderColor(props.color),
            backgroundColor:
              props.color === 'secondary'
                ? colors.white
                : colors.buttonHoverPrimary + 'B2',
          },
          '&:active': {
            borderColor: getBorderColor(props.color),
            backgroundColor:
              props.color === 'secondary'
                ? colors.buttonActiveSecondary
                : colors.focusedVisible + 'B2',
          },
        };
      }
      // Generate Contained
      return {
        boxShadow: 'none',
        backgroundColor: colors.buttonHoverPrimary,
        '&:hover': {
          backgroundColor: colors.buttonHoverPrimary,
          boxShadow: 'none',
        },
        '&:active': {
          backgroundColor: colors.focusedVisible,
          boxShadow: 'none',
        },
      };
    },
    underlinedPrimary: {
      color: 'white',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:active': {
        backgroundColor: 'transparent',
        color: colors.focusedVisible,
      },

      '&:hover $linePrimary': {
        backgroundColor: colors.buttonHoverPrimary,
        color: 'red',
      },
      '&:active $linePrimary': {
        backgroundColor: colors.focusedVisible,
        color: 'red',
      },
    },
    underlinedSecondary: {
      color: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '&:active': {
        backgroundColor: 'transparent',
        color: '#1C304A',
      },

      '&:hover $lineSecondary': {
        backgroundColor: colors.shoreGreen,
      },
      '&:active $lineSecondary': {
        backgroundColor: '#1C304A',
      },
    },
    underContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    lineCommon: {
      marginTop: '-2px',
      height: '2px',
      width: '100%',
      transition: 'backgroundColor 250ms',
    },
    linePrimary: {
      backgroundColor: 'white',
    },
    lineSecondary: {
      backgroundColor: theme.palette.primary.main,
    },
    lineDisabled: {
      backgroundColor: 'rgba(0, 0, 0, 0.26)',
    },
  };
});

export type PGEButtonVariant =
  | 'contained'
  | 'outlined'
  | 'underlined'
  | 'tooltip';
export type PGEButtonColor = 'primary' | 'secondary';

export interface PGEButtonProps extends Omit<ButtonProps, 'variant' | 'color'> {
  variant?: PGEButtonVariant;
  color?: PGEButtonColor;
}

const PGEButton = forwardRef<HTMLButtonElement, PGEButtonProps>(
  ({ children, variant, color, className, ...props }, ref) => {
    const styleProps = { variant, color };
    const isUnderlined = variant === 'underlined' || variant === 'tooltip';
    let btnVariant: 'text' | 'outlined' | 'contained' | undefined = 'contained';
    btnVariant = !variant && color === 'secondary' ? 'outlined' : btnVariant;
    btnVariant = variant === 'outlined' ? 'outlined' : btnVariant;
    btnVariant =
      variant === 'underlined' || variant === 'tooltip'
        ? undefined
        : btnVariant;

    const classes = useStyles(styleProps);
    let btnClass: any = classes.primaryButton;
    btnClass = variant === 'underlined' ? classes.underlinedPrimary : btnClass;
    btnClass =
      variant === 'underlined' && color === 'secondary'
        ? classes.underlinedSecondary
        : btnClass;

    return (
      <Button
        variant={btnVariant}
        color={'primary'}
        disableRipple={true}
        disableFocusRipple={true}
        className={clsx(btnClass, className)}
        focusVisibleClassName={'.MuiButton-containedPrimary-Focused'}
        {...props}
        ref={ref}
      >
        {!isUnderlined ? (
          children
        ) : (
          <div className={classes.underContainer}>
            {children}
            <div
              id="line"
              className={clsx(
                classes.lineCommon,
                props && props.disabled
                  ? classes.lineDisabled
                  : color === 'secondary'
                  ? classes.lineSecondary
                  : classes.linePrimary,
              )}
            />
          </div>
        )}
      </Button>
    );
  },
);

export default PGEButton;
