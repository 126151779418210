import { graphql, useStaticQuery } from 'gatsby';
import {
  getTranslationsByLocale,
  UnpackContentfulNodes,
} from '../../hooks/useTranslation';
import { AllContentfulGlobalSettingsQuery } from '../../__generated__/gatsby-types';
import { useContext } from 'react';
import { LanguageContext } from '../../providers/LanguageProvider';

export const useNavigationTranslations = (): UnpackContentfulNodes<AllContentfulGlobalSettingsQuery['allContentfulGlobalSettings']['nodes']> => {
  const data = useStaticQuery<AllContentfulGlobalSettingsQuery>(graphql`
    query AllContentfulGlobalSettings {
      allContentfulGlobalSettings(
        filter: { contentful_id: { eq: "MITp4kshRFb5j2MM90GOm" } }
      ) {
        nodes {
          node_locale
          navigation {
            menus {
              url
              label
              groups {
                mainNavMobileOnly
                label
                link
                links {
                  ... on ContentfulNavigationBlock {
                    label
                    link
                  }
                  ... on ContentfulApplicationPage {
                    url
                    title
                    customerClass
                  }
                  ... on ContentfulNavigationBlockSubItem {
                    label
                    slug: url
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const translatedData = getTranslationsByLocale(
    data.allContentfulGlobalSettings.nodes,
  );

  const { language } = useContext(LanguageContext);
  return translatedData[language];
};
