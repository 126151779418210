import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const richTextStyles = makeStyles((theme: Theme) =>
  createStyles({
    styles: {
      '& h3, & h4': {
        fontSize: '1.25rem',
        letterSpacing: '.01ch',
      },

      '& h3, & h4, & h5, & h6': {
        lineHeight: 1.2,
      },

      '& h4': {
        fontStyle: 'italic',
      },

      '& h5': {
        fontSize: '1.4rem',
        fontWeight: 400,
      },

      '& h6': {
        fontSize: '1rem',
      },

      '& p': {
        fontSize: '1rem',
        lineHeight: 1.4,

        [theme.breakpoints.up('sm')]: {
          fontSize: '1.25rem',
        },
      },

      '& > *:not(hr)': {
        marginTop: '.8em',
      },

      '& hr': {
        border: 0,
        margin: theme.spacing(3, 0, 1),
      },

      '& ul': {
        listStyle: 'disc',
        paddingLeft: theme.spacing(3),
      },

      '& ol': {
        listStyle: 'decimal',
        paddingLeft: theme.spacing(3),
      },

      '& > ul, & > ol': {
        msOverflowStyle: '-ms-autohiding-scrollbar',
        overflowX: 'auto',
      },

      '& li': {
        display: 'list-item',
        paddingLeft: 0,
      },

      '& img': {
        width: '100%',
        marginTop: '1rem',
        border: '1px solid #eee',
      },

      '& code': {
        fontSize: '.8em',
      },
    },
  }),
);

export default richTextStyles;
