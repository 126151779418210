import { getFromStorage } from '../util/storage-utils';

declare global {
  interface Window {
    dataLayer: any;
  }
}

export function updatePersonIDForMedallia(personID?: string) {
  if (typeof window !== 'undefined') {
    if (personID) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer[0] = {
        ...(window.dataLayer[0] || {}),
        userLoginState: 'loggedIn',
        user: { personID },
      };
    }
  }
}

export function setOutageReportedStatusForMedallia(outageRPTStatus = 0) {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer[0] = {
      ...(window.dataLayer[0] || {}),
      outage_rpt: outageRPTStatus,
    };
  }
}

export function postGTMAttributes(path: string): void {
  const personID = getFromStorage('gtmpId');

  if (typeof window !== 'undefined') {
    window.dataLayer?.push({
      pageCategory: path,
      visitoryType: 'high-value',
      userLoginState: personID ? 'loggedIn' : 'loggedOut',
      user: personID ? { personID } : { personID: null },
    });
  }
}
