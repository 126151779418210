import React, { useContext, useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormControlLabel, FormGroup, Switch } from '@material-ui/core';
import { useTranslation } from '../../hooks/useTranslation';
import useQuickLogin from '../../hooks/useQuickLogin';

import colors from '../../themes/main-colors';

export interface Props {
  section: string;
  setSection: (sectionDetails: string) => void;
}
const useStyles = makeStyles((theme: Theme) => ({
  toggleButton: {
    fontSize: '1rem',
    color: colors.sparkBlue
  },
  thumb: {
    backgroundColor: '#27788C',
      width: '16px',
      height: '17px',
      marginTop: '3px'
  },
  track: {
    border: '2px solid #27788C',
    borderRadius: '13px',
    backgroundColor: '#ffffff !important',
    height:'18px',
  },
}));

const SignInToggle = (props: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { handleBack } = useQuickLogin({});
  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.checked
      ? props.setSection('OneTimePay')
      : props.setSection('SignIn');

    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    handleBack();
  };
  return (
    <>
      <FormGroup >
        <FormControlLabel
          control={
            <Switch
              checked={props.section === 'OneTimePay'}
              onChange={handleToggleChange}
              classes={{
                thumb: classes.thumb,
                track: classes.track,
              }}
            />
          }
          label={t('USE_QUICK_LOGIN')}
          className={classes.toggleButton}
        />
      </FormGroup>
    </>
  );
};
export default SignInToggle;
