// adding embed in yourtube url so that it can be used in iframe
export const formatYoutubeUrl = (videoUrl: string): string => {
  let urlToReturn: string = videoUrl;
  const YOUTUBE_URL = 'youtube.com';
  const IFRAME_EMBED_PART = 'embed';
  const regexToMatch = new RegExp('/' + IFRAME_EMBED_PART + '/', 'g');
  const regexToReplace = new RegExp(YOUTUBE_URL + '/', 'g');
  const linkToAppend = YOUTUBE_URL + '/' + IFRAME_EMBED_PART + '/';
  if (videoUrl.match(regexToMatch) === null) {
    urlToReturn = videoUrl.replace(regexToReplace, linkToAppend);
  }
  urlToReturn = urlToReturn.replace('/watch?v=', '/');
  return urlToReturn;
};

export const formatVimeoUrl = (videoUrl: string): string => {
  let urlToReturn: string = videoUrl;
  const VIMEO_URL = 'vimeo.com';
  const IFRAME_VIDEO_PART = 'video';
  const regexToMatch = new RegExp('/' + IFRAME_VIDEO_PART + '/', 'g');
  const regexToReplace = new RegExp(VIMEO_URL + '/', 'g');
  const linkToAppend = 'player.' + VIMEO_URL + '/' + IFRAME_VIDEO_PART + '/';

  if (videoUrl.match(regexToMatch) === null) {
    urlToReturn = videoUrl.replace(regexToReplace, linkToAppend);
  }

  return urlToReturn;
};
