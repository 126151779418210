import { useContext } from 'react';
import { useTranslation } from '../../hooks/useTranslation';
import { NotificationsContext } from '../../providers/NotificationsProvider';

export default () => {
  const { t } = useTranslation();
  const notificationContext = useContext(NotificationsContext);

  const setErrorNotification = (isError: boolean) => {
    notificationContext.setState({
      isOpen: isError,
      message: isError
        ? t('GENERIC_ERROR_NOTIFICATION_MESSAGE_BODY')
        : undefined,
      severity: isError ? 'error' : undefined,
    });
    isError && typeof window !== 'undefined' && window.scrollTo(0, 0);
  };

  return { setErrorNotification };
};
