import React, {
  Context,
  FunctionComponent as FC,
  createContext,
  useEffect,
  useState,
} from 'react';
import useAccountCustomer from '../hooks/useAccountCustomer';
import useAuth from '../hooks/useAuth';
import { PreferredLanguages } from '../util/languageUtil';
import { getFromStorage, setInStorage } from '../util/storage-utils';

// NOTE: The supported languages data point has a dependency to c2m's PERSON.LANGUAGE_CD as well as to contentful's allowed 'locale' values.
// Ideally, we stick to the values that contentful offers since that is the system of record for translations.
// We offer a large language list (PreferredLanguages in languageUtil.ts) to the user which seems to be an overpromise. Values that will eventually tie in here.
export enum SupportedLanguages {
  English = 'en',
  Spanish = 'es',
}
export const DEFAULT_LANGUAGE = SupportedLanguages.English;
export const LANGUAGE_PREFERENCE_CHANGED = 'isLanguagePreferenceChanged';
const LOCALE_KEY = 'locale';
const OVERRIDE_PREF_LANG_KEY = 'overridePrefLang';

/* acccess the language preference from outside of a functional component
   ex: useTranslations: 'hooks can only be called inside the body of a function component'
   Prefer useContext when in functional components
*/
export const getLanguage = (): string => {
  return getFromStorage(LOCALE_KEY) || DEFAULT_LANGUAGE;
};

export interface LanguageContext {
  language: SupportedLanguages;
  handleLanguageChange: (s: string, b1: boolean, b2: boolean) => void;
}

const defaultContext: LanguageContext = {
  language: DEFAULT_LANGUAGE,
  handleLanguageChange: (_s: string, _b1: boolean, _b?: boolean): void =>
    undefined,
};

// eslint-disable-next-line no-redeclare
export const LanguageContext: Context<LanguageContext> = createContext<
  LanguageContext
>(defaultContext);

interface IProps {
  children: any;
}

/** Attempts to map any language to a supported langugage of type SupportedLanguages.
 * Defaults to SupportedLanguages.English if no match.
 *
 * If we add a new translation to the contentful entries, we can add that language value here and it should just work */
const mapLangToSupportedLang = (
  lang: PreferredLanguages | SupportedLanguages | string,
): SupportedLanguages => {
  if (Object.values(SupportedLanguages).includes(lang as SupportedLanguages)) {
    return lang as SupportedLanguages;
  }
  switch (lang) {
    case PreferredLanguages.ENGLISH:
      return SupportedLanguages.English;
    case PreferredLanguages.SPANISH:
      return SupportedLanguages.Spanish;
    default:
      return SupportedLanguages.English;
  }
};

export const LanguageProvider: FC<IProps> = ({ children }) => {
  const [language, setLanguage] = useState<SupportedLanguages>(
    DEFAULT_LANGUAGE,
  );

  const { loading, customer } = useAccountCustomer();
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (loading || !customer?.prefLanguage) {
      return;
    }
    const isOverridePrefLang = getFromStorage(OVERRIDE_PREF_LANG_KEY);
    if (isOverridePrefLang === 'true') {
      // If an authernticated user selects a language other than their C2M
      // pref lang, honor the selection for the duration of the log in.
      return;
    }
    handleLanguageChange(customer?.prefLanguage, false, true);
  }, [loading, customer]);

  const handleLanguageChange = (
    preferredLanguage: string,
    isUserSelectedLanguage: boolean,
    reloadWindowLocation: boolean,
  ) => {
    const supportedLanguage = mapLangToSupportedLang(preferredLanguage);
    const storageLanguage = getFromStorage(LOCALE_KEY);
    if (supportedLanguage === language && language === storageLanguage) {
      return;
    }

    setLanguage(supportedLanguage);
    setInStorage(LOCALE_KEY, supportedLanguage);

    if (isUserSelectedLanguage) {
      setInStorage(LANGUAGE_PREFERENCE_CHANGED, 'true');
      if (isAuthenticated) {
        // If an authernticated user selects a language other than their C2M pref lang, honor the
        // selection for the duration of the log in. This cookei will be deleted on sign out/in.
        setInStorage(OVERRIDE_PREF_LANG_KEY, 'true');
      }
    }

    if (reloadWindowLocation && typeof window !== 'undefined') {
      window.location.reload();
    }
  };

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }
    const queryStringParamLocale = new URLSearchParams(
      window.location.search,
    ).get(LOCALE_KEY);
    const localStorageLocale = getFromStorage(LOCALE_KEY);
    const preferredLanguage =
      queryStringParamLocale || localStorageLocale || DEFAULT_LANGUAGE;
    handleLanguageChange(preferredLanguage, false, false);
  }, []);

  return (
    <LanguageContext.Provider
      value={{ language, handleLanguageChange }}
      children={children}
    />
  );
};

export default LanguageProvider;
