/* tslint:disable:no-invalid-this */

import React, {
  FC,
  createContext,
  useContext,
  useReducer,
  Dispatch,
} from 'react';
import Container from './Container';
import { Animate } from '../../../constants';
import {
  GalleryProps as Props,
  State,
  Action,
  UseSliderContext,
  Reducer,
} from './types.d';

/**
 * Inits
 */

const initState: State = {
  position: 0,
  forward: true,
  prev: 99,
  next: 1,
  total: 99,
};

const GallerySliderContext = createContext<State>(initState);
const GallerySliderDispatch = createContext<Dispatch<Action>>(() => true);

/**
 * State Reducer
 */

const createReducer = (max: number): Reducer => (
  state: State,
  action: Action,
): State => {
  // Calculate position
  const step = (arg: number, ctx: number = state.position): number => {
    if (Math.sign(arg) === 1) {
      return ctx + arg <= max ? ctx + arg : 0;
    }
    return ctx + arg >= 0 ? ctx + arg : max;
  };

  switch (action.type) {
    case Animate.NEXT: {
      return {
        ...state,
        position: step(1),
        forward: true,
        get next() {
          return step(1, this.position);
        },
        get prev() {
          return step(-1, this.position);
        },
      };
    }
    case Animate.PREV: {
      return {
        ...state,
        position: step(-1),
        forward: false,
        get next() {
          return step(1, this.position);
        },
        get prev() {
          return step(-1, this.position);
        },
      };
    }
    default:
      return state;
  }
};

/**
 * Component Provider
 */

const GallerySlider: FC<Props> = ({ children, ...props }) => {
  if (!children || !Array.isArray(children)) {
    return null;
  }

  const max = children.length - 1;

  initState.prev = max;
  initState.total = max + 1;

  const reducer = createReducer(max);
  const [context, dispatch] = useReducer(reducer, initState);

  return (
    <GallerySliderContext.Provider value={context}>
      <GallerySliderDispatch.Provider value={dispatch}>
        <Container {...props}>{children}</Container>
      </GallerySliderDispatch.Provider>
    </GallerySliderContext.Provider>
  );
};

/**
 * Hook
 */

const useSliderContext = (): UseSliderContext => {
  return {
    context: useContext(GallerySliderContext),
    dispatch: useContext(GallerySliderDispatch),
  };
};

export default GallerySlider;
export { useSliderContext };
