import template from 'lodash/template';
import _truncate from 'lodash/truncate';

const interpolateTemplate = /{{([\s\S]+?)}}/g;

export const replaceVariables = (
  value: string,
  variables: Record<string, string | undefined>,
): string => {
  return template(value, {
    interpolate: interpolateTemplate,
  })(variables);
};

export const truncate = (str = '', length = 15) => {
  return _truncate(str, {
    length,
  });
};

//Converts TITLECASE to Titlecase
export const titleCase = (str: string = '') => {
  return str.replace(/\w\S*/g, function(txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export function splitName(fullName: string) {
  const name = fullName.split(',');
  return {
    lastName: name.length > 1 ? name[0] : '',
    firstName:
      name.length > 1
        ? name[1].trim().split(' ').length > 1
          ? name[1].trim().split(' ')[0]
          : name[1].trim()
        : '',

    middleName:
      name.length > 1
        ? name[1].trim().split(' ').length > 1
          ? name[1].trim().split(' ')[1]
          : ''
        : '',
  };
}

export function FormattedFullName(
  firstName: string,
  middleName: string,
  lastName: string,
) {
  return `${lastName}, ${firstName} ${middleName}`;
}
