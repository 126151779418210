import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import overrides from './overrides';
import palette from './palette';
import typography from './typography';

const theme: any = createMuiTheme({
  palette,
  typography,
  overrides,
});

export default responsiveFontSizes(theme, {
  breakpoints: ['sm'],
  disableAlign: true,
  factor: 1.5,
});
