import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

interface StyleProps {
  hasLeftNav?: boolean;
  bgColor?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: (props: StyleProps) => ({
      contain: 'layout',
      width: '100%',
      backgroundColor: props?.bgColor,
      padding: props?.hasLeftNav ? theme.spacing(6.25) : theme.spacing(3.75),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3.75, 2.5),
      },
      marginTop: theme.spacing(7),

      '.CardLayout + & ': {
        marginTop: theme.spacing(14),
      },

      '& > .RichText': {
        marginBottom: theme.spacing(3),
      },
    }),
    container: {
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
      gap: '20px',
      '& .MuiGrid-grid-md-4,.MuiGrid-grid-md-6': {
        display: 'flex',
        [theme.breakpoints.up('sm')]: {
          flex: 1,
        },
      },
    },
    outline: {
      width: 1,
      height: 1,
      position: 'absolute',
      overflow: 'hidden',
    },
  }),
);

export default useStyles;
