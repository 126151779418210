/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useEffect } from 'react';
import { Box, ThemeProvider, CssBaseline, Grid } from '@material-ui/core';
import Backdrop from '../components/backdrop';
import { NotificationsContextProvider } from '../providers/NotificationsProvider';
import TransitionProvider from '../providers/TransitionProvider';
import theme from '../themes/theme';
import ErrorBoundary from '../components/error-boundary/ErrorBoundary';
import { Navigation } from '../components/installer-portal/navigation';
import TranslationLoader from '../components/translation-loader/TranslationLoader';
import Notifications from '../components/notifications';
import InstallerSessionTimeout from '../components/installer-portal/InstallerSessionTimeout';
import { auth } from '../firebase';
import config from '../config';
import mainColors from '../themes/main-colors';

interface Props {
  readonly element: any;
  readonly props: Readonly<{
    pageContext: Record<string, any>;
    pageResources: Record<string, any>;
    location: Record<string, any>;
  }>;
}

const InstallerLayout: React.FC<Props> = ({ element }) => {
  useEffect(() => {
    //Support for multi tenants - https://cloud.google.com/identity-platform/docs/multi-tenancy-authentication#sign_in_with_tenants
    auth.tenantId = config.firebase.installerTenentId;

    return () => {
      auth.tenantId = null;
    };
  }, []);

  const RenderPageComponents: React.FC = () => (
    <Box
      id={'full-height-shim'}
      display={'flex'}
      flexDirection={'column'}
      minHeight="100vh"
    >
      <Box id="pge-header">
        <Navigation />
      </Box>
      <Box id="global-notifications-banner">
        <Notifications />
      </Box>
      <Box flexGrow={1}>{element}</Box>
      <Backdrop />
      <Box>
        <Grid
          css={{
            marginTop: '20px',
            position: 'static',
            left: 0,
            bottom: 0,
            width: '100%',
            height: '85px',
            backgroundColor: mainColors.noirBlur,
            color: mainColors.white,
          }}
        />
      </Box>
    </Box>
  );
  return (
    <ThemeProvider theme={theme}>
      <TransitionProvider>
        <NotificationsContextProvider>
          <CssBaseline />
          <TranslationLoader />
          <ErrorBoundary>
            <InstallerSessionTimeout />
            <RenderPageComponents />
          </ErrorBoundary>
        </NotificationsContextProvider>
      </TransitionProvider>
    </ThemeProvider>
  );
};

export default InstallerLayout;
