import React from 'react';
import { Button, Typography } from '@material-ui/core';
import scrollTo from 'gatsby-plugin-smoothscroll';
import useStyles from './JumpLink.styles';
import RichText, { richTextStyles } from '../../rich-text';
import { HeaderVariant } from './types';

interface JumpLinkProps {
  jumpLinkHeading?: string;
  jumpLinkHeadingType?: string;
  outlineEntries?: any;
  jumpLinkIntroCopy?: any;
  backgroundColor?: any;
}
function JumpLink({
  outlineEntries,
  jumpLinkHeading,
  jumpLinkHeadingType,
  jumpLinkIntroCopy,
  backgroundColor,
}: JumpLinkProps): ElementNode {
  const classes = useStyles({ backgroundColor });
  const richText = richTextStyles();

  const headerTag =
    !jumpLinkHeadingType || !/^h[1-4]$/.test(jumpLinkHeadingType)
      ? ('h2' as HeaderVariant)
      : (jumpLinkHeadingType as HeaderVariant);
  return (
    <article className={classes.root}>
      <div>
        {jumpLinkHeading && (
          <Typography variant={headerTag} className={classes.header}>
            {jumpLinkHeading}
          </Typography>
        )}

        <RichText>{jumpLinkIntroCopy}</RichText>
      </div>
      <div className={classes.content}>
        <ul>
          {outlineEntries?.map((entry: any, index: number) => {
            const anchorId = `#_${entry?.contentful_id}`;
            return (
              <li key={index}>
                <Button
                  data-testid={index}
                  className={classes.text}
                  onClick={() => scrollTo(anchorId)}
                >
                  {entry?.anchorLabel}
                </Button>
              </li>
            );
          })}
        </ul>
      </div>
    </article>
  );
}

export default JumpLink;
