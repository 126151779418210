import React, { FunctionComponent, useEffect, useState, useRef } from 'react';
import { useIsMobile } from '../../util/style-utils';

export interface JotProps {
  src: string;
  isUseMinHeight?: boolean;
}

const Jot: FunctionComponent<JotProps> = props => {
  const { src, isUseMinHeight = false } = props;
  const [height, setHeight] = useState(465);
  const isMobile = useIsMobile();
  const iFrameElement = useRef<HTMLIFrameElement | null>(null);

  const scrollToTopOfIframe = () => {
    //Change the scrollbar position to focus the top of the iframe
    if (iFrameElement.current && typeof window !== 'undefined') {
      window.scrollTo({
        top: iFrameElement.current.offsetTop - 150,
        behavior: 'auto',
      });
    }
  };

  const handlePostMessage = (e: any) => {
    const iframeOrigin = new URL(src).origin;
    const eventSourceOrigin = e.origin;
    const message: {
      action?: string;
      payload?: string;
    } = {};

    // Ex: data: {action: 'submission-completed' ...}
    // OR
    // Ex: data: {action: 'sendUrlHashFragment', payload: 'abcdefghijklmnopqrstuvwxyz1234567890'}
    if (typeof e?.data === 'object' && e?.data !== null && e?.data?.action) {
      message.action = e?.data?.action;
      if (e?.data?.payload) {
        message.payload = e?.data?.payload;
      }
    }
    // Ex: data: 'setHeight:2940:...'
    else if (e.data.split) {
      const args: string[] = e.data.split(':');
      if (!args.length) {
        return;
      }
      message.action = args[0];
      message.payload = args.length > 1 ? args[1] : undefined;
    } else {
      return;
    }

    switch (message.action) {
      case 'setHeight': {
        const extraHeight =
          parseInt(message.payload!) +
          (isUseMinHeight ? 15 : isMobile ? 220 : 150);
        setHeight(extraHeight);
        break;
      }

      case 'scrollIntoView':
        scrollToTopOfIframe();
        break;

      case 'submission-completed':
        scrollToTopOfIframe();
        break;

      case 'reloadPage':
        window.location.reload();
        break;

      // iframe requesting URL hash from the parent page
      case 'requestUrlHashFragment':
        if (iframeOrigin !== eventSourceOrigin) {
          return;
        }
        // Send hash from parent to iframe
        iFrameElement.current?.contentWindow?.postMessage(
          { action: 'sendUrlHashFragment', payload: window.location.hash },
          iframeOrigin,
        );
        break;

      // iframe sending a new URL has to the parent page
      case 'sendUrlHashFragment':
        if (iframeOrigin !== eventSourceOrigin) {
          return;
        }
        if (!message?.payload) {
          console.log('sendUrlHashFragment: missing hash payload');
        }
        console.log('sendUrlHashFragment', message?.payload);
        window.location.hash = message?.payload!;
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (window.addEventListener) {
      window.addEventListener('message', handlePostMessage, false);
    }

    return () => {
      if (window.removeEventListener) {
        window.removeEventListener('message', handlePostMessage, false);
      }
    };
  }, []);

  return (
    <iframe
      src={src}
      frameBorder="0"
      style={{ width: '100%', height: `${height}px`, border: 'none' }}
      scrolling="yes"
      ref={iFrameElement}
      allow="clipboard-write"
    />
  );
};

export default Jot;
