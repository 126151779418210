import React, { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import moment from 'moment';
import Body from '../components/content-body/Body';
import Note from '../components/note';
import PgeContact from '../components/pge-contact';
import SplitHeader from '../components/split-header';
import PullQuote from '../components/pull-quote';
import CTA from '../components/call-to-action';
import PGEAccordion from '../components/accordion';
import Tabset from '../components/tabset';
import Toc from '../components/toc';
import Jot from '../components/jot/';
import MessagingBlock from '../components/messaging-block';
import ContentList from '../components/content-list';
import DocumentList from '../components/document-list';
import MediaWrapper from '../components/media-wrapper';
import Related from '../components/related';
import Overlay from '../components/pge-overlay';
import SlugLink from '../components/slug-link';
import Callout from '../components/callout';
import Column from '../components/column';
import colors from '../themes/main-colors';
import PromoGroup from '../components/promo-group';
import PromoItem from '../components/promo-item';
import TableWrapper from '../components/contentful-table-wrapper';
import PgeButton from '../components/pge-button/PGEButtons';
import AlertNotifications from '../components/alert-notifications';
import { LandingHeroImage } from '../components/contentful-blocks/LandingHeroImage';
import { usePathContext } from '../providers/PathProvider';
import { Field } from '../contentful.d';
import ProgramCard, { ProgramCardProps } from '../components/program-card';
import StaticList, { StaticListProps } from '../components/static-list';
import CTACard, { CtaCardProps } from '../components/cta-card';
import CardLayout, { CardLayoutProps } from '../components/card-layout';
import GallerySlider from '../components/gallery-slider';
import WrapperImage from '../components/wrapper-image';
import SocialFeed from '../components/social-feed';
import JumpLink from '../components/jump-link';
import { Typography } from '@material-ui/core';
import { truncateWithEllipses } from './format';
import RealTimeContent from '../components/real-time-content';
import CerosExperience from '../components/ceros-experience/CerosExperience';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacing: {
      padding: theme.spacing(2, 0),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1.25, 0),
      },
    },
    smallSpacing: {
      padding: theme.spacing(1, 0),
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(0.8, 0),
      },
    },
    iconSize: {
      width: '17.53px !important',
      marginRight: '4px',
      [theme.breakpoints.down('sm')]: {
        width: '17.53px !important',
      },
    },
  }),
);

export const releaseDateFormat = (releaseDate: any) => {
  const month = moment(releaseDate).format('MMMM');
  let monthFormat;

  switch (month) {
    case 'September': {
      monthFormat = 'Sept.';
      break;
    }
    default: {
      monthFormat = month?.length > 4 ? `${month?.substr(0, 3)}.` : month;
    }
  }
  return `${monthFormat} ${moment(releaseDate).format('DD, YYYY')}`;
};

export const OverlayComponent = (props: any) => {
  const { node, children, overlay } = props;
  const { content, entryName, statusAlert } = node?.data?.target || overlay;

  const [open, setOpen] = useState(false);
  const setOverlay = (status: any): any => {
    setOpen(status);
  };
  const statusComponent: any = statusAlert
    ? getStatusAlertComponent(statusAlert)
    : null;
  const contentBody = content ? getBodyComponent(content) : null;
  return (
    <>
      {/* This is an invalid anchor, needs to be a button */}
      <a style={{ cursor: 'pointer' }} onClick={e => setOpen(true)}>
        {children ? children : entryName}
      </a>
      <Overlay
        onOverlay={(status: any) => setOverlay(status)}
        content={contentBody}
        show={open}
        entryName={entryName}
        statusAlert={statusComponent}
      />
    </>
  );
};
// RichTextOption is used outside of this file. Please review impact of any proposed changes.
export const RichTextOption = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node: any, children: any) => (
      <Typography className="pge-contentful-body" variant="body1">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_1]: (node: any, children: any) => (
      <Typography className="pge-contenful-H1" variant="h1">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_2]: (node: any, children: any) => (
      <Typography className="pge-contenful-H2" variant="h2">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_3]: (node: any, children: any) => (
      <Typography className="pge-contenful-H3" variant="h3">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_4]: (node: any, children: any) => (
      <Typography className="pge-contenful-H4" variant="h4">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_5]: (node: any, children: any) => (
      <Typography className="pge-contenful-H5" variant="h5">
        {children}
      </Typography>
    ),
    [BLOCKS.HEADING_6]: (node: any, children: any) => (
      <Typography className="pge-contenful-H6" variant="h6">
        {children}
      </Typography>
    ),
    [BLOCKS.QUOTE]: (node: any, children: any) => (
      <blockquote
        style={{
          margin: '0px 0px 1.3125rem',
          borderLeft: '6px solid rgb(229, 235, 237)',
          paddingLeft: '0.875rem',
        }}
      >
        {children}
      </blockquote>
    ),
    [INLINES.HYPERLINK]: (node: any, children: any) => {
      const pattern = /\.[a-z]+$/i;

      const extension = pattern.exec(node?.data?.uri);
      let img: any = null;
      if (Array.isArray(extension)) {
        switch (extension[0]) {
          case '.pdf': {
            img = '/static/icon_PDF.svg';
            break;
          }
          default: {
            img = !node?.data?.uri?.includes('mailto:')
              ? '/static/icon_external_link.svg'
              : null;
          }
        }
      } else {
        img = !(
          node?.data?.uri?.includes('mailto:') ||
          node?.data?.uri?.includes('tel:') ||
          (node?.data?.uri?.charAt(0) === '/' &&
            node?.data?.uri?.charAt(1) !== '/')
        )
          ? '/static/icon_external_link.svg'
          : null;
      }

      return (
        <a href={node?.data?.uri} target="_blank">
          {children}
          {img && (
            <>
              {' '}
              <img className={useStyles().iconSize} src={img} alt="" />
            </>
          )}
        </a>
      );
    },
    [INLINES.ASSET_HYPERLINK]: (node: any, children: any) => {
      return <RichTextLinkWrapper node={node} children={children} />;
    },
    [INLINES.ENTRY_HYPERLINK]: (node: any, children: any) => {
      if (node?.data?.target?.__typename) {
        const componentType = node?.data?.target?.__typename;
        const { title, slug } = node?.data?.target;
        switch (componentType) {
          case 'ContentfulWrapperExternalLink': {
            const img = !(
              slug?.includes('mailto:') ||
              slug?.includes('tel:') ||
              (slug?.charAt(0) === '/' && slug?.charAt(1) !== '/')
            )
              ? '/static/icon_external_link.svg'
              : null;
            const link = img ? (
              <a href={slug} target="_blank">
                {children}
                {img && (
                  <>
                    {' '}
                    <img
                      className={useStyles().iconSize}
                      src={img}
                      alt={title}
                    />
                  </>
                )}
              </a>
            ) : (
              <SlugLink slug={slug} linkText={children} />
            );
            return <>{link}</>;
          }
          case 'ContentfulPageOverlay': {
            return <OverlayComponent node={node} children={children} />;
          }
          default:
            return <SlugLink slug={slug} linkText={children} />;
        }
      } else {
        return <div>{children}</div>;
      }
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node: any, children: any) => {
      if (node?.data?.target?.__typename) {
        const componentType = node?.data?.target?.__typename;
        switch (componentType) {
          case 'ContentfulElementColumns3': {
            return getColumnComponent(node?.data?.target);
          }
          case 'ContentfulElementColumns2': {
            return getColumnComponent(node?.data?.target);
          }
          case 'ContentfulWrapperImage': {
            return <WrapperImage {...node?.data?.target} />;
          }
          case 'ContentfulElementCallout': {
            return getCallOutComponent(node?.data?.target);
          }
          case 'ContentfulUiTableExtention': {
            if (!node?.data.target) {
              return null;
            }

            return <TableWrapper {...node.data.target} />;
          }
          case 'ContentfulElementPullquote': {
            return getPullQuoteComponent(node?.data?.target);
          }
          case 'ContentfulElementContact': {
            return getContactComponent(node?.data?.target);
          }
          case 'ContentfulWrapperembedMedia': {
            return <MediaWrapper {...node?.data?.target} />;
          }
          case 'ContentfulElementButtonAction': {
            const { button, buttonLink } = node?.data?.target;
            const btnProps: any = getButtonVariable(button, buttonLink);
            return <PgeButton {...btnProps} />;
          }
          case 'ContentfulElementProgramCard': {
            return <ProgramCard {...node?.data?.target} />;
          }
          case 'ContentfulModuleSocialFeed': {
            return <SocialFeed {...node?.data?.target} />;
          }
          default:
            return null;
        }
      } else {
        return <div>{children}</div>;
      }
    },
    [BLOCKS.EMBEDDED_ASSET]: (node: any, children: any) => {
      if (node?.data?.target?.__typename) {
        const { title, description, file } = node.data.target;
        const mimeType = file?.contentType || 'nope/undefined';
        const mimeGroup = mimeType.split('/')[0];

        switch (mimeGroup) {
          case 'image':
            return <img alt={description ? description : ''} src={file?.url} />;
          case 'application':
            return <a href={file?.url}>{title ? title : file?.fileName}</a>;
          default:
            return (
              <span style={{ backgroundColor: 'red', color: 'white' }}>
                {' '}
                {mimeType} embedded asset{' '}
              </span>
            );
        }
      } else {
        return <div>{children}</div>;
      }
    },
  },
};

export const getNavigationObject = () => {
  return { menu: 'test' };
};

export const getBackgroundColor = (color: any | null): any | null => {
  let bgColor = colors.white;
  switch (color) {
    case 'Blue':
      bgColor = colors.lightBlue1;
      break;
    case 'Grey':
      bgColor = colors.lightGray2;
      break;
    default:
      bgColor = colors.white;
      break;
  }
  return bgColor;
};

export const getBodyComponent = (bodyData?: any): any | null => {
  if (!bodyData || bodyData.references?.includes(null)) {
    return null;
  }

  return <Body bodyCopy={renderRichText(bodyData, RichTextOption)} />;
};

const RichTextLinkWrapper = (props: any) => {
  const { node, children } = props;
  const href = node?.data?.target?.file?.url;
  if (!href) {
    return null;
  }

  const contentType = node?.data?.target?.file?.contentType;
  const img: any = contentType ? getIcon(contentType) : null;

  return (
    <a href={href} target="_blank">
      {children}{' '}
      {img && <img className={useStyles().iconSize} src={img} alt="" />}
    </a>
  );
};

export const getNoteComponent = (noteData: any | null): any | null => {
  const noteCopy = noteData?.noteCopy
    ? getBodyComponent(noteData?.noteCopy)
    : null;

  return <Note noteHeading={noteData?.noteHeading} noteCopy={noteCopy} />;
};
export const getTabData = (tabItemArray: any) => {
  const tabsData = tabItemArray?.map((itemObj: any, index: number): any => {
    const formatedData = itemObj?.tabBody
      ? getBodyComponent(itemObj?.tabBody)
      : null;

    let tabIcon = null;
    if (itemObj.tabTitleIcon?.file?.url) {
      tabIcon = (
        <img
          src={itemObj?.tabTitleIcon?.file?.url}
          style={{ width: '28px' }}
          alt={''}
        />
      );
    }
    return {
      label: itemObj.tabTitle,
      data: formatedData,
      icon: tabIcon,
    };
  });
  return tabsData;
};
export const getTabsetComponent = (tabsetData: any | null): any | null => {
  const formatedIntroCopy: any = tabsetData?.tabsetIntroCopy
    ? getBodyComponent(tabsetData?.tabsetIntroCopy)
    : null;
  const tabData = tabsetData.addItems ? getTabData(tabsetData.addItems) : null;

  return (
    tabData && (
      <Tabset
        header={tabsetData?.tabsetHeader}
        headerType={tabsetData?.tabsetHeaderType}
        intro={formatedIntroCopy}
        tabsetImage={tabsetData?.tabsetImage}
        tabData={{
          tabs: tabData,
        }}
        imagePosition={tabsetData.imagePosition}
        backgroundColor={tabsetData?.backgroundColor}
      />
    )
  );
};
export const getTocComponent = (tocData: any | null): any | null => {
  const formatedIntroCopy = tocData?.tocIntroCopy
    ? getBodyComponent(tocData?.tocIntroCopy)
    : null;
  const referenceLinks = tocData?.addTocItems
    ? getReferenceLinks(tocData?.addTocItems)
    : null;
  const chunkedArray = referenceLinks
    ? chunk(referenceLinks, tocData?.distributeAcrossColumns)
    : null;

  return (
    <Toc
      tocImage={tocData?.tocImage}
      colorBackground={tocData?.colorBackground}
      distributeAcrossColumns={tocData?.distributeAcrossColumns}
      tocHeader={tocData?.tocHeader}
      tocHeaderType={tocData?.tocHeaderType}
      tocIntroCopy={formatedIntroCopy}
      addTocItems={chunkedArray}
    />
  );
};
export const getAccordionComponent = (
  accordionData: any | null,
): any | null => {
  return (
    <PGEAccordion
      accordionItems={accordionData?.accordionItems}
      accordionHeader={accordionData?.accordionHeader}
      accordionHeaderType={accordionData?.accordionHeaderType}
      accordionIntroCopy={accordionData?.accordionIntroCopy}
      colorBackground={accordionData?.colorBackground}
    />
  );
};

export const getContactComponent = (contactData: any | null): any | null => {
  const contactCopy = contactData?.contactCopy
    ? getBodyComponent(contactData?.contactCopy)
    : null;

  return (
    <PgeContact
      contactCopy={contactCopy}
      contactName={contactData?.contactName}
      contactEmailAddress={contactData?.emailAddress}
      contactPhoneNumber={contactData?.phoneNumber}
    />
  );
};

export const getColumnComponent = (
  ColumnData: any | null,
  pageTemplate?: Field['Template'],
): any | null => {
  const ColumnLength = ColumnData?.column?.length;
  const output = [];
  const shortDesc = ColumnData?.columnIntroCopy
    ? getBodyComponent(ColumnData?.columnIntroCopy)
    : null;

  for (let i = 0; i < ColumnLength; i++) {
    const callout = getComponent(ColumnData?.column[i], i, '', true);
    output.push(callout);
  }
  return (
    <Column
      header={ColumnData?.columnHeader}
      headerType={ColumnData?.columnHeaderType}
      data={output}
      backGroundColor={ColumnData?.colorBackground}
      shortDesc={shortDesc}
      leftNav={pageTemplate === 'Left Nav'}
    />
  );
};

export const getHeroComponent = (entries: any) => {
  const [hero] = entries?.filter(
    (entry: any) => entry?.__typename === 'ContentfulHeaderhero',
  );
  const btnProps = getButtonVariable(hero?.button, hero?.buttonLink);
  return (
    <>
      {hero && (
        <LandingHeroImage
          image={hero?.image?.file?.url}
          heading={hero?.heading}
          copy={hero?.subheading}
          button={btnProps}
          backgroundColor={hero.backgroundColor}
          backgroundImage={hero.backgroundImage}
        />
      )}
    </>
  );
};
export const getButtonVariable = (btnObj: any, btnLink: any) => {
  let btnJSON = null;
  if (btnObj || btnLink) {
    let btnLinkUrl = null;
    let icon = btnObj?.buttonIcon?.file?.url;
    let target = null;

    switch (btnLink?.__typename) {
      case 'ContentfulWrapperExternalLink': {
        if (btnLink && btnLink?.websiteUrl) {
          btnLinkUrl = btnLink?.websiteUrl;
          target = !(
            btnLink?.websiteUrl?.includes('mailto:') ||
            btnLink?.websiteUrl?.includes('tel:') ||
            (btnLink?.websiteUrl?.charAt(0) === '/' &&
              btnLink?.websiteUrl?.charAt(1) !== '/')
          )
            ? '_blank'
            : null;
        } else {
          btnLinkUrl = btnLink?.slug;
          target = !(
            btnLink?.slug?.includes('mailto:') ||
            btnLink?.slug?.includes('tel:') ||
            (btnLink?.slug?.charAt(0) === '/' &&
              btnLink?.slug?.charAt(1) !== '/')
          )
            ? '_blank'
            : null;
        }
        break;
      }
      case 'ContentfulWrapperMediaAsset': {
        if (typeof btnLink.media !== 'undefined') {
          const { contentType, url } = btnLink?.media?.file;
          icon = contentType ? getIcon(contentType) : icon;
          btnLinkUrl = url;
          target = '_blank';
        }

        break;
      }
      default: {
        const { check, resolve } = usePathContext();

        if (check(btnLink?.slug)) {
          btnLinkUrl = resolve(btnLink?.slug);
        } else {
          btnLinkUrl =
            btnLink?.slug?.charAt(0) === '/'
              ? btnLink?.slug
              : `/${btnLink?.slug}`;
        }
      }
    }

    btnJSON =
      btnLink?.__typename === 'ContentfulPageOverlay'
        ? {
            buttonMode: btnObj?.buttonType
              ? btnObj?.buttonType
              : btnObj?.buttonText
              ? 'Primary'
              : '',
            text:
              btnObj && btnObj?.buttonText ? btnObj?.buttonText : btnLink?.slug,
            icon: icon ? (
              <img src={icon} alt="" className={useStyles().iconSize} />
            ) : null,
            buttonLink: btnLink,
          }
        : {
            buttonMode: btnObj?.buttonType
              ? btnObj?.buttonType
              : btnObj?.buttonText
              ? 'Primary'
              : '',
            text:
              btnObj && btnObj?.buttonText ? btnObj?.buttonText : btnLink?.slug,
            icon: icon ? (
              <img src={icon} alt="" className={useStyles().iconSize} />
            ) : null,
            onClick: btnLinkUrl,
            target: target,
          };
  }
  return btnJSON;
};

export const getCallOutComponent = (CalloutData: any | null): any | null => {
  const shortDesc = CalloutData?.shortDescription
    ? getBodyComponent(CalloutData.shortDescription)
    : null;

  const btnProps = getButtonVariable(
    CalloutData?.button,
    CalloutData?.buttonLink,
  );

  return (
    <Callout
      photo={CalloutData?.image?.file?.url}
      heading={CalloutData?.heading}
      headingType={CalloutData?.headingType}
      shortDescription={shortDesc}
      button={btnProps}
      link={CalloutData?.buttonLink}
      imagePosition={CalloutData.imagePosition}
      backgroundColor={CalloutData.backgroundColor}
    />
  );
};

export const getComponent = (
  itemObj: any | null,
  index: any,
  pageTemplate?: any,
  isFromColumn: boolean = false,
): any | null => {
  const id = itemObj?.contentful_id;
  const classes = useStyles();

  // Prepend an undescore to the id value to ensure that it doesn't start with a digit
  // https://www.w3.org/TR/CSS2/syndata.html
  // 4.1.3 Characters and case
  const anchorId = itemObj?.anchorLabel ? `_${id}` : undefined;
  switch (itemObj?.__typename) {
    case 'ContentfulElementJumpLinks': {
      return (
        <div
          key={index}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
        >
          <JumpLink {...itemObj} />
        </div>
      );
    }

    case 'ContentfulContentBody': {
      return (
        <div
          id={anchorId}
          key={index}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
        >
          {getBodyComponent(itemObj?.bodyCopy)}
        </div>
      );
    }
    case 'ContentfulContentNote': {
      return (
        <div
          id={anchorId}
          className={`spacingComponent ${classes.smallSpacing} ${itemObj.__typename}`}
          key={index}
        >
          {getNoteComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulHeaderSplit': {
      if (index) {
        return (
          <div
            key={index}
            className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          >
            {getSplitHeaderComponent(itemObj)}
          </div>
        );
      } else {
        return <React.Fragment key={index}></React.Fragment>;
      }
    }
    case 'ContentfulHeaderhero': {
      if (index) {
        return (
          <div
            key={index}
            className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          >
            {getHeroComponent([itemObj])}
          </div>
        );
      } else {
        return <React.Fragment key={index}></React.Fragment>;
      }
    }
    case 'ContentfulElementPullquote': {
      return (
        <div
          id={anchorId}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          key={index}
        >
          {getPullQuoteComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulElementCta': {
      if (itemObj.imageBorderColor) {
        return (
          <div id={anchorId} key={`${id}${index}`}>
            <CTACard {...itemObj} />
          </div>
        );
      }
      return (
        <div
          id={anchorId}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          key={`${id}${index}`}
        >
          {getCTAComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulWrapperForm': {
      return (
        <div
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          key={index}
        >
          {getJotFormComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulElementMessagingBlock': {
      return (
        <div
          id={anchorId}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          key={index}
        >
          <MessagingBlock {...itemObj} />
        </div>
      );
    }
    case 'ContentfulModuleContentList': {
      return (
        <div
          id={anchorId}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          key={index}
        >
          {getContentListComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulModuleDocumentList': {
      return (
        <div
          id={anchorId}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          key={index}
        >
          {getDocumentListComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulWrapperImage': {
      return <WrapperImage {...itemObj} key={itemObj.contentful_id + index} />;
    }
    case 'ContentfulElementContact': {
      return (
        <div
          id={anchorId}
          className={`spacingComponent ${classes.smallSpacing} ${itemObj.__typename}`}
          key={index}
        >
          {getContactComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulElementCallout': {
      return (
        <div
          id={anchorId}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          key={index}
        >
          {getCallOutComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulElementColumns2': {
      return (
        <div
          id={anchorId}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          key={index}
        >
          {getColumnComponent(itemObj, pageTemplate)}
        </div>
      );
    }
    case 'ContentfulElementColumns3': {
      return (
        <div
          id={anchorId}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          key={index}
        >
          {getColumnComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulModuleGallery': {
      const { galleryItems } = itemObj;

      return (
        <GallerySlider id={anchorId} key={`${id}${index}`} {...itemObj}>
          {galleryItems}
        </GallerySlider>
      );
    }
    case 'ContentfulModuleRelated': {
      return (
        <div
          id={anchorId}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          key={index}
        >
          {getRelatedComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulModuleAccordion': {
      return (
        <div
          id={anchorId}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          key={index}
        >
          {getAccordionComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulModuleTabset': {
      return (
        <div
          id={anchorId}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          key={index}
        >
          {getTabsetComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulWrapperembedMedia': {
      return (
        <div
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          key={index}
        >
          <MediaWrapper {...itemObj} />
        </div>
      );
    }
    case 'ContentfulModuleToc': {
      return (
        <div
          id={anchorId}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          key={index}
        >
          {getTocComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulModulePromoGroup': {
      const hasProgamCards = (itemObj as CardLayoutProps).addItems?.some(
        item => item?.__typename === 'ContentfulElementProgramCard',
      );

      if (hasProgamCards) {
        return (
          <div id={anchorId} key={`${id}${index}`}>
            <CardLayout
              {...itemObj}
              pageTemplate={pageTemplate}
              bgColor={itemObj?.colorBackground}
            />
          </div>
        );
      }

      return (
        <div
          id={anchorId}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          key={index}
        >
          {getPromoGroupComponent(itemObj, pageTemplate)}
        </div>
      );
    }
    case 'ContentfulContentStatusAlert': {
      return (
        <div className={`spacingComponent ${itemObj.__typename}`} key={index}>
          {getStatusAlertComponent([itemObj])}
        </div>
      );
    }
    case 'ContentfulModulePromoItem': {
      return (
        <div key={index} className={`spacingComponent ${itemObj.__typename}`}>
          {getPromoItemComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulModulePromoVideoItem': {
      return (
        <div key={index} className={`spacingComponent ${itemObj.__typename}`}>
          {getPromoItemComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulElementProgramCard': {
      return (
        <ProgramCard
          id={anchorId}
          key={`${id}${index}`}
          {...(itemObj as ProgramCardProps)}
        />
      );
    }
    case 'ContentfulModuleStaticList': {
      return (
        <StaticList
          id={anchorId}
          key={`${id}${index}`}
          {...(itemObj as StaticListProps)}
        />
      );
    }
    case 'ContentfulUiTableExtention': {
      return (
        <div id={anchorId} key={id + index}>
          <TableWrapper {...itemObj} />
        </div>
      );
    }
    case 'ContentfulModuleSocialFeed': {
      return (
        <SocialFeed
          anchorId={anchorId}
          key={index}
          {...itemObj}
          isFromColumn={isFromColumn}
        />
      );
    }
    case 'ContentfulElementColumns4': {
      return (
        <div
          id={anchorId}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
          key={index}
        >
          {getColumnComponent(itemObj)}
        </div>
      );
    }
    case 'ContentfulCerosExperience': {
      if (!itemObj?.embedCode?.embedCode) {
        return null;
      }
      return (
        <div
          key={index}
          className={`spacingComponent ${classes.spacing} ${itemObj.__typename}`}
        >
          <CerosExperience embedCode={itemObj.embedCode.embedCode} />
        </div>
      );
    }
    case 'ContentfulRealTimePublishing': {
      const entryId = itemObj?.contentful_id;
      return <RealTimeContent key={index} entryId={entryId} />;
    }
    default: {
      return <React.Fragment key={index}></React.Fragment>; // ???
    }
  }
};

export const getSplitHeaderComponent = (
  splitHeaderData: any | null,
): any | null => {
  const description = splitHeaderData?.introContent
    ? getBodyComponent(splitHeaderData?.introContent)
    : null;

  const btnProps = getButtonVariable(
    splitHeaderData?.button,
    splitHeaderData?.buttonLink,
  );

  return (
    <SplitHeader
      image={splitHeaderData?.image?.file?.url}
      video={
        splitHeaderData?.videowrappedMedia?.url ??
        splitHeaderData?.videowrappedMedia?.vimeoEmbedUrl
      }
      largeHeadline={splitHeaderData?.heading}
      paragraphHeadline={splitHeaderData?.subheading}
      description={description}
      button={btnProps}
      backgroundColor={splitHeaderData?.backgroundColor}
    />
  );
};

export const getPullQuoteComponent = (
  pullQuoteData: any | null,
): any | null => {
  return (
    <PullQuote
      image={pullQuoteData?.image?.file?.url}
      statement={pullQuoteData?.textStatement?.textStatement}
      attribution={pullQuoteData?.attributionLine}
      bgColor={pullQuoteData?.colorBackground}
    />
  );
};

export const getCTAComponent = (CTAData: any | null): any | null => {
  const btnProps = getButtonVariable(CTAData?.button, CTAData?.buttonLink);

  return (
    <CTA
      header={CTAData?.heading}
      headingType={CTAData?.headingType}
      description={CTAData?.shortDescription}
      bgMode={CTAData?.displayOptions}
      image={CTAData?.image?.file?.url}
      imagePosition={CTAData?.imagePosition}
      button={btnProps}
    />
  );
};

//Function to create structure of content list items
export const getContentItems = (itemArray: any) => {
  const itemsList: any = [];

  itemArray?.map((item: any) => {
    const description = truncateWithEllipses(
      item?.description ?? item?.descriptionTextNode?.description,
      120,
    );

    itemsList.push([
      item?.date,
      <>
        <Typography>
          <SlugLink slug={item.slug} linkText={item.title} />
        </Typography>
        <Typography color="primary">{description}</Typography>
      </>,
    ]);
  });
  return itemsList;
};

export const getContentListComponent = (
  contentListData: any | null,
): any | null => {
  const Introcopy = contentListData?.contentListIntroCopy
    ? getBodyComponent(contentListData?.contentListIntroCopy)
    : null;

  const btnProps = getButtonVariable(
    contentListData?.button,
    contentListData?.buttonLink,
  );

  return (
    contentListData?.contentItems && (
      <ContentList
        introHeading={contentListData?.contentListHeader}
        introHeadingType={contentListData?.contentListHeaderType}
        introCopy={Introcopy}
        rows={getContentItems(contentListData?.contentItems)}
        button={btnProps}
        columnBackground="None"
        bordered={true}
      />
    )
  );
};

export const getIcon = (contentType: any) => {
  switch (contentType) {
    case 'application/pdf': {
      return '/static/icon_PDF.svg';
    }
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
      return '/static/icon_word.svg';
    }
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
      return '/static/icon_excel.svg';
    }
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
      return '/static/icon_powerpoint.svg';
    }
    default: {
      return null;
    }
  }
};
export const getDocumentListLinkArray = (itemArray: any) => {
  const itemsList: any = [];
  itemArray?.map((item: any) => {
    const src = item?.file?.contentType
      ? getIcon(item?.file?.contentType)
      : null;
    itemsList.push({
      title: item.title,
      url: item?.file?.url,
      src: src,
      target: src ? '_blank' : '_self',
    });
  });
  return itemsList;
};

export const getDocumentListComponent = (
  documentListData: any | null,
): any | null => {
  const Introcopy = documentListData?.documentListIntroCopy
    ? getBodyComponent(documentListData?.documentListIntroCopy)
    : null;
  return (
    <DocumentList
      header={documentListData?.documentListHeader}
      headerType={documentListData?.documentListHeaderType}
      intro={Introcopy}
      lists={getDocumentListLinkArray(documentListData?.documents)}
    />
  );
};

export const getReferenceLinks = (graphqlObj: any | null): any | null => {
  return graphqlObj?.map((itemObj: any, index: number): any => {
    const componentType = itemObj?.__typename;
    switch (componentType) {
      case 'ContentfulWrapperMediaAsset': {
        const { title, file, description } = itemObj?.media;
        const src = file?.contentType ? getIcon(file?.contentType) : null;

        return {
          title: itemObj?.entryName,
          subTitle: description,
          link: file?.url ? file?.url : '#',
          icon: {
            src: src,
            altText: description ? description : itemObj?.entryName,
          },
        };
      }
      case 'ContentfulWrapperExternalLink': {
        const img = !(
          itemObj?.slug?.includes('mailto:') ||
          itemObj?.slug?.includes('tel:') ||
          (itemObj?.slug?.charAt(0) === '/' && itemObj?.slug?.charAt(1) !== '/')
        )
          ? '/static/icon_external_link.svg'
          : null;
        const icon = {
          src: img,
          altText: itemObj?.title ? itemObj?.title : itemObj?.slug,
        };
        return {
          title: itemObj?.title ? itemObj?.title : itemObj?.slug,
          subTitle: itemObj?.subtitle,
          link: itemObj?.slug ? itemObj?.slug : '#',
          icon: icon?.src ? icon : null,
        };
      }
      case 'ContentfulPageOverlay': {
        return {
          title: (
            <OverlayComponent
              overlay={{
                content: itemObj?.content,
                entryName: itemObj?.entryName,
                statusAlert: itemObj?.statusAlert,
              }}
            />
          ),
          pageType: componentType,
        };
      }
      default: {
        return {
          title: itemObj?.title ? itemObj?.title : itemObj?.entryName,
          subTitle: itemObj?.subtitle,
          link: itemObj.slug ? itemObj.slug : '#',
        };
      }
    }
  });
};

export const chunk = (array: any, size: string) => {
  const numericSize: number = size ? parseInt(size) : 2;
  const chunked_arr = [];
  const copied = [...array]; // ES6 destructuring
  const numOfChild = Math.ceil(copied.length / numericSize); // Round up to the nearest integer
  for (let i = 0; i < numericSize; i++) {
    chunked_arr.push(copied.splice(0, numOfChild));
  }
  return chunked_arr;
};
export const getJotFormComponent = (formData: any | null): any | null => {
  return (
    <Jot src={formData.formUrl} isUseMinHeight={formData.isEmbeddedForm} />
  );
};
export const getRelatedComponent = (relatedData: any | null): any | null => {
  const formatedLegacy = relatedData?.legacySupplementalLinks
    ? getBodyComponent(relatedData?.legacySupplementalLinks)
    : null;

  const formatedIntroCopy = relatedData?.relatedLinksIntroCopy
    ? getBodyComponent(relatedData?.relatedLinksIntroCopy)
    : null;

  const referenceLinks = relatedData?.referenceLInks
    ? getReferenceLinks(relatedData?.referenceLInks)
    : null;
  const chunkedArray = referenceLinks
    ? chunk(referenceLinks, relatedData?.distributeAcrossColumns)
    : null;
  return (
    <Related
      entryName={relatedData.entryName}
      relatedLinksHeader={relatedData.relatedLinksHeader}
      relatedLinksIntroCopy={formatedIntroCopy}
      headerIntroAlignment={
        relatedData.headerIntroAlignment !== null
          ? relatedData.headerIntroAlignment
          : true
      }
      relatedLinksHeaderType={relatedData.relatedLinksHeaderType}
      backgroundColor={relatedData.backgroundColor}
      referenceLinks={chunkedArray}
      distributeAcrossColumns={relatedData.distributeAcrossColumns}
      legacySupplementalLinks={formatedLegacy}
    />
  );
};

export const getStatusAlertComponent = (
  statusAlertData: any | null,
): any | null => {
  if (Array.isArray(statusAlertData)) {
    return statusAlertData.map((notification: any, index: number) => {
      const alertCopy = notification?.alertCopy
        ? getBodyComponent(notification?.alertCopy)
        : null;

      return (
        <AlertNotifications
          key={index}
          message={alertCopy}
          severity={notification?.alertIconType?.toLowerCase()}
          backgroundColor={notification?.backgroundColor}
          hideTheCloseAction={notification?.hideTheCloseAction}
        />
      );
    });
  } else if (statusAlertData) {
    const alertCopy = statusAlertData?.alertCopy
      ? getBodyComponent(statusAlertData?.alertCopy)
      : null;

    return (
      <AlertNotifications
        message={alertCopy}
        severity={statusAlertData?.alertIconType?.toLowerCase()}
        backgroundColor={statusAlertData?.backgroundColor}
      />
    );
  } else {
    return <></>;
  }
};

export const getPromoGroupComponent = (
  promoGroupData: any | null,
  pageTemplate?: Field['Template'],
): any | null => {
  const promoIntroCopy = promoGroupData?.promoIntroCopy
    ? getBodyComponent(promoGroupData?.promoIntroCopy)
    : null;

  const promoItems: any = [];
  promoGroupData?.addItems?.map((item: any) => {
    promoItems.push(getPromoItemComponentPropsObject(item));
  });

  return (
    <PromoGroup
      promoItems={promoItems}
      header={promoGroupData?.promoHeader}
      headerType={promoGroupData?.promoHeaderType}
      desc={promoIntroCopy}
      bgColor={promoGroupData?.colorBackground}
      leftNav={pageTemplate === 'Left Nav'}
    />
  );
};

export const getPromoItemComponentPropsObject = (
  promoItemData: any | null,
): any | null => {
  let mediaType;
  let promoLink;
  let promoHeaderLink = null;

  const description = promoItemData?.description
    ? getBodyComponent(promoItemData?.description)
    : null;

  if (promoItemData?.image?.file?.url) {
    mediaType = 'image';
  } else if (
    promoItemData?.videowrappedImage?.url ??
    promoItemData?.videowrappedImage?.vimeoEmbedUrl
  ) {
    mediaType = 'video';
  } else {
    mediaType = '';
  }

  if (mediaType === 'image') {
    promoLink = promoItemData?.promoLInk;
  } else if (mediaType === 'video') {
    promoLink = promoItemData?.promoLink;
  } else {
    promoLink = promoItemData?.promoLInk ?? promoItemData?.promoLink;
  }

  switch (promoLink?.__typename) {
    case 'ContentfulWrapperMediaAsset': {
      const { file } = promoLink?.media;
      promoHeaderLink = file ? file?.url : null;
      break;
    }
    case 'ContentfulPageOverlay': {
      promoHeaderLink = promoLink;
      break;
    }
    default: {
      promoHeaderLink = promoLink ? promoLink?.slug : null;
    }
  }
  const mediaURL =
    mediaType === 'image'
      ? promoItemData?.image?.file?.url
      : mediaType === 'video'
      ? promoItemData?.videowrappedImage?.url ??
        promoItemData?.videowrappedImage?.vimeoEmbedUrl
      : '';

  const vimeoDownloadUrl =
    mediaType === 'video' && promoItemData?.videowrappedImage?.vimeoDownloadUrl;

  return {
    header: promoItemData?.heading,
    headerType: promoItemData?.headingType,
    description: description,
    mediaType: mediaType,
    mediaURL: mediaURL,
    link: promoHeaderLink,
    imageLink: promoItemData?.imageLink,
    imagePosition: promoItemData?.imagePosition,
    vimeoDownloadUrl,
  };
};

export const getPromoItemComponent = (
  promoItemData: any | null,
): any | null => {
  const promoItem = getPromoItemComponentPropsObject(promoItemData);

  return (
    <PromoItem
      header={promoItem?.heading}
      headerType={promoItem?.headingType}
      description={promoItem?.description}
      mediaType={promoItem?.mediaType}
      mediaURL={promoItem?.mediaURL}
      link={promoItem?.link}
      imageLink={promoItem?.imageLink}
      imagePosition={promoItem?.imagePosition}
      vimeoDownloadUrl={promoItem?.vimeoDownloadUrl}
    />
  );
};

export const getEntriesComponent = (
  graphqlObj: any,
  pageTemplate: any,
): any | null => {
  const result = [];
  for (let i = 0; i <= graphqlObj.length; i++) {
    result.push(getComponent(graphqlObj[i], i, pageTemplate));
  }
  const entriesComponent = <React.Fragment>{result}</React.Fragment>;
  return entriesComponent;
};

export const renderPageComponents = (props: any) => {
  const entriesData = props ? props.entriesData : null;
  const pageTemplate = props ? props?.pageTemplate : null;

  if (entriesData) {
    const entriesComponent = getEntriesComponent(entriesData, pageTemplate);
    return <>{entriesComponent}</>;
  } else {
    return null;
  }
};
