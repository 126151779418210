import { ComponentPropsWithoutRef } from 'react';
import {
  ListItemFragment,
  StaticListFragment,
} from '../../../__generated__/gatsby-types';
import { Entry, Field } from '../../../contentful.d';
import { Variant } from '@material-ui/core/styles/createTypography';

export const ItemOmits = ['aria-label', 'style', 'tabTeaser'] as const;

export const ListOmits = ['aria-label', 'style'] as const;

interface ItemBase
  extends ListItemFragment,
    ComponentPropsWithoutRef<'section'> {
  readonly length: number;
  readonly pos: number;
  readonly tabTitleHeaderType?: HeaderVariant;
}

interface ListBase
  extends StaticListFragment,
    ComponentPropsWithoutRef<'article'> {
  readonly colorBackground?: Field['Background'];
  readonly imagePosition?: Field['Position'];
}

export type ListItemProps = Partial<Omit<ItemBase, typeof ItemOmits[number]>>;

export type StaticListProps = Partial<Omit<ListBase, typeof ListOmits[number]>>;

type HeaderVariant = Extract<Variant, 'h1' | 'h2' | 'h3' | 'h4'>;
