import React, { useContext, useEffect, useState } from 'react';
import BroadcastCommunicationBanner from './BroadcastCommunicationBanner';
import RichText, { richTextStyles } from '../rich-text';
import { LanguageContext } from '../../providers/LanguageProvider';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../themes/main-colors';
import useContentMgmt from '../../hooks/useContentMgmt';

const useStyles = makeStyles(() =>
  createStyles({
    globalBannerLink: {
      '& a, & .MuiButton-label': {
        color: colors.black,
      },
    },
  }),
);

const BroadcastCommunication = () => {
  const classes = useStyles();
  const richText = richTextStyles();
  const { language } = useContext(LanguageContext);
  const { contentfulClient } = useContentMgmt();
  const infoBannerStyle = {
    backgroundColor: colors.white,
    color: colors.black,
  };

  const [data, setData] = useState<any>();

  const getData = async () => {
    try {
      const entries = await contentfulClient.getEntries({
        content_type: 'broadcastMessage',
        'sys.id[ne]': '1yTL9ZuX2x3V8UorolfVQK', // Exclude placeholder entry
        locale: language,
      });
      setData(entries);
    } catch (e) {
      console.error('error', e);
    }
  };

  useEffect(() => {
    void getData();
  }, []);

  if (!data) {
    return null;
  }

  return (
    <>
      {data?.items?.map((message: any) => (
        <BroadcastCommunicationBanner
          data-testid="broadcast-banner"
          key={message.sys.id}
          style={
            message.fields.messageType?.toLowerCase() === 'info'
              ? infoBannerStyle
              : {}
          }
          severity={message.fields.messageType?.toLowerCase()}
          messageBody={
            message.fields.messageBody && (
              <RichText
                className={`${
                  message.messageType?.toLowerCase() === 'info'
                    ? classes.globalBannerLink
                    : ''
                } RichText ${richText.styles}`}
              >
                {message.fields.messageBody}
              </RichText>
            )
          }
          isEaseIn
        />
      ))}
    </>
  );
};

export default BroadcastCommunication;
