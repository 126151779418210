import React from 'react';

export default function usePrevious<V>(value: V): V | null {
  const ref = React.useRef<V | null>(null);

  const previous = ref.current;
  ref.current = value;

  return previous;
}
