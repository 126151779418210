import React, { FunctionComponent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    embedMedia: {
      padding: theme.spacing(2.5, 0),
      '& > iframe': {
        maxHeight: 576,
        width: '100%',
        borderRadius: 5,
        height: 418,
        [theme.breakpoints.down('sm')]: {
          height: 275,
        },
      },
    },
  }),
);

export interface mapProps {
  src?: string;
}

const Map: FunctionComponent<mapProps> = props => {
  const { src } = props;
  const classes = useStyles();

  return (
    <div className={classes.embedMedia}>
      <iframe
        src={src}
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen={false}
        aria-hidden={false}
        tabIndex={0}
      ></iframe>
    </div>
  );
};

export default Map;
