import { Tokens } from '../../types';
import { User } from 'firebase/auth';
import jwtDecode from 'jwt-decode';
import { FirebaseError } from 'firebase/app';

export class FirebaseAuthAdapterBase {
  protected async buildToken(user: User): Promise<Tokens> {
    const accessToken = await user.getIdToken();
    const idToken = await user.getIdToken(true);
    const refreshToken = user.refreshToken;

    const decodedToken: any = jwtDecode(idToken);

    return {
      accessToken: accessToken
        ? {
            token: accessToken,
            payload: decodedToken,
          }
        : null,
      idToken: {
        token: idToken,
        payload: decodedToken,
      },
      expiresAt: decodedToken.exp,
      refreshToken: refreshToken,
    };
  }

  protected getError(err: unknown): Error {
    const error = new Error('Http error');

    if (err instanceof FirebaseError) {
      //Firebase error contains customData for handling MFA, hence returning same error
      //error.message = err.code;
      err.message = err.code;
      return err;
    }
    return error;
  }
}
