import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import colors from '../../themes/main-colors';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      color: 'inherit',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      padding: 0,
      '& a:link': {
        textDecoration: 'underline',
      },
      '& a:hover': {
        textDecoration: 'underline',
      },
      '& .button a': {
        textDecoration: 'none',
      },
      '& .button a > span': {
        color: 'inherit',
      },
      '& img': {
        maxWidth: '100%',
      },
      '& li > p': {
        margin: '5px 0px',
      },
      '& ul': {
        fontSize: theme.spacing(2.5),
        [theme.breakpoints.down('sm')]: {
          fontSize: theme.spacing(2),
        },
      },
      '& ol': {
        fontSize: theme.spacing(2.25),
        [theme.breakpoints.down('sm')]: {
          fontSize: theme.spacing(1.75),
        },
      },
      [theme.breakpoints.down('sm')]: {
        '& img': {
          width: '100%',
        },
      },
    },
  }),
);

interface IProps {
  bodyCopy: any;
}

const Body = (props: IProps): any => {
  const classes = useStyles();
  const { bodyCopy } = props;

  return <Container className={classes.content}>{bodyCopy}</Container>;
};

export default Body;
