import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import colors from '../../themes/main-colors';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component={'div'}
      role={'tabpanel'}
      hidden={value !== index}
      id={`tabpanel-${index}`}
      data-testid={`tabpanel-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = (tabBackgroundOverride?: string, tabHeight?: string) =>
  makeStyles((theme: Theme) => ({
    root: {
      flexGrow: 1,
      width: '100%',
      border: `1px solid ${colors.white}`,
      backgroundColor: theme.palette.background.paper,
      '& .MuiTabs-flexContainer > .MuiTab-labelIcon': {
        minHeight: theme.spacing(6),
      },
      '& .MuiTab-labelIcon .MuiTab-wrapper > *:first-child': {
        marginBottom: 0,
        maxWidth: 33.48,
      },
      '& .MuiTab-labelIcon .MuiTab-wrapper': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: '-webkit-box',
        '-webkit-line-clamp': 2,
        '-webkit-box-orient': 'vertical',
      },
      '& .WrapperImage': {
        '& .MuiButton-root': {
          textDecoration: 'none',
        },
      },
    },
    indicator: {
      display: 'none',
    },
    topHeaderRadius: {
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
      boxShadow: 'none',
    },
    pgeBorderShadow: {
      boxShadow: theme.shadows[5],
    },
    pgeTab: {
      height: tabHeight ? tabHeight : '',
      fontSize: '1rem',
      fontWeight: 500,
      color: colors.sparkBlue,
      background: tabBackgroundOverride ?? colors.cloudGrey2,
    },
    pgeSelectedTabNav: {
      fontWeight: 700,
      color: `${colors.noirBlur} !important`,
      background: colors.white,
    },
  }));

export type TabProp = {
  label?: string;
  icon?: JSX.Element;
  data: JSX.Element;
};

export interface TabProps {
  tabs: TabProp[];
  variant?: 'standard' | 'scrollable' | 'fullWidth';
  onChange?: (index: number) => void;
  selectedIndex?: number;
  tabHeight?: string;
  tabBackgroundOverride?: string;
}

export default (props: TabProps) => {
  const { onChange, selectedIndex, tabBackgroundOverride, tabHeight } = props;
  const classes = useStyles(tabBackgroundOverride, tabHeight)();
  const [stateValue, setValue] = React.useState(selectedIndex || 0);

  const handleChange = (_: React.ChangeEvent<{}>, newValue: number) => {
    if (onChange) {
      onChange(newValue);
    }
    setValue(newValue);
  };

  // Use selectedIndex if its passed
  const value = selectedIndex === undefined ? stateValue : selectedIndex;

  return (
    <div className={classes.root}>
      <AppBar
        position={'static'}
        color={'default'}
        style={{ boxShadow: 'none' }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant={props.variant || 'fullWidth'}
          scrollButtons={'on'}
          textColor={'primary'}
          aria-label={'tabs'}
          classes={{ indicator: classes.indicator }}
        >
          {props.tabs.map((tab, idx) => (
            <Tab
              key={idx}
              {...tab}
              {...a11yProps(idx)}
              disableRipple
              disableFocusRipple
              className={`${value === idx && classes.pgeSelectedTabNav} 
              ${classes.pgeTab}`}
            />
          ))}
        </Tabs>
      </AppBar>
      {props.tabs.map((tab, idx) => (
        <TabPanel value={value} index={idx} key={idx}>
          {tab.data}
        </TabPanel>
      ))}
    </div>
  );
};
